import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Pawn1 from "../../../assets/svg/Pawn1.svg";
import Pawn2 from '../../../assets/svg/Pawn2.svg';
import Pawn3 from '../../../assets/svg/Pawn3.svg';
import Pawn4 from '../../../assets/svg/Pawn4.svg';
import Pawn5 from '../../../assets/svg/Pawn5.svg';
import Pawn6 from '../../../assets/svg/Pawn6.svg';
import { gameScreenDimentions, getLeaveStatus } from "../../../helper/globalFunctions";
import { buttonClickSound } from "../../../helper/playSound";
import ApiConstants from "../../../themes/apiConstants";
import strings from "../../../themes/constants/strings";
import appImages from "../../../themes/appImages/appImages";
import { spacing } from "../../../themes/constants/spacing";
import Spacer from "../Spacer";
import styles from './styles';
import { url } from "../../../redux/axios/apikit";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { popupType } from "../../../themes/constants/gameConstants";
import ButtonCustom from "../ButtonCustom";
import fontSizes from "../../../themes/constants/fontSizes";
import { socket } from "../../../helper/socket";
import { isInternetConnected } from "../../../helper/networkUtils";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";

const width = gameScreenDimentions.width;
const ShowUserDetails = (props) => {
    const dispatch = useDispatch()
    const showUserDetail = useSelector((state) => state.homeReducer.showUserDetail);
    const roomData = useSelector((state) => state.homeReducer.roomData);
    const userId = useSelector((state) => state.authenticationReducer.userId);
    const blockedUsers = useSelector((state) => state.homeReducer.blockedUsersList.blockList);
    const isSpectator = useSelector((state) => state.homeReducer.isSpectator);
    const [tiles, setTiles] = React.useState([])
    const [disabled, setDisabled] = React.useState(true);
    const [isOn, setIsOn] = React.useState(true);
    const [isOnBlock, setIsOnBlock] = React.useState(true);
    const [disableButton, setDisableButton] = React.useState(false);

    React.useEffect(() => {
        if (roomData && showUserDetail) {
            setDisableButton(false);
            if (blockedUsers.length > 0) {
                blockedUsers.map(i => {
                    if (roomData?.players[showUserDetail.index]?.user?._id === i._id) {
                        setDisableButton(true);
                    }
                })
            } else {
                setDisableButton(false);
            }
            let reportedByArray = roomData?.players[showUserDetail.index]?.reportedBy;
            setDisabled(reportedByArray?.includes(userId));
            updateTiles(roomData, showUserDetail.index)
        }
    }, [roomData, showUserDetail, blockedUsers])

    const isDisabled = () => {
        let reportedByArray = roomData?.players[showUserDetail.index]?.reportedBy;
        return
    }

    const updateTiles = (roomData, index) => {
        let tempArray = []
        tempArray.push({
            id: 1,
            image: appImages.roundArrowWithOne,
            count: roomData?.players[index]?.tokens?.privilege,
        })
        // tempArray.push({
        //     id: 2,
        //     image: appImages.justiceRound,
        //     count: roomData?.players[index]?.tokens?.justice,
        // })
        // tempArray.push({
        //     id: 3,
        //     image: appImages.hands,
        //     count: roomData?.players[index]?.tokens?.power,
        // })
        tempArray.push({
            id: 4,
            image: appImages.homeDollarRound,
            count: roomData?.players[index]?.tiles?.home,
        })
        tempArray.push({
            id: 5,
            image: appImages.multiHomeRound,
            count: roomData?.players[index]?.tiles?.realEstate,
        })
        tempArray.push({
            id: 6,
            image: appImages.skill,
            count: roomData?.players[index]?.tiles?.skill,
        })
        tempArray.push({
            id: 7,
            image: appImages.degree,
            count: roomData?.players[index]?.tiles?.degree,
        })
        tempArray.push({
            id: 8,
            image: appImages.startup,
            count: roomData?.players[index]?.tiles?.startUp,
        })
        tempArray.push({
            id: 9,
            image: appImages.bag,
            count: roomData?.players[index]?.tiles?.job,
        })
        tempArray.push({
            id: 10,
            image: appImages.moneyBag,
            count: roomData?.players[index]?.tiles?.business,
        })

        setTiles(tempArray)
    }

    const pawnImage = () => {
        if (showUserDetail.playerNum === 'Red') {
            return (
                <img src={Pawn1} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />

            )
        }
        else if (showUserDetail.playerNum === 'Yellow') {
            return (
                <img src={Pawn2} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />
            )
        } else if (showUserDetail.playerNum === 'Orange') {
            return (
                <img src={Pawn3} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />
            )
        } else if (showUserDetail.playerNum === 'Green') {
            return (
                <img src={Pawn4} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />
            )
        } else if (showUserDetail.playerNum === 'Purple') {
            return (
                <img src={Pawn5} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />
            )
        } else {
            return (
                <img src={Pawn6} alt="React Logo" style={{
                    width: gameScreenDimentions.gameBoardWidth * 0.015,
                    height: gameScreenDimentions.gameBoardWidth * 0.015,
                }} />
            )
        }
    }

    return (
        <>
            {showUserDetail &&
                <div style={{ padding: 15 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: width * 0.042,
                            right: width * 0.012,
                            zIndex: 9,
                            display: 'flex',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            buttonClickSound();
                            dispatch({
                                type: ApiConstants.SHOW_USER_DETAIL,
                                openRuleBook: null
                            })
                        }}>
                        <img src={appImages.close} style={{ width: 32, height: 32 }} />
                    </div>
                    <div style={styles.modalView} >
                        <div style={{
                            flexDirection: 'row',
                            width: '100%',
                            display: 'flex',
                        }}>
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <span style={styles.fontTextHeading}>Name: <span style={styles.fontText}>{roomData?.players[showUserDetail.index]?.user?.name}</span></span>
                                <Spacer height={spacing.scH1cent} />
                                <span style={styles.fontTextHeading}>Role: <span style={styles.fontText}>{roomData?.players[showUserDetail.index]?.role}</span></span>
                                <Spacer height={spacing.scH1cent} />
                                <div style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                }}>
                                    <span style={styles.fontTextHeading}>{strings.pawn}</span>
                                    <div
                                        style={{
                                            alignItems: 'center', justifyContent: "center",

                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                        {pawnImage()}
                                    </div>
                                </div>
                                <Spacer height={spacing.scH1cent} />
                                <div style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                }}>
                                    <span style={styles.fontTextHeading}>Skip Turn: <span style={styles.fontText}>{roomData?.players[showUserDetail.index]?.skipTurn}</span></span>
                                </div>
                                <Spacer height={spacing.scH1cent} />
                            </div>
                            <div style={{
                                alignItems: 'center', paddingTop: 3.4,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <div style={styles.imageViewStyle} >
                                    <img
                                        style={styles.profileImageStyle} src={`${url}${roomData?.players[showUserDetail.index]?.user?.profileImage}`} />
                                </div>

                            </div>
                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                            <span style={styles.fontTextHeading}>{strings.tilesAndTOkens}</span>
                            <Spacer height={spacing.scH1cent} />
                            <div
                                style={{
                                    alignItems: 'flex-start', justifyContent: "center",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                <div style={{
                                    width: width * 0.215,
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto auto auto auto'
                                }}>
                                    {
                                        tiles.map((item, index) => {
                                            return (
                                                <div style={{
                                                    position: 'relative',
                                                    marginRight: 8, //index < tiles.length - 1 ? 9.2 : 0,
                                                    marginBottom: index % 2 !== 0 ? 8 : 0,
                                                }} >
                                                    <img
                                                        style={{
                                                            objectFit: 'contain',
                                                            width: gameScreenDimentions.width * 0.035,
                                                            height: gameScreenDimentions.width * 0.035,
                                                        }}
                                                        src={item.image}
                                                    />
                                                    {item.count > 0 && <div style={{
                                                        position: 'absolute',
                                                        backgroundColor: 'black',
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: 10,
                                                        top: gameScreenDimentions.width * 0.0,
                                                        left: gameScreenDimentions.width * 0.0,
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}><span style={{
                                                        fontWeight: '700',
                                                        fontSize: 10,
                                                        textAlign: 'center',
                                                        color: '#ffffff',
                                                        paddingTop: 1.4,
                                                    }}>{item.count}</span></div>}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {((roomData?.players[showUserDetail.index]?.user?._id !== userId) && !isSpectator && roomData?.host === userId && roomData?.players[showUserDetail.index]?.type !== "computer") &&
                                <>
                                    <Spacer height={spacing.scH2cent} />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <ButtonCustom
                                            resize={"cover"}
                                            outerContainer={{ height: 40, width: 70, alignSelf: 'center' }}
                                            disabled={disabled}
                                            onPress={() => {
                                                const checkAllowed = getLeaveStatus()
                                                if (isInternetConnected() && checkAllowed) {
                                                    if (isOn) {
                                                        setIsOn(false)
                                                        socket.emit('report', roomData?.players[showUserDetail.index]?.user?._id);
                                                        setTimeout(() => {
                                                            setIsOn(true)
                                                        }, 2500)
                                                    }
                                                } else {
                                                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                                                }
                                            }}
                                            disabledStyle={{
                                                marginTop: 0,
                                                height: 20,
                                                width: 70,
                                                borderRadius: 4,
                                            }}
                                            textColor={{
                                                fontSize: fontSizes.f35, padding: 2
                                            }}
                                            style={{ height: 28, width: 70, borderRadius: 4 }}
                                            imgBg={appImages.buttonBg}
                                            title={strings.report}
                                            className={disabled ? 'img_disabled' : ''}
                                        />
                                        <ButtonCustom
                                            resize={"cover"}
                                            outerContainer={{ height: 40, width: 70, alignSelf: 'center', marginLeft: 24 }}
                                            onPress={() => {
                                                if (!disableButton) {
                                                    const checkAllowed = getLeaveStatus()
                                                    if (isInternetConnected() && checkAllowed) {
                                                        if (isOnBlock) {
                                                            setIsOnBlock(false)
                                                            socket.emit('blockUser', roomData?.players[showUserDetail.index]?.user?._id);
                                                            setTimeout(() => {
                                                                setIsOnBlock(true)
                                                            }, 2500)
                                                        }
                                                    } else {
                                                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                                                    }
                                                }
                                            }}
                                            disabledStyle={{
                                                marginTop: 0,
                                                height: 20,
                                                width: 70,
                                                borderRadius: 4,
                                            }}
                                            textColor={{
                                                fontSize: fontSizes.f35, padding: 2
                                            }}
                                            style={{ height: 28, width: 70, borderRadius: 4 }}
                                            imgBg={appImages.buttonBg}
                                            title={'Block'}
                                            className={disableButton ? 'img_disabled' : ''}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ShowUserDetails;

