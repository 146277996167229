import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScreenDimentions, getCardType } from "../../../helper/globalFunctions";
import { buttonClickSound, cardMovementSound } from "../../../helper/playSound";
import CardComponent from "../CardComponent";
import styles from './styles';
import { useAnimation } from 'framer-motion';
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import { socket } from '../../../helper/socket';
import ApiConstants from "../../../themes/apiConstants";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { popupType } from "../../../themes/constants/gameConstants";

const MoveCardComponent = (props) => {
    const card1Animation = useAnimation();
    const card2Animation = useAnimation();
    const showMoveCard = useSelector((state) => state.homeReducer?.showMoveCard);
    const showMoveCardCheck = useSelector((state) => state.homeReducer?.showMoveCardCheck);
    const roomData = useSelector((state) => state.homeReducer?.roomData);
    const displayContinueButton = useSelector((state) => state.homeReducer?.displayContinueButton);
    const userId = useSelector((state) => state.authenticationReducer.userId);
    const dispatch = useDispatch()


    useEffect(() => {
        if (showMoveCard && showMoveCardCheck) {
            if ((getCardType() === 'event') || (getCardType() === 'rollOff') ||
                (getCardType() === 'direct') || (getCardType() === 'penalityEvent') ||
                (getCardType() === 'shuffle') || (getCardType() === 'highestRoll') ||
                (getCardType() === 'penalityAllEvent') || (getCardType() === 'singleRoll')) {
                move1(-gameScreenDimentions.gameBoardWidth * 0.19, -gameScreenDimentions.gameBoardWidth * 0.08)
            } else {
                move(gameScreenDimentions.gameBoardWidth * 0.025, gameScreenDimentions.gameBoardWidth * 0.82)
            }
        }
    }, [showMoveCard, showMoveCardCheck])

    const move = async (x, y) => {
        cardMovementSound();
        await card1Animation.start({
            transition: { duration: 0 },
            x: 0,
            y: 0,
            scaleX: 0,
            scaleY: 0,
        })
        await card1Animation.start({
            transition: { duration: 1.500 },
            x: x,
            y: y,
            scaleX: 2.3,
            scaleY: 2.3,
        })
    }


    const move1 = async (x, y) => {
        cardMovementSound();
        await card2Animation.start({
            transition: { duration: 0 },
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
        })
        await card2Animation.start({
            transition: { duration: 1.500 },
            x: x,
            y: y,
            scaleX: 2.3,
            scaleY: 2.3,
        })
    }


    return (
        <>
            {showMoveCardCheck &&
                <div style={styles.outerContainer}>
                    {
                        (getCardType() === 'event') || (getCardType() === 'rollOff') ||
                            (getCardType() === 'direct') || (getCardType() === 'penalityEvent') ||
                            (getCardType() === 'shuffle') || (getCardType() === 'highestRoll') ||
                            (getCardType() === 'penalityAllEvent') || (getCardType() === 'singleRoll') ?
                            <CardComponent
                                style={styles.card2}
                                animate={card2Animation}
                                code={showMoveCard}
                                selfKey={'moveCard2'}
                            />
                            :
                            <CardComponent
                                style={styles.card1}
                                animate={card1Animation}
                                code={showMoveCard}
                                selfKey={'moveCard2'}
                            />
                    }
                    {((roomData?.turn?.player?._id === userId) && displayContinueButton) ?
                        <div style={{
                            position: 'absolute',
                            right: gameScreenDimentions.gameBoardWidth * 0.115,
                            bottom: gameScreenDimentions.gameBoardWidth * 0.0395,
                        }}>
                            <ButtonCustom
                                resize={'stretch'}
                                textColor={{ fontSize: fontSizes.f40 }}
                                iContainer={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    height: gameScreenDimentions.gameBoardWidth * 0.043,
                                    minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                                }}
                                style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    height: gameScreenDimentions.gameBoardWidth * 0.043,
                                    minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                                }}
                                imgBg={appImages.buttonBg}
                                title={strings.continue}
                                onPress={() => {
                                    buttonClickSound();
                                    if (!roomData?.isPause) {
                                        socket.emit('playCard')
                                        dispatch({
                                            type: ApiConstants.DISPLAY_CONTINUE_BUTTON,
                                            payload: false
                                        })
                                        dispatch({
                                            type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                                            payload: false
                                        })
                                    } else {
                                        ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                                    }
                                }}
                            />
                        </div> : null
                    }
                </div>
            }
        </>
    );
};

export default MoveCardComponent;

