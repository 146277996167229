import { put, call, takeLatest } from "redux-saga/effects";
import { signUpApi, loginApi, forgotPasswordApi, logoutApi, changePasswordApi, deleteAccountApi } from "../axios/axiosApi";
import ApiConstants from '../../themes/apiConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage'
import { DataManager } from '../../helper/dataManager'
import { setAccessToken } from "../axios/apiMethods";
import { navigate } from '../../Routes/navigationServices';
import { socket } from "../../helper/socket";
import { logout } from "../../helper/globalFunctions";
import { ValidationConstants } from "../../themes/constants/validationConstants";
import { popupType } from "../../themes/constants/gameConstants";
import { FirebaseMethods } from "../../helper/firebaseMethod";

function* signupApiSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(signUpApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_SIGNUP_SUCCESS,
                result: result,
            });
            ShowAlertMessage(result.message, popupType.info, 3000)
            navigate('LogIn')
        } else {
            ShowAlertMessage(result.msg, popupType.error, 3000)
            yield put({ type: ApiConstants.API_SIGNUP_ERROR });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_SIGNUP_ERROR });
    }
}

function* loginApiSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(loginApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            DataManager.setAccessToken(result.data.token);
            DataManager.setUserId(result.data.userId);
            setAccessToken(result.data.token);
            yield put({
                type: ApiConstants.API_LOGIN_SUCCESS,
                result: result.data.userId,
            });
            FirebaseMethods.createUserList(result.data.userId.toString(), true)
            navigate('playjoin')
            socket.emit('login', { userId: result.data.userId, isKilled: true, accessToken: result.data.token });
            DataManager.setSound('on');
            yield put({
                type: ApiConstants.SOUND_ON_OFF,
                payload: true
            })

            // reset('Home', 0)
        } else {
            ShowAlertMessage(result.msg, popupType.error, 3000)
            yield put({ type: ApiConstants.API_LOGIN_ERROR });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_LOGIN_ERROR });
    }
}

function* forgotPasswordSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(forgotPasswordApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_FORGOT_PASSWORD_SUCCESS,
                result: result,
            });
        } else {
            ShowAlertMessage(result?.msg, popupType.info, 3000)
            yield put({ type: ApiConstants.API_FORGOT_PASSWORD_ERROR });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.info, 3000)
        yield put({ type: ApiConstants.API_FORGOT_PASSWORD_ERROR });
    }
}


function* logoutApiSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(logoutApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            DataManager.removeData();
            navigate('LogIn');
            ShowAlertMessage(result?.message, popupType.info, 3000);
            yield put({
                type: ApiConstants.API_LOGOUT_SUCCESS
            });
            FirebaseMethods.createUserList(action.userId.toString(), false)
        }
        else if (status == 2) {
            logout(result?.msg);
        }
        else {
            ShowAlertMessage(result?.msg, popupType.error, 3000);
            yield put({ type: ApiConstants.API_LOGOUT_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_LOGOUT_ERROR });
    }
}

function* changePasswordSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(changePasswordApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            DataManager.removeData()
            navigate('LogIn')
            ShowAlertMessage(result?.message, popupType.info);
            yield put({
                type: ApiConstants.API_CHANGE_PASSWORD_SUCCESS
            });
            FirebaseMethods.createUserList(action.payload.userId.toString(), false)
        }
        else if (status == 2) {
            logout(result?.msg);
        }
        else {
            ShowAlertMessage(result?.msg, popupType.error);
            yield put({ type: ApiConstants.API_CHANGE_PASSWORD_ERROR });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error)
        yield put({ type: ApiConstants.API_CHANGE_PASSWORD_ERROR });
    }
}


//Delete Accound
function* deleteAccountSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(deleteAccountApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            DataManager.removeData();
            navigate('LogIn');
            ShowAlertMessage(result?.message, popupType.info, 3000);
            yield put({
                type: ApiConstants.API_LOGOUT_SUCCESS
            });
            FirebaseMethods.createUserList(action.userId.toString(), false)
        }
        else if (status == 2) {
            logout(result?.msg);
        }
        else {
            ShowAlertMessage(result?.msg, popupType.error);
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error)
    }
}

export default function* rootAuthenticationSaga() {
    yield takeLatest(ApiConstants.API_SIGNUP_LOAD, signupApiSaga);
    yield takeLatest(ApiConstants.API_LOGIN_LOAD, loginApiSaga);
    yield takeLatest(ApiConstants.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga);
    yield takeLatest(ApiConstants.API_LOGOUT_LOAD, logoutApiSaga);
    yield takeLatest(ApiConstants.API_CHANGE_PASSWORD_LOAD, changePasswordSaga);
    yield takeLatest(ApiConstants.API_DELETE_ACCOUNT_LOAD, deleteAccountSaga);
}