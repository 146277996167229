import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Linkify from 'react-linkify';
import Header from '../../../common/header';
import { ShowAlertMessage } from '../../../helper/showAlertMessage';
import { popupType } from '../../../themes/constants/gameConstants';
import { ValidationConstants } from '../../../themes/constants/validationConstants';
import strings from '../../../themes/constants/strings'
import { isInternetConnected } from '../../../helper/networkUtils';
import { tAndCAction } from '../../../redux/actions/homeActions/homeActions';


const Terms = (props) => {
  const dispatch = useDispatch();
  const termAndConditions = useSelector((state) => state.homeReducer.termAndConditions);

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

    if (isInternetConnected()) {
      dispatch(tAndCAction());
    } else {
      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
    }
  }, [])

  return (
    <div>
      <div className='background_image '>
        <Header title={strings.termsAndConditions} settings={false} redirect={true} />
        <div className='faq_page' style={{
          background: 'Linear-gradient(-109deg, rgba(39, 40, 40, 0.99608) 0%, rgb(46, 47, 47) 100%)'
        }}>
          <span className='tet_color' style={{
            whiteSpace: 'pre-wrap',
          }}>
            <Linkify>{termAndConditions}</Linkify>
          </span>
        </div>
      </div>
    </div>
  )

}
export default Terms