import { modal } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';

const styles = {
    outerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 6,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
    },
    cardWrapper1: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.028,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardWrapper2: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.167,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardWrapper3: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.308,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardWrapper4: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.45,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardWrapper5: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.59,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardWrapper6: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.195,
        left: gameScreenDimentions.width * 0.73,
        height: gameScreenDimentions.width * 0.15,
        width: gameScreenDimentions.width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red'
    },
}

export default styles;