
import React from 'react';
import Header from '../../common/header';
import { commonImages } from '../../themes/appImage';
import strings from '../../themes/constants/strings'

const HelpCenter = () => {
  React.useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <div>
      <div className='background_image'>
        <Header title='Game Help' settings={false} />
        <div className='wrapper-inner'>
        <div className='first_para'>
          <h2>Disparity Tarp</h2>
          <p><span className='highLgt_color'>{strings.disparityTrapText1} </span>{`provides ${strings.disparityTrapText2}`}</p>
          <p>{strings.disparityTrapText3}</p>
        </div>
        <div className='first_para'>
          <h3>{strings.howToPlay}</h3>
          <p>{strings.howToPlayText}</p>
        </div>
        <div className='first_para'>
          <h3>{strings.howToWin}</h3>
          <p><span className='highLgt_color'>{strings.competitivePlay} </span>{strings.competitivePlayText}</p>
          <p><span className='highLgt_color'>{strings.cooperativePlay} </span>{strings.cooperativePlayText}</p>
        </div>
        <div className='first_para'>
          <h3>{strings.howToLose}</h3>
          <p>{strings.howToLoseText}</p>
        </div>
        {/* path to winning */}
        <div className='first_para'>
          <h3>{strings.pathToWinning}</h3>
          <p>{strings.pathToText}</p>
          <p><span className='highLgt_color'>{strings.tierOneGoal} </span>{strings.tierOneGoalText}</p>
          <p><span className='highLgt_color'>{strings.tierTwoGoal} </span>{strings.tierTwoGoalText}</p>
          <p><span className='highLgt_color'>{strings.tierThreeGoal} </span>{strings.tierThreeGoalText}</p>
          <p><span className='highLgt_color'>{strings.tierFourGoal} </span>{strings.tierFourGoalText}</p>

        </div>
         {/* Card Layout */}
          <div className='first_para'>
          <h3>{strings.cardLayout}</h3>
          <img src={commonImages.cardRule} className="img_p img3"/>
          <p><span className='highLgt_color'>{strings.prerequisiteTile} </span>{strings.needToPlay}</p>
          <p><span className='highLgt_color'>{strings.diceRolls} </span>{strings.diceRollText}</p>
          <p><span className='highLgt_color'>{strings.singlePlayerOr} </span>{strings.singlePlayerOrText}</p>
          <p><span className='highLgt_color'>{strings.tileEarned} </span>{strings.tileEarnedText}</p>
          <p><span className='highLgt_color'>{strings.tileEarned} </span>{strings.tileEarnedText}</p>
          <p><span className='highLgt_color'>{strings.diveDeeper} </span>{strings.diveDeeperText}</p>

        </div>
        {/* Before Starting */}
        <div className='first_para'>
          <h3>{strings.beforeStarting}</h3>
          <p>{strings.beforeStartingText}</p>
          <div className='imgki_d'>
          <p><span className='highLgt_color'>{strings.systemicallySND} </span></p>
          <img src={commonImages.sndImage} className="img_p"/>
          </div>
          <div className='imgki_d'>
          <p><span className='highLgt_color'>{strings.systemicallySD} </span></p>
          <img src={commonImages.sdImage} className="img_p"/>
          </div>
          <p>{strings.twoModes}</p>
          <p><span className='highLgt_color'>{strings.automatic} </span>{strings.automaticText}</p>
          <p>{strings.givingIdentity}</p>
          <p>{strings.systemWill}</p>
           {/* -manual */}
          
          <p><span className='highLgt_color'>{strings.manual1} </span>{strings.manualText}</p>
          <p>{strings.givingIdentityManual}</p>
          <p>{strings.selectingThePawn}</p>
          <p>{strings.allSDplayer}</p>
          <p>{strings.allThePlayer}</p>
          <p>{strings.sndPlayersGet}</p>
          <p>{strings.theTurnsMove}</p>

          {/* On Your Turn */}

          <div className='first_para'>
          <h3>{strings.onYourTurn}</h3>
          <p>{strings.onYourTurnText}</p>
          <p><span className='highLgt_color'>{strings.playAPrepAction} </span>{strings.selectOneOf}</p>
          <p><span className='highLgt_color'>{strings.getTwoSkill} </span>{strings.getTwoSkillText}</p>
        </div>

         {/* Trades */}

         <div className='first_para'>
          <h3>{strings.trades}</h3>
          <p>{strings.tradeInvolvePrivilege}</p>
          <img src={commonImages.trade_help} className="img_p trages"/>
        </div>

         {/* Trades */}

         <div className='first_para'>
          <h3>Vote</h3>
          <p>{strings.voteText1}</p>
          <p>{strings.voteText2}</p>
          <p>{strings.voteText3}</p>
        </div>
         {/* Reserve Vote */}

         <div className='first_para'>
          <h3>Reserve Vote</h3>
          <p>{strings.reverseVoteText}</p>
          <p>{strings.reverseVoteNote}</p>
        </div>

        {/* play an opportunity  */}

        <div className='first_para'>
          <h3>{strings.playAnOpportunity}</h3>
          <p>{strings.playAnOpportunityCard}</p>
          <p>{strings.playAnOpportunityCard2}</p>
        </div>

         {/* Discard for new Card  */}

         <div className='first_para'>
          <h3>{strings.discardForNew}</h3>
          <p>{strings.discardForNewText}</p>
        </div>

         {/* End of Turn  */}

         <div className='first_para'>
          <h3>{strings.endOfTurn}</h3>
          <p>{strings.endOfTurnText}</p>
          <p><span className='highLgt_color'>{strings.endOfTurnText2} </span></p>
          <p>{strings.endOfTurnText3}</p>
          <p>{strings.endOfTurnText4}</p>
        </div>
          {/* Prosperity Points */}

          <div className='first_para'>
          <h3>{strings.prosperityPoints}</h3>
          <p>{strings.prosperityPointsText}</p>
          <p><span className='highLgt_color'>{strings.eachSkillTile} </span>1 Prosperity Point</p>
          <p><span className='highLgt_color'>{strings.eachDegreeTile} </span>2 Prosperity Point</p>
          <p><span className='highLgt_color'>{strings.eachStartupTile} </span>3 Prosperity Point</p>
          <p><span className='highLgt_color'>{strings.eachPrivilege} </span>0.5 Prosperity Point(round up)</p>
          <p><span className='highLgt_color'>{strings.eachJustice} </span>-2 Prosperity Points</p>
          <p><span className='highLgt_color'>{strings.eachPower} </span>3 points(must have at end of game to count)</p>
          <p><span className='highLgt_color'>{strings.eachTier} </span></p>
          <p><span className='highLgt_color'>{strings.competitiveWinOnly} </span>Add 5 Prosperity Points to the winner</p>
          <p><span className='highLgt_color'>{strings.cooperativeWinOnly} </span>{strings.cooperativeWinText}</p>
        </div>

        {/* Turn Skips */}

        <div className='first_para'>
          <h3>{strings.turnSkips}</h3>
          <p>{strings.turnSkipsText}</p>
        </div>

         {/* Help or Hinder */}

         <div className='first_para'>
          <h3>{strings.helpOrHinder}</h3>
          <p>{strings.helpOrHinderText}</p>
        </div>
         
          {/* Help */}

          <div className='first_para'>
          <h3>{strings.help}</h3>
          <p><span className='highLgt_color'>{strings.influenceCard} </span></p>
          <p>{strings.influenceCardText}</p>

          <p><span className='highLgt_color'>{strings.vote2} </span></p>
          <p>{strings.vote2Text}</p>

          <p><span className='highLgt_color'>{strings.powerToken2} </span></p>
          <p>{strings.powerToken2Text}</p>

          <p><span className='highLgt_color'>{strings.privilegeToken2} </span></p>
          <p>{strings.privilegeToken2Text}</p>
        </div>

         {/* Hinder */}

         <div className='first_para'>
          <h3>{strings.hinder}</h3>
          <p><span className='highLgt_color'>{strings.influenceCard} </span></p>
          <p>{strings.influenceCard2Text}</p>
        </div>

          {/* Types of Board Spaces */}

          <div className='first_para'>
          <h3>{strings.typesOfBoardSpaces}</h3>
          <p>{strings.typesBoardSpacesText}</p>
        </div>

        <div className='first_para'>
          <h3>{strings.justiceSpace}</h3>
          <p>{strings.justiceSpaceText}</p>
          <p><span className='highLgt_color'>{strings.justiceSpaceText2} </span></p>
        </div>

        <div className='first_para'>
          <h3>{strings.taxSpace}</h3>
          <p>{strings.taxSpaceText}</p>
        </div>

        <div className='first_para'>
          <h3>{strings.BlankSpace}</h3>
          <p>{strings.BlankSpaceText}</p>
        </div>

         {/* types of cards */}

         <div className='first_para'>
          <h3>{strings.typesOfCards}</h3>
          <h3>{strings.OpportunityCards}</h3>
          <p>{strings.OpportunityCardsText}</p>
        </div>


        
        <div className='first_para'>
         <h3>{strings.influenceCards}</h3>
          <img src={commonImages.opportunity} className="img_p small"/>
          <p>{strings.influenceCardsText}</p>
        </div>
          

        <div className='first_para'>
         <h3>{strings.EventCards}</h3>
          <p>{strings.eventCardsText}</p>
        </div>

        <div className='first_para'>
         <h3>{strings.rollOfCards}</h3>
          <p>{strings.rollOfCardsText}</p>
        </div>

        <div className='first_para'>
         <h3>{strings.tierGoalCards}</h3>
         <img src={commonImages.event1} className="img_p small"/>
          <p>{strings.tierGoalCardsText}</p>
        </div>
            

            
         {/* types of tokens */}

         <div className='first_para'>
          <h3>{strings.typesOfTokens}</h3>
          <h3>{strings.privilegeTokens}</h3>
          <img src={commonImages.RoundArrowWithOne} className="img_p small"/>
          <p>{strings.privilegeTokensText}</p>

          <p><span className='highLgt_color'>{strings.extraDiceRoll} </span></p>
          <p>{strings.extraDiceRollText}</p>

          <p><span className='highLgt_color'>{strings.participateInVote} </span></p>
          <p>{strings.participateInVoteText}</p>

          <p><span className='highLgt_color'>{strings.influenceCard3} </span></p>
          <p>{strings.influenceCard3Text}</p>

        </div>

         {/* justice tokens */}

         <div className='first_para'>
          <h3>{strings.justiceTokens}</h3>
          <p>{strings.justiceTokensText}</p>
          </div>

          <div className='first_para'>
          <h3>{strings.powerTokens}</h3>
          <p>{strings.powerTokensText}</p>
          </div>
          
          <p><span className='highLgt_color'>{strings.justiceCard} </span></p>
          <p>{strings.justiceCardText}</p>

          <p><span className='highLgt_color'>{strings.taxesCard} </span></p>
          <p>{strings.taxesCardText}</p>
          <p><span className='highLgt_color'>{strings.EventCards} </span></p>
          <p>{strings.EventCardsText}</p>

        {/* Preparation tiles */}

        <div className='first_para'>
          <h3>{strings.preparationTiles}</h3>
          <p>{strings.preparationTilesText}</p>

          <h3>{strings.skillTile}</h3>
          <img src={commonImages.Skill} className="img_p small"/>
          <p>{strings.skillTileText}</p>

          <h3>{strings.degreeTile}</h3>
          <img src={commonImages.degree} className="img_p small"/>
          <p>{strings.degreeTileText}</p>

          <h3>{strings.startupTile}</h3>
          <img src={commonImages.Startup} className="img_p small"/>
          <p>{strings.startupTileText}</p>

          <h3>{strings.wealthTiles}</h3>
          <p>{strings.wealthTilesText}</p>
          </div>  

            <h3>{strings.jobTile}</h3>
          <img src={commonImages.MoneyBag} className="img_p small"/>
          <p>{strings.jobTileText}</p>    

          <h3>{strings.homeTile}</h3>
          <img src={commonImages.HomeDollar} className="img_p small"/>
          <p>{strings.homeTileText}</p> 

          <h3>{strings.businessTile}</h3>
          <img src={commonImages.BusinessTile} className="img_p small"/>
          <p>{strings.businessTileText}</p>  

           <h3>{strings.realEstateTile}</h3>
          <img src={commonImages.MultiHome} className="img_p small"/>
          <p>{strings.realEstateTileText}</p>       

        </div>
        </div>
      </div>
    </div>
  )
}

export default HelpCenter;