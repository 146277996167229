import appImages from '../../themes/appImages/appImages';
import { url } from '../../redux/axios/apikit';
import { navigate } from '../../Routes/navigationServices';


const WinnerComponent = (props) => {
    const showImage = () => {
        if (props?.type === 'cooperative') {
            if (props?.item?.isUltimate && props?.item?.isWin) {
                return appImages.trophy
            } else if (!props?.item?.isUltimate && props?.item?.isWin) {
                return appImages.win
            }
        } else if (props?.type === 'competitive') {
            if (props?.item?.isUltimate) {
                return appImages.trophy
            } else if (!props?.item?.isUltimate && props?.item?.isWin) {
                return appImages.win
            } else {
                return appImages.lose
            }
        }
    }

    return (

        <div className='inner_room' key={props.key} onClick={() => {
            navigate("pointsBreakdown", { points: props?.item?.points, pointHistory: props?.item?.pointHistory })
        }}>
            <div className='first_join home'>
                <img
                    style={{ height: '60px', width: '60px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }}
                    src={`${url}${props?.item?.user?.profileImage}`} />
                <div className='hostname' style={{ marginTop: 5, marginLeft: 12 }}>
                    <h4> {props?.item?.user?.name + ` (${props?.item?.role})`}</h4>
                    <h5>Total score: {props?.item?.points}</h5>
                </div>
                <div style={{ width: '40%', display: 'flex', flexDirection: 'row-reverse' }}>
                    <img
                        style={{ height: '60px', width: '60px' }}
                        src={showImage()} />
                </div>
            </div>
        </div>
    )
}
export default WinnerComponent