import React from 'react';

const Border = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 1529 961" {...props}>
    <defs>
      <style>
        {`.cls-1 {
        fill: none;
      }`}

        {`.cls-1, .cls-2 {
        stroke: #46c1af;
        stroke-width: 2px;
        fill-rule: evenodd;
      }`}

        {`.cls-2 {
        fill: #fff;
      }`}
      </style>
    </defs>
    <path class="cls-1" d="M2.487,53.52l686.57-51L1146.82,162.11,1377.31,57.861l149.4,355.009-35.36,388.108L1297.56,929.431,816.78,958.37,583.492,786.346,43.326,745.62Z" />
    <path class="cls-1" d="M195.479,618.061L169.045,190.554l497.467-38.775L1154.16,323.618l152.45-67.855,79.24,183.159-29.3,291.129-96.78,59.119L870.7,811.953,647.636,634.261Z" />
    <path class="cls-2" d="M500.182,17.348l-6.919,148.271" />
    <path class="cls-2" d="M1245.85,116.422l60.31,139.028" />
    <path class="cls-2" d="M1258.04,789.709l38.22,139.918" />
    <path class="cls-2" d="M348.552,622.569L331.61,766.62" />
  </svg>
)

export default Border