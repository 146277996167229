import React from 'react';
import { useSelector } from 'react-redux';
import { gameScreenDimentions, getAwardTileCount } from '../../helper/globalFunctions';
import appImages from '../../themes/appImages/appImages';
import { FontFamily } from '../../themes/fontFamily/fontFamily';

var isTileFillFirstTime = true

const TilesContainer = (props) => {
    const [tiles, setTiles] = React.useState([])
    const roomData = useSelector((state) => state.homeReducer.roomData);
    const ourGameData = useSelector((state) => state.homeReducer.ourGameData);
    React.useEffect(() => {
        if (roomData && ourGameData) {
            if (isTileFillFirstTime) {
                updateTiles(roomData, ourGameData)
            } else {
                if (getAwardTileCount() > 1) {
                    setTimeout(() => {
                        updateTiles(roomData, ourGameData)
                    }, 2300)
                }
                else {
                    setTimeout(() => {
                        updateTiles(roomData, ourGameData)
                    }, 1000)
                }
            }
        }
    }, [roomData, ourGameData])

    const updateTiles = (roomData, ourGameData) => {
        let tempArray = []
        tempArray.push({
            id: 1,
            image: appImages.skill,
            count: ourGameData?.tiles?.skill,
            isDisparityRemoved: roomData?.vote?.skill
        })
        tempArray.push({
            id: 2,
            image: appImages.roundArrowWithOne,
            count: ourGameData?.tokens?.privilege,
            isDisparityRemoved: false
        })
        // tempArray.push({
        //     id: 3,
        //     image: appImages.justiceRound,
        //     count: ourGameData?.tokens?.justice,
        //     isDisparityRemoved: roomData?.vote?.justice
        // })
        tempArray.push({
            id: 3,
            image: appImages.startup,
            count: ourGameData?.tiles?.startUp,
            isDisparityRemoved: roomData?.vote?.startUp
        })
        tempArray.push({
            id: 4,
            image: appImages.degree,
            count: ourGameData?.tiles?.degree,
            isDisparityRemoved: roomData?.vote?.degree
        })
        // tempArray.push({
        //     id: 5,
        //     image: appImages.hands,
        //     count: ourGameData?.tokens?.power,
        //     isDisparityRemoved: false,
        // })
        tempArray.push({
            id: 5,
            image: appImages.homeDollarRound,
            count: ourGameData?.tiles?.home,
            isDisparityRemoved: roomData?.vote?.home
        })
        tempArray.push({
            id: 6,
            image: appImages.bag,
            count: ourGameData?.tiles?.job,
            isDisparityRemoved: roomData?.vote?.job
        })
        tempArray.push({
            id: 7,
            image: appImages.multiHomeRound,
            count: ourGameData?.tiles?.realEstate,
            isDisparityRemoved: roomData?.vote?.realEstate
        })
        tempArray.push({
            id: 8,
            image: appImages.moneyBag,
            count: ourGameData?.tiles?.business,
            isDisparityRemoved: roomData?.vote?.business
        })
        setTiles(tempArray)
        isTileFillFirstTime = false
    }

    return (
        <>
            <div style={{
                position: 'absolute',
                width: gameScreenDimentions.width * 0.12,
                bottom: gameScreenDimentions.width * 0.13,
                left: gameScreenDimentions.width * 0.07,
                display: 'grid',
                gridTemplateColumns: '1fr 2fr'
            }}>
                {tiles.map((item, index) => {
                    return (
                        <div style={{
                            display: 'flex',
                            position: 'relative',
                            flexDirection: 'column'
                        }}
                            key={item.id}
                        >
                            <img
                                style={{
                                    marginTop: (
                                        (index % 2 !== 0) ?
                                            ((index !== 1) ?
                                                (-gameScreenDimentions.width * 0.02) :
                                                0)
                                            : (index !== 0) ?
                                                gameScreenDimentions.width * 0.008 :
                                                gameScreenDimentions.width * 0.028),
                                    objectFit: 'contain',
                                    width: gameScreenDimentions.width * 0.042,
                                    height: gameScreenDimentions.width * 0.042,
                                }}
                                src={item.image} />
                            {item.count > 0 && <div style={{
                                position: 'absolute',
                                backgroundColor: 'black',
                                width: 20,
                                height: 20,
                                borderRadius: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: (
                                    (index % 2 !== 0) ?
                                        ((index !== 1) ?
                                            (-gameScreenDimentions.width * 0.02) :
                                            0)
                                        : (index !== 0) ?
                                            gameScreenDimentions.width * 0.008 :
                                            gameScreenDimentions.width * 0.028),

                            }}><span style={{
                                fontSize: gameScreenDimentions.width * 0.0068,
                                fontWeight: '700',
                                textAlign: 'center',
                                color: '#ffffff',
                                marginTop: -1,
                            }}>{item.count}</span></div>}
                            {item.isDisparityRemoved && <span
                                style={{
                                    color: '#000000',
                                    fontSize: gameScreenDimentions.width * 0.0055,
                                    // textAlign: 'center',
                                    paddingLeft: gameScreenDimentions.width * 0.0042
                                }}
                            >No Disparity</span>}
                        </div>
                    )
                })}
            </div>
        </>

    );
};

export default TilesContainer