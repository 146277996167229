import ApiConstants from '../../themes/apiConstants';

const initialState = {
    messageArray: [],
    chatIcon: true
}

function gameReducer(state = initialState, action) {

    switch (action.type) {

        case ApiConstants.UPDATE_MESSAGE_DATA:
            state[action.key] = action.payload
            return { ...state, }
        default:
            return state;
    }
}


export default gameReducer;

