
export default {
    f19: 7,
    f22: 8,
    f24: 8.8,
    f25: 9,
    f28: 10,
    f33: 12,
    f35: 12.8,
    f39: 14,
    f40: 14.5,
    f42: 15.3,
    f44: 16,
    f45: 16.5,
    f48: 17.5,
    f50: 18,
    f51: 18.7,
    f55: 20,
    f60: 21.8,
    f65: 23.8,
    f75: 27.2,
    f85: 32,
    f90: 35,
    f100: 42
};