import React from 'react';
import { Navigate } from 'react-router-dom';
import { DataManager } from '../helper/dataManager';

export const PrivateRoute = ({ type, children }) => {
    let tokenCheck = DataManager.getAccessToken()
    const currentRoute = DataManager.getCurrentRount()
    return tokenCheck ? (currentRoute ? (
        (type == currentRoute) ?
            children :
            <Navigate to={`/${currentRoute}`} />)
        : ((type == 'GameLobby' || type == 'Game') ? <Navigate to='playjoin' /> : children))
        : <Navigate to="/" />;
};

export const PublicRoute = ({ isAuth, children }) => {
    let tokenCheck = DataManager.getAccessToken()
    return !tokenCheck ? children : <Navigate to="/" />;
};

