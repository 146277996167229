import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { message } from 'antd';
import { isObjEmpty } from "../../utils/validators/validator"
import ApiConstants from "../../themes/apiConstants";
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { isInternetConnected } from '../../helper/networkUtils';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { popupType } from '../../themes/constants/gameConstants';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#ffffff',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#ffffff',
            iconColor: '#fa755a',
            BorderColor: 'red'
        },
    },
    placeholder: 'Card Number'
};

const EXPIRE_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#ffffff',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#ffffff',
            iconColor: '#fa755a',
            BorderColor: 'red'
        },
    },
    // placeholder: 'Expiry Date'
};

const CVC_ELEMENT = {
    style: {
        base: {
            color: '#ffffff',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        // empty: {
        //     backgroundColor: 'red'
        // },
        invalid: {
            color: '#ffffff',
            iconColor: '#fa755a',
            BorderColor: 'red'
        },
    },
    placeholder: 'CVV'
};


const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();


    let errorsObj = {
        name: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: ''
    }
    const [errors, setErrors] = useState(errorsObj);

    React.useEffect(() => {
        return () => {
            setErrors(errorsObj)
        }
    }, [])

    const handleCardChange = (event) => {
        console.log("this is event = ", event)
        if (event.error) {
            if (event.elementType == "cardNumber") {
                setErrors(prevState => ({
                    ...prevState,
                    cardNumber: event.error.message,
                }))
            } else if (event.elementType == "cardExpiry") {
                setErrors(prevState => ({
                    ...prevState,
                    cardExpiry: event.error.message,
                }))
            } else if (event.elementType == "cardCvc") {
                setErrors(prevState => ({
                    ...prevState,
                    cardCvc: event.error.message,
                }))
            }
        } else if (event && !event.complete) {
            console.log("this is event third = ", event)
        } else {
            console.log("this is event 2 = ", event)
            if (event.elementType == "cardNumber" && event.complete) {
                // console.log("card number = ", event)
                setErrors(prevState => ({
                    ...prevState,
                    cardNumber: '',
                }))
            } else if (event.elementType == "cardCvc" && event.complete) {
                setErrors(prevState => ({
                    ...prevState,
                    cardCvc: '',
                }))
            } else if (event.elementType == "cardExpiry" && event.complete) {
                setErrors(prevState => ({
                    ...prevState,
                    cardExpiry: '',
                }))
            }

        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isObjEmpty(errorsObj)) {
            if (isInternetConnected()) {
                if (!stripe || !elements) {
                    return;
                }
                const card = elements.getElement(CardNumberElement);
                if (card) {
                    try {
                        const result = await stripe.createToken(card)
                        if (result.error) {
                            dispatch({
                                type: ApiConstants.UPDATE_LODING_STATE,
                                data: false
                            })
                            ShowAlertMessage(result.error.message, popupType.error, 3000);
                            if (result?.error?.param === 'number') {
                                return setErrors(prevState => ({
                                    ...prevState,
                                    cardNumber: result?.error?.message,
                                }))
                            }
                        } else {
                            const data = { token: result.token.id }
                            dispatch({
                                type: ApiConstants.API_BUY_PACKAGE_LOAD,
                                payload: data
                            })
                            props.closeModal()
                        }
                    } catch (error) {
                        dispatch({
                            type: ApiConstants.UPDATE_LODING_STATE,
                            data: true
                        })
                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                        console.log('#################', error);
                    }

                } else {
                    message.config({
                        maxCount: 1, duration: 0.9
                    })
                    message.error("error occured")
                }
            }
            else {
                ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
            }
        }
    }

    return (
        <div className="content-view" style={{
            marginTop: -46,
            marginLeft: -35,
            marginRight: -35,
        }}>
            <form id='card_form' onSubmit={handleSubmit} >
                <div className="inner_box" style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <label style={{
                        color: 'white',
                        marginBottom: 10,
                        fontSize: 20,
                        fontWeight: 700
                        // height: 16
                    }}>Enter Card Details</label>
                    <div className="form-group" style={{
                        width: '50%'
                    }}>
                        <label>Card Number</label>
                        <CardNumberElement
                            options={CARD_ELEMENT_OPTIONS}
                            id="card-element"
                            placeholder="Card number"
                            // className='StripeElement'
                            className='formcontrol'
                            style={{
                                width: '100%'
                            }}
                            onChange={(e) => handleCardChange(e)}
                        />
                        <label style={{
                            color: 'red',
                            marginBottom: 0,
                            fontSize: 15,
                            height: 16
                        }}>{errors?.cardNumber}</label>

                    </div>
                    <div className="form-group" style={{
                        width: '50%'
                    }}>
                        <label>Expiry Date</label>
                        <CardExpiryElement
                            options={EXPIRE_ELEMENT_OPTIONS}
                            id="card-element"
                            className='formcontrol'
                            style={{
                                width: '100%'
                            }}
                            onChange={(e) => handleCardChange(e)}

                        />

                        <label style={{
                            color: 'red',
                            marginBottom: 0,
                            fontSize: 15,
                            height: 16
                        }}>{errors?.cardExpiry}</label>

                    </div>
                    <div className="form-group" style={{
                        width: '50%'
                    }}>
                        <label>CVV</label>
                        <CardCvcElement
                            options={CVC_ELEMENT}
                            id="card-element"
                            className='formcontrol'
                            style={{
                                width: '100%'
                            }}
                            onChange={(e) => handleCardChange(e)}
                        />

                        <label style={{
                            color: 'red',
                            marginBottom: 0,
                            fontSize: 15,
                            height: 16
                        }}>{errors?.cardCvc}</label>

                    </div>
                    <button
                        id="submit"
                        disabled={!stripe}
                        type="submit"
                        class="buy_button"
                    >
                        {"Pay"}
                    </button>
                </div>
            </form >
        </div >
    )
}

export default CheckoutForm