import { useAnimation } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import { buttonClickSound } from "../../../helper/playSound";
import ApiConstants from "../../../themes/apiConstants";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import CardComponent from "../CardComponent";
import styles from './styles';


const ViewAllCards = (props) => {
    const dispatch = useDispatch()
    const viewAllCards = useSelector((state) => state.homeReducer.viewAllCards);
    const ourCards = useSelector((state) => state.homeReducer.ourCards);



    return (
        <>
            {viewAllCards && <div style={{ ...styles.outerContainer, position: 'absolute' }}>
                {ourCards.length > 0 && <CardComponent
                    style={styles.card1}
                    code={ourCards[0]}
                />}
                {ourCards.length > 1 && <CardComponent
                    style={styles.card2}
                    code={ourCards[1]}
                />}
                {ourCards.length > 2 && <CardComponent
                    style={styles.card3}
                    code={ourCards[2]}
                />}
                <div style={{
                    position: 'absolute',
                    right: gameScreenDimentions.gameBoardWidth * 0.115,
                    bottom: gameScreenDimentions.gameBoardWidth * 0.038,
                }}>
                    <ButtonCustom
                        resize={'stretch'}
                        textColor={{ fontSize: fontSizes.f40 }}
                        iContainer={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        style={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        imgBg={appImages.buttonBg}
                        title={strings.back}
                        onPress={() => {
                            buttonClickSound();
                            dispatch({
                                type: ApiConstants.VIEW_ALL_CARDS,
                                payload: false
                            })
                        }}
                    />
                </div>
            </div>}
        </>
    );
};

export default ViewAllCards;

