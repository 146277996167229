const ApiConstants = {

  UPDATE_LODING_STATE: "UPDATE_LODING_STATE",

  //Authentication
  UPDATE_AUTHENTICATION_FORM: 'UPDATE_AUTHENTICATION_FORM',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',

  //Signup
  API_SIGNUP_LOAD: 'API_SIGNUP_LOAD',
  API_SIGNUP_SUCCESS: 'API_SIGNUP_SUCCESS',
  API_SIGNUP_FAIL: 'API_SIGNUP_FAIL',
  API_SIGNUP_ERROR: 'API_SIGNUP_ERROR',

  //Login
  API_LOGIN_LOAD: 'API_LOGIN_LOAD',
  API_LOGIN_SUCCESS: 'API_LOGIN_SUCCESS',
  API_LOGIN_FAIL: 'API_LOGIN_FAIL',
  API_LOGIN_ERROR: 'API_LOGIN_ERROR',
  //Login
  API_LOGOUT_LOAD: 'API_LOGOUT_LOAD',
  API_LOGOUT_SUCCESS: 'API_LOGOUT_SUCCESS',
  API_LOGOUT_FAIL: 'API_LOGOUT_FAIL',
  API_LOGOUT_ERROR: 'API_LOGOUT_ERROR',

  //Forgot Password
  API_FORGOT_PASSWORD_LOAD: 'API_FORGOT_PASSWORD_LOAD',
  API_FORGOT_PASSWORD_SUCCESS: 'API_FORGOT_PASSWORD_SUCCESS',
  API_FORGOT_PASSWORD_ERROR: 'API_FORGOT_PASSWORD_ERROR',

  //Create Room
  API_CREATE_ROOM_LOAD: 'API_CREATE_ROOM_LOAD',
  API_CREATE_ROOM_SUCCESS: 'API_CREATE_ROOM_SUCCESS',

  API_PUBLIC_ROOM_LIST_SUCCESS: 'API_PUBLIC_ROOM_LIST_SUCCESS',
  API_REEDEEM_VOUCHER_LOAD: 'API_REEDEEM_VOUCHER_LOAD',
  API_REEDEEM_VOUCHER_SUCCESS: 'API_REEDEEM_VOUCHER_SUCCESS',

  API_FAIL: 'API_FAIL',
  API_ERROR: 'API_ERROR',

  UPDATE_MESSAGE_DATA: "UPDATE_MESSAGE_DATA",

  ADD_CONTACT_LOAD: 'ADD_CONTACT_LOAD',
  ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
  ADD_CONTACT_FAIL: 'ADD_CONTACT_LOAD',
  RESET_AUTH_DATA: 'RESET_AUTH_DATA',

  UPDATE_IS_NEW_GAME: 'UPDATE_IS_NEW_GAME',
  UPDATE_TURN: 'UPDATE_TURN',
  CURRENT_TIMER_UPDATE: 'CURRENT_TIMER_UPDATE',
  OUR_GAME_DATA_UPDATE: 'OUR_GAME_DATA_UPDATE',
  OUR_CARD_UPDATE: 'OUR_CARD_UPDATE',
  SELECT_CARD_UPDATE: 'SELECT_CARD_UPDATE',
  ROLL_DICE_UPDATE: 'ROLL_DICE_UPDATE',
  TRADE_TILE_UPDATE: 'TRADE_TILE_UPDATE',
  ENTER_TOKEN_FOR_VOTE_UPDATE: 'ENTER_TOKEN_FOR_VOTE_UPDATE',
  AWARD_TILE_UPDATE: 'AWARD_TILE_UPDATE',
  BACK_FROM_SELECT_CARD_UPDATE: 'BACK_FROM_SELECT_CARD_UPDATE',
  SHOW_MESSAGE_COMPONENT_UPDATE: 'SHOW_MESSAGE_COMPONENT_UPDATE',
  NO_INTERNET_COMPONENT_UPDATE: 'NO_INTERNET_COMPONENT_UPDATE',
  SHOW_CARD_NORMALLY_UPDATE: 'SHOW_CARD_NORMALLY_UPDATE',
  REST_HOME: 'REST_HOME',
  OPEN_DIALOG_BOX: 'OPEN_DIALOG_BOX',
  SELECTED_CARD: 'SELECTED_CARD',
  OPEN_DICE_BOX: 'OPEN_DICE_BOX',
  INFLUENCE_ACTION: 'INFLUENCE_ACTION',
  INFLUENCE_TYPE: 'INFLUENCE_TYPE',
  SHOW_MOVE_CARD: 'SHOW_MOVE_CARD',
  SHOW_MOVE_CARD_CHECK: 'SHOW_MOVE_CARD_CHECK',
  SHOW_MOVE_CARD_OTHER: 'SHOW_MOVE_CARD_OTHER',
  DISPLAY_DICE: 'DISPLAY_DICE',
  SMALL_TIMER: 'SMALL_TIMER',
  PAWN_1: 'PAWN_1',
  PAWN_2: 'PAWN_2',
  PAWN_3: 'PAWN_3',
  PAWN_4: 'PAWN_4',
  PAWN_5: 'PAWN_5',
  PAWN_6: 'PAWN_6',
  CPAWN_1: 'CPAWN_1',
  CPAWN_2: 'CPAWN_2',
  CPAWN_3: 'CPAWN_3',
  CPAWN_4: 'CPAWN_4',
  CPAWN_5: 'CPAWN_5',
  CPAWN_6: 'CPAWN_6',
  DISPLAY_ROLL_DICE_POPUP_TYPE: 'DISPLAY_ROLL_DICE_POPUP_TYPE',
  RE_ROLL_TYPE: 'RE_ROLL_TYPE',
  PLAY_CARD_TYPE: 'PLAY_CARD_TYPE',
  OUT_COME_TYPE: 'OUT_COME_TYPE',
  SHUFFLE_CARDS: 'SHUFFLE_CARDS',
  SHUFFLE: 'SHUFFLE',
  OPEN_RULE_BOOK: 'OPEN_RULE_BOOK',
  IS_SPECTATOR: 'IS_SPECTATOR',
  SHOW_USER_DETAIL: 'SHOW_USER_DETAIL',
  UPDATE_PART_COMPLETE: 'UPDATE_PART_COMPLETE',
  IS_SOMEONE_LEFT: 'IS_SOMEONE_LEFT',
  USE_INFLUENCE_CARD: 'USE_INFLUENCE_CARD',
  SOUND_ON_OFF: 'SOUND_ON_OFF',
  WINNER_DETAILS: 'WINNER_DETAILS',
  OTHER_PLAYER_ROLE_TYPE: 'OTHER_PLAYER_ROLE_TYPE',

  //Update Profile Action
  API_UPDATE_PROFILE_LOAD: 'API_UPDATE_PROFILE_LOAD',
  API_UPDATE_PROFILE_SUCCESS: 'API_UPDATE_PROFILE_SUCCESS',
  API_UPDATE_PROFILE_FAIL: 'API_UPDATE_PROFILE_FAIL',
  API_UPDATE_PROFILE_ERROR: 'API_UPDATE_PROFILE_ERROR',

  //change password
  API_CHANGE_PASSWORD_LOAD: 'API_CHANGE_PASSWORD_LOAD',
  API_CHANGE_PASSWORD_SUCCESS: 'API_CHANGE_PASSWORD_SUCCESS',
  API_CHANGE_PASSWORD_FAIL: 'API_CHANGE_PASSWORD_FAIL',
  API_CHANGE_PASSWORD_ERROR: 'API_CHANGE_PASSWORD_ERROR',

  //About US
  API_ABOUT_US_LOAD: 'API_ABOUT_US_LOAD',
  API_ABOUT_US_SUCCESS: 'API_ABOUT_US_SUCCESS',
  API_ABOUT_US_FAIL: 'API_ABOUT_US_FAIL',
  API_ABOUT_US_ERROR: 'API_ABOUT_US_ERROR',

  //Privacy Policy
  API_PRIVACY_POLICY_LOAD: ' API_PRIVACY_POLICY_LOAD',
  API_PRIVACY_POLICY_SUCCESS: ' API_PRIVACY_POLICY_SUCCESS',
  API_PRIVACY_POLICY_FAIL: ' API_PRIVACY_POLICY_FAIL',
  API_PRIVACY_POLICY_ERROR: ' API_PRIVACY_POLICY_ERROR',

  //Terms & Conditions
  API_T_AND_C_LOAD: ' API_T_AND_C_LOAD',
  API_T_AND_C_SUCCESS: ' API_T_AND_C_SUCCESS',

  //Faq Policy
  API_FAQ_LOAD: 'API_FAQ_LOAD',
  API_FAQ_SUCCESS: ' API_FAQ_SUCCESS',
  API_FAQ_FAIL: ' API_FAQ_FAIL',
  API_FAQ_ERROR: ' API_FAQ_ERROR',

  //leader board
  API_LEADER_BOARD_LOAD: 'API_LEADER_BOARD_LOAD',
  API_LEADER_BOARD_SUCCESS: ' API_LEADER_BOARD_SUCCESS',
  API_LEADER_BOARD_FAIL: ' API_LEADER_BOARD_FAIL',
  API_LEADER_BOARD_ERROR: ' API_LEADER_BOARD_ERROR',


  //Buy Package Action
  API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD: 'API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD',
  API_CHECK_FOR_ANY_SUBSCRIPTION_SUCCESS: 'API_CHECK_FOR_ANY_SUBSCRIPTION_SUCCESS',
  API_BUY_PACKAGE_LOAD: 'API_BUY_PACKAGE_LOAD',

  API_DELETE_ACCOUNT_LOAD: 'API_DELETE_ACCOUNT_LOAD',
  API_BLOCKED_USERS_LIST_SUCCESS: 'API_BLOCKED_USERS_LIST_SUCCESS',


  //Solo play
  API_SOLO_PLAY_LOAD: 'API_SOLO_PLAY_LOAD',

  IS_TRADE_AVAILABLE: 'IS_TRADE_AVAILABLE',
  TRADE_TILE_AGAIN: 'TRADE_TILE_AGAIN',
  LOGS_DATA: 'LOGS_DATA',
  DISPLAY_CONTINUE_BUTTON: 'DISPLAY_CONTINUE_BUTTON',
  GET_RANGE: 'GET_RANGE',

  SHOW_MENU_BUTTON: 'SHOW_MENU_BUTTON',
  VIEW_ALL_CARDS: 'VIEW_ALL_CARDS',
  SAVE_DIALOG_ID: 'SAVE_DIALOG_ID',

  API_CANCEL_SUBSCRIPTION_LOAD: 'API_CANCEL_SUBSCRIPTION_LOAD',

  PAUSE_GAME: 'PAUSE_GAME',

  iosAppLink: 'https://apps.apple.com/us/app/disparity-trap/id1629665659',
  androidAppLink: 'https://play.google.com/store/apps/details?id=com.disparity',
  webLink: 'https://game.disparitytrap.net',
}

export default ApiConstants;