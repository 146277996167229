import { put, call, takeLatest } from "redux-saga/effects";
import { addContact } from "../axios/axiosApi";
import ApiConstants from '../../themes/apiConstants';
import { ShowAlertMessage } from "../../helper/showAlertMessage";
import { popupType } from "../../themes/constants/gameConstants";

//// Add Contact Saga
function* addContactSaga(action) {
    try {
        const response = yield call(addContact, action.data)
        let { data, message } = response.result;
        if (response.status === 1) {
            yield put({ type: ApiConstants.ADD_CONTACT_SUCCESS, result: data })
        }
        else {
            ShowAlertMessage(message, popupType.error, 3000)
            yield put({ type: ApiConstants.ADD_CONTACT_FAIL })
        }
    }

    catch (err) {
        yield put({ type: ApiConstants.ADD_CONTACT_FAIL })
    }
}


export default function* rootContactSaga() {
    yield takeLatest(ApiConstants.ADD_CONTACT_LOAD, addContactSaga);
}