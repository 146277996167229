import { DataManagersKeys } from './dataManagersKeys'

export const DataManager = {
    setAccessToken(token) {
        return localStorage.setItem(DataManagersKeys.access_token, token)
    },
    getAccessToken() {
        return localStorage.getItem(DataManagersKeys.access_token)
    },
    setUserId(data) {
        return localStorage.setItem(DataManagersKeys.userId, data)
    },
    getUserId() {
        return localStorage.getItem(DataManagersKeys.userId)
    },
    setisLoggedin(isLoggedin) {
        return localStorage.setItem(DataManagersKeys.isLoggedin, isLoggedin)
    },
    getisLoggedin() {
        return localStorage.getItem(DataManagersKeys.isLoggedin)
    },
    setCurrentRoute(data) {
        return localStorage.setItem(DataManagersKeys.currentRoute, data)
    },
    getCurrentRount() {
        return localStorage.getItem(DataManagersKeys.currentRoute)
    },
    deleteCurrentRount() {
        return localStorage.removeItem(DataManagersKeys.currentRoute)
    },
    removeData() {
        localStorage.clear()
    },
    setLeaveGameStatus(data) {
        return localStorage.setItem(DataManagersKeys.leaveGameAction, data)
    },
    getLeaveGameStatus() {
        return localStorage.getItem(DataManagersKeys.leaveGameAction)
    },
    setSound(data) {
        return localStorage.setItem(DataManagersKeys.sound, data)
    },
    getSound() {
        return localStorage.getItem(DataManagersKeys.sound)
    },
    removeLeaveGameStatus() {
        return localStorage.removeItem(DataManagersKeys.leaveGameAction)
    }
}

