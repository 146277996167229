import './App.css';
import './css/style.css';
import './css/responsive.css';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import RoutesNew from './Routes/index';
//////
import { socket } from './helper/socket';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigate, getCurrentRoute, navigate } from './Routes/navigationServices';
import { ShowAlertMessage } from './helper/showAlertMessage';
import { roomSuccessAction } from './redux/actions/homeActions/homeActions';
import { DataManager } from './helper/dataManager';
import { setCurrentP1, setCurrentP2, setCurrentP3, setCurrentP4, setCurrentP5, setCurrentP6, updateLeaveStatus } from './helper/globalFunctions';
import Loader from './components/loader'
import { checkTurnSuccess, discardCardSuccess, emitSkillTileSuccess, hostExit, initialTurnResult, joinRoomSuccess, updateRoomDetail, selectedVoteTile, socketError, startMatchSuccess, totalVoteTokens, tradeTileSuccess, updateDispatch, userBlock, userProfileSuccess, voteRollDiceSuccess, winner, qualifiedStatusSuccess, powerOpportunityCardSuccess, playOpportunitySuccess, useInfluenceCard, influenceSuccess, displayRollDicePopUp, displayCard, useInfluenceForOther, startRollDice, displayDice, useReRollDice, useReRollForOther, reRollSuccess, diceRange, decisionProcess, displayPawn, displayInfo, usePowerForTax, displayPlayPopUp, justiceSpaceSuccess, useOutcome, useOutcomeForOther, outcomeSuccess, pullEventCard, takeEventCardSuccess, playEventCardSuccess, shuffleCard, timerReset, otherPlayerWin, newTierSuccess, taxSpaceSuccess, reportSuccess, blockUserListSuccess, blockUserSuccess, logs, displayContinue, closeTimerPopUp, tokenForVoteSuccess, gameStatus, activeScreen } from './helper/socketFunctions';
import ApiConstants from './themes/apiConstants';
import { stopTimer, timer } from './helper/timer';
import { dialogNames, popupType } from './themes/constants/gameConstants';
import { backgroundMusicSound, playSound } from './helper/playSound';
import { FirebaseMethods } from './helper/firebaseMethod';
import { commonImages } from './themes/appImage';
import ModalPop from './common/Modal';
import ReloadResize from './components/game/reloadResize';
import { message } from "antd"

var isAppOpenFromKilled = true
var isSocketLoggedInFromKilled = true

const App = () => {
  const navigate2 = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const userId = useSelector((state) => state.authenticationReducer.userId);
  const [isInternetAvailable, setInternetStatus] = React.useState(true)
  const [tabAlertPopup, setTabAlertPopup] = React.useState(false);

  React.useLayoutEffect(() => {
    message.config({
      rtl: false,
      maxCount: 1,
    })
    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
      if (e.key == "openpages") {
        localStorage.page_available = Date.now();
        showModalSuccess()
      }
      if (e.key == "page_available") {
        //  alert("you are open another page.")
      }
    }, false);
  }, [])

  React.useEffect(() => {
    setNavigate(navigate2, location)
    updateDispatch(dispatch)
    playSound()

    window.addEventListener('online', () => {
      setInternetStatus(true);
    });
    window.addEventListener('offline', () => {
      setInternetStatus(false);
      updateLeaveStatus(false)
      stopTimer()
      dispatch({
        type: ApiConstants.NO_INTERNET_COMPONENT_UPDATE,
        payload: true
      })
      dispatch({
        type: ApiConstants.OUR_GAME_DATA_UPDATE,
        payload: {
          isOurTurn: false,
          ourGameData: null,
          isPart1Completed: false
        }
      })
    });
    socket.connect();
    socket.on('connect', socketConnect);
    socket.on('reconnect', socketConnect);
    socket.on('reconnect_attempt', (attempt) => {
      ShowAlertMessage(JSON.stringify(attempt), popupType.error)
    });
    socket.on('reconnect_error', (error) => {
      ShowAlertMessage(JSON.stringify(error), popupType.error)
    });
    socket.on('loginSuccess', loginSuccess);
    socket.on('userProfileSuccess', userProfileSuccess);
    socket.on('joinRoomSuccess', joinRoomSuccess);
    socket.on('updateRoomDetail', updateRoomDetail);
    socket.on('startMatchSuccess', startMatchSuccess);
    socket.on('initialTurnResult', initialTurnResult);
    socket.on('checkTurnSuccess', checkTurnSuccess);
    socket.on('earnSkillTileSuccess', emitSkillTileSuccess);
    socket.on('tradeTileSuccess', tradeTileSuccess);
    socket.on('discardCardSuccess', discardCardSuccess);
    socket.on('selectedVoteTile', selectedVoteTile);
    socket.on('totalVoteTokens', totalVoteTokens);
    socket.on('tokenForVoteSuccess', tokenForVoteSuccess);
    socket.on('voteRollDiceSuccess', voteRollDiceSuccess);
    socket.on('qualifiedStatusSuccess', qualifiedStatusSuccess);
    socket.on('powerOpportunityCardSuccess', powerOpportunityCardSuccess);
    socket.on('useInfluenceCard', useInfluenceCard);
    socket.on('influenceSuccess', influenceSuccess);
    socket.on('displayRollDicePopUp', displayRollDicePopUp);
    socket.on('displayCard', displayCard);
    socket.on('useInfluenceForOther', useInfluenceForOther);
    socket.on('diceRange', diceRange);
    socket.on('startRollDice', startRollDice);
    socket.on('displayDice', displayDice);
    socket.on('useReRollDice', useReRollDice);
    socket.on('useReRollForOther', useReRollForOther);
    socket.on('reRollSuccess', reRollSuccess);
    socket.on('playOpportunitySuccess', playOpportunitySuccess);
    socket.on('decisionProcess', decisionProcess);
    socket.on('displayPawn', displayPawn);
    socket.on('displayInfo', displayInfo);
    socket.on('usePowerForTax', usePowerForTax);
    socket.on('displayPlayPopUp', displayPlayPopUp);
    socket.on('taxSpaceSuccess', taxSpaceSuccess);
    socket.on('justiceSpaceSuccess', justiceSpaceSuccess);
    socket.on('playEventCardSuccess', playEventCardSuccess);
    socket.on('useOutcome', useOutcome);
    socket.on('useOutcomeForOther', useOutcomeForOther);
    socket.on('outcomeSuccess', outcomeSuccess);
    socket.on('pullEventCard', pullEventCard);
    socket.on('shuffleCard', shuffleCard);
    socket.on('takeEventCardSuccess', takeEventCardSuccess);//
    socket.on('timerReset', timerReset);//
    socket.on('otherPlayerWin', otherPlayerWin);
    socket.on('newTierSuccess', newTierSuccess);
    socket.on('logs', logs);
    socket.on('displayContinue', displayContinue);
    socket.on('closeTimerPopUp', closeTimerPopUp);
    socket.on('winner', winner);
    socket.on('hostExit', hostExit);
    socket.on('userBlock', userBlock);
    socket.on('socketError', socketError);
    socket.on('reportSuccess', reportSuccess);
    socket.on('blockUserSuccess', blockUserSuccess);
    socket.on('blockUserListSuccess', blockUserListSuccess);
    socket.on('gameStatus', gameStatus);
    socket.on('activeScreen', activeScreen);
    socket.on('disconnect', function () {
      // socket.connect();
    });
    checkSoundState()
    return () => {
      socket.disconnect();
    }
  }, [])

  React.useEffect(() => {
    if (userId) FirebaseMethods.createUserList(userId.toString(), true)
  }, [userId])

  React.useEffect(() => {
    if (isInternetAvailable && !isAppOpenFromKilled) {
      checkReconnection()
    }
    isAppOpenFromKilled = false
  }, [isInternetAvailable])

  const checkReconnection = async () => {
    const tempUserId = await DataManager.getUserId();
    const accessToken = await DataManager.getAccessToken();
    socket.connect();
    setTimeout(() => {
      if (socket.connected) {
        if (tempUserId && accessToken) {
          socket.emit('login', { userId: tempUserId, isKilled: isSocketLoggedInFromKilled, accessToken: accessToken });
        }
        // isSocketLoggedInFromKilled = false
      }
      else {
        checkReconnection()
      }
    }, 500)
  }

  const socketConnect = async () => {
    const tempUserId = await DataManager.getUserId();
    const accessToken = await DataManager.getAccessToken();
    if (tempUserId && accessToken) {
      socket.emit('login', { userId: tempUserId, isKilled: isSocketLoggedInFromKilled, accessToken: accessToken });
    }
    // isSocketLoggedInFromKilled = false
  }

  const loginSuccess = async (data) => {
    const tempUserId = await DataManager.getUserId();
    if (tempUserId) {
      updateLeaveStatus(true);
      dispatch({
        type: ApiConstants.NO_INTERNET_COMPONENT_UPDATE,
        payload: false
      })
      socket.emit('userProfile')
      socket.emit('blockUserList')
      socket.emit('room')
      let route = getCurrentRoute()
      if (data?.roomDetail) {
        dispatch(roomSuccessAction(data?.roomDetail));
        dispatch({ type: ApiConstants.IS_SPECTATOR, payload: data?.roomDetail?.isSpectator ? true : false })
        data?.roomDetail?.players?.map((i, index) => {
          if (index == 0) {
            dispatch({ type: ApiConstants.CPAWN_1, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_1, payload: i?.position });
            setCurrentP1(i?.position)
          } else if (index == 1) {
            dispatch({ type: ApiConstants.CPAWN_2, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_2, payload: i?.position });
            setCurrentP2(i?.position)
          } else if (index == 2) {
            dispatch({ type: ApiConstants.CPAWN_3, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_3, payload: i?.position });
            setCurrentP3(i?.position)
          } else if (index == 3) {
            dispatch({ type: ApiConstants.CPAWN_4, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_4, payload: i?.position });
            setCurrentP4(i?.position)
          } else if (index == 4) {
            dispatch({ type: ApiConstants.CPAWN_5, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_5, payload: i?.position });
            setCurrentP5(i?.position)
          } else if (index == 5) {
            dispatch({ type: ApiConstants.CPAWN_6, payload: i?.position });
            dispatch({ type: ApiConstants.PAWN_6, payload: i?.position });
            setCurrentP6(i?.position)
          }
        })
        if (data?.roomDetail?.isSpectator) {
          timer(
            dispatch,
            data?.roomDetail?.roomId,
            data?.roomDetail?.turn?.difference,
            false
          )
        }
      }

      if (route.pathname == '/GameLobby' || route.pathname === 'Game') {
        if (data?.roomDetail) {
          if (data?.roomDetail?.isStart) {
            dispatch({
              type: ApiConstants.OPEN_DICE_BOX,
              payload: {
                tokenType: '',
                openDiceBox: false
              }
            })
            dispatch({
              type: ApiConstants.OPEN_DIALOG_BOX,
              payload: {
                openDialog: dialogNames.close,
              }
            })
            socket.emit('checkTurn', data?.roomDetail?.roomId)
            if (route.pathname == '/GameLobby') {
              navigate("Game");
            }
          }
        }
        else {
          ShowAlertMessage('Room no more available.', popupType.error);
          DataManager.deleteCurrentRount()
          navigate("playjoin");
          dispatch({ type: ApiConstants.REST_HOME })
          stopTimer()
        }
      }
      else {
        if (data?.roomDetail) {
          if (data?.roomDetail?.isStart) {
            dispatch({
              type: ApiConstants.OPEN_DICE_BOX,
              payload: {
                tokenType: '',
                openDiceBox: false
              }
            })
            dispatch({
              type: ApiConstants.OPEN_DIALOG_BOX,
              payload: {
                openDialog: dialogNames.close,
              }
            })
            socket.emit('checkTurn', data?.roomDetail?.roomId)
            navigate("Game");
          }
          else {
            navigate("GameLobby");
          }
        }
        else {
          DataManager.deleteCurrentRount()
        }
      }
    }

  }

  const showModalSuccess = () => {
    setTabAlertPopup(true);
  };

  const handleOkSuccess = () => {
    setTabAlertPopup(false);
  };

  const handleCancelSuccess = () => {
    setTabAlertPopup(false);
  };

  const checkSoundState = () => {
    setTimeout(() => {
      const sound = DataManager.getSound();
      if (sound) {
        dispatch({
          type: ApiConstants.SOUND_ON_OFF,
          payload: sound == 'on' ? true : false
        })
        document.body.addEventListener("mousemove", function () {
          sound == 'on' && backgroundMusicSound()
        })

      }
      else {
        DataManager.setSound('on');
        dispatch({
          type: ApiConstants.SOUND_ON_OFF,
          payload: true
        })
        backgroundMusicSound()
      }
    }, 2000)
  };

  const fragment = (
    <>
      <div className='main_pop pops'>
        <div className='img_y alert_icons'>
          <img src={commonImages.alert} />
        </div>
        <div>
          <div className='form-group success_d leave-pop'>
            <p className='sure_delete'>Disparity Trap is open in another window. Click "Use Here" to use Disparity Trap in this window.</p>
          </div>
        </div>
      </div>
    </>
  )

  const successBtn = (
    <>
      <button className='forgot_text' onClick={async () => {
        window.location.reload()
      }}>
        <img src={commonImages.useHere} className="send_new" />
      </button>
    </>
  )

  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        limit={1}
        // transition={Slide}
        icon={false}
      />
      <RoutesNew />
      <Loader />
      {
        tabAlertPopup &&
        <ModalPop
          maskClosable={false}
          content={fragment}
          btnSuccess={successBtn}
          btnCancel={true}
          handleSuccess={handleOkSuccess}
          handleCancel={handleCancelSuccess}
          isVisible={tabAlertPopup}
          center='lobby_modal_reload'
        />
      }
      <ReloadResize />
    </>

  )
}

export default App;
