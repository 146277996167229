import { toast } from 'react-toastify';
import { textColor } from "../themes/colors/colors";
import { message } from "antd"


const ShowAlertMessage = (message1, type, duration = 6000) => {
    // toast.dismiss();
    message.destroy()
    // const colour = (type === 'info') ? textColor.green : textColor.red;
    message.success({
        content: message1,
        style: {
            color: 'white'
        },
        className: (type === 'info') ? 'customToastSuccess' : 'customToastError',
        duration: duration / 1000
    }
    );
    // toast(message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: duration,
    //     progressStyle: { backgroundColor: colour },
    //     type: 'success',
    // });

};


export {
    ShowAlertMessage
}