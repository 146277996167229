import { DataManager } from "../helper/dataManager";

var navigationRef = null;
var pathNameRef = null

export const setNavigate = (data, pathName) => {
  navigationRef = data
  pathNameRef = pathName
};



export const navigate = (path, params = null) => {
  if (path === 'GameLobby' || path === 'Game') {
    DataManager.setCurrentRoute(path)
  }

  if (params) {
    return navigationRef(path, { state: params })
  }
  return navigationRef(path);
};

export const goBack = () => {
  navigationRef(-1)
};

export const reset = (name, index) => {
  // navigationRef.current.dispatch(
  //   CommonActions.reset({
  //     index: index,
  //     routes: [
  //       {
  //         name: name,
  //       },
  //     ],
  //   })
  // );
};

export const getCurrentRoute = (name, index) => {
  return pathNameRef
};
