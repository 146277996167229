import { modal, textInput } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';

const width = gameScreenDimentions.width;
const styles = {
    card: {
        backgroundColor: 'white',
        alignSelf: 'center',
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderColor: '#46c1af55',
        borderStyle: 'solid',
        overflow: 'hidden',
        zIndex: 2,
        scaleX: 2,
        scaleY: 2,
    },
    outerContainer: {
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 1006,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column'
    },
    modalView: {
        paddingHorizontal: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        justifyContent: 'center',
        width: width * 0.52,
        height: width * 0.38
    },

}

export default styles;