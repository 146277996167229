export const ValidationConstants = {
  accessphoto: "Allow access to photos to upload photos from your library.",
  accessCamera: "Monoplug needs to access your camera to allow you to upload photos to Monoplug.",
  fileSize: "File size should not be more than 20 MB.",
  emptyPassword: "Please enter password.",
  internetCheck: "Please check your internet connection.",
  invalidPassword: "Password should not contain whitespaces.",
  invalidPasswordNew: "Password must include 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.",
  invalidEmail: "Please enter valid email address.",
  emptyEmail: "Please enter email address.",
  emptyFirstName: "Please enter username.",
  emptyLoc: "Please enter location.",
  shortPassword: "Password should be at least 8 characters long.",
  emptyConfirm: "Please enter confirm password.",
  enterVoucher: "Please enter voucher code.",
  invalidConfirmPassword: "Password and confirm password should be same.",
  emptyCat: "Please select category",
  emptySubCat: "Please select sub category",
  shortFirstName: "Username should be at least 3 characters long.",
  userNamevalidation: "Username contains only alphanumeric characters, underscore and dot.",
  offline: "Your internet connection appears to be offline. Please try again.",
  roomId: "Please enter room ID.",
  pleaseSubmit: 'Please select a dice number.',
  emptyOldPassword: "Please enter old password.",
  emptyNewPassword: "Please enter new password.",
  emptyConfirmPassword: "Please enter confirm new password.",
  notSamePassword: "New password and confirm new password should be same.",
  oShortPassword: "Old password should be at least 8 characters long.",
  purchaseValidation: 'You already have an active package.',
  noDiceRange: 'All dice numbers are already added.',
  noDiceRangeToTake: 'All dice numbers are already taken.',
  oneDiceRange: 'A dice range cannot exceed five digits.',
  oneDiceToTake: 'You can not take last dice number.',
  gamePaused: "Game is paused by ",
};