import { gameScreenDimentions } from '../../../helper/globalFunctions';
import { modal, textColor } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import { FontFamily } from '../../../themes/fontFamily/fontFamily';

const width = gameScreenDimentions.width;
const styles = {
    outerContainer: {
        height: '100%',
        alignItems: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        zIndex: 6,
    },
    closeBtnView: {
        // width: 30,
        left: -13,
        top: -10,
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute'
    },
    innerView: {
        backgroundColor: 'rgb(33,33,33)',
        height: gameScreenDimentions.height * 0.9,
        width: gameScreenDimentions.height * 0.9,
        borderRadius: 10,
        position: 'absolute',
        bottom: 20,
        right: 40,
    },
    chatBoxView: {
        marginHorizontal: 20,
        paddingVertical: 4,
        marginTop: 30,
        flexGrow: 1,
        marginBottom: 20,
        borderRadius: 10,
        borderBottomRightRadius: 25,
        borderBottomLeftRadius: 25,
        borderColor: 'rgb(0,132,118)',
        borderWidth: 2,
        backgroundColor: 'rgb(47,47,47)'
    },
    chatBoxView1: {
        paddingHorizontal: 12,
        paddingVertical: 8,
        flexGrow: 1,
        // width: '100%',
        borderRadius: 10,
        borderBottomRightRadius: 25,
        borderBottomLeftRadius: 25,
    },
    chatTextView: {
        width: 150,
        backgroundColor: 'rgb(39,38,38)',
        borderRadius: 15,
        justifyContent: 'center',
        padding: 10,
        marginLeft: 10,
        marginBottom: 5,
    },
    selectTile: {
        flex: 1,
        color: textColor.green,
        fontFamily: FontFamily.medium,
        fontSize: fontSizes.f33,
        paddingStart: 8
    },
    dateStyle: {
        color: textColor.green,
        textAlign: "center",
        fontFamily: FontFamily.medium,
        fontSize: fontSizes.f33,
    },
    viewStyle: {
        flexDirection: 'row',
    },
}

export default styles;