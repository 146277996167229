import { Method } from './apiMethods';
export const signUpApi = (data) => Method.POST('user/register', data);
export const loginApi = (data) => Method.POST('user/login', data);
export const forgotPasswordApi = (data) => Method.POST('user/forgotPassword', data);
export const createRoomApi = (data) => Method.POST('room/create', data);
export const redeemVoucher = (data) => Method.POST('voucher/redeem', data);  //{voucherCode: 'HELLOO'}
export const logoutApi = () => Method.GET('user/logout');
export const addContact = (data) => Method.POST('contact/add', data);
export const aboutUsApi = () => Method.GET('main/aboutUs');
export const privacyPolicyApi = () => Method.GET('main/privacyPolicy');
export const faqApi = () => Method.POST('faq/list');
export const leaderBoardApi = () => Method.GET('game/leaderboard');
export const updateProfileApi = (data) => Method.PUT('user/edit', data);
export const changePasswordApi = (data) => Method.POST('user/changePassword', data);
export const buyPackage = (data) => Method.POST('/user/purchase', data);
export const checkForSubsApi = (data) => Method.POST('user/checkSubscription', data);
export const termsAndCondApi = () => Method.GET('main/terms');
export const deleteAccountApi = () => Method.DELETE('user/removeAccount');
export const cancelSubscriptionApi = () => Method.DELETE('user/cancelSubscription');
export const soloPlayApi = (data) => Method.POST('room/custom', data);
