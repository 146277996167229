import React, { memo } from 'react';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { ChatSVGIcon } from './chatSVGIcon'


const ChatIconComponent = (props) => {
    return (
        <div>
            <div style={{
                position: 'absolute',
                // right: gameScreenDimentions.width * 0.039,
                // bottom: gameScreenDimentions.width * 0.02,
                right: gameScreenDimentions.width * 0.036,
                bottom: gameScreenDimentions.width * 0.055,
                width: gameScreenDimentions.gameBoardWidth * 0.064,
                height: gameScreenDimentions.gameBoardWidth * 0.062,
                zIndex: 0,
                display: props.display
            }}
            >
                <ChatSVGIcon onClick={props.onClickChat}
                    width={gameScreenDimentions.gameBoardWidth * 0.064}
                    height={gameScreenDimentions.gameBoardWidth * 0.062}
                />
            </div>
        </div>

    )
}

export default memo(ChatIconComponent);
