import React from "react";
import fontSizes from "../../themes/constants/fontSizes";
import { spacing } from "../../themes/constants/spacing";
import strings from "../../themes/constants/strings";
import Spacer from "../../components/game/Spacer";


import styles from './styles';

const GameRuleModal = (props) => {

    const { createRoom } = props;

    return (
        <>
            {(!createRoom && !props?.gameLobby) && <span style={{ ...styles.fontLabel, alignSelf: 'center' }}>{strings.howToPlay}</span>}
            {
                createRoom ?
                    <div className='scroll_div' style={{
                        overflow: 'auto',
                        height: 450,
                        marginRight: -34,
                        paddingRight: 12
                    }}>
                        <Spacer height={spacing.xs} />
                        <span style={{ ...styles.fontTextHeading, fontSize: fontSizes.f75, }}>{strings.thisIsWhere}</span>
                        <Spacer height={spacing.xs} />
                        <span style={styles.fontTextHeading}>{strings.gameMode}: <span style={styles.fontText}>{strings.assignPlayerText}</span></span>
                        <Spacer height={spacing.xs} />
                        <span style={styles.fontTextHeading}>{strings.roomType}: <span style={styles.fontText}>{strings.roomTypeText}</span></span>
                        <Spacer height={spacing.xs} />
                        <span style={styles.fontTextHeading}>{strings.numbersOfPlayers}: <span style={styles.fontText}>{strings.numPlayerText}</span></span>
                        <Spacer height={spacing.xs} />
                        <span style={styles.fontTextHeading}>{strings.createRoom}: <span style={styles.fontText}>{strings.createRoomText}</span></span>
                        <Spacer height={spacing.xs} />
                    </div>
                    :
                    props?.gameLobby ?
                        <div className='scroll_div' style={{
                            overflow: 'auto',
                            height: 450,
                            marginRight: -34,
                            paddingRight: 12
                        }}>
                            <Spacer height={spacing.scH4cent} />
                            <span style={{ ...styles.fontTextHeading, fontSize: fontSizes.f75, }}>{strings.youAreIn}</span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.roomCode}: <span style={styles.fontText}>{strings.roomCodeText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.shareRoomCode}: <span style={styles.fontText}>{strings.shareCodeText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.joinedUsers} <span style={styles.fontText}>{strings.usersText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.spectators}: <span style={styles.fontText}>{strings.spectatorsText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.startGame}: <span style={styles.fontText}>{strings.startGText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.leave}: <span style={styles.fontText}>{strings.leaveText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.sd}: <span style={styles.fontText}>{strings.sdText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.snd}: <span style={styles.fontText}>{strings.sndText}</span></span>
                            <Spacer height={spacing.scH4cent} />
                        </div>
                        :
                        <>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.createAndPlay}: <span style={styles.fontText}>{strings.selectThisButton}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.joinAndPlay}: <span style={styles.fontText}>{strings.joinAPlayText}</span></span>
                            <Spacer height={spacing.xs} />
                            <span style={styles.fontTextHeading}>{strings.publicRooms}: <span style={styles.fontText}>{strings.publicRText}</span></span>
                        </>
            }
        </>
    );
};

export default GameRuleModal;