export default {
    screenBg: require('../../assets/images/bg.png'),
    planeBg: require('../../assets/images/planeBg.png'),
    loginImg: require('../../assets/images/login.png'),
    textInput: require('../../assets/images/textInput.png'),
    buttonBg: require('../../assets/images/buttonBg.png'),
    buttonBlack: require('../../assets/images/buttonBlack.png'),
    registerImg: require('../../assets/images/register.png'),
    profilePic: require('../../assets/images/profileImage.png'),
    addImageIcon: require('../../assets/images/add.png'),
    close: require('../../assets/images/close.png'),
    modalBg: require('../../assets/images/popup.png'),
    modalBg1: require('../../assets/images/popup_1.png'),
    forgotPassIcon: require('../../assets/images/forgotPassword.png'),
    success: require('../../assets/images/success.png'),
    back: require('../../assets/images/back.png'),
    edit: require('../../assets/images/edit.png'),
    createRoomBox: require('../../assets/images/createRoomBox.png'),
    auto: require('../../assets/images/auto.png'),
    leaderboardImg: require('../../assets/images/leaderboard.png'),
    myProfile: require('../../assets/images/myProfile.png'),
    changePassword: require('../../assets/images/changePassword.png'),
    soundOn: require('../../assets/images/soundOn.png'),
    gameHelp: require('../../assets/images/gameHelp.png'),
    faq: require('../../assets/images/faq.png'),
    privacyPolicy: require('../../assets/images/privacyPolicy.png'),
    aboutUs: require('../../assets/images/aboutUs.png'),
    contactUs: require('../../assets/images/contactUs.png'),
    logout: require('../../assets/images/logout.png'),
    yearlyPackage: require('../../assets/images/yearlyPackage.png'),
    settingsBox: require('../../assets/images/settingsBox.png'),
    crown: require('../../assets/images/crown.png'),
    inAppPurchase: require('../../assets/images/inAppPurchase.png'),
    joinedUserBox: require('../../assets/images/joinedUserBox.png'),
    profile: require('../../assets/images/profile.png'),
    profile1: require('../../assets/images/profile1.png'),
    profile2: require('../../assets/images/profile2.png'),
    profile3: require('../../assets/images/profile3.png'),
    profile4: require('../../assets/images/profile4.png'),
    settings: require('../../assets/images/settings.png'),
    settings: require('../../assets/images/settings.png'),
    logo2: require('../../assets/images/logo2.png'),
    readyStatus: require('../../assets/images/readyStatus.png'),
    notReadyStatus: require('../../assets/images/notReadyStatus.png'),
    alarm: require('../../assets/images/alarm.png'),
    alert: require('../../assets/images/alert.png'),
    history: require('../../assets/images/history.png'),

    startup: require('../../assets/images/gameplayScreen/Startup.png'),
    degree: require('../../assets/images/gameplayScreen/Degree.png'),
    skill: require('../../assets/images/gameplayScreen/Skill.png'),
    hands: require('../../assets/images/gameplayScreen/Hands.png'),
    justiceRound: require('../../assets/images/gameplayScreen/JusticeRound.png'),
    roundArrowWithOne: require('../../assets/images/gameplayScreen/RoundArrowWithOne.png'),
    bag: require('../../assets/images/gameplayScreen/MoneyBag.png'),
    homeDollarRound: require('../../assets/images/gameplayScreen/HomeDollar.png'),
    moneyBag: require('../../assets/images/gameplayScreen/BusinessTile.png'),
    multiHomeRound: require('../../assets/images/gameplayScreen/MultiHome.png'),

    dice: require('../../assets/images/gameplayScreen/dice.png'),
    tick: require('../../assets/images/gameplayScreen/tick.png'),
    sdImage: require('../../assets/images/gameplayScreen/sdImage.png'),
    sndImage: require('../../assets/images/gameplayScreen/sndImage.png'),
    cardRule: require('../../assets/images/gameplayScreen/cardRule.png'),

    diceBlack: require('../../assets/images/diceBlack.png'),
    usersIcon: require('../../assets/images/usersIcon.png'),
    qrCode: require('../../assets/images/qrCode.png'),
    user: require('../../assets/images/user.png'),
    degreeCap: require('../../assets/images/degreeCap.png'),
    arrow1: require('../../assets/images/arrow1.png'),
    store: require('../../assets/images/store.png'),
    homedollar: require('../../assets/images/homedollar.png'),
    briefcase: require('../../assets/images/briefcase.png'),
    medal1: require('../../assets/images/medal1.png'),
    mortarboard: require('../../assets/images/mortarboard.png'),
    handshake: require('../../assets/images/handshake.png'),
    suitcase: require('../../assets/images/suitcase.png'),
    store1: require('../../assets/images/store1.png'),
    medalBlack: require('../../assets/images/medalBlack.png'),
    homedollarBlack: require('../../assets/images/homedollarBlack.png'),
    multiHome: require('../../assets/images/multiHome.png'),
    moneyBf: require('../../assets/images/moneyBf.png'),
    help: require('../../assets/images/help.png'),
    tradeHelp: require('../../assets/images/trade_help.png'),
    info: require('../../assets/images/info2.png'),

    send: require('../../assets/images/send.png'),
    arrowUp: require('../../assets/images/up.png'),
    arrowDown: require('../../assets/images/down.png'),
    tick: require('../../assets/images/charm_tick.png'),
    looserBg: require('../../assets/images/looserBg.png'),
    winnerBg: require('../../assets/images/winnerBg.png'),
    sad: require('../../assets/images/sad.png'),
    winner: require('../../assets/images/winner.png'),
    closeSq: require('../../assets/images/closeSq.png'),
    rules: require('../../assets/images/rule.png'),
    ruleBox: require('../../assets/images/ruleBox.png'),
    win: require('../../assets/images/win.png'),
    trophy: require('../../assets/images/trophy.png'),
    lose: require('../../assets/images/lose.png'),


    card_DT1: require('../../assets/cards/card_DT1.png'),
    card_DT2: require('../../assets/cards/card_DT2.png'),
    card_DT3: require('../../assets/cards/card_DT3.png'),
    card_DT4: require('../../assets/cards/card_DT4.png'),
    card_DT5: require('../../assets/cards/card_DT5.png'),
    card_DT6: require('../../assets/cards/card_DT6.png'),
    card_DT7: require('../../assets/cards/card_DT7.png'),
    card_DT8: require('../../assets/cards/card_DT8.png'),
    card_DT9: require('../../assets/cards/card_DT9.png'),
    card_DT10: require('../../assets/cards/card_DT10.png'),
    card_DT11: require('../../assets/cards/card_DT11.png'),
    card_DT12: require('../../assets/cards/card_DT12.png'),
    card_DT13: require('../../assets/cards/card_DT13.png'),
    card_DT14: require('../../assets/cards/card_DT14.png'),
    card_DT15: require('../../assets/cards/card_DT15.png'),
    card_DT16: require('../../assets/cards/card_DT16.png'),
    card_DT17: require('../../assets/cards/card_DT17.png'),
    card_DT18: require('../../assets/cards/card_DT18.png'),
    card_DT19: require('../../assets/cards/card_DT19.png'),
    card_DT20: require('../../assets/cards/card_DT20.png'),
    card_DT21: require('../../assets/cards/card_DT21.png'),
    card_DT22: require('../../assets/cards/card_DT22.png'),
    card_DT23: require('../../assets/cards/card_DT23.png'),
    card_DT24: require('../../assets/cards/card_DT24.png'),
    card_DT25: require('../../assets/cards/card_DT25.png'),
    card_DT26: require('../../assets/cards/card_DT26.png'),
    card_DT27: require('../../assets/cards/card_DT27.png'),
    card_DT28: require('../../assets/cards/card_DT28.png'),
    card_DT29: require('../../assets/cards/card_DT29.png'),
    card_DT30: require('../../assets/cards/card_DT30.png'),
    card_DT31: require('../../assets/cards/card_DT31.png'),
    card_DT32: require('../../assets/cards/card_DT32.png'),
    card_DT33: require('../../assets/cards/card_DT33.png'),
    card_DT34: require('../../assets/cards/card_DT34.png'),
    card_DT35: require('../../assets/cards/card_DT35.png'),
    card_DT36: require('../../assets/cards/card_DT36.png'),
    card_DT37: require('../../assets/cards/card_DT37.png'),
    card_DT38: require('../../assets/cards/card_DT38.png'),
    card_DT39: require('../../assets/cards/card_DT39.png'),
    card_DT40: require('../../assets/cards/card_DT40.png'),
    card_DT41: require('../../assets/cards/card_DT41.png'),
    card_DT42: require('../../assets/cards/card_DT42.png'),
    card_DT43: require('../../assets/cards/card_DT43.png'),
    card_DT44: require('../../assets/cards/card_DT44.png'),

    blockedUser: require('../../assets/images/blockedUser.png'),
    deleteAcc: require('../../assets/images/deleteAcc.png'),
    instructionVideo: require('../../assets/images/instructionVideo.png'),
    pointsBreak2: require('../../assets/images/pointsBreak2.png'),
    pointsBreak: require('../../assets/images/pointsBreak.png'),
    soundOffIcon: require('../../assets/images/soundOff.png'),
    soundOnIcon: require('../../assets/images/soundOn1.png'),
    menuIcon: require('../../assets/images/menuIcon.png'),
    gamePausedBg: require('../../assets/images/gamePaused.png'),
    play: require('../../assets/images/play.png'),
    pause: require('../../assets/images/pause.png'),

};