export const textColor = {
    placeholder: '#D9D7D7',
    white: '#FFFFFF',
    green: '#2E8F81',
    black: '#121315',
    grey: '#757575',
    greyDark: '#5B5B5B',
    red: 'red',
};


export const textInput = {
    green: '#44bca4',
    grey: '#303030',
    green: "#2E8F81",
    black: "#121315",
    greyDark: "#303030",
};

export const modal = {
    outerView: '#00000099',
}

export const header = {
    outerView: '#30303033',
    transparent: '#00000000',
}
export const toggle = {
    background: '#202020',
    textInputBg: "#1c1c1c"
}
export const statusBar = {
    bgColor: '#1e1e1e',
    green: '#2E8F8199',
}
export const compoColor = {
    background: '#272727',
    cardBg: '#00000044',
}
