import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../../helper/socket";
import ApiConstants from "../../../themes/apiConstants";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import { SelectCardSubmoissionType, dialogNames, influenceType, influenceAction, popupType, gameScreen } from "../../../themes/constants/gameConstants";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import Spacer from "../Spacer";
import styles from './styles';
import { gameScreenDimentions, getLeaveStatus } from "../../../helper/globalFunctions";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { stopSmallTimer } from "../../../helper/timer";
import { buttonClickSound } from "../../../helper/playSound";
import { isInternetConnected } from "../../../helper/networkUtils";
import Emitter from "../../../helper/emitter";

const Width = gameScreenDimentions.width;

const TextCardBox = (props) => {
    const dispatch = useDispatch()
    const openDialog = useSelector((state) => state.homeReducer.openDialog);
    const selectedCard = useSelector((state) => state.homeReducer?.selectedCard);
    const ourGameData = useSelector((state) => state.homeReducer?.ourGameData);
    const ourCards = useSelector((state) => state.homeReducer?.ourCards);
    const influenceTypeBack = useSelector((state) => state.homeReducer?.influenceType);
    const displayRollDicePopUpType = useSelector((state) => state.homeReducer?.displayRollDicePopUpType);
    const reRollType = useSelector((state) => state.homeReducer?.reRollType);
    const playCardType = useSelector((state) => state.homeReducer?.playCardType);
    const outComeType = useSelector((state) => state.homeReducer?.outComeType);
    const range = useSelector((state) => state.homeReducer?.range);
    const [fromPrivilege, setFromPrivilege] = React.useState(false);
    const [fromPrivilegeOther, setFromPrivilegeOther] = React.useState(false);
    const [isOther, setIsOther] = React.useState(false);
    const [isPower, setIsPower] = React.useState(false);

    React.useEffect(() => {
        if (openDialog === dialogNames.close) {
            setFromPrivilege(false)
            setFromPrivilegeOther(false)
            setIsOther(false)
        }
    }, [openDialog])

    const onBackPress = () => {

        Emitter.emit("openRadioBox", { screen: gameScreen.screen1 });
        // dispatch({
        //     type: ApiConstants.SELECT_CARD_UPDATE,
        //     payload: {
        //         selectCard: true,
        //         selectCardSubmissionType: SelectCardSubmoissionType.opportunity,
        //     }
        // })
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.close
            }
        })
    }

    const backWithEmit = () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.close
                }
            })
            socket.emit('influence', { number: 1, action: influenceAction.no, type: influenceType.power, cardType: influenceTypeBack })
            stopSmallTimer(dispatch)
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
        }
    }

    const outcome = {
        notQualified: {
            id: 1,
            text: strings.youDontQualify,
            btnCenter: strings.back,
            onPressCenter: onBackPress
        },
        usePowerToken: {
            id: 2,
            text: strings.usePowerToken,
            btnLeft: strings.no,
            btnRight: strings.yes,
            onPressLeft: () => {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.notQualified,
                    }
                })
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    socket.emit('powerOpportunityCard', ourCards[selectedCard - 1])
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        useInfluence: {
            id: 3,
            text: strings.toUseYour,
            btnCenter: strings.back,
            onPressCenter: onBackPress
        },
        readyToPlay: {
            id: 4,
            text: strings.readyToPlay,
            btnLeft: strings.playCard,
            btnRight: strings.back,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    socket.emit('playOpportunity', ourCards[selectedCard - 1])
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: onBackPress
        },
        notEnoughSpace: {
            id: 5,
            text: strings.youDontHave,
            btnCenter: strings.back,
            onPressCenter: onBackPress
        },
        useYourInfluenceWithPower: {
            id: 6,
            text: strings.useYourInfluence,
            btnLeft: strings.no,
            btnRight: strings.yes,
            onPressRight: () => {
                dispatch({
                    type: ApiConstants.SAVE_DIALOG_ID,
                    payload: {
                        id: 6
                    }
                })
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.usePowerTokenBtnTwo
                    }
                })
            },
            onPressLeft: () => {
                backWithEmit();
            },

        },
        useYourInfluenceWithPrivilege: {
            id: 7,
            text: strings.useYourInfluence,
            btnLeft: strings.no,
            btnRight: strings.yes,
            onPressRight: () => {
                dispatch({
                    type: ApiConstants.SAVE_DIALOG_ID,
                    payload: {
                        id: 7
                    }
                })
                if (ourGameData?.tokens?.power > 0) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.usePowerTokenBtnThree
                        }
                    })
                } else {
                    setFromPrivilege(true);
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.usePowerTokenBtnTwo
                        }
                    })
                }
            },
            onPressLeft: () => {
                backWithEmit()
            }
        },
        rollDiceToPlay: {
            id: 8,
            text: strings.rollDice,
            btnLeft: strings.rollDice1,
            btnRight: strings.exit,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('readyToRollDice', displayRollDicePopUpType)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    socket.emit('refund')
                    onBackPress()
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        rollDiceToPlayWithoutBack: {
            id: 8,
            text: strings.rollDice,
            btnLeft: strings.rollDice1,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('readyToRollDice', displayRollDicePopUpType)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        useInfluenceOtherWithPower: {
            id: 9,
            text: strings.useYourInfluenceCard,
            btnLeft: strings.no,
            btnRight: strings.yes,
            onPressRight: () => {
                setIsOther(true);
                setFromPrivilegeOther(false);
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.usePowerTokenBtnTwo
                    }
                })
            },
            onPressLeft: () => {
                backWithEmit()
                dispatch({
                    type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                    payload: true
                })
            }
        },
        useInfluenceOtherWithPrivilege: {
            id: 10,
            text: strings.useYourInfluenceCard,
            btnLeft: strings.no,
            btnRight: strings.yes,
            onPressRight: () => {
                setIsOther(true);
                setFromPrivilegeOther(true)
                if (ourGameData?.tokens?.power > 0) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.usePowerTokenBtnThree
                        }
                    })
                } else {
                    setFromPrivilege(true);
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.usePowerTokenBtnTwo
                        }
                    })
                }
            },
            onPressLeft: () => {
                backWithEmit()
                dispatch({
                    type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                    payload: true
                })
            }
        },
        usePowerTokenBtnTwo: {
            id: 11,
            text: strings.useYourInfluence,
            btnLeft: (fromPrivilegeOther || fromPrivilege) ? strings.payThree : strings.usePowerToken1,
            btnRight: strings.back,
            onPressRight: () => {
                if (!isOther) {
                    if (fromPrivilege) {
                        dispatch({
                            type: ApiConstants.OPEN_DIALOG_BOX,
                            payload: {
                                openDialog: dialogNames.useYourInfluenceWithPrivilege
                            }
                        })
                    } else {
                        dispatch({
                            type: ApiConstants.OPEN_DIALOG_BOX,
                            payload: {
                                openDialog: dialogNames.useYourInfluenceWithPower
                            }
                        })
                    }
                }
                else if (isOther) {
                    if (fromPrivilegeOther) {
                        dispatch({
                            type: ApiConstants.OPEN_DIALOG_BOX,
                            payload: {
                                openDialog: dialogNames.useInfluenceOtherWithPrivilege
                            }
                        })
                    } else {
                        dispatch({
                            type: ApiConstants.OPEN_DIALOG_BOX,
                            payload: {
                                openDialog: dialogNames.useInfluenceOtherWithPower
                            }
                        })
                    }
                }
            },
            onPressLeft: () => {
                if (!isOther) {
                    if (fromPrivilege || fromPrivilegeOther) {
                        dispatch({
                            type: ApiConstants.OPEN_DICE_BOX,
                            payload: {
                                openDiceBox: true,
                                tokenType: influenceType.privilege
                            }
                        })
                    } else {
                        dispatch({
                            type: ApiConstants.OPEN_DICE_BOX,
                            payload: {
                                openDiceBox: true,
                                tokenType: influenceType.power
                            }
                        })
                    }
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                } else if (isOther) {
                    if (fromPrivilegeOther || fromPrivilege) {
                        setIsPower(false)
                    }
                    else {
                        setIsPower(true)
                    }
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.wouldYouLikeTo
                        }
                    })

                }
            }
        },
        usePowerTokenBtnThree: {
            id: 12,
            btnLeft: strings.payThree,
            btnCenter: strings.usePowerToken1,
            btnBottom: strings.back,
            onPressLeft: () => {
                if (!isOther) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DICE_BOX,
                        payload: {
                            openDiceBox: true,
                            tokenType: influenceType.privilege,
                        }
                    })
                }
                else if (isOther) {
                    setIsPower(false)
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.wouldYouLikeTo
                        }
                    })
                }
            },
            onPressCenter: () => {
                if (!isOther) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DICE_BOX,
                        payload: {
                            openDiceBox: true,
                            tokenType: influenceType.power,
                        }
                    })
                }
                else if (isOther) {
                    setIsPower(true)
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.wouldYouLikeTo
                        }
                    })
                }
            },
            onPressBottom: () => {
                if (!isOther) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.useYourInfluenceWithPrivilege
                        }
                    })
                }
                else if (isOther) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.useInfluenceOtherWithPrivilege
                        }
                    })
                }

            }
        },
        wouldYouLikeTo: {
            id: 13,
            text: strings.addOrTakeDice,
            btnLeft: strings.add,
            btnRight: strings.take,
            btnBottom: strings.back,
            onPressLeft: () => {
                if (range.length >= 5) {
                    ShowAlertMessage(ValidationConstants.oneDiceRange, popupType.error);
                } else {
                    setIsOther(false);
                    dispatch({
                        type: ApiConstants.SAVE_DIALOG_ID,
                        payload: {
                            id: 13
                        }
                    })
                    dispatch({
                        type: ApiConstants.INFLUENCE_ACTION,
                        payload: influenceAction.add
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DICE_BOX,
                        payload: {
                            openDiceBox: true,
                            tokenType: isPower ? influenceType.power : influenceType.privilege
                        }
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                }
            },
            onPressRight: () => {
                if (range.length < 2) {
                    ShowAlertMessage(ValidationConstants.oneDiceToTake, popupType.error);
                } else {
                    setIsOther(false);
                    dispatch({
                        type: ApiConstants.SAVE_DIALOG_ID,
                        payload: {
                            id: 13
                        }
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    dispatch({
                        type: ApiConstants.INFLUENCE_ACTION,
                        payload: influenceAction.take
                    })
                    dispatch({
                        type: ApiConstants.OPEN_DICE_BOX,
                        payload: {
                            openDiceBox: true,
                            tokenType: isPower ? influenceType.power : influenceType.privilege
                        }
                    })
                }
            },
            onPressBottom: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.useInfluenceOtherWithPrivilege
                        }
                    })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
        },
        wantAnotherChanceWithPrivilegeThree: {
            id: 14,
            text: strings.wantAnotherChance,
            btnLeft: strings.yesPay3Privilege,
            btnCenter: strings.yesUsePowerToken,
            btnBottom: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.own, type: influenceType.privilege, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }

            },
            onPressCenter: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.own, type: influenceType.power, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressBottom: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.power, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        wantAnotherChanceWithPrivilegeTwo: {
            id: 15,
            text: strings.wantAnotherChance,
            btnLeft: strings.yesPay3Privilege,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.own, type: influenceType.privilege, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }

            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.power, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        wantAnotherChanceWithPower: {
            id: 16,
            text: strings.wantAnotherChance,
            btnLeft: strings.yesUsePowerToken,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.own, type: influenceType.power, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.power, cardType: reRollType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        wantToGiveWithPower: {
            id: 17,
            text: strings.wantToGive,
            btnLeft: strings.yesUsePowerToken,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.other, type: influenceType.power, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.power, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        wantToGiveWithPrivilegeThree: {
            id: 18,
            text: strings.wantToGive,
            btnLeft: strings.yesPay3Privilege,
            btnCenter: strings.yesUsePowerToken,
            btnBottom: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.other, type: influenceType.privilege, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressCenter: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.other, type: influenceType.power, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressBottom: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.privilege, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        wantToGiveWithPrivilegeTwo: {
            id: 19,
            text: strings.wantToGive,
            btnLeft: strings.yesPay3Privilege,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.other, type: influenceType.privilege, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('reRoll', { action: influenceAction.no, type: influenceType.privilege, cardType: reRollType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        deflectOutcomeForYourself: {
            id: 20,
            text: strings.doYouWant,
            btnLeft: strings.yes,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('outcome', { action: influenceAction.own, type: outComeType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('outcome', { action: influenceAction.no, type: outComeType })
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        deflectOutcomeForAnotherPlayer: {
            id: 21,
            text: strings.doYouWantAnotherPlayer,
            btnLeft: strings.yes,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('outcome', { action: influenceAction.other, type: outComeType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('outcome', { action: influenceAction.no, type: outComeType })
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        usePowerTokenForTaxBreak: {
            id: 22,
            text: strings.usePowerTokenForBreak,
            btnLeft: strings.yes,
            btnRight: strings.no,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('powerTaxCard', true)
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            },
            onPressRight: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('powerTaxCard', false)
                    stopSmallTimer(dispatch)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
        playCard: {
            id: 23,
            text: strings.readyToPlay,
            btnLeft: strings.playCard,
            onPressLeft: () => {
                const checkAllowed = getLeaveStatus()
                if (isInternetConnected() && checkAllowed) {
                    dispatch({
                        type: ApiConstants.OPEN_DIALOG_BOX,
                        payload: {
                            openDialog: dialogNames.close
                        }
                    })
                    socket.emit('playSelection', playCardType)
                } else {
                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                }
            }
        },
    }

    return (
        <>
            {openDialog !== 'close' && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <Spacer height={spacing.xl} />
                    {outcome[openDialog]?.text &&
                        <>
                            <span style={styles.selectTile}>{outcome[openDialog].text}</span>
                            <Spacer height={spacing.m} />
                        </>
                    }
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                        {outcome[openDialog]?.btnLeft &&
                            <ButtonCustom
                                outerContainer={{ width: 210, height: 38, marginRight: 10 }}
                                resize={"stretch"}
                                style={{ width: 210, height: 38 }}
                                textColor={{ fontSize: fontSizes.f39 }}
                                imgBg={appImages.buttonBg}
                                title={outcome[openDialog]?.btnLeft}
                                onPress={() => {
                                    buttonClickSound();
                                    outcome[openDialog]?.onPressLeft();
                                }}
                            />
                        }
                        {outcome[openDialog]?.btnCenter &&
                            <ButtonCustom
                                outerContainer={{ width: 210, height: 38, marginLeft: 10 }}
                                resize={"stretch"}
                                style={{ width: 210, height: 38 }}
                                textColor={{ fontSize: fontSizes.f39 }}
                                imgBg={appImages.buttonBg}
                                title={outcome[openDialog]?.btnCenter}
                                onPress={() => {
                                    buttonClickSound();
                                    outcome[openDialog]?.onPressCenter();
                                }}
                            />
                        }
                        {outcome[openDialog]?.btnRight &&
                            <ButtonCustom
                                outerContainer={{ width: 210, height: 38, }}
                                resize={"stretch"}
                                style={{ width: 210, height: 38 }}
                                textColor={{ fontSize: fontSizes.f39 }}
                                imgBg={appImages.buttonBg}
                                title={outcome[openDialog]?.btnRight}
                                onPress={() => {
                                    buttonClickSound();
                                    outcome[openDialog]?.onPressRight();
                                }}
                            />
                        }
                    </div>
                    {outcome[openDialog]?.btnBottom &&
                        <>
                            <Spacer height={spacing.l} />
                            <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', display: 'flex' }}>
                                <ButtonCustom
                                    outerContainer={{ width: 210, height: 38, }}
                                    resize={"stretch"}
                                    style={{ width: 210, height: 38 }}
                                    textColor={{ fontSize: fontSizes.f39 }}
                                    imgBg={appImages.buttonBg}
                                    title={outcome[openDialog]?.btnBottom}
                                    onPress={() => {
                                        buttonClickSound();
                                        outcome[openDialog]?.onPressBottom();
                                    }}
                                />
                            </div>
                        </>
                    }
                    <Spacer height={spacing.xl} />
                </div>
            </div >
            }
        </>

    );
};

export default TextCardBox;