import ApiConstants from '../../../../themes/apiConstants';

export const soloPlayAction = (payload) => {
    return ({
        type: ApiConstants.API_SOLO_PLAY_LOAD,
        payload
    });
}

export const createRoomAction = (payload) => {
    return ({
        type: ApiConstants.API_CREATE_ROOM_LOAD,
        payload,
    });
}

export const roomSuccessAction = (payload) => {
    return ({
        type: ApiConstants.API_CREATE_ROOM_SUCCESS,
        data: payload,
    });
}

export const getRoomListSocketAction = (payload) => {
    return ({
        type: ApiConstants.API_PUBLIC_ROOM_LIST_SUCCESS,
        payload,
    });
}
export const redeemVoucherAction = (payload) => {
    return ({
        type: ApiConstants.API_REEDEEM_VOUCHER_LOAD,
        payload,
    });
}
export const aboutUsAction = () => {
    return ({
        type: ApiConstants.API_ABOUT_US_LOAD,
    });
}
export const privacyPolicyAction = () => {
    return ({
        type: ApiConstants.API_PRIVACY_POLICY_LOAD,
    });
}

export const tAndCAction = () => {
    return ({
        type: ApiConstants.API_T_AND_C_LOAD,
    });
}

export const faqAction = () => {
    return ({
        type: ApiConstants.API_FAQ_LOAD,
    });
}

export const leaderBoardAction = () => {
    return ({
        type: ApiConstants.API_LEADER_BOARD_LOAD,
    });
}

export const updateProfileAction = (payload) => {
    return ({
        type: ApiConstants.API_UPDATE_PROFILE_LOAD,
        payload,
    });
}

export const getBlockedUsersSocketAction = (payload) => {
    return ({
        type: ApiConstants.API_BLOCKED_USERS_LIST_SUCCESS,
        payload,
    });
}