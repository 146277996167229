import React from "react";
import strings from "../../../themes/constants/strings";
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import styles from './styles';
import Spacer from "../Spacer";
import fontSizes from "../../../themes/constants/fontSizes";
import { spacing } from "../../../themes/constants/spacing";
import { commonImages } from "../../../themes/appImage";

const PointsBreakdownModal = (props) => {
    const { load, closeDisplay } = props;
    return (
        <>
            {load && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <Spacer height={spacing.scH2cent} />
                    <span style={{ ...styles.fontLabel, alignSelf: 'center' }}>{strings.prosperityPoints}</span>
                    <Spacer height={spacing.m} />
                    <div showsVerticalScrollIndicator={false} contentContainerStyle={{ ...styles.outerModalView }}>
                        <span style={{ ...styles.fontLabel, fontSize: fontSizes.f38, }}>
                            {`After the game has ended points are allocated to each player and an ultimate winner is determined. Here is how the points are allocated:`}
                        </span>
                        <>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Skill tile = <span style={styles.fontText}>1 Prosperity point</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Degree tile = <span style={styles.fontText}>2 Prosperity points</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Startup tile = <span style={styles.fontText}>3 Prosperity points</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Privilege token = <span style={styles.fontText}>.5 Prosperity point(round up)</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Justice token = <span style={styles.fontText}>-2 Prosperity points</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontTextHeading}>1 Power token =  <span style={styles.fontText}>3 points (must have at end of game to count)</span></span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontText}>Tier you are on multiplied by1 Prosperity point</span>
                            <Spacer height={spacing.m} />
                            <span style={styles.fontTextHeading}>Competitive Win Only:</span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontText}>Add 5 Prosperity points to the winner's total.</span>
                            <Spacer height={spacing.m} />
                            <span style={styles.fontTextHeading}>Cooperative Win Only:</span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontText}>Add 1 Prosperity point each time you Help,</span>
                            <Spacer height={spacing.xxxs} />
                            <span style={styles.fontText}>Deduct 1 Prosperity point each time you Hinder.</span>

                        </>
                        <Spacer height={spacing.scH2cent} />
                    </div>
                    <img src={commonImages.back}
                        onClick={() => closeDisplay()}
                        style={{
                            position: 'absolute',
                            left: gameScreenDimentions.width * 0.014,
                            top: 16,
                            width: gameScreenDimentions.gameBoardWidth * 0.030,
                            height: gameScreenDimentions.gameBoardWidth * 0.028,
                            cursor: 'pointer'
                        }}
                    />
                </div>
            </div >}
        </>

    );
};

export default PointsBreakdownModal;