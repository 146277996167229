import React from "react";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import Spacer from "../Spacer";
import ButtonCustom from "../ButtonCustom";
import styles from './styles';
import fontSizes from "../../../themes/constants/fontSizes";
import appImages from "../../../themes/appImages/appImages";

const RelaodFocus = () => {
    const [isResized, setIsResized] = React.useState(false)
    React.useEffect(() => {
        window.addEventListener("blur", blur);
        return () => {
            window.removeEventListener('blur', blur);
        }
    }, [])
    const blur = () => {
        //setIsResized(true)
    }
    return (
        <>
            {isResized && <div style={styles.outerContainer}>
                <div style={styles.modalView} >
                    <Spacer height={spacing.l} />
                    <span style={styles.selectTile}>{strings.reloadFocus}</span>
                    <Spacer height={spacing.l} />
                    <ButtonCustom
                        textColor={{ fontSize: fontSizes.f40 }}
                        imgBg={appImages.buttonBg}
                        title={'Reload'}
                        onPress={() => {
                            window.location.reload()
                        }}
                    />
                    <Spacer height={spacing.l} />
                </div>
            </div>
            }
        </>

    );
};

export default RelaodFocus;

