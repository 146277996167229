import { textColor, textInput } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';
import fontSizes from '../../../themes/constants/fontSizes';
import bg from '../../../assets/images/popup_1.png';

const width = gameScreenDimentions.width;
const styles = {
    outerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    modalView: {
        paddingLeft: 10,
        paddingRight: 14,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 18,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: width * 0.26,
        position: "absolute",
        right: width * 0.02,
        top: width * 0.048,
        zIndex: 8,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column'
    },
    imageViewStyle: {
        width: width * 0.06,
        height: width * 0.06,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: textColor.green,
        borderRadius: (width * 0.06) / 2,
        alignSelf: 'center',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    profileImageStyle: {
        width: width * 0.06 - 2,
        height: (width * 0.06) - 2,
        borderRadius: (width * 0.06) - 2 / 2,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
        objectFit: 'cover',
        borderColor: textColor.green,
        borderWidth: 2,
        borderStyle: 'solid',
    },
    fontTextHeading: {
        includeFontPadding: false,
        fontWeight: '800',
        color: textColor.green,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
        marginRight: 4,
        display: 'flex',
    },
    fontText: {
        paddingLeft: 4,
        fontWeight: '600',
        color: textColor.white,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
        display: 'flex',
    },
}

export default styles;