import React from 'react';
import ImageProfileWIthProgress from './ImageProfileWIthProgress';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { url } from '../../redux/axios/apikit';
import { useSelector } from 'react-redux';
import RadioCardBox from './radioCardBox';
import EnterTokenComponent from './enterTokenComponent';
import RollDice from './RollDice';

const GameUsers = (props) => {
    const roomData = useSelector((state) => state.homeReducer.roomData);
    const currentTimer = useSelector(state => state.homeReducer.currentTimer);
    const userLength = roomData?.players.length
    const user1Style = userLength === 6 ? styles.user6 : userLength === 5 ? styles.user5 : userLength === 4 ? styles.user4 : userLength === 3 ? styles.user3 : styles.user2
    const user2Style = userLength === 6 ? styles.user5 : userLength === 5 ? styles.user4 : userLength === 4 ? styles.user3 : userLength === 3 ? styles.user2 : styles.user1
    const user3Style = userLength === 6 ? styles.user4 : userLength === 5 ? styles.user3 : userLength === 4 ? styles.user2 : styles.user1
    const user4Style = userLength === 6 ? styles.user3 : userLength === 5 ? styles.user2 : styles.user1
    const user5Style = userLength === 6 ? styles.user2 : styles.user1
    return (
        <>
            <ImageProfileWIthProgress
                style={{ ...styles.main, ...user1Style }}
                profileImage={`${url}${roomData?.players[0]?.user?.profileImage}`}
                userType={roomData?.players[0]?.role}
                percent={(roomData?.players[0]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[0]?.pawn}
                index={0}
            />
            <ImageProfileWIthProgress
                style={{ ...styles.main, ...user2Style }}
                userType={roomData?.players[1]?.role}
                profileImage={`${url}${roomData?.players[1]?.user?.profileImage}`}
                percent={(roomData?.players[1]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[1]?.pawn}
                index={1}
            />
            {roomData?.players.length >= 3 && <ImageProfileWIthProgress
                style={{ ...styles.main, ...user3Style }}
                userType={roomData?.players[2]?.role}
                profileImage={`${url}${roomData?.players[2]?.user?.profileImage}`}
                percent={(roomData?.players[2]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[2]?.pawn}
                index={2}
            />}
            {roomData?.players.length >= 4 && <ImageProfileWIthProgress
                style={{ ...styles.main, ...user4Style }}
                userType={roomData?.players[3]?.role}
                profileImage={`${url}${roomData?.players[3]?.user?.profileImage}`}
                percent={(roomData?.players[3]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[3]?.pawn}
                index={3}
            />}
            {roomData?.players.length >= 5 && <ImageProfileWIthProgress
                style={{ ...styles.main, ...user5Style }}
                userType={roomData?.players[4]?.role}
                profileImage={`${url}${roomData?.players[4]?.user?.profileImage}`}
                percent={(roomData?.players[4]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[4]?.pawn}
                index={4}
            />}
            {roomData?.players.length >= 6 && <ImageProfileWIthProgress
                style={{ ...styles.main, ...styles.user1 }}
                userType={roomData?.players[5]?.role}
                profileImage={`${url}${roomData?.players[5]?.user?.profileImage}`}
                percent={(roomData?.players[5]?.user?._id === roomData?.turn?.player?._id) ? currentTimer : 0}
                playerNum={roomData?.players[5]?.pawn}
                index={5}
            />}
            <RadioCardBox />
            <EnterTokenComponent />
            <RollDice />
        </>
    );
};

const styles = {
    main: {
        width: gameScreenDimentions.width * 0.03,
        height: gameScreenDimentions.width * 0.03,
        display: 'flex',
        position: 'absolute',
        top: gameScreenDimentions.width * 0.01,
        backgroundColor: 'white',
        borderRadius: gameScreenDimentions.width * 0.021,
        boxShadow: '0px 0px 8px 5px #00000044',
        cursor: 'pointer'
    },
    user1: {
        right: gameScreenDimentions.width * 0.02,
    },
    user2: {
        right: gameScreenDimentions.width * 0.06,
    },
    user3: {
        right: gameScreenDimentions.width * 0.10,
    },
    user4: {
        right: gameScreenDimentions.width * 0.14,
    },
    user5: {
        right: gameScreenDimentions.width * 0.18,
    },
    user6: {
        right: gameScreenDimentions.width * 0.22,
    }
}

export default GameUsers