import React from 'react';

const Spacer = (props) => {

    const { height, width } = props;
    return (
        <div style={{ height: height, width: width, ...props }} />
    );
}

export default Spacer;