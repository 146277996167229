import ApiConstants from '../../../themes/apiConstants';

export const updateAuthenticationForm = (payload, key, subKey) => {
    return ({
        type: ApiConstants.UPDATE_AUTHENTICATION_FORM,
        payload,
        key,
        subKey
    });
}

export const signupAction = (payload) => {
    return ({
        type: ApiConstants.API_SIGNUP_LOAD,
        payload,
    });
}


export const loginAction = (payload) => {
    return ({
        type: ApiConstants.API_LOGIN_LOAD,
        payload,
    });
}
export const logoutAction = (userId) => {
    return ({
        type: ApiConstants.API_LOGOUT_LOAD,
        userId,
    });
}

export const forgotPasswordAction = (payload) => {
    return ({
        type: ApiConstants.API_FORGOT_PASSWORD_LOAD,
        payload,
    });
}

export const changePassAction = (payload) => {
    return ({
        type: ApiConstants.API_CHANGE_PASSWORD_LOAD,
        payload,
    });
}

export const deleteAccountAction = (userId) => {
    return ({
        type: ApiConstants.API_DELETE_ACCOUNT_LOAD,
        userId
    });
}