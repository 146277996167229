import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import {
  gameScreenDimentions, getCurrentP1, getCurrentP2, getCurrentP3, getCurrentP4, getCurrentP5, getCurrentP6, setCurrentP1, setCurrentP2,
  setCurrentP3, setCurrentP4, setCurrentP5, setCurrentP6
} from '../../helper/globalFunctions';
import Pawn1 from '../../assets/svg/Pawn1.svg';
import Pawn2 from '../../assets/svg/Pawn2.svg';
import Pawn3 from '../../assets/svg/Pawn3.svg';
import Pawn4 from '../../assets/svg/Pawn4.svg';
import Pawn5 from '../../assets/svg/Pawn5.svg';
import Pawn6 from '../../assets/svg/Pawn6.svg';
import { pawn1Array, pawn2Array, pawn3Array, pawn4Array, pawn5Array, pawn6Array } from '../../utils/pawnMovements';
import ApiConstants from '../../themes/apiConstants'
import { pawnMovementSound } from '../../helper/playSound';

const Pawn = () => {
  const dispatch = useDispatch()
  const pawn1Animation = useAnimation()
  const pawn2Animation = useAnimation()
  const pawn3Animation = useAnimation()
  const pawn4Animation = useAnimation()
  const pawn5Animation = useAnimation()
  const pawn6Animation = useAnimation()
  const roomData = useSelector((state) => state.homeReducer.roomData);
  const isSomeoneLeft = useSelector((state) => state.homeReducer.isSomeoneLeft);
  const previousP1 = useSelector((state) => state.homeReducer.pawn1);
  const previousP2 = useSelector((state) => state.homeReducer.pawn2);
  const previousP3 = useSelector((state) => state.homeReducer.pawn3);
  const previousP4 = useSelector((state) => state.homeReducer.pawn4);
  const previousP5 = useSelector((state) => state.homeReducer.pawn5);
  const previousP6 = useSelector((state) => state.homeReducer.pawn6);
  let nextP1 = useSelector((state) => state.homeReducer.cPawn1);
  let nextP2 = useSelector((state) => state.homeReducer.cPawn2);
  let nextP3 = useSelector((state) => state.homeReducer.cPawn3);
  let nextP4 = useSelector((state) => state.homeReducer.cPawn4);
  let nextP5 = useSelector((state) => state.homeReducer.cPawn5);
  let nextP6 = useSelector((state) => state.homeReducer.cPawn6);

  React.useEffect(() => {
    setTimeout(() => {
      setPawn1();
      setPawn2();
      setPawn3();
      setPawn4();
      setPawn5();
      setPawn6();
    }, 500);
    return () => {
      setCurrentP1(1)
      setCurrentP2(1)
      setCurrentP3(1)
      setCurrentP4(1)
      setCurrentP5(1)
      setCurrentP6(1)
    }
  }, [])

  React.useEffect(() => {
    if (isSomeoneLeft) {
      setPawn1();
      setPawn2();
      setPawn3();
      setPawn4();
      setPawn5();
      setPawn6();
      dispatch({ type: ApiConstants.IS_SOMEONE_LEFT, payload: false });
    }
  }, [isSomeoneLeft])

  React.useEffect(() => {
    if (nextP1 !== previousP1) {
      movePawn1(nextP1, nextP1 > previousP1);
    }
    else {
      setPawn1();
    }
  }, [nextP1, previousP1])

  React.useEffect(() => {
    if (nextP2 !== previousP2) {
      movePawn2(nextP2, nextP2 > previousP2);
    }
    else {
      setPawn2();
    }
  }, [nextP2, previousP2])

  React.useEffect(() => {
    if (nextP3 !== previousP3) {
      movePawn3(nextP3, nextP3 > previousP3);
    }
  }, [nextP3, previousP3])

  React.useEffect(() => {
    if (nextP4 !== previousP4) {
      movePawn4(nextP4, nextP4 > previousP4);
    }
  }, [nextP4, previousP4])

  React.useEffect(() => {
    if (nextP5 !== previousP5) {
      movePawn5(nextP5, nextP5 > previousP5);
    }
  }, [nextP5, previousP5])

  React.useEffect(() => {
    if (nextP6 !== previousP6) {
      movePawn6(nextP6, nextP6 > previousP6);
    }
  }, [nextP6, previousP6])


  const movePawn1 = async (p1Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn1Array.slice(getCurrentP1(), p1Steps);
      if (tempArray.length > 0) {
        await pawn1Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP1((getCurrentP1() + 1))
        movePawn1(p1Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_1, payload: getCurrentP1() });
      }
    }
    else {
      let tempArray = pawn1Array.slice(p1Steps - 1, getCurrentP1() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn1Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP1((getCurrentP1() - 1))
        movePawn1(p1Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_1, payload: getCurrentP1() });
      }
    }
  }
  const movePawn2 = async (p2Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn2Array.slice(getCurrentP2(), p2Steps);
      if (tempArray.length > 0) {
        await pawn2Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP2((getCurrentP2() + 1))
        movePawn2(p2Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_2, payload: getCurrentP2() });
      }
    }
    else {
      let tempArray = pawn2Array.slice(p2Steps - 1, getCurrentP2() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn2Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP2((getCurrentP2() - 1))
        movePawn2(p2Steps, isIncrementing)

      }
      else {
        dispatch({ type: ApiConstants.PAWN_2, payload: getCurrentP2() });
      }
    }
  }
  const movePawn3 = async (p3Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn3Array.slice(getCurrentP3(), p3Steps);
      if (tempArray.length > 0) {
        await pawn3Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP3((getCurrentP3() + 1))
        movePawn3(p3Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_3, payload: getCurrentP3() });
      }
    }
    else {
      let tempArray = pawn3Array.slice(p3Steps - 1, getCurrentP3() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn3Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP3((getCurrentP3() - 1))
        movePawn3(p3Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_3, payload: getCurrentP3() });
      }
    }
  }
  const movePawn4 = async (p4Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn4Array.slice(getCurrentP4(), p4Steps);
      if (tempArray.length > 0) {
        await pawn4Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP4((getCurrentP4() + 1))
        movePawn4(p4Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_4, payload: getCurrentP4() });
      }
    }
    else {
      let tempArray = pawn4Array.slice(p4Steps - 1, getCurrentP4() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn4Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP4((getCurrentP4() - 1))
        movePawn4(p4Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_4, payload: getCurrentP4() });
      }
    }
  }
  const movePawn5 = async (p5Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn5Array.slice(getCurrentP5(), p5Steps);
      if (tempArray.length > 0) {
        await pawn5Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP5((getCurrentP5() + 1))
        movePawn5(p5Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_5, payload: getCurrentP5() });
      }
    }
    else {
      let tempArray = pawn5Array.slice(p5Steps - 1, getCurrentP5() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn5Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP5((getCurrentP5() - 1))
        movePawn5(p5Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_5, payload: getCurrentP5() });
      }
    }
  }
  const movePawn6 = async (p6Steps, isIncrementing) => {
    if (isIncrementing) {
      let tempArray = pawn6Array.slice(getCurrentP6(), p6Steps);
      if (tempArray.length > 0) {
        await pawn6Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound();
        setCurrentP6((getCurrentP6() + 1))
        movePawn6(p6Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_6, payload: getCurrentP6() });
      }
    }
    else {
      let tempArray = pawn6Array.slice(p6Steps - 1, getCurrentP6() - 1);
      if (tempArray.length > 0) {
        tempArray = tempArray.reverse()
        await pawn6Animation.start({
          x: tempArray[0]?.x,
          y: tempArray[0]?.y,
          transition: { duration: 0.500 }
        })
        pawnMovementSound()
        setCurrentP6((getCurrentP6() - 1))
        movePawn6(p6Steps, isIncrementing)
      }
      else {
        dispatch({ type: ApiConstants.PAWN_6, payload: getCurrentP6() });
      }
    }
  }

  const setPawn1 = async () => {
    let tempArray = pawn1Array.slice(getCurrentP1() - 1, getCurrentP1());
    if (tempArray.length > 0) {
      await pawn1Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }
  const setPawn2 = async () => {
    let tempArray = pawn2Array.slice(getCurrentP2() - 1, getCurrentP2());
    if (tempArray.length > 0) {
      await pawn2Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }
  const setPawn3 = async () => {
    let tempArray = pawn3Array.slice(getCurrentP3() - 1, getCurrentP3());
    if (tempArray.length > 0) {
      await pawn3Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }
  const setPawn4 = async () => {
    let tempArray = pawn4Array.slice(getCurrentP4() - 1, getCurrentP4());
    if (tempArray.length > 0) {
      await pawn4Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }
  const setPawn5 = async () => {
    let tempArray = pawn5Array.slice(getCurrentP5() - 1, getCurrentP5());
    if (tempArray.length > 0) {
      await pawn5Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }
  const setPawn6 = async () => {
    let tempArray = pawn6Array.slice(getCurrentP6() - 1, getCurrentP6());
    if (tempArray.length > 0) {
      await pawn6Animation.start({
        x: tempArray[0]?.x,
        y: tempArray[0]?.y,
        transition: { duration: 0 }
      })
    }
  }

  return (
    <AnimatePresence>
      {/*First */}
      <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.584,
          bottom: gameScreenDimentions.gameBoardWidth * 0.551,
          zIndex: 1,
          objectFit: 'cover'
        }}
        animate={pawn1Animation}
        layoutId={'pawn1'}
        key={'pawn1'}
      >
        {PawnForUser(roomData?.players[0])}
      </motion.div>
      {/*Second */}
      <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.609,
          bottom: gameScreenDimentions.gameBoardWidth * 0.551,
          zIndex: 1
        }}
        animate={pawn2Animation}
        layoutId={'pawn2'}
        key={'pawn2'}
      >
        {PawnForUser(roomData?.players[1])}
      </motion.div>
      {/*Third */}
      {roomData?.players.length >= 3 && <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.582,
          bottom: gameScreenDimentions.gameBoardWidth * 0.503,
          zIndex: 1
        }}
        animate={pawn3Animation}
        layoutId={'pawn3'}
        key={'pawn3'}
      >
        {PawnForUser(roomData?.players[2])}
      </motion.div>}

      {/*Fourth */}
      {roomData?.players.length >= 4 && <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.6,
          bottom: gameScreenDimentions.gameBoardWidth * 0.503,
          zIndex: 1
        }}
        animate={pawn4Animation}
        layoutId={'pawn4'}
        key={'pawn4'}
      >
        {PawnForUser(roomData?.players[3])}
      </motion.div>}

      {/*Fifth */}
      {roomData?.players.length >= 5 && <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.594,
          bottom: gameScreenDimentions.gameBoardWidth * 0.526,
          zIndex: 1,
          objectFit: 'fill'
        }}
        animate={pawn5Animation}
        layoutId={'pawn5'}
        key={'pawn5'}
      >
        {PawnForUser(roomData?.players[4])}
      </motion.div>}

      {/*Sixth */}
      {roomData?.players.length >= 6 && <motion.div
        className='container_pawn'
        style={{
          height: gameScreenDimentions.gameBoardWidth * 0.015,
          width: gameScreenDimentions.gameBoardWidth * 0.015,
          position: 'absolute',
          left: gameScreenDimentions.gameBoardWidth * 0.616,
          bottom: gameScreenDimentions.gameBoardWidth * 0.526,
          zIndex: 1
        }}
        animate={pawn6Animation}
        layoutId={'pawn6'}
        key={'pawn6'}
      >
        {PawnForUser(roomData?.players[5])}
      </motion.div>}
    </AnimatePresence>
  )
}

const PawnForUser = (props) => {
  if (props?.pawn === 'Red') {
    return (
      <motion.img src={Pawn1} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />

    )
  }
  else if (props?.pawn === 'Yellow') {
    return (
      <motion.img src={Pawn2} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />
    )
  } else if (props?.pawn === 'Orange') {
    return (
      <motion.img src={Pawn3} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />
    )
  } else if (props?.pawn === 'Green') {
    return (
      <motion.img src={Pawn4} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />
    )
  } else if (props?.pawn === 'Purple') {
    return (
      <motion.img src={Pawn5} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />
    )
  } else {
    return (
      <motion.img src={Pawn6} alt="React Logo" style={{
        width: gameScreenDimentions.gameBoardWidth * 0.015,
        height: gameScreenDimentions.gameBoardWidth * 0.015,
      }} />
    )
  }
}


export default Pawn;