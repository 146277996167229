import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from 'react-redux';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { textColor } from '../../themes/colors/colors';
import { FontFamily } from '../../themes/fontFamily/fontFamily';

const SmallTimer = () => {
    const smallTimer = useSelector(state => state.homeReducer.smallTimer);

    const gethours = (secTotal, type) => {

        const m = Math.floor(secTotal / (60));
        let secondsLeft = secTotal - m * 60;

        var mDisplay = m > 9 ? `${m}:` : ((m > 0) && (m < 10)) ? '0' + `${m}:` : '00:';
        var sDisplay = secondsLeft > 9 ? secondsLeft : ((secondsLeft > 0) && (secondsLeft < 10)) ? '0' + secondsLeft : '00';
        if (type == 'm') {
            return mDisplay;
        } else {
            return sDisplay;
        }
    };

    return (
        <>
            {smallTimer > 0 ?
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.800 }}
                        animate={{ opacity: 1, x: [gameScreenDimentions.width - (gameScreenDimentions.width * 0.044), gameScreenDimentions.width - 100] }}
                        layoutId={'1122'}
                        key={'1122'}
                        style={{
                            backgroundColor: '#000000bb',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: gameScreenDimentions.width * 0.022,
                            width: gameScreenDimentions.width * 0.044,
                            height: gameScreenDimentions.width * 0.044,
                            position: 'absolute',
                            bottom: gameScreenDimentions.width * 0.21,
                            zIndex: 6,
                            display: 'flex',
                        }}
                    >
                        <motion.span style={{
                            marginTop: 1,
                            marginLeft: 1,
                            color: textColor.white,
                            fontFamily: FontFamily.bold,
                            fontSize: gameScreenDimentions.width * 0.015,
                        }}>
                            {gethours(smallTimer, 'm')}
                            {gethours(smallTimer, 's')}
                        </motion.span>
                    </motion.div>
                </AnimatePresence>
                :
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.800 }}
                        animate={{ opacity: 0, x: [gameScreenDimentions.width - 100, gameScreenDimentions.width - (gameScreenDimentions.width * 0.044)] }}
                        layoutId={'1122'}
                        key={'1122'}
                        style={{
                            backgroundColor: '#000000bb',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: gameScreenDimentions.width * 0.022,
                            width: gameScreenDimentions.width * 0.044,
                            height: gameScreenDimentions.width * 0.044,
                            position: 'absolute',
                            bottom: gameScreenDimentions.width * 0.21,
                            zIndex: 6,
                            display: 'flex',
                        }}
                    >
                        <motion.span style={{
                            marginTop: 1,
                            marginLeft: 1,
                            color: textColor.white,
                            fontFamily: FontFamily.bold,
                            fontSize: gameScreenDimentions.width * 0.015,
                        }}>
                            {gethours(smallTimer, 'm')}
                            {gethours(smallTimer, 's')}
                        </motion.span>
                    </motion.div>
                </AnimatePresence>
            }
        </>
    );
};

export default SmallTimer;