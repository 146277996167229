import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../common/header';
import InputField from '../../common/textFiled';
import { commonImages } from '../../themes/appImage';
import { trimText, changePasswordCheck } from '../../utils/validators/validator';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { isInternetConnected } from '../../helper/networkUtils'
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import {
  changePassAction,
} from '../../redux/actions/authActions';


const ChangePassword = (props) => {

  const dispatch = useDispatch();

  const [oPass, onChangeOPass] = useState('');
  const [nPass, onChangeNPass] = useState('');
  const [cNewPass, onChangeCNewPass] = useState('');

  const { userId } = useSelector((state) => state.authenticationReducer);

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])

  const handleSubmitChangePassword = () => {
    const isValidationFailed = changePasswordCheck(oPass, nPass, cNewPass, ValidationConstants)
    if (isValidationFailed) {
        ShowAlertMessage(isValidationFailed, popupType.error, 3000);
    }
    else {
        
      if (isInternetConnected()) {
          dispatch(changePassAction({
              oldPassword: oPass.trim()?.replace(/ /g, ''),
              password: nPass?.trim()?.replace(/ /g, ''),
              userId: userId
          }));
      } else {
          ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000
        );
      }
    }
}

  // console.log(props, 'props--------------')
  return (
    <div>
      <div className='background_image'>
        <Header title='Change Password' settings={false} redirect={true} />

        <div className='inner_box'>
          {/* <div className='heading_text'>
                        <img src={commonImages.registerLogo} style={{ width: '147px' }} />
                    </div> */}
          <div className='form-group'>
            <label>Old Password</label>
            <InputField
              type='password'
              placeholder="Old Password"
              width='70%'
              height='35px'
              className='formcontrol'
              value={trimText(oPass)}
              onChange={(e) => {
                let value = e.target.value.trimLeft();
                onChangeOPass(value)
              }}
            />
          </div>
          <div className='form-group'>
            <label>New Password</label>
            <InputField
              type='password'
              placeholder="New Password"
              width='70%'
              height='35px'
              className='formcontrol'
              value={trimText(nPass)}
              onChange={(e) => {
                let value = e.target.value.trimLeft();
                onChangeNPass(value)
              }}
            />
          </div>
          <div className='form-group'>
            <label>Confirm New Password</label>
            <InputField
              type='password'
              placeholder="Confirm New Password"
              width='70%'
              height='35px'
              className='formcontrol'
              value={trimText(cNewPass)}
              onChange={(e) => {
                let value = e.target.value.trimLeft();
                onChangeCNewPass(value)
              }}
            />
          </div>

          <div className='data_new change_password'>
            <div>
              {/* <Link to='/login'> */}
                <img onClick={() => handleSubmitChangePassword()} src={commonImages.update} alt="" />
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default ChangePassword
