import axios from "axios";

// export const url = 'http://localhost:3001/'  //local
// export const websocket = 'http://localhost:3001/'  //local

// export const url = 'http://192.168.3.174:3001/'  //local
// export const websocket = 'http://192.168.3.174:3001/'  //local

// export const url = 'http://1.6.98.139:4010/'  //Public
// export const websocket = 'http://1.6.98.139:4010/'  //Public

// export const url = 'http://1.6.98.141:3001/'  //stagging
// export const websocket = 'http://1.6.98.141:3001/'  //stagging

export const url = 'https://api.disparitytrap.net/'  //live
export const websocket = 'https://api.disparitytrap.net:3000'  //live

export const pdfURL = 'https://rulebook.game.disparitytrap.net'
export const videoURL = 'https://rules.game.disparitytrap.net'
export const buyHardCopyURL = 'https://disparitytrap.net/?utm_content=virtualgame'


const APIKit = axios.create({
    baseURL: `${url}api/v4`,
    timeout: 60000000,
});

APIKit.interceptors.request.use(async (config) => {
    return config;
});

export default APIKit;
