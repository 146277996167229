import React from 'react';
import { commonImages } from '../../themes/appImage';
import Header from '../../common/header';

import appImages from '../../themes/appImages/appImages';
import strings from '../../themes/constants/strings';
import { FirebaseMethods } from '../../helper/firebaseMethod';
import { useSelector, useDispatch } from 'react-redux';
import { url } from '../../redux/axios/apikit'
import { isInternetConnected } from '../../helper/networkUtils';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import moment from 'moment'
import { FontFamily } from '../../themes/fontFamily/fontFamily';
import AppImages from '../../themes/appImages/appImages'
import { contactAddAction } from '../../redux/actions/contactAction';
import Linkify from 'react-linkify';

const ContactUs = (props) => {
    const dispatch = useDispatch();
    const msgRef = React.useRef(null);
    const [loader, setLoader] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [messageData, setMessageData] = React.useState('');
    const [sendBtnDisable, setSendBtnDisable] = React.useState(false);
    const [loaderWillCall, setLoaderWillCall] = React.useState(true);
    const { userId, userData } = useSelector((state) => state.authenticationReducer);

    React.useEffect(() => {
        if (userId) {
            FirebaseMethods.createRoomWithAdmin(userId.toString())
            FirebaseMethods.getAllMessagesWithAdmin(syncPosts, userId, 2)
        }
    }, [userId])

    React.useEffect(() => {
        msgRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])


    const syncPosts = async (object) => {
        let arr = []
        if (object.length > 0) {
            const d = [...object];
            await d.map(async (item) => {
                item['user'] = userData
                arr.push(item)
            })
            setTimeout(async () => {
                await setMessages([...messages, ...arr].reverse());
                setLoader(false)
                msgRef.current?.scrollIntoView({ behavior: "smooth" })
            }, 1500);
        } else {
            setLoader(false)
            setLoaderWillCall(false)
        }
    };

    const _onSendMessage = async () => {
        setLoaderWillCall(false)
        setSendBtnDisable(true)
        if (isInternetConnected()) {
            if (messageData.trim().length == 0) {
                ShowAlertMessage('Please enter message.', 'error')
                setSendBtnDisable(false)
            } else {
                let messageObj = {
                    messageBody: messageData.trim(),
                    createdAt: moment(new Date()).utc().format(),
                    senderUserId: userId,
                    receiverId: 'dispairyTrapGame_Admin',
                    isDeleted: false
                }
                setMessageData('')
                await FirebaseMethods.saveAdminMessageToFirebase(messageObj, userId)
                let data = {
                    lastMessage: messageData.trim()
                }
                dispatch(contactAddAction(data))
                setSendBtnDisable(false)
            }
        } else {
            ShowAlertMessage('Please check your internet connection.', 'error')
            setSendBtnDisable(false)
        }
    }

    return (
        <div>
            <div className='background_image'>
                <Header title='Contact Us' settings={false} redirect={true} />
                <div className='inner_box chat-box'>
                    <div className='wrapper_inner_chart'>
                        <div className='scroll_div contacts' style={{
                            height: '63vh',
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'end'
                        }}>
                            {messages.map((msg) => {
                                let time = moment(moment(msg.createdAt).toISOString()).format('hh:mm A DD/MM/YYYY')
                                let messageBody = msg?.messageBody
                                if (msg.senderUserId == userId) {
                                    return (
                                        <div className='chat-wrapper' style={{ marginTop: 'auto' }} ref={msgRef}>
                                            <span className='text_data'><Linkify>{messageBody}</Linkify></span>
                                            <div className='chat_new'>
                                                <p className='time_chat'>{time}</p>
                                                <p><img src={`${url}${userData?.profileImage}`} style={{ width: '30px', height: '30px', borderRadius: '18px' }} /></p>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className='chat-wrapper left' style={{ marginTop: 'auto' }}>
                                            <span className='text_data'><Linkify>{messageBody}</Linkify></span>
                                            <div className='chat_new'>
                                                <p><img src={commonImages.disparitylogo} style={{ width: '30px' }} /></p>
                                                <p className='time_chat'>{time}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {messages.length === 0 && <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                            }}><span style={{ color: 'white' }}>No Data</span></div>}
                        </div>
                    </div>
                    <div className='footer-b'>
                        <input type='text' value={messageData} onChange={(e) => setMessageData(e.target.value)} className='form-control' placeholder='Type a message...' />
                        <img src={commonImages.send1} onClick={() => !sendBtnDisable && _onSendMessage()} className='data_send' />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ContactUs