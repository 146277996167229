import ApiConstants from '../../themes/apiConstants';

const initialState = {
    onLoad: false,
    forgotpasswordSuccess: false,
    userId: null,
    userData: null
}

function authenticationReducer(state = initialState, action) {

    switch (action.type) {
        ////Signup Reducer.....
        case ApiConstants.API_SIGNUP_LOAD:
            return { ...state, onLoad: true }
        case ApiConstants.API_SIGNUP_SUCCESS:
            return { ...state, onLoad: false }
        case ApiConstants.API_SIGNUP_ERROR:
            return { ...state, onLoad: false }

        ////Login Reducer.....
        case ApiConstants.API_LOGIN_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_LOGIN_SUCCESS:
            return { ...state, onLoad: false, userId: action.result }

        case ApiConstants.API_LOGIN_ERROR:
            return { ...state, onLoad: false }

        ////ForgotPassword Reducer.....
        case ApiConstants.API_FORGOT_PASSWORD_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_FORGOT_PASSWORD_SUCCESS:
            return { ...state, onLoad: false, forgotpasswordSuccess: true }

        case ApiConstants.API_FORGOT_PASSWORD_ERROR:
            return { ...state, onLoad: false, forgotpasswordSuccess: false }

        case ApiConstants.UPDATE_AUTHENTICATION_FORM:
            state[action.key] = action.payload
            return { ...state, onLoad: false }

        ////ChangePassword Reducer.....
        case ApiConstants.API_CHANGE_PASSWORD_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_CHANGE_PASSWORD_SUCCESS:
            return { ...state, onLoad: false }

        case ApiConstants.API_CHANGE_PASSWORD_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_CHANGE_PASSWORD_FAIL:
            return { ...state, onLoad: false }



        ////Logout Reducer.....
        case ApiConstants.API_LOGOUT_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_LOGOUT_SUCCESS:
            return { ...state, onLoad: false }

        case ApiConstants.API_LOGOUT_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_LOGOUT_FAIL:
            return { ...state, onLoad: false }

        case ApiConstants.UPDATE_USER_DATA:
            return { ...state, userData: action.data }

        case ApiConstants.RESET_AUTH_DATA:
            return { ...state, userId: null, userData: null }

        default:
            return state;
    }
}


export default authenticationReducer;

