import React from 'react';
import { gameScreenDimentions, getLeaveStatus, getTradedTile } from '../../helper/globalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from './CardComponent';
import fontSizes from '../../themes/constants/fontSizes';
import { socket } from '../../helper/socket';
import ApiConstants from '../../themes/apiConstants';
import { backFromSelectCardType, gameScreen, popupType, SelectCardSubmoissionType, tileNames } from '../../themes/constants/gameConstants';
import appImages from '../../themes/appImages/appImages';
import ButtonCustom from './ButtonCustom';
import strings from '../../themes/constants/strings';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { buttonClickSound, cardMovementSound } from '../../helper/playSound';
import { isInternetConnected } from '../../helper/networkUtils';
import { useAnimation, motion, AnimatePresence } from 'framer-motion';
import Emitter from '../../helper/emitter';

var myCards = 0
var isSubmitClicked = false
const allTiles = [{
    id: 1,
    title: tileNames.skill,
    image: appImages.skill,
},
{
    id: 2,
    title: tileNames.degree,
    image: appImages.degree,
}, {
    id: 3,
    title: tileNames.startUp,
    image: appImages.startup,
}, {
    id: 4,
    title: tileNames.job,
    image: appImages.bag,
}, {
    id: 5,
    title: tileNames.home,
    image: appImages.homeDollarRound,
}, {
    id: 6,
    title: tileNames.business,
    image: appImages.moneyBag,
}, {
    id: 7,
    title: tileNames.realEstate,
    image: appImages.multiHomeRound,
},
{
    id: 8,
    title: tileNames.power,
    image: appImages.hands,
},
{
    id: 9,
    title: tileNames.justice,
    image: appImages.justiceRound,
},
{
    id: 10,
    title: tileNames.privilege,
    image: appImages.roundArrowWithOne,
}]
const UserCard = (props) => {
    const dispatch = useDispatch();
    const bgColor = useAnimation();
    const fadeAnim = useAnimation();
    const card1Animation = useAnimation();
    const card2Animation = useAnimation();
    const card3Animation = useAnimation();
    const animatedTile = useAnimation();
    const animatedTile1 = useAnimation();
    const animatedTile2 = useAnimation();

    const [selected, setSelected] = React.useState(0);
    const [tile, setTile] = React.useState(tileNames.skill);

    const ourGameData = useSelector((state) => state.homeReducer.ourGameData);
    const ourCards = useSelector((state) => state.homeReducer.ourCards);
    const tradeTile = useSelector((state) => state.homeReducer.tradeTile);
    const selectCard = useSelector((state) => state.homeReducer.selectCard);
    const awardATile = useSelector((state) => state.homeReducer.awardATile);
    const selectCardSubmissionType = useSelector((state) => state.homeReducer.selectCardSubmissionType);
    const isTradeAvailable = useSelector((state) => state.homeReducer.isTradeAvailable);
    const roomData = useSelector((state) => state.homeReducer.roomData);

    React.useEffect(() => {

        return () => {
            myCards = 0
            isSubmitClicked = false
            Emitter.off('emitSkillTileSuccess')
            Emitter.off('showSelectedCardThenPlay')
        }
    }, [])

    Emitter.on("emitSkillTileSuccess", () => {
        if (selectCardSubmissionType && selected) {
            if (selectCardSubmissionType === SelectCardSubmoissionType.earn) {
                if (selected === 1) {
                    move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                    move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                } else if (selected === 2) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                    move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                    move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                } else if (selected === 3) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                    move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                    move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                }
                setTimeout(() => {
                    setTile(tileNames.skill)
                    moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, -gameScreenDimentions.gameBoardWidth * 0.0775)
                    setSelected(0)
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: false,
                            selectCardSubmissionType: null,
                        }
                    })
                }, 900);
            }
        }
    })


    React.useEffect(() => {

        Emitter.on("showSelectedCardThenPlay", (data) => {
            if (data.cardNo === 1) {
                setSelected(1);
                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 0, 0);
                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0);
                move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0);
            }
            if (data.cardNo === 2) {
                setSelected(2);
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0);
                move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0);
                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 1, 0);
            }
            if (data.cardNo === 3) {
                setSelected(3);
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0);
                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0);
                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 2, 0);
            }

            setTimeout(() => {
                if (data.type == "Discard") {
                    if (data.cardNo === 1) {
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                    } else if (data.cardNo === 2) {
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                    } else if (data.cardNo === 3) {
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                    }
                    setTimeout(() => {
                        myCards = myCards - 1
                        console.log('################', ourCards[data.cardNo - 1]);
                        socket.emit('discardCard', ourCards[data.cardNo - 1])
                        setSelected(0)
                        dispatch({
                            type: ApiConstants.SELECT_CARD_UPDATE,
                            payload: {
                                selectCard: false,
                                selectCardSubmissionType: null,
                            }
                        })
                    }, 900);
                }
                else if (data.type == "Play") {
                    if (data.cardNo === 1) {
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                    } else if (data.cardNo === 2) {
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                    } else if (data.cardNo === 3) {
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                    }
                    setTimeout(() => {
                        myCards = myCards - 1
                        dispatch({
                            type: ApiConstants.SELECTED_CARD,
                            payload: {
                                selectedCard: data.cardNo,
                            }
                        })
                        socket.emit('qualifiedStatus', ourCards[data.cardNo - 1])
                        setSelected(0)
                        dispatch({
                            type: ApiConstants.SELECT_CARD_UPDATE,
                            payload: {
                                selectCard: false,
                                selectCardSubmissionType: null,
                            }
                        })
                    }, 900);
                }
            }, 500)

        })
        if (ourCards.length > 0) {
            if (myCards === 0) {
                // assigning new cards
                setTimeout(() => {
                    myCards = ourCards.length
                    move(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38)
                }, 1000)
            }
            else if (myCards > ourCards.length) {
                // card removed and nothing happend
                myCards = ourCards.length
                if (ourCards.length > 1) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0);
                    move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.0);
                }
                else if (ourCards.length > 0) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0);
                }

            }
            else if (myCards < ourCards.length) {
                // new card added
                myCards = ourCards.length
                if (ourCards.length > 2) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0);
                    move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.0);
                    moveLastCard(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 900);
                }
                else if (ourCards.length > 1) {
                    move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0);
                    moveLastCard2(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 900);
                }
                else if (ourCards.length > 0) {
                    moveLastCard3(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 900);
                }
            }
            else {
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                setTimeout(() => {
                    setSelected(0)
                }, 900)
            }
        }
        return () => {
            Emitter.off('showSelectedCardThenPlay')
        }
    }, [ourCards])

    React.useEffect(() => {
        if (selectCard) {
            if (ourCards.length > 2) {
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0)
                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.0)
                move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.0)
            }
            else if (ourCards.length > 1) {
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0)
                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.0)
            }
            else if (ourCards.length > 0) {
                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.0)
            }
            setSelected(0)
            changeBg(1)
            fadeText();
        }
    }, [selectCard, ourGameData])

    React.useEffect(() => {
        if (tradeTile) {
            fadeTile2()
        }
    }, [tradeTile])

    React.useEffect(() => {
        if (awardATile) {
            setTile(awardATile)
            if (awardATile === tileNames.skill) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, -gameScreenDimentions.gameBoardWidth * 0.12)
            }
            else if (awardATile === tileNames.degree) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.53, -gameScreenDimentions.gameBoardWidth * 0.09)
            }
            else if (awardATile === tileNames.startUp) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, -gameScreenDimentions.gameBoardWidth * 0.05)
            }
            else if (awardATile === tileNames.job) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.53, -gameScreenDimentions.gameBoardWidth * 0.017)
            }
            else if (awardATile === tileNames.home) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, gameScreenDimentions.gameBoardWidth * 0.023)
            }
            else if (awardATile === tileNames.business) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.53, gameScreenDimentions.gameBoardWidth * 0.055)
            }
            else if (awardATile === tileNames.realEstate) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, gameScreenDimentions.gameBoardWidth * 0.095)
            }
            else if (awardATile === tileNames.privilege) {
                moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.53, -gameScreenDimentions.gameBoardWidth * 0.16)
            }
            // else if (awardATile === tileNames.power) {
            //     moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, -gameScreenDimentions.gameBoardWidth * 0.0062)
            // }
            // else if (awardATile === tileNames.justice) {
            //     moveTileCenterToLeft(-gameScreenDimentions.gameBoardWidth * 0.59, -gameScreenDimentions.gameBoardWidth * 0.0775)
            // }
        }
    }, [awardATile])

    const changeBg = (x) => {
        bgColor.start({
            transition: { duration: 0.700 },
            opacity: 0.5
        })
    }

    const fadeText = () => {
        fadeAnim.start({
            transition: { duration: 0.700 },
            opacity: [0, 1]
        })
    }

    const move = async (valueX, valueY, sizeX, sizeY) => {
        await card1Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: [0, valueX],
            y: [0, valueY],
        })
        await card2Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: [0, -gameScreenDimentions.gameBoardWidth * 0.411],
            y: [0, gameScreenDimentions.gameBoardWidth * 0.292],
        })
        await card3Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: [0, -gameScreenDimentions.gameBoardWidth * 0.33933],
            y: [0, gameScreenDimentions.gameBoardWidth * 0.292],
        })
    }

    const moveLastCard = async (valueX, valueY, sizeX, sizeY) => {
        await card3Animation.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: -gameScreenDimentions.gameBoardWidth * 0,
            y: gameScreenDimentions.gameBoardWidth * 0.001,
        })
        await card3Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: -gameScreenDimentions.gameBoardWidth * 0.33933,
            y: gameScreenDimentions.gameBoardWidth * 0.292,
        })
    }
    const moveLastCard2 = async (valueX, valueY, sizeX, sizeY) => {
        await card2Animation.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: -gameScreenDimentions.gameBoardWidth * 0,
            y: gameScreenDimentions.gameBoardWidth * 0.001,
        })
        await card2Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: -gameScreenDimentions.gameBoardWidth * 0.411,
            y: gameScreenDimentions.gameBoardWidth * 0.292,
        })
    }
    const moveLastCard3 = async (valueX, valueY, sizeX, sizeY) => {
        await card1Animation.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: -gameScreenDimentions.gameBoardWidth * 0,
            y: gameScreenDimentions.gameBoardWidth * 0.001,
        })
        await card1Animation.start({
            transition: { duration: 0.900 },
            scaleX: sizeX,
            scaleY: sizeY,
            x: -gameScreenDimentions.gameBoardWidth * 0.481,
            y: gameScreenDimentions.gameBoardWidth * 0.292,
        })
    }
    const move1 = async (valueX, valueY, sizeX, sizeY, num, duration) => {
        const x = num === 0 ? card1Animation : num === 1 ? card2Animation : card3Animation;
        await x.start({
            transition: { duration: duration },
            scaleX: sizeX,
            scaleY: sizeY,
            x: valueX,
            y: valueY,
        })
    }
    const moveTileCenterToLeft = async (x, y) => {
        await animatedTile.start({
            transition: { duration: 0 },
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            x: 0,
            y: 0,
        })
        await animatedTile.start({
            transition: { duration: 0 },
            opacity: 1,
        })
        await animatedTile.start({
            transition: { duration: 0.350 },
            scaleX: 2,
            scaleY: 2,
        })
        await animatedTile.start({
            transition: { duration: 0.500 },
            scaleX: 1,
            scaleY: 1,
            x: x,
            y: y,
        })
        await animatedTile.start({
            transition: { duration: 0.250 },
            opacity: 0,
        })
        await animatedTile.start({
            transition: { duration: 0 },
            opacity: 0,
            x: 0,
            y: 0,
        })
        dispatch({
            type: ApiConstants.AWARD_TILE_UPDATE,
            payload: null
        })
    }

    const fadeTile2 = async (x, y) => {
        await animatedTile1.start({
            transition: { duration: 0.500 },
            opacity: 1,
        })
        await animatedTile1.start({
            transition: { duration: 0.500 },
            scaleX: 1,
            scaleY: 1,
        })
        await animatedTile1.start({
            transition: { duration: 0.800 },
            rotateY: [0, 90]
        })
        await animatedTile1.start({
            transition: { duration: 0.1 },
            opacity: 0,
        })
        await animatedTile2.start({
            transition: { duration: 0.800 },
            opacity: 1,
            rotateY: [270, 360]
        })
        await animatedTile2.start({
            transition: { duration: 0 },
            opacity: 0,
        })
        await animatedTile1.start({
            transition: { duration: 0 },
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            rotateY: 0
        })
        await animatedTile2.start({
            transition: { duration: 0 },
            opacity: 0,
            rotateY: 0
        })
        if (isTradeAvailable) {
            dispatch({
                type: ApiConstants.TRADE_TILE_AGAIN,
                payload: true
            })
            dispatch({
                type: ApiConstants.TRADE_TILE_UPDATE,
                payload: false
            })
        } else {
            socket.emit('startNextPart')
            dispatch({
                type: ApiConstants.TRADE_TILE_UPDATE,
                payload: false
            })
        }
    }

    const img = (tileName = null) => {
        let imageShow = null
        const tempTile = tileName ? tileName : tile
        allTiles.map((x) => {
            if (x.title === tempTile) {
                imageShow = x.image
            }
        })
        return imageShow;
    }

    const img2 = (tileName) => {
        let imageShow = null
        const tempTile = tileName === tileNames.skill ? tileNames.degree : tileName === tileNames.degree ? tileNames.startUp : tileName === tileNames.startUp ? tileNames.job : tileNames.job
        allTiles.map((x) => {
            if (x.title === tempTile) {
                imageShow = x.image
            }
        })
        return imageShow;
    }
    return (
        <>
            {selectCard && <AnimatePresence>
                <motion.div style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000050',
                    zIndex: 2,
                }}
                // animate={bgColor}
                >
                    <motion.div style={{
                        position: 'absolute',
                        right: gameScreenDimentions.gameBoardWidth * 0.115,
                        bottom: gameScreenDimentions.gameBoardWidth * 0.038,
                        zIndex: 2,
                    }}
                        animate={fadeAnim}
                        layoutId={'fadeAnim1'}
                        key={'fadeAnim1'}
                    >
                        <ButtonCustom
                            resize={'stretch'}
                            textColor={{ fontSize: fontSizes.f40 }}
                            iContainer={{
                                height: gameScreenDimentions.gameBoardWidth * 0.042,
                                paddingHorizontal: 16, width: null,
                                minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                            }}
                            style={{
                                height: gameScreenDimentions.gameBoardWidth * 0.042,
                                paddingHorizontal: 16, width: null,
                                minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                            }}
                            imgBg={appImages.buttonBg}
                            title={strings.back}
                            onPress={() => {
                                buttonClickSound();
                                if (!roomData?.isPause) {
                                    if (isSubmitClicked === false) {
                                        isSubmitClicked = true
                                        if (selected > 0) {
                                            move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                            move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                            move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                        }
                                        setSelected(0)
                                        dispatch({
                                            type: ApiConstants.SELECT_CARD_UPDATE,
                                            payload: {
                                                selectCard: false,
                                                selectCardSubmissionType: null,
                                            }
                                        })
                                        if (selectCardSubmissionType === SelectCardSubmoissionType.earn) {
                                            socket.emit("gameScreen", gameScreen.screen2);
                                            dispatch({
                                                type: ApiConstants.BACK_FROM_SELECT_CARD_UPDATE,
                                                payload: backFromSelectCardType.earn
                                            })
                                        }
                                        else if (selectCardSubmissionType === SelectCardSubmoissionType.discard) {
                                            socket.emit("gameScreen", gameScreen.screen1);
                                            dispatch({
                                                type: ApiConstants.BACK_FROM_SELECT_CARD_UPDATE,
                                                payload: backFromSelectCardType.discard
                                            })
                                        }
                                        else if (selectCardSubmissionType === SelectCardSubmoissionType.opportunity) {
                                            socket.emit("gameScreen", gameScreen.screen1);
                                            dispatch({
                                                type: ApiConstants.BACK_FROM_SELECT_CARD_UPDATE,
                                                payload: backFromSelectCardType.opportunity
                                            })
                                        }
                                        setTimeout(() => {
                                            isSubmitClicked = false
                                        }, 1100)
                                    }
                                } else {
                                    ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error);
                                }
                            }}
                        />
                    </motion.div>
                    {selected === 0 ?
                        <motion.span style={{
                            fontWeight: '800',
                            fontSize: fontSizes.f90,
                            color: 'yellow', position: 'absolute',
                            left: selectCardSubmissionType === SelectCardSubmoissionType.earn ? gameScreenDimentions.gameBoardWidth / 2 : gameScreenDimentions.gameBoardWidth / 1.8,
                            top: gameScreenDimentions.gameBoardWidth / 3.6,
                            zIndex: 2,
                        }}
                            animate={fadeAnim}
                            layoutId={'fadeAnim2'}
                            key={'fadeAnim2'}
                        >
                            {(selectCardSubmissionType === SelectCardSubmoissionType.earn) ? "Please select a card to sacrifice." : "Please select a card."}
                        </motion.span>
                        :
                        <>
                            <motion.div style={{
                                position: 'absolute',
                                right: gameScreenDimentions.gameBoardWidth * 0.295,
                                bottom: gameScreenDimentions.gameBoardWidth * 0.038,
                                zIndex: 2,
                            }}
                                animate={fadeAnim}
                                layoutId={'fadeAnim3'}
                                key={'fadeAnim3'}
                            >
                                <ButtonCustom
                                    resize={'stretch'}
                                    textColor={{ fontSize: fontSizes.f40 }}
                                    iContainer={{
                                        height: gameScreenDimentions.gameBoardWidth * 0.042,
                                        paddingHorizontal: 16, width: null,
                                        minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                                    }}
                                    style={{
                                        height: gameScreenDimentions.gameBoardWidth * 0.042,
                                        paddingHorizontal: 16, width: null,
                                        minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                                    }}
                                    imgBg={appImages.buttonBg}
                                    title={selectCardSubmissionType === SelectCardSubmoissionType.earn ? strings.sacrificeCard : selectCardSubmissionType === SelectCardSubmoissionType.discard ? strings.discard : strings.submit}
                                    onPress={() => {
                                        buttonClickSound();
                                        cardMovementSound();
                                        const checkAllowed = getLeaveStatus()
                                        if (isInternetConnected() && checkAllowed) {
                                            if (!roomData?.isPause) {
                                                if (isSubmitClicked === false) {
                                                    isSubmitClicked = true
                                                    // if (selected === 1) {
                                                    //     move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                    // } else if (selected === 2) {
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                    //     move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                                                    // } else if (selected === 3) {
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                    //     move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                    //     move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                                                    // }
                                                    if (selected > 0) {
                                                        changeBg(0);
                                                        if (selectCardSubmissionType === SelectCardSubmoissionType.earn) {
                                                            socket.emit('earnSkillTile', ourCards[selected - 1])
                                                        }
                                                        else if (selectCardSubmissionType === SelectCardSubmoissionType.discard) {
                                                            if (selected === 1) {
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                            } else if (selected === 2) {
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                                                            } else if (selected === 3) {
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                                                            }
                                                            setTimeout(() => {
                                                                myCards = myCards - 1
                                                                socket.emit('discardCard', ourCards[selected - 1])
                                                                setSelected(0)
                                                                dispatch({
                                                                    type: ApiConstants.SELECT_CARD_UPDATE,
                                                                    payload: {
                                                                        selectCard: false,
                                                                        selectCardSubmissionType: null,
                                                                    }
                                                                })
                                                            }, 900)
                                                        }
                                                        else if (selectCardSubmissionType === SelectCardSubmoissionType.opportunity) {
                                                            if (selected === 1) {
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                            } else if (selected === 2) {
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900)
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 1, 0.900)
                                                            } else if (selected === 3) {
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900)
                                                                move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900)
                                                                move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 0, 0, 2, 0.900)
                                                            }
                                                            setTimeout(() => {
                                                                myCards = myCards - 1
                                                                dispatch({
                                                                    type: ApiConstants.SELECTED_CARD,
                                                                    payload: {
                                                                        selectedCard: selected,
                                                                    }
                                                                })
                                                                socket.emit('qualifiedStatus', ourCards[selected - 1])
                                                                setSelected(0)
                                                                dispatch({
                                                                    type: ApiConstants.SELECT_CARD_UPDATE,
                                                                    payload: {
                                                                        selectCard: false,
                                                                        selectCardSubmissionType: null,
                                                                    }
                                                                })
                                                            }, 900)
                                                        }
                                                        setTimeout(() => {
                                                            isSubmitClicked = false
                                                        }, 1100)
                                                    }
                                                }
                                            } else {
                                                ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error);
                                            }
                                        } else {
                                            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                                        }
                                    }
                                    }
                                />
                            </motion.div>
                        </>}
                </motion.div>
            </AnimatePresence>}
            {ourCards.length > 2 && <CardComponent
                style={styles.card}
                animate={card3Animation}
                code={ourCards[2]}
                selfKey={'userCard3'}
            />}
            {ourCards.length > 1 && <CardComponent
                style={styles.card}
                animate={card2Animation}
                code={ourCards[1]}
                selfKey={'userCard2'}
            />}
            {ourCards.length > 0 && <CardComponent
                style={styles.card}
                animate={card1Animation}
                code={ourCards[0]}
                selfKey={'userCard1'}
            />}
            {(selected !== 0) && <span style={{
                fontWeight: 800,
                fontSize: fontSizes.f50,
                color: 'yellow',
                position: 'absolute',
                bottom: gameScreenDimentions.gameBoardWidth * 0.10,
                left: gameScreenDimentions.gameBoardWidth * 0.02,
                zIndex: 2,
            }}>Switch Cards</span>}
            <div
                onClick={() => {
                    if (isSubmitClicked === false) {
                        if (selectCard) {
                            setSelected(1);
                            move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 0, 0.900);
                            move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900);
                            move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900);
                        } else {
                            dispatch({
                                type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                                payload: ourCards[0]
                            })
                        }
                    }
                }
                }
                style={styles.cardWrapper1} />
            <div onClick={() => {
                if (isSubmitClicked === false) {
                    if (selectCard) {
                        setSelected(2);
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900);
                        move1(-gameScreenDimentions.gameBoardWidth * 0.33933, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 2, 0.900);
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 1, 0.900);
                    } else {
                        dispatch({
                            type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                            payload: ourCards[1]
                        })
                    }
                }
            }
            }
                style={styles.cardWrapper2} />
            <div onClick={() => {
                if (isSubmitClicked === false) {
                    if (selectCard) {
                        setSelected(3);
                        move1(-gameScreenDimentions.gameBoardWidth * 0.481, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 0, 0.900);
                        move1(-gameScreenDimentions.gameBoardWidth * 0.411, gameScreenDimentions.gameBoardWidth * 0.292, 0.318, 0.38, 1, 0.900);
                        move1(gameScreenDimentions.gameBoardWidth * 0.20, -gameScreenDimentions.gameBoardWidth * 0.001, 2.2, 2.2, 2, 0.900);
                    } else {
                        dispatch({
                            type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                            payload: ourCards[2]
                        })
                    }
                }
            }
            } style={styles.cardWrapper3} />

            <AnimatePresence>
                <motion.div style={{
                    position: "absolute",
                    bottom: gameScreenDimentions.gameBoardWidth * 0.28,
                    left: gameScreenDimentions.gameBoardWidth * 0.69,
                    zIndex: 1,
                    opacity: 0,
                }}
                    animate={animatedTile}
                    layoutId={'animatedTile'}
                    key={'animatedTile'}
                >
                    <motion.img
                        style={{
                            width: gameScreenDimentions.width * 0.042,
                            height: gameScreenDimentions.width * 0.042,
                            objectFit: 'contain'
                        }}
                        src={img()} />
                </motion.div>
            </AnimatePresence>

            {tradeTile && <>
                <AnimatePresence>
                    <motion.div style={{
                        zIndex: 10,
                        position: "absolute",
                        top: gameScreenDimentions.gameBoardWidth * 0.2,
                        left: gameScreenDimentions.gameBoardWidth * 0.62,
                        zIndex: 2,
                    }}
                        animate={animatedTile1}
                        layoutId={'animatedTile1'}
                        key={'animatedTile1'}
                    >
                        <motion.img
                            style={{
                                width: gameScreenDimentions.width * 0.14,
                                height: gameScreenDimentions.width * 0.14,
                                objectFit: 'contain'
                            }}
                            src={img(getTradedTile())} />
                    </motion.div>
                    <motion.div style={{
                        zIndex: 1000,
                        position: "absolute",
                        top: gameScreenDimentions.gameBoardWidth * 0.2,
                        left: gameScreenDimentions.gameBoardWidth * 0.62,
                        zIndex: 2,
                        opacity: 0
                    }}
                        animate={animatedTile2}
                        layoutId={'animatedTile2'}
                        key={'animatedTile2'}
                    >
                        <motion.img
                            style={{
                                width: gameScreenDimentions.width * 0.14,
                                height: gameScreenDimentions.width * 0.14,
                                objectFit: 'contain'
                            }}
                            src={img2(getTradedTile())} />
                    </motion.div>
                </AnimatePresence>
            </>}
        </>
    );
};

const styles = {
    cardWrapper1: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.012,
        left: gameScreenDimentions.width * 0.01,
        height: gameScreenDimentions.width * 0.06,
        width: gameScreenDimentions.width * 0.04,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        cursor: 'pointer'
    },
    cardWrapper2: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.012,
        left: gameScreenDimentions.width * 0.059,
        height: gameScreenDimentions.width * 0.06,
        width: gameScreenDimentions.width * 0.04,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        cursor: 'pointer'
    },
    cardWrapper3: {
        position: 'absolute',
        bottom: gameScreenDimentions.width * 0.012,
        left: gameScreenDimentions.width * 0.11,
        height: gameScreenDimentions.width * 0.06,
        width: gameScreenDimentions.width * 0.04,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        cursor: 'pointer'
    },
    card: {
        position: 'absolute',
        alignSelf: 'center',
        bottom: gameScreenDimentions.gameBoardWidth * 0.2468,
        left: gameScreenDimentions.gameBoardWidth * 0.4426,
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.21,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#46c1af55',
        overflow: 'hidden',
        boxShadow: '0px 0px 8px 5px #00000044',
        zIndex: 2,
    }
}

export default UserCard;