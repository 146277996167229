import React from 'react'
import { Spin } from 'antd'
import { useSelector } from 'react-redux'
import { modal } from '../../themes/colors/colors'

const Loader = props => {
    const isLoading = useSelector(state => state.globalReducer.isLoading)

    return (
        <>
            {isLoading ?
                <div style={styles.modalBackground}>
                    <div style={styles.activityIndicatorWrapper}>
                        <Spin />
                    </div>
                </div>
                : null}
        </>
    )
}

const styles = {
    modalBackground: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        position: 'fixed',
        backgroundColor: modal.outerView,
        zIndex: 99999,
        top: 0
    },
    activityIndicatorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    }
}

export default Loader
