import { textColor } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';

const styles = {
    container: {
        flex: 1
    },
    scrollviewStyle: {
        flexGrow: 1,
        elevation: 5,
        justifyContent: 'flex-start',
        paddingLeft: 25,
        paddingRight: 25
    },
    fontLabel: {
        // lineHeight: 20,
        textAlign: 'justify',
        includeFontPadding: false,
        fontWeight: 600,
        color: textColor.green,
        fontSize: fontSizes.f40,
        textAlignVertical: 'center'
    },
    fontLabel1: {
        // lineHeight: 20,
        textAlign: 'justify',
        includeFontPadding: false,
        fontWeight: 600,
        color: textColor.white,
        fontSize: fontSizes.f40,
        textAlignVertical: 'center',
        whiteSpace: 'pre-wrap',
    },
}

export default styles;