import { textColor } from '../../themes/colors/colors';
import fontSizes from '../../themes/constants/fontSizes';
import { spacing } from '../../themes/constants/spacing';

const styles = {
    container: {
        flex: 1,
    },
    outerView: {
        width: '100%',
        alignItems: "center",
    },
    textContainer: {
        alignItems: "center",
        justifyContent: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: textColor.green,
        marginLeft: 22,
        marginRight: 22,
    },
    scrollviewStyle: {
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    modalView: {
        alignItems: "center",
        width: spacing.scW40cent + spacing.scW32cent,
        height: spacing.scH38cent + spacing.scH5cent,
    },
    fontLabel: {
        includeFontPadding: false,
        fontWeigth: 700,
        color: textColor.black,
        fontSize: fontSizes.f60,
        textAlignVertical: 'center',
        alignSelf: 'center',
    },
    fontLabel1: {
        includeFontPadding: false,
        fontWeigth: 800,
        color: textColor.white,
        fontSize: fontSizes.f45,
        textAlignVertical: 'center',
        alignSelf: 'center',
    },
    fontText: {
        includeFontPadding: false,
        fontWeigth: 600,
        color: textColor.white,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
    fontTextDone: {
        paddingLeft: 16,
        paddingRight: 16,
        includeFontPadding: false,
        fontWeigth: 600,
        color: textColor.green,
        fontSize: fontSizes.f50,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
    fontTextHeading: {
        includeFontPadding: false,
        fontWeigth: 600,
        color: textColor.white,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
}

export default styles;