import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../common/header';
import RoomsList from '../../common/roomsList';
import { DataManager } from '../../helper/dataManager';
import { socket } from '../../helper/socket';
import { getRoomListSocketAction } from '../../redux/actions/homeActions/homeActions';


const PublicRoom = (props) => {

  const dispatch = useDispatch();

  const roomsArray = useSelector((state) => state.homeReducer.publicRoomList);

  const [on, setOn] = useState(true);

  useEffect(() => {
    DataManager.removeLeaveGameStatus()
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])
  React.useEffect(() => {
    setOn(true);
    socket.on('roomSuccess', getRoomList);
    socket.emit('room')
    //     { offset: 0, limit: limit }
    // )
    return () => {
      socket.off('roomSuccess', getRoomList);
    }
  }, [])

  const getRoomList = (data) => {
    dispatch(getRoomListSocketAction(data.data))
  }
  // console.log(props, 'props--------------')
  return (
    <div>

      <div className='background_image' style={{ paddingBottom: '70px' }}>
        <Header title='Public Rooms' settings={false} redirect={true} />
        <div className='inner_box box inner_text'>
          <h3 className='rooms'>Joined Users:</h3>
        </div>

        <div className='room '>
          <div className='scroll_div'>
            <RoomsList
              roomsArray={roomsArray}
              on={on}
              setOnFalse={() => setOn(false)}
              setOnTrue={() => setOn(true)}
            />
          </div>
        </div>

      </div>
    </div>
  )

}
export default PublicRoom