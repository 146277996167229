import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../common/header';
import strings from '../../themes/constants/strings'
import { url } from '../../redux/axios/apikit';
import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';
import { socket } from '../../helper/socket';
import { isInternetConnected } from '../../helper/networkUtils';
import { getLeaveStatus } from '../../helper/globalFunctions';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { popupType } from '../../themes/constants/gameConstants';


const LeaderBoard = (props) => {
  const dispatch = useDispatch()
  const blockedUsers = useSelector((state) => state.homeReducer.blockedUsersList);
  const [isOnBlock, setIsOnBlock] = React.useState(true);


  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    socket.emit('blockUserList')
  }, [])



  return (
    <div>
      <div className='background_image' style={{ paddingBottom: '79px' }}>
        <Header title='Blocked Users' settings={false} redirect={true} />

        <div className='room lobby mt_12' style={{ width: '40%' }}>
          <div className='scroll_div'>
            {
              blockedUsers?.blockList && blockedUsers?.blockList.length > 0 ? (
                blockedUsers?.blockList.map((item) => {
                  return (
                    <div className='inner_room'>
                      <div className='first_join home'>
                        <img src={`${url}${item.profileImage}`} style={{ width: 50, height: 50, borderRadius: 25, borderStyle: 'solid', borderWidth: 2, borderColor: '#2E8F81' }} />
                        <div className='hostname'>
                          <h4>{item.name}</h4>
                        </div>
                        <ButtonCustom
                          resize={"cover"}
                          outerContainer={{ height: 40, alignSelf: 'center' }}
                          textColor={{ fontSize: fontSizes.f39, padding: 2 }}
                          style={{ height: 40, width: 150, borderRadius: 4 }}
                          iContainer={{ height: 40 }}
                          imgBg={appImages.buttonBg}
                          title={'Unblock'}
                          className="send_new"
                          onPress={() => {
                            const checkAllowed = getLeaveStatus()
                            if (isInternetConnected() && checkAllowed) {
                              if (isOnBlock) {
                                setIsOnBlock(false)
                                socket.emit('blockUser', item?._id);
                                setTimeout(() => {
                                  setIsOnBlock(true)
                                }, 2500)
                              }
                            } else {
                              ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className='no-data-found'><div className='no_data_found_child'>No data available.</div></div>
              )
            }
          </div>

        </div>

      </div>
    </div>
  )

}
export default LeaderBoard