import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import { buttonClickSound } from "../../../helper/playSound";
import ApiConstants from "../../../themes/apiConstants";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import CardComponent from "../CardComponent";
import styles from './styles';


const ShowCardComponent = (props) => {
    const dispatch = useDispatch()
    const showCardNormally = useSelector((state) => state.homeReducer.showCardNormally);

    return (
        <>
            {showCardNormally && <div style={styles.outerContainer}>
                <CardComponent
                    style={styles.card}
                    code={showCardNormally}
                />

                <div style={{
                    position: 'absolute',
                    right: gameScreenDimentions.gameBoardWidth * 0.115,
                    bottom: gameScreenDimentions.gameBoardWidth * 0.038,
                }}>
                    <ButtonCustom
                        resize={'stretch'}
                        textColor={{ fontSize: fontSizes.f40 }}
                        iContainer={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        style={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        imgBg={appImages.buttonBg}
                        title={strings.back}
                        onPress={() => {
                            buttonClickSound();
                            dispatch({
                                type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                                payload: null
                            })
                        }}
                    />
                </div>
            </div>}
        </>
    );
};

export default ShowCardComponent;

