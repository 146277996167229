import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InputField from '../../common/textFiled';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { isInternetConnected } from '../../helper/networkUtils';
import { commonImages } from '../../themes/appImage';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { trimText, userSignUpCheck } from '../../utils/validators/validator';
import { signupAction } from "../../redux/actions/authActions"
import { getLeaveStatus } from '../../helper/globalFunctions'
import { popupType } from '../../themes/constants/gameConstants';


const Register = (props) => {

  const dispatch = useDispatch()

  const { onLoad } = useSelector((state) => state.authenticationReducer)

  const [userName, onChangeUserName] = useState('');
  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [confirmPass, onChangeConfirmPass] = useState('');

  const [file, setFile] = useState(null);
  const [imageUpload, setImageUpload] = useState("");
  const [profileImg, setProfileImg] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])


  const imageChange = (e) => {
    console.log("welcome")
    if (e.target.files) {
      let file = e.target.files[0]
      if (!file.name.match(/\.(jpg|png|jpeg|gif)$/)) {
        ShowAlertMessage('Only .jpeg, .jpg, .png format images are allowed.', popupType.error, 3000)
        // setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        return false;
      }
      const extentType = ['jpeg', 'jpg', 'png'];
      const extent = file?.name?.split('.');
      const extentT = extent && extent[extent.length - 1];
      console.log('imageChange', extentType, extentType.indexOf(extentT) >= 0, extentType.indexOf(extentT))
      if (extentType.indexOf(extentT) >= 0) {
        if (file.size > 20971520) {
          console.log("this is image first error= ")
          // setImageError('File size must be equal or less than 20 MB.')
          ShowAlertMessage('File size must be equal or less than 20 MB.', popupType.error, 3000)

        } else {
          setFile(URL.createObjectURL(file));
          setFileUrl(file)
          setImageUpload("")
          setImageError(null)
        }
      }
      else {
        if (e.target.files.length == 0) {

        } else {
          setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        }
      }
    }
  }

  const handleSubmitForm = () => {
    const isValidationFailed = userSignUpCheck(userName, email, password, confirmPass, ValidationConstants);

    if (isValidationFailed) {
      ShowAlertMessage(isValidationFailed, popupType.error, 3000)
    }
    else {
      let body = new FormData()
      if (fileUrl) {
        body.append('profileImage', fileUrl);
      }
      body.append('name', userName.trim()?.replace(/ /g, ''));
      body.append('email', email.trim()?.replace(/ /g, ''));
      body.append('password', password.trim()?.replace(/ /g, ''));
      body.append('deviceToken', 'sdfsdfdssdfsdfsfdfds')

      if (isInternetConnected()) {
        dispatch(signupAction(body));
      } else {
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
      }
    }
  }

  // console.log(props, 'props--------------')
  return (
    <div>
      <div className='background_image'>
        <div className='inner_box'>
          <form
            id="register_form"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmitForm();
              }
            }}
          >
            <div className='heading_text'>
              <div>
                <img src={commonImages.registerlogo} style={{ width: '270px' }} />
              </div>
              <div className="upload-image">
                <div className="upload-image">

                  <div
                    className="upload_img common-flex"
                  >
                    <input
                      type="file"
                      id="pic"
                      accept=".png,.jpg,.jpeg"
                      style={{ display: "none" }}
                      onChange={imageChange}
                    />
                    {file ? <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                      <label for="pic">
                        <img
                          alt=""
                          className="table-img"
                          src={file ? file : commonImages.registerLogo}
                          type="file"
                          // onChange={imageChange}
                          height="100"
                          width="100"
                          style={{
                            cursor: 'pointer',
                            borderRadius: '50%',
                            border: '1px solid rgb(51, 194, 173)'
                          }}
                        />
                        <img
                          style={{ cursor: "pointer" }}
                          className="plus_icon"
                          src={commonImages.plus}
                          onClick={() => imageChange}
                        ></img>
                      </label>
                    </div> : (
                      <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                        <label for="pic">
                          <img
                            className="table-img"
                            src={profileImg ? profileImg : commonImages.profile}
                            type="file"
                            onChange={imageChange}
                            height="100"
                            width="100"
                            style={{
                              // padding: file ? '0px' : '0px .5px 1px 1px',
                              cursor: 'pointer',
                              borderRadius: '50%',
                              // border: '1px solid rgb(51, 194, 173)'
                            }}
                          />
                          <img
                            style={{ cursor: "pointer" }}
                            className="plus_icon"
                            src={commonImages.plus}
                            onClick={() => imageChange}
                          ></img>
                        </label>
                      </div>
                    )}
                  </div>
                  <div
                    className="image-upload"
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {imageUpload}
                    {/* {fileUrl} */}
                  </div>
                </div>


                <div
                  className="image-upload"
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                </div>
              </div>
              {/* <img src={commonImages.registerLogo} style={{ width: '156px' }} /> */}
            </div>
            <div className='form-group'>
              <label>Username</label>
              <InputField
                type='text'
                placeholder="Username"
                width='70%'
                height='35px'
                maxLength={40}
                className='formcontrol'
                value={trimText(userName)}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangeUserName(value)
                }}
              />
            </div>
            <div className='form-group'>
              <label>Email Address</label>
              <InputField
                type='text'
                placeholder="Email Address"
                width='70%'
                height='35px'
                className='formcontrol'
                value={email?.trim()?.replace(/ /g, '')}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangeEmail(value)
                }}
              />
            </div>
            <div className='form-group'>
              <label>Password</label>
              <InputField
                type='password'
                placeholder="Password"
                width='70%'
                height='35px'
                className='formcontrol'
                value={password.trim()?.replace(/ /g, '')}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangePassword(value)
                }}
              />
            </div>
            <div className='form-group'>
              <label>Confirm Password</label>
              <InputField
                type='password'
                placeholder="Confirm Password"
                width='70%'
                height='35px'
                className='formcontrol'
                value={confirmPass.trim()?.replace(/ /g, '')}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangeConfirmPass(value)
                }}
              />
            </div>

            <div className='data_new top-space_one'>
              <div>
                {/* <Link to='/login'> */}
                <img id="register_form" onClick={() => handleSubmitForm()} src={commonImages.registerbutton} alt="" />
                {/* </Link> */}

              </div>
              <div>
                <Link to='/login'>
                  <img src={commonImages.loginblack} alt="" />
                </Link>
              </div>


            </div>
          </form>
        </div>
      </div>
    </div>
  )

}
export default Register
