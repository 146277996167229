import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { socket } from '../../helper/socket';
import ModalPop from '../../common/Modal';
import { commonImages } from '../../themes/appImage';
import { isInternetConnected } from '../../helper/networkUtils';
import { gameScreenDimentions, getLeaveStatus } from '../../helper/globalFunctions';
import { goBack, navigate } from '../../Routes/navigationServices';
import ApiConstants from '../../themes/apiConstants';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import history from '../../Routes/history'
import { DataManager } from '../../helper/dataManager';
import { stopTimer, stopSmallTimer } from '../../helper/timer'
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import strings from '../../themes/constants/strings';


import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';

const BackAndModal = (props) => {
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    history.listen(location => {
      DataManager.setLeaveGameStatus(true)
    })
  }, [])

  useEffect(() => {
    if (DataManager.getLeaveGameStatus()) {
      showModal()
    }
  }, [DataManager.getLeaveGameStatus()])

  const showModal = () => {
    setIsModalVisible(true);

  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    DataManager.removeLeaveGameStatus()
  };

  const successBtn = (
    <>
      <ButtonCustom
        resize={"cover"}
        outerContainer={{
          height: 49, alignSelf: 'center', cursor: 'pointer', justifyContent: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 30
        }}
        textColor={{ fontSize: fontSizes.f50, padding: 2 }}
        style={{ height: 49, width: 180, borderRadius: 4 }}
        // iContainer={{ height: 49 }}
        imgBg={appImages.buttonBg}
        title={'Leave'}
        className="forgot_text"
        onPress={() => {
          const checkAllowed = getLeaveStatus()
          if (isInternetConnected() && checkAllowed) {
            DataManager.deleteCurrentRount()
            socket.emit('selfExit');
            goBack()
            stopTimer()
            stopSmallTimer(dispatch)
            dispatch({ type: ApiConstants.REST_HOME })
          }
          else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
          }
        }}
      />
      {/* <button className='forgot_text' onClick={() => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
          DataManager.deleteCurrentRount()
          socket.emit('selfExit');
          goBack()
          stopTimer()
          stopSmallTimer(dispatch)
          dispatch({ type: ApiConstants.REST_HOME })
        }
        else {
          ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
        }
      }} style={{ marginBottom: 30 }}>
        <img src={commonImages.yes} className="send_new" />
      </button> */}
    </>
  )

  const fragment = (
    <>
      <div className='main_pop' style={{ padding: 0 }}>
        <div className='img_y alert_icons' style={{ marginTop: -34 }}>
          <img src={commonImages.alert} />
        </div>
        <div style={{ marginTop: 40 }}>
          <div className='form-group success_d leave-pop'>
            <p> {strings?.areYouSureGame}</p>
          </div>
        </div>
      </div>
    </>
  )


  return (
    <div>
      {isModalVisible && <ModalPop
        content={fragment}
        btnSuccess={successBtn}
        btnCancel={true}
        handleSuccess={handleOk}
        handleCancel={handleCancel}
        isVisible={isModalVisible}

      />}
      <img src={commonImages.back} onClick={() => showModal()} className="back_img" style={{
        position: 'absolute',
        top: gameScreenDimentions.width * 0.01,
        left: gameScreenDimentions.width * 0.02,
        zIndex: 9999
      }} />
    </div>
  )

}
export default BackAndModal