import { useLocation } from 'react-router';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { navigate } from '../../Routes/navigationServices';
import { commonImages } from '../../themes/appImage';
import strings from '../../themes/constants/strings';
import appImages from '../../themes/appImages/appImages';

const GameLoser = (props) => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    const location = useLocation()
    const noPoints = location.state?.noPoints
    return (
        <div>
            <div className='background_image loser '>
                <div className='winner'>
                    <img src={commonImages.sad} style={{ width: '300px' }} />
                    <h3 >{noPoints ? '' : strings.loser}</h3>
                    <p style={{
                        fontSize: 28,
                        fontWeight: 600
                    }}> {noPoints ? strings.noOne : strings.sorry}</p>
                    <p style={{
                        fontSize: 28,
                        fontWeight: 600
                    }}>  {noPoints ? '' : strings.competitiveLoose}</p>
                </div>
            </div>
            <img
                src={appImages.closeSq}
                className="back_img"
                style={{
                    position: 'absolute',
                    top: gameScreenDimentions.width * 0.01,
                    left: gameScreenDimentions.width * 0.02,
                    zIndex: 6
                }}
                onClick={() => navigate("playjoin")}
            />
            <img
                src={appImages.pointsBreak}
                className="back_img"
                style={{
                    position: 'absolute',
                    top: gameScreenDimentions.width * 0.01,
                    right: gameScreenDimentions.width * 0.02,
                    zIndex: 6,
                    width: 64
                }}
                onClick={() => navigate("pointsBreakdown")}
            />
        </div >
    )
}
export default GameLoser