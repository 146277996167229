import { commonImages } from '../../themes/appImage';
import { useSelector } from 'react-redux';
import strings from '../../themes/constants/strings';
import WinnerComponent from './WinnerComponent';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { navigate } from '../../Routes/navigationServices';
import { useLocation } from 'react-router';
import appImages from '../../themes/appImages/appImages';

const GameWinner = (props) => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    const location = useLocation()
    const isUltimate = location.state?.isUltimate
    const type = location.state?.type
    const isWin = location.state?.isWin
    const winnerDetails = location.state?.winnerDetails //useSelector((state) => state.homeReducer.winnerDetails);
    const array = winnerDetails.sort((a, b) => b.points - a.points);


    const showDetails = (innerType) => {
        if (type === 'cooperative') {
            if (isUltimate && isWin) {
                return innerType === 'heading' ? strings.ultimateWinner : strings.cooperativeWinPoints
            } else if (!isUltimate && isWin) {
                return innerType === 'heading' ? strings.greatWork : strings.cooperativeWin
            }
        } else if (type === 'competitive') {
            if (isUltimate) {
                return innerType === 'heading' ? strings.ultimateWinner : strings.competitiveWin
            } else if (!isUltimate && isWin) {
                return innerType === 'heading' ? strings.greatWork : strings.competitiveLoose
            }
        }
    }

    return (
        <div>
            <div className='background_image Winner '>
                <div className='winner'>
                    <img src={commonImages.winner} style={{ width: '300px' }} />
                    <h3 style={{ marginTop: -20 }} >  {showDetails("heading")}</h3>
                    {isUltimate && <p style={{ marginTop: -16 }}> {strings.congratulations}</p>}
                    <p style={{ marginTop: -16 }}>   {showDetails("text")}</p>

                    <div className='room lobby'>
                        <div className='scroll_div'>
                            {
                                array.map((item) => {
                                    return (
                                        <WinnerComponent
                                            item={item}
                                            isUltimate={isUltimate}
                                            type={type}
                                            isWin={isWin}
                                            key={Math.random().toString()}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={appImages.closeSq}
                className="back_img"
                style={{
                    position: 'absolute',
                    top: gameScreenDimentions.width * 0.01,
                    left: gameScreenDimentions.width * 0.02,
                    zIndex: 6
                }}
                onClick={() => navigate("playjoin")}
            />
            <img
                src={appImages.pointsBreak}
                className="back_img"
                style={{
                    position: 'absolute',
                    top: gameScreenDimentions.width * 0.01,
                    right: gameScreenDimentions.width * 0.02,
                    zIndex: 6,
                    width: 64
                }}
                onClick={() => navigate("pointsBreakdown")}
            />
        </div>
    )
}
export default GameWinner