import { modal, statusBar, textColor, textInput } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import { gameScreenDimentions } from '../../../helper/globalFunctions';
import bg from '../../../assets/images/popup_1.png';

const Width = gameScreenDimentions.width;

const styles = {
    outerContainer: {
        elevation: 5,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
    },
    modalView: {
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: Width * 0.32,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column'
    },
    radioBox: {
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 12,
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
    },
    radioBox2: {
        paddingTop: 12,
        paddingBottom: 12,
        flexDirection: 'row',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    outerDot: {
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: statusBar.bgColor,
        marginTop: 3,
        marginBottom: 3,
        margingLeft: 3,
        marginRight: 3,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    innerDot: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: statusBar.green,
        alignSelf: 'center',
        display: 'flex',
    },
    selectTile: {
        color: textColor.green,
        textAlign: "center",
        fontWeight: '800',
        fontSize: fontSizes.f51,
        marginLeft: 24,
        marginRight: 24,
        display: 'flex',
    },
    imageStyle: {
        paddingLeft: 24,
        paddingRight: 24,
        alignItems: "center",
        width: Width * 0.45,
        height: Width * 0.34,
        display: 'flex',
    },
    selectTile1: {
        color: textColor.green,
        textAlign: "center",
        fontWeight: '800',
        fontSize: fontSizes.f60,
        marginTop: 2,
        display: 'flex',
    },
    profileImageStyle: {
        width: Width * 0.03,
        height: Width * 0.03,
        borderRadius: (Width * 0.03) / 2,
        borderWidth: 3,
        borderColor: textColor.green,
        borderStyle: 'solid',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
}

export default styles;