let soundsObj = [
    require('../assets/audio/buttonclick.wav'),
    require('../assets/audio/pawnmovement.wav'),
    require('../assets/audio/cardflip.mp3'),
]

let bgMusicObj = [
    require('../assets/audio/disparitytrapmusic.mp3'),
]

var bgMusic = null;
var buttonSound = null;
var pawnSound = null;
var cardSound = null;
var isPlaying = false

export function playSound() {
    const localBgSound = new Audio(bgMusicObj[0])
    bgMusic = localBgSound

    const localButtonSound = new Audio(soundsObj[0])
    buttonSound = localButtonSound

    const localPawnSound = new Audio(soundsObj[1])
    pawnSound = localPawnSound

    const localCardSound = new Audio(soundsObj[2])
    cardSound = localCardSound

}

export const buttonClickSound = () => {
    buttonSound?.play()
}

export const pawnMovementSound = () => {
    pawnSound?.play()
}

export const cardMovementSound = () => {
    cardSound?.play()
}

export const backgroundMusicSound = async () => {
    if (isPlaying === false) {
        try {
            await bgMusic.play()
            isPlaying = true
            if (bgMusic) {
                bgMusic.volume = 0.1
                bgMusic.loop = true
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export const stopBgMusic = () => {
    bgMusic?.pause()
}

export const soundOn = () => {
    buttonSound.muted = false;
    pawnSound.muted = false;
    cardSound.muted = false;
    bgMusic.muted = false;
}

export const soundOff = () => {
    buttonSound.muted = true;
    pawnSound.muted = true;
    cardSound.muted = true;
    bgMusic.muted = true;
}

export const releaseSound = () => {
    buttonSound?.release();
    pawnSound?.release();
    cardSound?.release();
}