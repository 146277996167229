import React from "react";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import Spacer from "../Spacer";
import styles from './styles';
import { CARDS } from '../../../utils/jsonData';
import { useDispatch, useSelector } from "react-redux";
import { getLeaveStatus } from "../../../helper/globalFunctions";
import { socket } from "../../../helper/socket";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { dialogNames, influenceAction, popupType } from "../../../themes/constants/gameConstants";
import ApiConstants from "../../../themes/apiConstants";
import { stopSmallTimer } from "../../../helper/timer";
import { buttonClickSound } from "../../../helper/playSound";
import { isInternetConnected } from "../../../helper/networkUtils";

const SelectDiceComponent = (props) => {

    const dispatch = useDispatch()
    const roomData = useSelector((state) => state.homeReducer?.roomData);
    const ourGameData = useSelector((state) => state.homeReducer?.ourGameData);
    const isOurTurn = useSelector((state) => state.homeReducer?.isOurTurn);
    const showMoveCardOther = useSelector((state) => state.homeReducer?.showMoveCardOther);
    const otherPlayRoleType = useSelector((state) => state.homeReducer?.otherPlayRoleType);
    const openDiceBox = useSelector((state) => state.homeReducer?.openDiceBox);
    const tokenType = useSelector((state) => state.homeReducer?.tokenType);
    const influenceAction1 = useSelector((state) => state.homeReducer?.influenceAction);
    const influenceType = useSelector((state) => state.homeReducer?.influenceType);
    const useInfluenceCard = useSelector((state) => state.homeReducer?.useInfluenceCard);
    const range = useSelector((state) => state.homeReducer?.range);
    const dialogId = useSelector((state) => state.homeReducer?.dialogId);

    const [dicesArray, setDicesArray] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const showDice = ((influenceAction1 === influenceAction.add) || (influenceAction1 === influenceAction.own)) ? 'T' : 'F';


    React.useEffect(() => {
        if (ourGameData && useInfluenceCard && roomData && tokenType && range) {
            const card = CARDS[useInfluenceCard]
            let array = []
            let isDisparityOn = false
            if (card.reward) {
                if (roomData?.vote) {
                    Object.entries(roomData?.vote).map(([i, j]) => {
                        if (j && (i === card.reward)) {
                            isDisparityOn = true
                        }
                    })
                }
            }

            if (isDisparityOn) {
                array = range
            }
            else {
                array = range
            }
            const allDices = [{
                id: 1,
                title: 1,
                show: array[0],
                toShow: array.includes(1)
            },
            {
                id: 2,
                title: 2,
                show: array[1],
                toShow: array.includes(2)
            }, {
                id: 3,
                title: 3,
                show: array[2],
                toShow: array.includes(3)
            }, {
                id: 4,
                title: 4,
                show: array[3],
                toShow: array.includes(4)
            }, {
                id: 5,
                title: 5,
                show: array[4],
                toShow: array.includes(5)
            }, {
                id: 6,
                title: 6,
                show: array[5],
                toShow: array.includes(6)
            }]
            setDicesArray(allDices)
        }
    }, [ourGameData, roomData, useInfluenceCard, tokenType, range])

    React.useEffect(() => {
        if (ourGameData && showMoveCardOther && otherPlayRoleType && roomData && tokenType && range) {
            const card = CARDS[showMoveCardOther]
            let array = []
            let isDisparityOn = false
            if (card.reward) {
                if (roomData?.vote) {
                    Object.entries(roomData?.vote).map(([i, j]) => {
                        if (j && (i === card.reward)) {
                            isDisparityOn = true
                        }
                    })
                }
            }

            if (isDisparityOn) {
                array = range
            }
            else {
                array = range
            }

            const allDices = [{
                id: 1,
                title: 1,
                show: array[0],
                toShow: array.includes(1)
            },
            {
                id: 2,
                title: 2,
                show: array[1],
                toShow: array.includes(2)
            }, {
                id: 3,
                title: 3,
                show: array[2],
                toShow: array.includes(3)
            }, {
                id: 4,
                title: 4,
                show: array[3],
                toShow: array.includes(4)
            }, {
                id: 5,
                title: 5,
                show: array[4],
                toShow: array.includes(5)
            }, {
                id: 6,
                title: 6,
                show: array[5],
                toShow: array.includes(6)
            }]
            setDicesArray(allDices)
        }
    }, [ourGameData, showMoveCardOther, otherPlayRoleType, roomData, tokenType, range])

    const onDiceClick = (item, index) => {
        setSelectedIndex(index);
    }

    const onSubmitPress = () => {
        if (selectedIndex !== null && selectedIndex > -1) {
            const checkAllowed = getLeaveStatus()
            if (isInternetConnected() && checkAllowed) {
                dispatch({
                    type: ApiConstants.OPEN_DICE_BOX,
                    payload: {
                        openDiceBox: false
                    }
                })
                socket.emit('influence', { number: selectedIndex + 1, action: influenceAction1, type: tokenType, cardType: influenceType })
                dispatch({
                    type: ApiConstants.INFLUENCE_ACTION,
                    payload: null
                })
                setSelectedIndex(null);
                stopSmallTimer(dispatch)
                if (!isOurTurn) {
                    dispatch({
                        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                        payload: true
                    })
                }
            } else {
                ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
            }
        } else {
            ShowAlertMessage(ValidationConstants.pleaseSubmit, popupType.error);
        }
    }

    const onBackPress = () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            dispatch({
                type: ApiConstants.OPEN_DICE_BOX,
                payload: {
                    openDiceBox: false
                }
            })
            setSelectedIndex(null);
            if (dialogId === 6) {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.useYourInfluenceWithPower
                    }
                })
            } else if (dialogId === 7) {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.useYourInfluenceWithPrivilege
                    }
                })
            } else if (dialogId === 13) {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.wouldYouLikeTo
                    }
                })
            }
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
        }
    }


    return (
        <>
            {openDiceBox && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <Spacer height={spacing.l} />
                    <span style={styles.selectTile}>{influenceAction1 === influenceAction.own ? strings.selectOneNumber : `Select one number to ${(influenceAction1 === influenceAction.add) ? influenceAction.add : influenceAction.take} to the dice roll.`}</span>
                    <Spacer height={spacing.m} />
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
                    }}>
                        {dicesArray.map((item, index) => {
                            return (
                                item?.toShow === (((influenceAction1 === influenceAction.add) || (influenceAction1 === influenceAction.own)) ? false : true) &&
                                <div style={{
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }} key={item.id}>
                                    <div
                                        style={styles.profileImageStyle}
                                        onClick={() => {
                                            buttonClickSound();
                                            onDiceClick(item, index)
                                        }}
                                    >
                                        <span style={styles.selectTile1}>{item.title}</span>
                                    </div>
                                    <div style={styles.radioBox2}>
                                        <div
                                            onClick={() => {
                                                buttonClickSound();
                                                onDiceClick(item, index)
                                            }}
                                        >
                                            <div style={styles.outerDot}>
                                                {selectedIndex === index &&
                                                    <div View style={styles.innerDot}>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Spacer height={spacing.m} />
                    <div style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
                        width: '100%'
                    }}>

                        <ButtonCustom
                            textColor={{ fontSize: fontSizes.f40 }}
                            imgBg={appImages.buttonBg}
                            title={strings.back}
                            onPress={() => {
                                buttonClickSound();
                                onBackPress();
                            }}
                        />
                        <ButtonCustom
                            textColor={{ fontSize: fontSizes.f40 }}
                            imgBg={appImages.buttonBg}
                            title={strings.submit}
                            onPress={() => {
                                buttonClickSound();
                                onSubmitPress();
                            }}
                        />
                    </div>
                    <Spacer height={spacing.l} />
                </div>
            </div >}
        </>
    );
};

export default SelectDiceComponent;