import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { getBlockedUsersSocketAction, roomSuccessAction } from '../redux/actions/homeActions/homeActions';
import { store } from '../redux/store';
import { navigate, reset } from '../Routes/navigationServices';
import ApiConstants from '../themes/apiConstants';
import { dialogNames, influenceAction, popupType, RollDiceType, tileNames } from '../themes/constants/gameConstants';
import { DataManager } from './dataManager';
import Emitter from './emitter';
import { FirebaseMethods } from './firebaseMethod';
import { getCardType, getIsIRoleDice, logout, setAwardTileCount, setCardType, setCurrentP1, setCurrentP2, setCurrentP3, setCurrentP4, setCurrentP5, setCurrentP6, updateRollDiceEmit } from './globalFunctions';
import { ShowAlertMessage } from './showAlertMessage';
import { socket } from './socket';
import { timer, stopTimer, getIsTimerRunning, getCurrentPausedTime, getCurrentPausedTimeCount, smallTimer, stopSmallTimer } from './timer';

var dispatch = null;
var startMatchTimeout = null;
var currentRoomData = null;

export const updateDispatch = (localDispatch) => { dispatch = localDispatch }
export const userProfileSuccess = (data) => {
    dispatch({
        type: ApiConstants.UPDATE_USER_DATA,
        data: data.profile
    })
}

export const hostExit = (data) => {
    ShowAlertMessage(data.message, popupType.error);
    dispatch({ type: ApiConstants.REST_HOME })
    stopTimer()
    stopSmallTimer(dispatch)
    DataManager.deleteCurrentRount()
    navigate("playjoin");
}

export const winner = (data) => {
    dispatch({
        type: ApiConstants.WINNER_DETAILS,
        winnerDetails: data?.roomDetail?.players
    })
    toast.dismiss();
    if (data?.message) {
        ShowAlertMessage(data.message, popupType.error);
    }
    DataManager.deleteCurrentRount()
    if (data?.type) {
        navigate((((data?.isWin || data?.isUltimate) && !data?.noPoints)) ? "gamewinner" : "gameloser", { type: data?.type, isUltimate: data?.isUltimate, isWin: data?.isWin, noPoints: data?.noPoints, winnerDetails: data?.roomDetail?.players })
    }
    dispatch({ type: ApiConstants.REST_HOME })
    stopTimer()
}

export const userBlock = (data) => {
    let userId = store.getState().authenticationReducer.userId
    FirebaseMethods.createUserList(userId.toString(), false)
    logout(data.message)
    dispatch({ type: ApiConstants.REST_HOME })
    dispatch({ type: ApiConstants.RESET_AUTH_DATA })
    stopTimer()
}

export const joinRoomSuccess = (data) => {
    dispatch(roomSuccessAction(data.roomDetail))
    navigate(data?.roomDetail?.isStart ? "Game" : "GameLobby")
    if (data?.roomDetail?.isStart && data?.roomDetail?.isSpectator) {
        timer(
            dispatch,
            data?.roomDetail?.roomId,
            data?.roomDetail?.turn?.difference,
            false
        )
    }
}

export const updateRoomDetail = (data) => {
    if (data?.message) {
        ShowAlertMessage(data.message, popupType.info)
    }
    dispatch({
        type: ApiConstants.PAUSE_GAME,
        data: data?.roomDetail?.isPause,
    });
    if (data?.type !== 'selfExit') {
        tileAwardAnimation(data)
        updateRoomDetails(data)
        if (data?.stayHere) {
            // stopSmallTimer(dispatch, data?.stayHere)
        } else {
            stopSmallTimer(dispatch)
            dispatch({
                type: ApiConstants.SHOW_MOVE_CARD,
                payload: {
                    showMoveCard: null,
                }
            })
            dispatch({
                type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                payload: false
            })
            dispatch({
                type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
                payload: false,
                voteTileName: ''
            })
        }
    }
    else {
        let index = 0
        for (let i of data.roomDetail?.players) {
            if (i?.user?._id === store.getState().authenticationReducer.userId) {
                dispatch({
                    type: ApiConstants.OUR_GAME_DATA_UPDATE,
                    payload: {
                        isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                        ourGameData: i,
                        isPart1Completed: i?.isPart1Completed
                    }
                })
            }
            if (index == 0) {
                dispatch({ type: ApiConstants.CPAWN_1, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_1, payload: i?.position });
                setCurrentP1(i?.position)
            } else if (index == 1) {
                dispatch({ type: ApiConstants.CPAWN_2, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_2, payload: i?.position });
                setCurrentP2(i?.position)
            } else if (index == 2) {
                dispatch({ type: ApiConstants.CPAWN_3, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_3, payload: i?.position });
                setCurrentP3(i?.position)
            } else if (index == 3) {
                dispatch({ type: ApiConstants.CPAWN_4, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_4, payload: i?.position });
                setCurrentP4(i?.position)
            } else if (index == 4) {
                dispatch({ type: ApiConstants.CPAWN_5, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_5, payload: i?.position });
                setCurrentP5(i?.position)
            } else if (index == 5) {
                dispatch({ type: ApiConstants.CPAWN_6, payload: i?.position });
                dispatch({ type: ApiConstants.PAWN_6, payload: i?.position });
                setCurrentP6(i?.position)
            }
            dispatch({ type: ApiConstants.IS_SOMEONE_LEFT, payload: true });
            index++
        }
        dispatch(roomSuccessAction(data.roomDetail))
    }
    if (data?.roomDetail?.isPause) {
        console.log("data?.roomDetail?.turn?.difference", data?.roomDetail?.turn?.difference);
        timer(dispatch, data?.roomDetail?.roomId, data?.roomDetail?.pauseStatus?.pendingTime - 1, data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId);
        setTimeout(() => {
            stopSmallTimer(dispatch);
            stopTimer();
        }, 1200);
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.close,
            }
        })
        dispatch({
            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
            payload: false,
            voteTileName: ''
        })
        dispatch({
            type: ApiConstants.ROLL_DICE_UPDATE,
            payload: { rollDice: false, rollDiceType: null }
        })
        dispatch({
            type: ApiConstants.OPEN_DICE_BOX,
            payload: {
                tokenType: '',
                openDiceBox: false
            }
        })
    }
}

export const startMatchSuccess = (data) => {
    let playerType = 'SD'
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            dispatch({
                type: ApiConstants.OUR_GAME_DATA_UPDATE,
                payload: {
                    isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                    ourGameData: i,
                    isPart1Completed: i?.isPart1Completed
                }
            })
            dispatch({
                type: ApiConstants.OUR_CARD_UPDATE,
                payload: {
                    ourCards: i.availableCards
                }
            })
            playerType = i?.role
        }
    }
    data?.roomDetail?.players?.map((i, index) => {
        if (index == 0) {
            dispatch({ type: ApiConstants.CPAWN_1, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_1, payload: 1 });
            setCurrentP1(1)
        } else if (index == 1) {
            dispatch({ type: ApiConstants.CPAWN_2, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_2, payload: 1 });
            setCurrentP2(1)
        } else if (index == 2) {
            dispatch({ type: ApiConstants.CPAWN_3, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_3, payload: 1 });
            setCurrentP3(1)
        } else if (index == 3) {
            dispatch({ type: ApiConstants.CPAWN_4, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_4, payload: 1 });
            setCurrentP4(1)
        } else if (index == 4) {
            dispatch({ type: ApiConstants.CPAWN_5, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_5, payload: 1 });
            setCurrentP5(1)
        } else if (index == 5) {
            dispatch({ type: ApiConstants.CPAWN_6, payload: 1 });
            dispatch({ type: ApiConstants.PAWN_6, payload: 1 });
            setCurrentP6(1)
        }
    })
    dispatch(roomSuccessAction(data.roomDetail))
    DataManager.setCurrentRoute('Game')
    navigate('Game')
    if (data?.initialRollUp) {
        startMatchTimeout = setTimeout(() => {
            if (playerType === 'SD') {
                dispatch({
                    type: ApiConstants.ROLL_DICE_UPDATE,
                    payload: { rollDice: true, rollDiceType: RollDiceType.turnSelect }
                })
            } else {
                dispatch({
                    type: ApiConstants.SHOW_MESSAGE_COMPONENT_UPDATE,
                    payload: true
                })
            }
        }, 4000)

    }
}

export const initialTurnResult = (data) => {
    clearTimeout(startMatchTimeout);
    if (data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId) {
        ShowAlertMessage(`It's your turn.`, popupType.info)
        dispatch({
            type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
            payload: null
        })
    }
    else {
        ShowAlertMessage(`${data.roomDetail?.turn?.player?.name} has first turn to play.`, popupType.info)
    }
    currentRoomData = data.roomDetail
    updateRoomDetails(data)
    dispatch({
        type: ApiConstants.ROLL_DICE_UPDATE,
        payload: { rollDice: false, rollDiceType: null }
    })
    dispatch({
        type: ApiConstants.SHOW_MESSAGE_COMPONENT_UPDATE,
        payload: false
    })
    timer(dispatch, data?.roomDetail?.roomId, data.roomDetail.turn.difference, data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId)
}

export const checkTurnSuccess = (data) => {
    var needCallForChangePart = false
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            if (i?.isPart1Completed === false && i?.isPart1Played) {
                needCallForChangePart = true
            }
        }
    }
    if (data?.roomDetail?.turnPlayed) {
        // setTimeout(() => {
        socket.emit('changeTurn')
        // }, 1500)
    }
    else if (needCallForChangePart) {
        socket.emit('startNextPart')
        dispatch({
            type: ApiConstants.UPDATE_PART_COMPLETE,
            payload: true
        })
    }
    else {
        if (data.roomDetail?.turn?.player) {
            if (currentRoomData?.turn?.player?._id === data.roomDetail?.turn?.player?._id) {
                // stop double notification
                if (getIsTimerRunning() === false && getCurrentPausedTimeCount() > 0) {
                    const oldTime = getCurrentPausedTime()
                    const newTime = moment()
                    const difference = newTime.diff(oldTime, 'seconds')
                    if (difference > getCurrentPausedTimeCount()) {
                        if (data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId) {
                            ShowAlertMessage(`It's your turn.`, popupType.info)
                            dispatch({
                                type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                                payload: null
                            })
                        }
                        else {
                            ShowAlertMessage(`${data.roomDetail?.turn?.player?.name} is now playing their turn.`, popupType.info)
                        }
                        resetDispatchCalled()
                    } else {
                        for (let i of data.roomDetail?.players) {
                            if (i?.user?._id === store.getState().authenticationReducer.userId) {
                                if (i?.isDiceRollPending) {
                                    updateRollDiceEmit(false)
                                    dispatch({
                                        type: ApiConstants.SHOW_MOVE_CARD,
                                        payload: {
                                            showMoveCard: null,
                                        }
                                    })
                                    dispatch({
                                        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                                        payload: false
                                    })
                                    // dispatch({
                                    //     type: ApiConstants.ROLL_DICE_UPDATE,
                                    //     payload: { rollDice: true, rollDiceType: getCardType() ? getCardType() : RollDiceType.opportunity }
                                    // })
                                }
                            }
                        }
                    }
                }
            }
            else {
                if (data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId) {
                    ShowAlertMessage(`It's your turn.`, popupType.info)
                    dispatch({
                        type: ApiConstants.SHOW_CARD_NORMALLY_UPDATE,
                        payload: null
                    })
                }
                else {
                    ShowAlertMessage(`${data.roomDetail?.turn?.player?.name} is now playing their turn.`, popupType.info)
                }
                // if (store.getState().homeReducer?.roomData?.turn?.player?._id !== data.roomDetail?.turn?.player?._id) {
                resetDispatchCalled()
                // }

            }
        }
        currentRoomData = data.roomDetail
        updateRoomDetails(data)
        timer(dispatch, data?.roomDetail?.roomId, data.roomDetail.turn.difference, data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId)
    }
}

export const emitSkillTileSuccess = (data) => {
    Emitter.emit("emitSkillTileSuccess");
    setTimeout(() => {
        updateRoomDetails(data)
        setTimeout(() => {
            socket.emit('startNextPart')
            dispatch({
                type: ApiConstants.UPDATE_PART_COMPLETE,
                payload: true
            })
        }, 1300)
    }, 1200)
}

export const tradeTileSuccess = (data) => {
    ShowAlertMessage(data?.message, popupType.info)
    updateRoomDetails(data)
    dispatch({
        type: ApiConstants.IS_TRADE_AVAILABLE,
        payload: data.isTradeAvailable
    })
    dispatch({
        type: ApiConstants.TRADE_TILE_UPDATE,
        payload: true
    })
}

export const discardCardSuccess = (data) => {
    updateRoomDetails(data)
    setTimeout(() => {
        socket.emit('startNextPart')
        dispatch({
            type: ApiConstants.UPDATE_PART_COMPLETE,
            payload: true
        })
    }, 1000)
}

export const selectedVoteTile = (data) => {
    // need to add check for spectator too
    if (store.getState().homeReducer?.ourGameData?.tokens?.privilege > 0) {
        let rTime = data?.timer ? data?.timer : 60;
        smallTimer(dispatch, rTime);
        ShowAlertMessage(`You have ${rTime} seconds to vote.`, popupType.info);
        dispatch({
            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
            payload: true,
            voteTileName: data.tile
        })
    }
    else {
        ShowAlertMessage('Please wait voting is going on.', popupType.info)
    }
}

export const totalVoteTokens = async (data) => {
    dispatch({
        type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
        payload: false,
        voteTileName: ''
    })
    ShowAlertMessage(`Total token vote count is ${data?.tokens}.`, popupType.info)
    if (store.getState().homeReducer?.roomData?.turn?.player?._id === store.getState().authenticationReducer.userId) {
        updateRollDiceEmit(false)
        dispatch({
            type: ApiConstants.ROLL_DICE_UPDATE,
            payload: { rollDice: true, rollDiceType: RollDiceType.vote }
        })
    }
}

export const tokenForVoteSuccess = async (data) => {
    updateRoomDetails(data)
}

export const voteRollDiceSuccess = (data) => {
    dispatch({
        type: ApiConstants.ROLL_DICE_UPDATE,
        payload: { rollDice: false, rollDiceType: null }
    })
    if (data?.isVotePass) {
        if (data?.message) {
            ShowAlertMessage(data?.message, popupType.info)
            if (data?.isTokenWin === true) {
                // power Token add animation
                dispatch({
                    type: ApiConstants.AWARD_TILE_UPDATE,
                    payload: tileNames.power
                })
            }
            else if (data?.isTokenWin === false) {
                // preiviliege Token remove animation
            }
        }
        else {
            ShowAlertMessage(`Vote Passed.`, popupType.info)
        }
    }
    // else {
    //     if (data?.message) {
    //         ShowAlertMessage(data?.message, popupType.error)
    //     }
    //     else {
    //         ShowAlertMessage(`Vote Failed.`, popupType.error)
    //     }
    // }
    updateRoomDetails(data)
    if (store.getState().homeReducer?.roomData?.turn?.player?._id === store.getState().authenticationReducer.userId) {
        setTimeout(() => {
            socket.emit('startNextPart')
            dispatch({
                type: ApiConstants.UPDATE_PART_COMPLETE,
                payload: true
            })
        }, 2500)
    }
}

export const qualifiedStatusSuccess = (data) => {
    checkQualifiedStatus(data)
}

export const powerOpportunityCardSuccess = (data) => {
    checkPowerOppartunity(data)
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            dispatch({
                type: ApiConstants.OUR_GAME_DATA_UPDATE,
                payload: {
                    isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                    ourGameData: i,
                    isPart1Completed: i?.isPart1Completed
                }
            })
        }
    }
    dispatch(roomSuccessAction(data.roomDetail))
}

export const useInfluenceCard = (data) => {
    if (store.getState().homeReducer?.ourGameData?.tokens?.privilege >= 3) {
        dispatch({
            type: ApiConstants.OTHER_PLAYER_ROLE_TYPE,
            payload: null
        })
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD,
            payload: {
                showMoveCard: null,
            }
        })
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD_CHECK,
            payload: false
        })
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.useYourInfluenceWithPrivilege
            }
        })
        dispatch({
            type: ApiConstants.INFLUENCE_ACTION,
            payload: influenceAction.own
        })
        dispatch({
            type: ApiConstants.INFLUENCE_TYPE,
            payload: data?.type
        })
        dispatch({
            type: ApiConstants.USE_INFLUENCE_CARD,
            payload: data?.card
        })
        dispatch({
            type: ApiConstants.GET_RANGE,
            payload: data?.range
        })
    } else {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.SHOW_MOVE_CARD,
                payload: {
                    showMoveCard: null,
                }
            })
            dispatch({
                type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                payload: false
            })
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.useYourInfluenceWithPower
                }
            })
            dispatch({
                type: ApiConstants.INFLUENCE_ACTION,
                payload: influenceAction.own
            })
            dispatch({
                type: ApiConstants.INFLUENCE_TYPE,
                payload: data?.type
            })
            dispatch({
                type: ApiConstants.USE_INFLUENCE_CARD,
                payload: data?.card
            })
            dispatch({
                type: ApiConstants.GET_RANGE,
                payload: data?.range
            })
        }
    }
}

export const influenceSuccess = (data) => {
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            dispatch({
                type: ApiConstants.OUR_GAME_DATA_UPDATE,
                payload: {
                    isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                    ourGameData: i,
                    isPart1Completed: i?.isPart1Completed
                }
            })
        }
    }
    dispatch(roomSuccessAction(data.roomDetail))
}

export const displayRollDicePopUp = (data) => {
    // if (data?.type === RollDiceType.opportunity || data?.type === RollDiceType.newTier) {
    socket.emit('readyToRollDice', data?.type)
    // dispatch({
    //     type: ApiConstants.OPEN_DIALOG_BOX,
    //     payload: {
    //         openDialog: dialogNames.rollDiceToPlay
    //     }
    // })
    // }
    // else {
    //     dispatch({
    //         type: ApiConstants.OPEN_DIALOG_BOX,
    //         payload: {
    //             openDialog: dialogNames.rollDiceToPlayWithoutBack
    //         }
    //     })
    // }
    // dispatch({
    //     type: ApiConstants.DISPLAY_ROLL_DICE_POPUP_TYPE,
    //     payload: data?.type
    // })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
}

export const displayCard = (data) => {
    dispatch({
        type: ApiConstants.UPDATE_IS_NEW_GAME,
        data: false
    })
    dispatch({
        type: ApiConstants.ROLL_DICE_UPDATE,
        payload: { rollDice: false, rollDiceType: null }
    })
    setCardType(data?.type)
    if (!store.getState().homeReducer.isOurTurn) {
        for (let i of store.getState().homeReducer?.roomData?.players) {
            if (i?.user?._id === store.getState().homeReducer.roomData?.turn?.player?._id) {
                dispatch({
                    type: ApiConstants.SHOW_MOVE_CARD_OTHER,
                    payload: {
                        showMoveCardOther: data?.card,
                        otherPlayRoleType: i?.role
                    }
                })
            }
        }
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD,
            payload: {
                showMoveCard: data?.card,
            }
        })
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD_CHECK,
            payload: true
        })
    }
    if (data?.type !== 'opportunity') {
        for (let i of store.getState().homeReducer?.roomData?.players) {
            if (i?.user?._id === store.getState().homeReducer.roomData?.turn?.player?._id) {
                dispatch({
                    type: ApiConstants.SHOW_MOVE_CARD_OTHER,
                    payload: {
                        showMoveCardOther: data?.card,
                        otherPlayRoleType: i?.role
                    }
                })
            }
        }
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD,
            payload: {
                showMoveCard: data?.card,
            }
        })
        dispatch({
            type: ApiConstants.SHOW_MOVE_CARD_CHECK,
            payload: true
        })
    }
    // if (data?.type === 'event') {
    //     setTimeout(() => {
    //         socket.emit('playEventCard')
    //     }, 10000)
    // }
}

export const useInfluenceForOther = (data) => {
    dispatch({
        type: ApiConstants.USE_INFLUENCE_CARD,
        payload: null
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_OTHER,
        payload: {
            showMoveCardOther: data?.card,
            otherPlayRoleType: data?.role
        }
    })
    dispatch({
        type: ApiConstants.GET_RANGE,
        payload: data?.range
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: data?.card,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: true
    })
    dispatch({
        type: ApiConstants.INFLUENCE_TYPE,
        payload: data?.type
    })
    if (store.getState().homeReducer?.ourGameData?.tokens?.privilege >= 3) {
        smallTimer(dispatch, 130, dialogNames.useInfluenceOtherWithPrivilege)
        ShowAlertMessage('You have 120 seconds to use your influence card.', popupType.info, 10000)
    } else {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            smallTimer(dispatch, 130, dialogNames.useInfluenceOtherWithPower)
            ShowAlertMessage('You have 120 seconds to use your influence card.', popupType.info, 10000)
        }
    }
}

export const diceRange = (data) => {
    ShowAlertMessage(data.message, popupType.info)
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    dispatch({
        type: ApiConstants.OPEN_DICE_BOX,
        payload: {
            openDiceBox: false,
            tokenType: ''
        }
    })
}

export const startRollDice = (data) => {
    // if (store.getState().homeReducer.isOurTurn) {
    updateRollDiceEmit(false)
    dispatch({
        type: ApiConstants.ROLL_DICE_UPDATE,
        payload: { rollDice: true, rollDiceType: data?.type }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    // }
}

export const displayDice = (data) => {
    //show roll dice to other
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    if (getIsIRoleDice() === false) {
        //use this to show dice roll to other.
        dispatch({
            type: ApiConstants.DISPLAY_DICE,
            payload: data?.number
        })
    }
}

export const useReRollDice = (data) => {
    // if (store.getState().homeReducer.isOurTurn) {
    if (store.getState().homeReducer?.ourGameData?.tokens?.privilege >= 3) {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantAnotherChanceWithPrivilegeThree
                }
            })
        } else {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantAnotherChanceWithPrivilegeTwo
                }
            })
        }
        dispatch({
            type: ApiConstants.RE_ROLL_TYPE,
            payload: data?.type
        })
    } else {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantAnotherChanceWithPower
                }
            })
            dispatch({
                type: ApiConstants.RE_ROLL_TYPE,
                payload: data?.type
            })
        }
    }
    // }
}

export const useReRollForOther = (data) => {
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    // if (!store.getState().homeReducer.isOurTurn) {
    if (store.getState().homeReducer?.ourGameData?.tokens?.privilege >= 3) {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantToGiveWithPrivilegeThree
                }
            })
            smallTimer(dispatch)
            ShowAlertMessage('You have 60 seconds to make a decision.', popupType.info)
        } else {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantToGiveWithPrivilegeTwo
                }
            })
            smallTimer(dispatch)
            ShowAlertMessage('You have 60 seconds to make a decision.', popupType.info)
        }
        dispatch({
            type: ApiConstants.RE_ROLL_TYPE,
            payload: data?.type
        })
    } else {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.wantToGiveWithPower
                }
            })
            dispatch({
                type: ApiConstants.RE_ROLL_TYPE,
                payload: data?.type
            })
            smallTimer(dispatch)
            ShowAlertMessage('You have 60 seconds to make a decision.', popupType.info)
        }
    }
    // }
}

export const reRollSuccess = (data) => {
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            dispatch({
                type: ApiConstants.OUR_GAME_DATA_UPDATE,
                payload: {
                    isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                    ourGameData: i,
                    isPart1Completed: i?.isPart1Completed
                }
            })
        }
    }
    dispatch(roomSuccessAction(data.roomDetail))
}

export const playOpportunitySuccess = (data) => {
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    tileAwardAnimation(data)
    if (data?.isDiceWin) {
        ShowAlertMessage('You did it!', popupType.info)
    }
    else {
        if (data?.isDiceWin === false) {
            ShowAlertMessage('Sorry, not this time!', popupType.error)
        }
        else {
            ShowAlertMessage('You did it!', popupType.info)
        }
    }
    updateRoomDetails(data)
    setTimeout(() => {
        socket.emit('startNextPart')
        dispatch({
            type: ApiConstants.UPDATE_PART_COMPLETE,
            payload: true
        })
    }, 1500)
}

export const displayPawn = (data) => {
    const index = store.getState().homeReducer?.roomData?.players?.findIndex((player) => player?.user?._id == data?.pawnInfo?.userId);
    if (index === 0) {
        dispatch({ type: ApiConstants.CPAWN_1, payload: (data?.pawnInfo?.position) });
    } else if (index === 1) {
        dispatch({ type: ApiConstants.CPAWN_2, payload: (data?.pawnInfo?.position) });
    } else if (index === 2) {
        dispatch({ type: ApiConstants.CPAWN_3, payload: (data?.pawnInfo?.position) });
    } else if (index === 3) {
        dispatch({ type: ApiConstants.CPAWN_4, payload: (data?.pawnInfo?.position) });
    } else if (index === 4) {
        dispatch({ type: ApiConstants.CPAWN_5, payload: (data?.pawnInfo?.position) });
    } else if (index === 5) {
        dispatch({ type: ApiConstants.CPAWN_6, payload: (data?.pawnInfo?.position) });
    }
    //data?.pawnInfo?.userId
    //data?.pawnInfo?.position
}

export const usePowerForTax = (data) => {
    resetDispatchCalled()
    //show dialog
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.usePowerTokenForTaxBreak
        }
    })
    smallTimer(dispatch)
}

export const displayPlayPopUp = (data) => {
    //show dialog
    if (data?.type === 'shuffle') {
        socket.emit('playSelection', data?.type)
    }
    else if (data?.type === 'direct') {
        socket.emit('playSelection', data?.type)
    }
    else {
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.playCard
            }
        })
        dispatch({
            type: ApiConstants.PLAY_CARD_TYPE,
            payload: data?.type
        })
    }

    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
}

export const useOutcome = (data) => {
    //show dialog
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.deflectOutcomeForYourself
        }
    })
    dispatch({
        type: ApiConstants.OUT_COME_TYPE,
        payload: data?.type
    })
}

export const useOutcomeForOther = (data) => {
    //show dialog
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.deflectOutcomeForAnotherPlayer
        }
    })
    dispatch({
        type: ApiConstants.OUT_COME_TYPE,
        payload: data?.type
    })
    smallTimer(dispatch)
    ShowAlertMessage('You have 60 seconds to make a decision.', popupType.info)
}

export const outcomeSuccess = (data) => {
    if (data.roomDetail) {
        updateRoomDetails(data)
    }
}

export const pullEventCard = (data) => {
    socket.emit('takeEventCard')
}

export const takeEventCardSuccess = (data) => {

}

export const timerReset = (data) => {
    timer(
        dispatch,
        data?.roomDetail?.roomId,
        data.roomDetail.turn.difference,
        data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId
    )
}

export const otherPlayerWin = (data) => {
    if (data?.message) {
        ShowAlertMessage(data.message, popupType.info)
    }
    tileAwardAnimation(data)
    updateRoomDetails(data)
}

export const newTierSuccess = (data) => {
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    tileAwardAnimation(data)
    updateRoomDetails(data)
    setTimeout(() => {
        socket.emit('changeTurn')
    }, 3000)
}

export const shuffleCard = (data) => {
    dispatch({
        type: ApiConstants.SHUFFLE_CARDS,
        payload: data?.shuffleCards
    })
    dispatch({
        type: ApiConstants.SHUFFLE,
        payload: true,
    })
}

export const taxSpaceSuccess = (data) => {
    if (data?.message) {
        ShowAlertMessage(data.message, popupType.info)
    }
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    tileAwardAnimation(data)
    resetDispatchCalled()
    updateRoomDetails(data)
    setTimeout(() => {
        socket.emit('changeTurn')
    }, 3000)

}

export const justiceSpaceSuccess = (data) => {
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    tileAwardAnimation(data)
    resetDispatchCalled()
    updateRoomDetails(data)
    setTimeout(() => {
        socket.emit('changeTurn')
    }, 3000)
}

export const playEventCardSuccess = (data) => {
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close
        }
    })
    tileAwardAnimation(data)
    resetDispatchCalled()
    updateRoomDetails(data)
    stopTimer(true)
    setTimeout(() => {
        socket.emit('changeTurn')
    }, 3000)
}

export const displayInfo = (data) => {
    ShowAlertMessage(data.message, popupType.info)
}

export const decisionProcess = (data) => {
    ShowAlertMessage(data.message, popupType.info)
}

export const socketError = (data) => {
    ShowAlertMessage(data.message, popupType.error)
}

export const reportSuccess = (data) => {
    updateRoomDetails(data);
}

export const blockUserSuccess = (data) => {
    ShowAlertMessage(data.message, popupType.info)
}

export const blockUserListSuccess = (data) => {
    dispatch({
        type: ApiConstants.UPDATE_LODING_STATE,
        data: false,
    })
    dispatch(getBlockedUsersSocketAction(data))
}
export const logs = (data) => {
    dispatch({
        type: ApiConstants.LOGS_DATA,
        payload: data?.history,
    })
}

export const displayContinue = (data) => {
    dispatch({
        type: ApiConstants.DISPLAY_CONTINUE_BUTTON,
        payload: true
    })
}

export const activeScreen = (data) => {
    if (data?.screen) {
        Emitter.emit("openRadioBox", { screen: data?.screen });
    }
}

export const gameStatus = (data) => {
    dispatch({
        type: ApiConstants.PAUSE_GAME,
        data: data?.roomDetail?.isPause,
    });
    if (data?.roomDetail?.isPause) {
        stopSmallTimer(dispatch);
        stopTimer();
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.close,
            }
        })
        dispatch({
            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
            payload: false,
            voteTileName: ''
        })
    } else {
        for (let i of data.roomDetail?.players) {
            if (i?.user?._id === store.getState().authenticationReducer.userId) {
                dispatch({
                    type: ApiConstants.OUR_GAME_DATA_UPDATE,
                    payload: {
                        isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                        ourGameData: i,
                        isPart1Completed: i?.isPart1Completed
                    }
                })
            }
        }
        timer(dispatch, data?.roomDetail?.roomId, data.roomDetail.turn.difference, data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId)
    }
    updateRoomDetails(data);
}

const checkQualifiedStatus = (data) => {
    if (data?.isQualified) {
        checkPowerOppartunity(data);
    } else {
        if (store.getState().homeReducer?.ourGameData?.tokens?.power > 0) {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.usePowerToken
                }
            })
        } else {
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames.notQualified
                }
            })
        }
    }
}

const checkPowerOppartunity = (data) => {
    if (data?.isTileReward) {
        if (data?.isRoll) {
            socket.emit('playOpportunity', store.getState().homeReducer?.ourCards[store.getState().homeReducer?.selectedCard - 1])
        } else {
            if (data?.isEnoughSpace) {
                socket.emit('playOpportunity', store.getState().homeReducer?.ourCards[store.getState().homeReducer?.selectedCard - 1])
            } else {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.notEnoughSpace
                    }
                })
            }
        }
    } else {
        if (data?.isPrivilegeReward) {
            socket.emit('playOpportunity', store.getState().homeReducer?.ourCards[store.getState().homeReducer?.selectedCard - 1])
        } else {
            if (data?.isInfluence) {
                dispatch({
                    type: ApiConstants.OPEN_DIALOG_BOX,
                    payload: {
                        openDialog: dialogNames.useInfluence
                    }
                })
            }
        }
    }
}

export const closeTimerPopUp = () => {
    stopSmallTimer(dispatch);
    dispatch({
        type: ApiConstants.OPEN_DICE_BOX,
        payload: {
            tokenType: '',
            openDiceBox: false
        }
    })
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close,
        }
    })
}

export const resetDispatchCalledForSmallTimer = (isStay = false) => {
    dispatch({
        type: ApiConstants.OPEN_DICE_BOX,
        payload: {
            tokenType: '',
            openDiceBox: false
        }
    })
    if (!isStay) {
        dispatch({
            type: ApiConstants.OPEN_DIALOG_BOX,
            payload: {
                openDialog: dialogNames.close,
            }
        })
    }

    dispatch({
        type: ApiConstants.INFLUENCE_ACTION,
        payload: null
    })
    dispatch({
        type: ApiConstants.SMALL_TIMER,
        payload: 0
    })
}

const resetDispatchCalled = () => {
    dispatch({
        type: ApiConstants.SELECT_CARD_UPDATE,
        payload: {
            selectCard: false,
            selectCardSubmissionType: null,
        }
    })
    dispatch({
        type: ApiConstants.ROLL_DICE_UPDATE,
        payload: { rollDice: false, rollDiceType: null }
    })
    dispatch({
        type: ApiConstants.TRADE_TILE_UPDATE,
        payload: false
    })
    dispatch({
        type: ApiConstants.SHOW_MESSAGE_COMPONENT_UPDATE,
        payload: false
    })
    dispatch({
        type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
        payload: false,
        voteTileName: ''
    })
    dispatch({
        type: ApiConstants.GET_RANGE,
        payload: []
    })
    dispatch({
        type: ApiConstants.SELECTED_CARD,
        payload: {
            selectedCard: 0,
        }
    })
    dispatch({
        type: ApiConstants.OPEN_DICE_BOX,
        payload: {
            tokenType: '',
            openDiceBox: false
        }
    })
    dispatch({
        type: ApiConstants.OPEN_DIALOG_BOX,
        payload: {
            openDialog: dialogNames.close,
        }
    })
    dispatch({
        type: ApiConstants.INFLUENCE_ACTION,
        payload: null
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD,
        payload: {
            showMoveCard: null,
        }
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_CHECK,
        payload: false
    })
    dispatch({
        type: ApiConstants.SHOW_MOVE_CARD_OTHER,
        payload: {
            showMoveCardOther: null,
            otherPlayRoleType: null,
        }
    })
    dispatch({
        type: ApiConstants.DISPLAY_DICE,
        payload: null
    })
    dispatch({
        type: ApiConstants.SMALL_TIMER,
        payload: 0
    })

    dispatch({
        type: ApiConstants.DISPLAY_ROLL_DICE_POPUP_TYPE,
        payload: null
    })
    dispatch({
        type: ApiConstants.RE_ROLL_TYPE,
        payload: null
    })
    dispatch({
        type: ApiConstants.PLAY_CARD_TYPE,
        payload: null
    })
    dispatch({
        type: ApiConstants.OUT_COME_TYPE,
        payload: null
    })
    dispatch({
        type: ApiConstants.SHUFFLE_CARDS,
        payload: []
    })
    dispatch({
        type: ApiConstants.SHUFFLE,
        payload: false
    })
    dispatch({
        type: ApiConstants.TRADE_TILE_AGAIN,
        payload: false
    })
    dispatch({
        type: ApiConstants.DISPLAY_CONTINUE_BUTTON,
        payload: false
    })
    stopSmallTimer(dispatch)
}

const tileAwardAnimation = (data) => {
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            let count = 0;
            if (store.getState().homeReducer?.ourGameData?.tokens?.privilege < i?.tokens?.privilege) {
                count++;
                setAwardTileCount(count)
                dispatch({
                    type: ApiConstants.AWARD_TILE_UPDATE,
                    payload: tileNames.privilege
                })
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.business < i?.tiles?.business) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.business
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.business
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.degree < i?.tiles?.degree) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.degree
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.degree
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.home < i?.tiles?.home) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.home
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.home
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.job < i?.tiles?.job) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.job
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.job
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.realEstate < i?.tiles?.realEstate) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.realEstate
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.realEstate
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.skill < i?.tiles?.skill) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.skill
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.skill
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tiles?.startUp < i?.tiles?.startUp) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.startUp
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.startUp
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tokens?.power < i?.tokens?.power) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.power
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.power
                    })
                }
            }
            if (store.getState().homeReducer?.ourGameData?.tokens?.justice < i?.tokens?.justice) {
                count++;
                setAwardTileCount(count)
                if (count > 1) {
                    setTimeout(() => {
                        dispatch({
                            type: ApiConstants.AWARD_TILE_UPDATE,
                            payload: tileNames.justice
                        })
                    }, 1400);
                } else {
                    dispatch({
                        type: ApiConstants.AWARD_TILE_UPDATE,
                        payload: tileNames.justice
                    })
                }
            }
        }
    }
}

const updateRoomDetails = (data) => {
    for (let i of data.roomDetail?.players) {
        if (i?.user?._id === store.getState().authenticationReducer.userId) {
            dispatch({
                type: ApiConstants.OUR_GAME_DATA_UPDATE,
                payload: {
                    isOurTurn: data.roomDetail?.turn?.player?._id === store.getState().authenticationReducer.userId,
                    ourGameData: i,
                    isPart1Completed: i?.isPart1Completed
                }
            })
            if (JSON.stringify(store.getState().homeReducer?.ourCards) != JSON.stringify(i.availableCards)) {
                dispatch({
                    type: ApiConstants.OUR_CARD_UPDATE,
                    payload: {
                        ourCards: i.availableCards
                    }
                })
            }
        }
    }
    dispatch(roomSuccessAction(data.roomDetail))
}