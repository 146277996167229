import React from "react";


const InputField = (props) => {
    const {
        id,
        error,
        helperText,
        placeholder,
        name,
        onBlur,
        onChange,
        type,
        value,
        className,
        maxLength,
        autoComplete,
        disabled,
        multiline,
        my,
        textArea,
        onKeyDown,
        width,
        formcontrol,
        height,
        style
    } = props;
    return (
        <input
            id={id}
            placeholder={placeholder}
            error={error}
            noValidate
            helperText={helperText}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            type={type}
            value={value}
            fullWidth
            onPaste={props.onPaste}
            onCopy={props.onCopy}
            onContextMenu={props.onContextMenu}
            // classes={{root:classes.my}}
            className={className}
            multiline={(textArea || multiline) && true}
            rows={textArea && 3}
            autoComplete={autoComplete ? autoComplete : ""}
            disabled={disabled}
            maxLength={maxLength && maxLength}
            width={width}
            height={height}
            style={style}
        />
    );
};

export default InputField;


