import appImages from "../themes/appImages/appImages";
import { tileNames } from "../themes/constants/gameConstants";

export const CARDS = {
    1: {
        id: 1,
        cardNo: appImages.card_DT1,
        voteRule1: "Select a tile that has a disparity you want to eliminate.",
        title1: "CHANGE THE RULES",
        voteRule2: "Pool privilege tokens from all willing players for vote.",
        voteRule3: "Roll dice to determine threshold to beat.",
        voteRule4: 'If the vote passes, add the tile to the vote slot on the board.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    2: {
        id: 2,
        cardNo: appImages.card_DT2,
        title: 'AN OFFICER STROLLS BY',
        rollDice: appImages.diceBlack,
        usersIcon: appImages.usersIcon,
        sd: '2-3',
        snd: '2-6',
        qrCode: appImages.qrCode,
        bottomText: 'Innocent Black defendants are 3-12 times more likely to be wrongfully convicted of a crime than their innocent White counterparts.',
        sdDice: ['T', 'F', 'F', 'T', 'T', 'T'],
        sndDice: ['T', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.justice
    },
    3: {
        id: 3,
        cardNo: appImages.card_DT3,
        usersIcon: appImages.usersIcon,
        grayText: "Players on Tier 1 get 3 tokens \nPlayers on Tier 2 pay 1 token\nPlayers on Tier 3 pay 3 tokens\nPlayers on Tier 4 pay 1 turn skip. \nTurn in a Power token to get a tax break, and you don't have to pay taxes.",
        eventHeading1: "Tax season is here!",
        grayText1: "If you can't afford taxes, move back 3 spaces on the board",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    4: {
        id: 4,
        cardNo: appImages.card_DT4,
        leftImage: appImages.degreeCap,
        usersIcon: appImages.user,
        arrowIcon: appImages.arrow1,
        multiplier: 'x3',
        eventHeading1: 'Apply for an fellowship',
        eventHeading: 'Roll to see if you get in and gain Privilege',
        grayText: 'You and a player of your choice will each roll the die until the highest roll wins.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    5: {
        id: 5,
        cardNo: appImages.card_DT5,
        leftImage: appImages.store1,
        usersIcon: appImages.user,
        arrowIcon: appImages.arrow1,
        multiplier: 'x3',
        eventHeading1: 'Apply for an Accelerator Program',
        eventHeading: 'Roll to see if you get in and gain Privilege',
        grayText: 'You and a player of your choice will each roll the die until the highest roll wins.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    6: {
        id: 6,
        cardNo: appImages.card_DT6,
        leftImage: appImages.homedollar,
        usersIcon: appImages.usersIcon,
        arrowIcon: appImages.arrow1,
        multiplier: 'x3',
        userBottomText: 'T2+',
        eventHeading1: 'Amazing!',
        eventHeading: 'The housing market soars.',
        grayText: 'If you have a Home, get 3 Privilege tokens.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    7: {
        id: 7,
        cardNo: appImages.card_DT7,
        leftImage: appImages.moneyBf,
        usersIcon: appImages.usersIcon,
        arrowIcon: appImages.arrow1,
        multiplier: 'x4',
        userBottomText: 'T4',
        eventHeading1: 'Wow!',
        eventHeading: 'Your business hit a huge mid-year boom.',
        grayText: 'If you have a Business, get 4 Privilege tokens.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    8: {
        id: 8,
        cardNo: appImages.card_DT8,
        usersIcon: appImages.user,
        arrowIcon: appImages.arrow1,
        rollDice: appImages.diceBlack,
        qrCode: appImages.qrCode,
        multiplier: 'x5',
        eventHeading1: 'Roll for a chance to recieve an inheitance.',
        sd: '2-5',
        snd: '2',
        bottomText: 'White families are 2-4 times more likely to receive an inheritance compared to their Black & Latinx counterparts.',
        sdDice: ['T', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'F', 'T', 'T', 'T', 'T'],
        reward: tileNames.privilege
    },
    9: {
        id: 9,
        cardNo: appImages.card_DT9,
        usersIcon: appImages.usersIcon,
        arrowIcon: appImages.arrow1,
        userBottomText: 'T1',
        multiplier: 'x5',
        eventHeading1: 'Stimulus Bill',
        eventHeading: 'Anyone on Tier 1 can recieve 5 Privilege tokens.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    10: {
        id: 10,
        cardNo: appImages.card_DT10,
        usersIcon: appImages.usersIcon,
        rightImage: appImages.medalBlack,
        userBottomText: 'T1',
        eventHeading1: 'Internship!',
        eventHeading: 'Anyone on Tier 1 can roll to earn a skill.',
        grayText: 'Highest roll wins the Skill tile.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.skill
    },
    11: {
        id: 11,
        cardNo: appImages.card_DT11,
        usersIcon: appImages.user,
        rightImage: appImages.mortarboard,
        rollDice: appImages.diceBlack,
        qrCode: appImages.qrCode,
        grayText: 'Between you and the eligible player to your left, from another identity.',
        eventHeading1: 'Scholarship Pageant',
        eventHeading: 'Roll once to see who wins.',
        sd: '1-5',
        snd: '6',
        bottomText: 'As of 2022, only 13% of crowned Miss America winners have been women of color.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'T', 'T', 'T', 'T', 'F'],
        reward: tileNames.degree
    },
    12: {
        id: 12,
        cardNo: appImages.card_DT12,
        leftImage: appImages.suitcase,
        rollDice: appImages.diceBlack,
        usersIcon: appImages.user,
        rightImage: appImages.handshake,
        qrCode: appImages.qrCode,
        userBottomText: 'T2',
        grayText: 'Roll once between you and the eligible player to your left,from another identity. If none, you win.',
        eventHeading1: 'A promotion opportunity opened up at work!',
        sd: '3-6',
        snd: '1-2',
        bottomText: 'White professionals are about twice as likely to be promoted into management as their Asian American counterparts.',
        sdDice: ['T', 'T', 'F', 'F', 'F', 'F'],
        noDisparity: ['T', 'T', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'T', 'T', 'T', 'T'],
        reward: tileNames.power
    },
    13: {
        id: 13,
        cardNo: appImages.card_DT13,
        usersIcon: appImages.user,
        rightImage: appImages.handshake,
        rollDice: appImages.diceBlack,
        qrCode: appImages.qrCode,
        grayCard: true,
        grayText: 'Roll once between you and the eligible player to your left,from another identity. If none, you win.',
        eventHeading1: 'A position in the local goverment has opened for election.',
        bottomText: 'There were nearly 10x more White city councils members than Black and Asian city council members across the state of California during 2005-2011.',
        sd: '1-5',
        snd: '6',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'T', 'T', 'T', 'T', 'F'],
        reward: tileNames.power,
    },
    14: {
        id: 14,
        cardNo: appImages.card_DT14,
        usersIcon: appImages.user,
        rightImage: appImages.handshake,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        grayText: 'Roll once between you and the eligible player to your left,from another identity. If none, you win.',
        eventHeading1: 'A seat on the Board of Directors for a big company has opened up.',
        sd: '2-6',
        snd: '1',
        bottomText: 'In 2019, only 15% of the new directors of publicly traded companies were from an ethnic minority group.',
        sdDice: ['T', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'T', 'T', 'T', 'T', 'T'],
        reward: tileNames.power
    },
    15: {
        id: 15,
        cardNo: appImages.card_DT15,
        leftImage: appImages.suitcase,
        usersIcon: appImages.usersIcon,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        userBottomText: 'T2+',
        grayText: 'If not, skip  1  turn',
        eventHeading1: 'Company Downsizing!',
        eventHeading: 'Roll to see if you keep your job.',
        sd: '2-5',
        snd: '3-4',
        bottomText: 'In 2020, Latinx adults were nearly twice as likely to be furloughed or laid off as their White counterparts.',
        sdDice: ['T', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'T', 'F', 'F', 'T', 'T'],
        reward: null
    },
    16: {
        id: 16,
        cardNo: appImages.card_DT16,
        leftImage: appImages.homedollar,
        usersIcon: appImages.usersIcon,
        grayText: 'Anyone with a Home tile must skip 1 turn.',
        eventHeading1: 'It looks like your water heater broke in your house.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    17: {
        id: 17,
        cardNo: appImages.card_DT17,
        leftImage: appImages.moneyBf,
        usersIcon: appImages.user,
        grayText: 'Skip  1  turn.',
        eventHeading1: 'Darn!',
        eventHeading: 'Your business is being audited by the IRS.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    18: {
        id: 18,
        cardNo: appImages.card_DT18,
        usersIcon: appImages.user,
        grayText: 'Pull three Opportunity cards into your hands, discard the three you no longer want, and play another turn.',
        eventHeading1: 'OMG!',
        eventHeading: 'You won the lottery!',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    19: {
        id: 19,
        cardNo: appImages.card_DT19,
        rightImage: appImages.suitcase,
        rollDice: appImages.diceBlack,
        qrCode: appImages.qrCode,
        usersIcon: appImages.user,
        eventHeading1: 'Get a job!',
        eventHeading: 'Roll to see if you get hired for a new job.',
        sd: '2-6',
        snd: '5-6',
        bottomText: 'White applications receive, on average, 36% more callbacks than Black applicants and 24% more callbacks than Latinx applicants with identical resumes.',
        sdDice: ['T', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['T', 'T', 'T', 'T', 'F', 'F'],
        reward: tileNames.job
    },
    20: {
        id: 20,
        cardNo: appImages.card_DT20,
        usersIcon: appImages.user,
        rightImage: appImages.homedollarBlack,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        eventHeading1: 'Roll to get a home loan to buy a house.',
        sd: '2-5',
        snd: '2-3',
        bottomText: 'Black borrowers are more than twice as likely to be denied a mortgage loan as their White counterparts.',
        sdDice: ['T', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['T', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'F', 'F', 'T', 'T', 'T'],
        reward: tileNames.home
    },
    21: {
        id: 21,
        cardNo: appImages.card_DT21,
        usersIcon: appImages.user,
        rightImage: appImages.briefcase,
        rollDice: appImages.diceBlack,
        qrCode: appImages.qrCode,
        eventHeading1: 'Roll to get a business loan to buy an existing business.',
        sd: '1-4',
        snd: '1-2',
        bottomText: 'Black-owned businesses are half as likely to have obtained bank funds compared to White-owned businesses.',
        sdDice: ['F', 'F', 'F', 'F', 'T', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'T', 'T'],
        sndDice: ['F', 'F', 'T', 'T', 'T', 'T'],
        reward: tileNames.business
    },
    22: {
        id: 22,
        cardNo: appImages.card_DT22,
        rightImage: appImages.multiHome,
        qrCode: appImages.qrCode,
        usersIcon: appImages.user,
        rollDice: appImages.diceBlack,
        eventHeading1: 'Roll to get a loan to buy property.',
        sd: '1-4',
        snd: '2-3',
        bottomText: 'Black borrowers are more than twice as likely to be denied a mortgage loan as their White counterparts.',
        sdDice: ['F', 'F', 'F', 'F', 'T', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'T', 'T'],
        sndDice: ['T', 'F', 'F', 'T', 'T', 'T'],
        reward: tileNames.realEstate
    },
    23: {
        id: 23,
        cardNo: appImages.card_DT23,
        rightImage: appImages.medalBlack,
        usersIcon: appImages.user,
        grayText: 'To play, move back 2  spaces.',
        eventHeading1: 'Learn a skill!',
        eventHeading: 'Take a free class at the local community center.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.skill
    },
    24: {
        id: 24,
        cardNo: appImages.card_DT24,
        rightImage: appImages.medalBlack,
        usersIcon: appImages.user,
        grayText: 'To play, move back 2 spaces.',
        eventHeading1: 'Help out!',
        eventHeading: 'Ask to help out a neighbor with their house project.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.skill
    },
    25: {
        id: 25,
        cardNo: appImages.card_DT25,
        rightImage: appImages.medalBlack,
        usersIcon: appImages.user,
        grayText: 'To play, move back 2 spaces.',
        eventHeading1: 'Volunteer!',
        eventHeading: 'You have an opportunity to earn a skill.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.skill
    },
    26: {
        id: 26,
        cardNo: appImages.card_DT26,
        rightImage: appImages.mortarboard,
        usersIcon: appImages.user,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        eventHeading1: 'Roll for a chance to get a degree.',
        sd: '1-4',
        snd: '1-2',
        bottomText: "Among the American Indian/Alaska Native population, 14.5% have earned a bachelor's degree or higher compared with 31.3% of the overall population.",
        sdDice: ['F', 'F', 'F', 'F', 'T', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'T', 'T'],
        sndDice: ['F', 'F', 'T', 'T', 'T', 'T'],
        reward: tileNames.degree
    },
    27: {
        id: 27,
        cardNo: appImages.card_DT27,
        rightImage: appImages.mortarboard,
        usersIcon: appImages.user,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        eventHeading1: 'Scholarship!',
        eventHeading: 'Roll to get a scholarship and earn a degree.',
        sd: '3-6',
        snd: '5-6',
        bottomText: "White students are twice as likely to receive merit-based aid from states, compared to their Black counterparts.",
        sdDice: ['T', 'T', 'F', 'F', 'F', 'F'],
        noDisparity: ['T', 'T', 'F', 'F', 'F', 'F'],
        sndDice: ['T', 'T', 'T', 'T', 'F', 'F'],
        reward: tileNames.degree
    },
    28: {
        id: 28,
        cardNo: appImages.card_DT28,
        rightImage: appImages.store,
        usersIcon: appImages.user,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        eventHeading1: "It's time to build your own startup!",
        sd: '1-5',
        snd: '4-5',
        bottomText: "Latinx-owned firms are 10.6% of all U.S. businesses, Black-owned are 7%, and Asian-owned are 4.3%; all underrepresented compared to their U.S. population.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'T'],
        sndDice: ['T', 'T', 'T', 'F', 'F', 'T'],
        reward: tileNames.startUp
    },
    29: {
        id: 29,
        cardNo: appImages.card_DT29,
        rightImage: appImages.store,
        usersIcon: appImages.user,
        qrCode: appImages.qrCode,
        rollDice: appImages.diceBlack,
        eventHeading1: "Roll to get an investor to help you launch your Startup.",
        sd: '1-4',
        snd: '4',
        bottomText: "In 2018, Black and Latinx founders made up 1.7% and 1.3% of VC-Funded startups, respectively.",
        sdDice: ['F', 'F', 'F', 'F', 'T', 'T'],
        noDisparity: ['F', 'F', 'F', 'F', 'T', 'T'],
        sndDice: ['T', 'T', 'T', 'F', 'T', 'T'],
        reward: tileNames.startUp
    },
    30: {
        id: 30,
        cardNo: appImages.card_DT30,
        firstImage: appImages.medal1,
        secondImage: appImages.mortarboard,
        rightImage: appImages.mortarboard,
        usersIcon: appImages.user,
        grayText: 'Move back to 3 spaces to accept.',
        eventHeading1: 'You were offered a grant to attend graduate school.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.degree
    },
    31: {
        id: 31,
        cardNo: appImages.card_DT31,
        firstImage: appImages.mortarboard,
        secondImage: appImages.store,
        rightImage: appImages.store,
        usersIcon: appImages.user,
        grayText: 'Move back to 4 spaces to accept.',
        eventHeading1: 'A friend college wants to create a new startup with you.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.startUp
    },
    32: {
        id: 32,
        cardNo: appImages.card_DT32,
        multiplier: 'x1',
        leftImage: appImages.medal1,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Skill, get 1 Privilege token.',
        eventHeading1: 'Cool!',
        eventHeading: 'Your skill helped you get a mentor.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    33: {
        id: 33,
        cardNo: appImages.card_DT33,
        multiplier: 'x3',
        firstImage: appImages.medal1,
        secondImage: appImages.medal1,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have two Skills, get 3 Privilege tokens.',
        eventHeading1: 'Your skills are getting noticed!',
        eventHeading: 'Recruiters are starting to reach out.',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    34: {
        id: 34,
        cardNo: appImages.card_DT34,
        multiplier: 'x2',
        leftImage: appImages.mortarboard,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Degree, get 2 Privilege tokens.',
        eventHeading1: 'Magna cum laude!',
        eventHeading: 'You graduated top of your class!',
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    35: {
        id: 35,
        cardNo: appImages.card_DT35,
        multiplier: 'x3',
        firstImage: appImages.medal1,
        secondImage: appImages.mortarboard,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Skill and a Degree, get 3 Privilege tokens.',
        eventHeading1: 'Building a following!',
        eventHeading: "People are liking the content you've been posting on social media.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    36: {
        id: 36,
        cardNo: appImages.card_DT36,
        multiplier: 'x4',
        firstImage: appImages.mortarboard,
        secondImage: appImages.mortarboard,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have two Degrees, get 4 Privilege tokens.',
        eventHeading1: 'Making waves!',
        eventHeading: "Your graduate research has been published.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    37: {
        id: 37,
        cardNo: appImages.card_DT37,
        multiplier: 'x3',
        leftImage: appImages.store1,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Startup, get 3 Privilege tokens.',
        eventHeading1: "You're Getting Recognition!",
        eventHeading: "Newspaper wants to write about your startup.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    38: {
        id: 38,
        cardNo: appImages.card_DT38,
        multiplier: 'x3',
        firstImage: appImages.medal1,
        secondImage: appImages.store,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Skill & a Startup, get 3 Privilege tokens.',
        eventHeading1: "Insta-Famous!",
        eventHeading: "A celebrity mentioned your product in a post.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    39: {
        id: 39,
        cardNo: appImages.card_DT39,
        multiplier: 'x4',
        firstImage: appImages.mortarboard,
        secondImage: appImages.store,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: 'If you have a Degree and a Startup, get 4 Privilege tokens.',
        eventHeading1: "Spotlight!",
        eventHeading: "A national magazine wants to feature your story as an Entrepreneur.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    40: {
        id: 40,
        cardNo: appImages.card_DT40,
        // type: 'oppurtunity',
        multiplier: 'x5',
        firstImage: appImages.store,
        secondImage: appImages.store,
        arrowIcon: appImages.arrow1,
        usersIcon: appImages.user,
        // rollDice: false,
        // centerData: false,
        // grayCard: true,
        grayText: 'If you have a two Startups, get 5 Privilege tokens.',
        eventHeading1: "Oh you fancy huh!",
        eventHeading: "You were invited to do a TV interview about entreprenuership.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: tileNames.privilege
    },
    41: {
        id: 41,
        cardNo: appImages.card_DT41,
        multiplierLeft: 'x3',
        leftImage: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: "Pay 3 Privilege tokens to add or take a number from your's or another's roll.",
        eventHeading1: "Influence!",
        eventHeading: "Your neighbor has become an elected official.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    42: {
        id: 42,
        cardNo: appImages.card_DT42,
        tintColor: true,
        multiplierLeft: 'x3',
        leftImage: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: "Pay 3 Privilege tokens to add or take a number from your's or another's roll.",
        eventHeading1: "Influence!",
        eventHeading: "Your cousin is an executive at a major corporation.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    43: {
        id: 43,
        cardNo: appImages.card_DT43,
        multiplierLeft: 'x3',
        leftImage: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: "Pay 3 Privilege tokens to add or take a number from your's or another's roll.",
        eventHeading1: "Influence!",
        eventHeading: "Your uncle is a local magistrate.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
    44: {
        id: 44,
        cardNo: appImages.card_DT44,
        multiplierLeft: 'x3',
        leftImage: appImages.arrow1,
        usersIcon: appImages.user,
        grayText: "Pay 3 Privilege tokens to add or take a number from your's or another's roll.",
        eventHeading1: "Influence!",
        eventHeading: "Your aunt is well-connected in the community.",
        sdDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        sndDice: ['F', 'F', 'F', 'F', 'F', 'F'],
        noDisparity: ['F', 'F', 'F', 'F', 'F', 'F'],
        reward: null
    },
}