import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveStatus } from '../../../helper/globalFunctions';
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { socket } from "../../../helper/socket";
import ApiConstants from "../../../themes/apiConstants";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import Spacer from "../Spacer";
import { trimText1 } from '../../../utils/validators/validator';
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { backFromSelectCardType, popupType, tileNames } from "../../../themes/constants/gameConstants";
import InputField from "../../../common/textFiled";
import { isInternetConnected } from "../../../helper/networkUtils";
import styles from './styles';
import { buttonClickSound } from "../../../helper/playSound";
import { stopSmallTimer } from '../../../helper/timer';



const EnterTokenComponent = (props) => {
    const dispatch = useDispatch()
    const [value, onChangeText] = React.useState('');
    const isEnterTokenVisible = useSelector((state) => state.homeReducer.enterTokenForVote);
    const voteTileName = useSelector((state) => state.homeReducer.voteTileName);
    const ourGameData = useSelector((state) => state.homeReducer.ourGameData);
    const isOurTurn = useSelector((state) => state.homeReducer.isOurTurn);
    const roomData = useSelector((state) => state.homeReducer.roomData);

    React.useEffect(() => {
        if (isEnterTokenVisible) {
            onChangeText('')
        }
    }, [isEnterTokenVisible])

    const onClosePress = () => {
        socket.emit('tokenForVote', { tokens: 0, status: false })
        stopSmallTimer(dispatch);
        dispatch({
            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
            payload: false,
            voteTileName: ''
        })
        onChangeText('')
    }

    const onSubmitPress = () => {
        if (value) {
            if (parseInt(value) > ourGameData?.tokens?.privilege) {
                ShowAlertMessage(`You cannot enter privilege token more then ${ourGameData?.tokens?.privilege}.`, popupType.error)
            }
            else
                if (isOurTurn && parseInt(value) < 2) {
                    ShowAlertMessage(`You cannot enter privilege token less then 2.`, popupType.error)
                }
                else {
                    const checkAllowed = getLeaveStatus()
                    if (isInternetConnected() && checkAllowed) {

                        if (isOurTurn) {
                            socket.emit('vote', { tile: voteTileName, tokens: value })
                        }
                        else {
                            stopSmallTimer(dispatch);
                            socket.emit('tokenForVote', { tokens: value, status: true })
                        }
                        dispatch({
                            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
                            payload: false,
                            voteTileName: ''
                        })
                        onChangeText('')
                        if (isOurTurn) {
                            ShowAlertMessage('Please wait voting is going on.', popupType.info)
                        }
                    } else {
                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                    }
                }
        }
        else {
            ShowAlertMessage('Please enter token for vote.', popupType.error)
        }
    }

    const onBackPress = () => {
        dispatch({
            type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
            payload: false,
            voteTileName: ''
        })
        dispatch({
            type: ApiConstants.BACK_FROM_SELECT_CARD_UPDATE,
            payload: backFromSelectCardType.earn
        })
        onChangeText('')
    }

    const getVoteTileName = (name) => {
        return name === tileNames.degree ? 'Degree tile.' : name === tileNames.startUp ? 'Startup tile.' : name === tileNames.job ? 'Job tile.' : name === tileNames.home ? 'Home tile.' : name === tileNames.business ? 'Business tile.' : name === tileNames.realEstate ? 'Real Estate tile.' : 'Justice token.'
    }

    return (
        <>
            {isEnterTokenVisible && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <Spacer height={spacing.l} />
                    <span style={styles.selectTile}>{strings.enterTokens} for {getVoteTileName(voteTileName)}</span>
                    <Spacer height={spacing.l} />
                    <InputField
                        type='text'
                        placeholder={strings.enterToken}
                        className='formcontrol'
                        value={trimText1(value)}
                        onChange={(e) => {
                            let txt1 = trimText1(e.target.value);
                            onChangeText(txt1)
                        }}
                        maxLength={5}
                        style={{ width: 168, height: 45, textAlign: 'center' }}
                    />
                    <Spacer height={spacing.l} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                        <ButtonCustom
                            textColor={{ fontSize: fontSizes.f40 }}
                            imgBg={appImages.buttonBg}
                            title={isOurTurn ? strings.back : strings.close}
                            onPress={() => {
                                buttonClickSound();
                                if (!roomData?.isPause) {
                                    if (isOurTurn) {
                                        onBackPress();
                                    } else {
                                        onClosePress();
                                    }
                                } else {
                                    ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                                }
                            }}
                        />
                        <ButtonCustom
                            textColor={{ fontSize: fontSizes.f40 }}
                            imgBg={appImages.buttonBg}
                            title={strings.submit}
                            onPress={() => {
                                buttonClickSound();
                                if (!roomData?.isPause) {
                                    onSubmitPress();
                                } else {
                                    ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                                }
                            }}
                        />
                    </div>
                    <Spacer height={spacing.l} />
                </div>
            </div>}
        </>

    );
};

export default EnterTokenComponent;

