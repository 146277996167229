import { gameScreenDimentions } from '../../../helper/globalFunctions';
import { modal, textColor, textInput } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import bg from '../../../assets/images/popup_1.png';
const Width = gameScreenDimentions.width;

const styles = {
    outerContainer: {
        elevation: 5,
        height: gameScreenDimentions.height,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: Width,
        zIndex: 2,
        position: 'absolute',
        display: 'flex',
    },
    modalView: {
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: Width * 0.60,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 3,
        position: 'relative'
    },
    fontLabel: {
        includeFontPadding: false,
        fontWeight: 800,
        color: textColor.green,
        fontSize: fontSizes.f55,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
    fontText: {
        includeFontPadding: false,
        fontWeight: 600,
        color: textColor.white,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
    fontTextHeading: {
        includeFontPadding: false,
        fontWeight: 800,
        color: textColor.green,
        fontSize: fontSizes.f35,
        textAlignVertical: 'center',
        textAlign: 'left',
    },

}

export default styles;