import react, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Header from '../../../common/header';
import InputField from '../../../common/textFiled';
import { commonImages } from '../../../themes/appImage';
import appImages from '../../../themes/appImages/appImages';
import { goBack } from '../../../Routes/navigationServices'
import { url } from '../../../redux/axios/apikit'
import { ShowAlertMessage } from '../../../helper/showAlertMessage';
import { popupType } from '../../../themes/constants/gameConstants';
import { trimText, updateProfileCheck } from '../../../utils/validators/validator';
import { updateProfileAction } from '../../../redux/actions/homeActions/homeActions'
import { isInternetConnected } from '../../../helper/networkUtils';
import { ValidationConstants } from '../../../themes/constants/validationConstants';

const ProfileView = (props) => {

  const { pathname } = useLocation();
  const dispatch = useDispatch()

  const userData = useSelector((state) => state.authenticationReducer.userData);

  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [profileImg, setProfileImg] = useState(null)
  const [file, setFile] = useState(null);
  const [imageUpload, setImageUpload] = useState("");
  const [imageError, setImageError] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    setUserName(userData?.name)
    setEmail(userData?.email)
    setProfileImg(userData?.profileImage)
  }, [userData])
  // console.log(props, 'props--------------')

  const imageChange = (e) => {
    if (e.target.files) {
      console.log('CheckinFile', e.target.files)
      let file = e.target.files[0]
      if (!file.name.match(/\.(jpg|png|jpeg|gif)$/)) {
        ShowAlertMessage('Only .jpeg, .jpg, .png format images are allowed.', popupType.error, 3000)
        // setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        return false;
      }
      const extentType = ['jpeg', 'jpg', 'png'];
      const extent = file?.name?.split('.');
      const extentT = extent && extent[extent.length - 1];
      console.log('imageChange', extentType, extentType.indexOf(extentT) >= 0, extentType.indexOf(extentT))
      if (extentType.indexOf(extentT) >= 0) {
        if (file.size > 20971520) {
          // setImageError('File size must be equal or less than 20 MB.')
          ShowAlertMessage('File size must be equal or less than 20 MB.', popupType.error, 3000)
        } else {
          setFile(URL.createObjectURL(file));
          setFileUrl(file)
          setImageUpload("")
          setImageError(null)
        }
      }
      else {
        if (e.target.files.length == 0) {

        } else {
          setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        }
      }
    }
  }

  const _onUpdateClick = () => {
    const isValidationFailed = updateProfileCheck(fileUrl, userName, email, ValidationConstants);

    if (isValidationFailed) {
      ShowAlertMessage(isValidationFailed, popupType.error, 3000)
    }
    else {
      let body = new FormData()
      if (fileUrl) {
        body.append('profileImage', fileUrl);
      }
      body.append('name', userName.trim()?.replace(/ /g, ''));
      body.append('email', email.trim()?.replace(/ /g, ''));
      // body.append('deviceToken', '123456');

      if (isInternetConnected()) {
        dispatch(updateProfileAction(body));
      } else {
        ShowAlertMessage('Please check your internet connection.', popupType.error, 3000)
      }

    }
  }

  const imageFragment = (
    <div className='heading_text'>
      {pathname === '/profileview' ?
        (<img src={`${url}${profileImg}`} style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }} />)
        : (

          <div className="upload-image">
            <div className="upload-image">

              <div
                className="upload_img common-flex"
              >
                <input
                  type="file"
                  id="pic"
                  accept=".png,.jpg,.jpeg"
                  style={{ display: "none" }}
                  onChange={imageChange}
                />
                {file ? <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                  <label for="pic">
                    <img
                      alt=""
                      className="table-img"
                      src={file ? file : commonImages.registerLogo}
                      type="file"
                      // onChange={imageChange}
                      height="100"
                      width="100"
                      style={{
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: '1px solid rgb(51, 194, 173)'
                      }}
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      className="plus_icon"
                      src={commonImages.plus}
                      onClick={imageChange}
                    ></img>
                  </label>
                </div> : (
                  <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                    <label for="pic">
                      <img
                        className="table-img"
                        src={profileImg ? `${url}${profileImg}` : commonImages.registerLogo}
                        type="file"
                        onChange={imageChange}
                        height="100"
                        width="100"
                        style={{
                          // padding: file ? '0px' : '0px .5px 1px 1px',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          border: '1px solid rgb(51, 194, 173)'
                        }}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        className="plus_icon"
                        src={appImages.edit}
                        onClick={imageChange}
                      ></img>
                    </label>
                  </div>
                )}
              </div>
              <div
                className="image-upload"
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {imageUpload}
                {/* {fileUrl} */}
              </div>
            </div>


            <div
              className="image-upload"
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
            </div>
          </div>
        )}
    </div>
  )
  return (
    <div>
      <div className='background_image'>
        <Header title={pathname === '/profileview' ? 'My Profile' : 'Edit Profile'} settings={false} redirect={true} />
        <div className='inner_box view_only'>
          {imageFragment}
          <h3 className='image'>Profile Image</h3>
          <div className='form-group'>
            <label>Username</label>
            <InputField
              type='text'
              placeholder="Username"
              width='70%'
              height='35px'
              value={userName}
              onChange={(e) => {
                let value = e.target.value.trimLeft();
                setUserName(value)
              }}
              className='formcontrol'
              disabled={pathname === '/profileview'}
              style={{
                border: '1px solid rgb(51, 194, 173)',
                boxShadow: '0px 0px 4px rgb(51 194 173)'
              }}
            />
          </div>
          <div className='form-group'>
            <label>Email Address</label>
            <InputField
              type='text'
              placeholder="Email Address"
              width='70%'

              height='35px'
              value={email?.trim()?.replace(/ /g, '')}
              onChange={(e) => {
                let value = e.target.value.trimLeft();
                setEmail(value)
              }}
              className='formcontrol'
              disabled={true}
              style={{
                border: '1px solid rgb(51, 194, 173)',
                boxShadow: '0px 0px 4px rgb(51 194 173)'
              }}
            />
          </div>

          <div className='data_new text_t'>
            {pathname === '/profileview' ? <div>
              <Link to="/profile">
                <img src={commonImages.edit} alt="" />
              </Link>
            </div> : (
              <div onClick={() => _onUpdateClick()}>
                {/* <Link to="/profileview"> */}
                <img src={commonImages.update} alt="" />
                {/* </Link> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

}
export default ProfileView
