import { gameScreenDimentions } from '../../../helper/globalFunctions';
import { modal, textColor, textInput } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import bg from '../../../assets/images/popup_1.png';

const width = gameScreenDimentions.width;
const styles = {
    outerContainer: {
        height: gameScreenDimentions.height,
        alignItems: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        justifyContent: 'center',
        position: 'absolute',
        display: 'flex',
        zIndex: 2,
    },
    imageStyle: {
        paddingLeft: 24,
        paddingRight: 24,
        alignItems: "center",
        width: '90%',
        height: '90%',
        objectFit: 'contain',
        display: 'flex',
    },
    modalView: {
        padding: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: width * 0.5,
        height: width * 0.35,
        backgroundImage: `url(${bg})`,
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
    },
    selectTile: {
        color: textColor.green,
        textAlign: "center",
        fontWeight: '600',
        fontSize: fontSizes.f51,
        // marginLeft: 25,
        marginRight: 25,
        marginBottom: 16,
        // display: 'flex',
        width: '100%'
    },
}

export default styles;