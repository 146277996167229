import { modal, textInput } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';

const width = gameScreenDimentions.width;
const styles = {
    card1: {
        position: 'absolute',
        left: gameScreenDimentions.gameBoardWidth * 0.162,
        bottom: gameScreenDimentions.gameBoardWidth * 0.27,
        backgroundColor: 'white',
        elevation: 4,
        alignSelf: 'center',
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderColor: '#46c1af55',
        overflow: 'hidden',
        scale: 1.5
    },
    card2: {
        position: 'absolute',
        left: gameScreenDimentions.gameBoardWidth * 0.58,
        bottom: gameScreenDimentions.gameBoardWidth * 0.27,
        backgroundColor: 'white',
        elevation: 4,
        alignSelf: 'center',
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderColor: '#46c1af55',
        overflow: 'hidden',
        scale: 1.5
    },
    card3: {
        position: 'absolute',
        left: gameScreenDimentions.gameBoardWidth * 1,
        bottom: gameScreenDimentions.gameBoardWidth * 0.27,
        backgroundColor: 'white',
        elevation: 4,
        alignSelf: 'center',
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderColor: '#46c1af55',
        overflow: 'hidden',
        scale: 1.5
    },
    outerContainer: {
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 1006
    },
    modalView: {
        paddingHorizontal: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        justifyContent: 'center',
        width: width * 0.52,
        height: width * 0.38
    },

}

export default styles;