import moment from 'moment';
import ApiConstants from '../themes/apiConstants';
import { socket } from "./socket";
import { resetDispatchCalledForSmallTimer } from './socketFunctions';
import { store } from '../redux/store';
import { ShowAlertMessage } from './showAlertMessage';
import { popupType } from '../themes/constants/gameConstants';
import strings from '../themes/constants/strings';

var globleTimer = 300
let intervalId;
var isTimerRunning = false
var currentPausedTime = null
var currentPausedTimeCount = 0

var smallTimerCount = 10
var smallTimerIntervalId;

export const timer = (dispatch, roomId, difference, isOurTurn) => {
    clearInterval(intervalId)
    globleTimer = (isOurTurn ? 300 : 303) - difference
    intervalId = setInterval(() => {
        if (globleTimer === 0) {
            clearInterval(intervalId);
            isTimerRunning = false
            currentPausedTime = null
            currentPausedTimeCount = 0
            socket.emit('checkTurn', roomId)
            return
        }
        isTimerRunning = true
        globleTimer = globleTimer - 1
        if (isOurTurn) {
            if (globleTimer === 120) {
                ShowAlertMessage(strings.timesUp, popupType.error)
            }
        }
        dispatch({ type: ApiConstants.CURRENT_TIMER_UPDATE, payload: globleTimer })
    }, 920)
};

export const stopTimer = (time = false) => {
    isTimerRunning = false
    currentPausedTime = moment()
    currentPausedTimeCount = time ? 1 : globleTimer
    clearInterval(intervalId);
}

export const getIsTimerRunning = () => {
    return isTimerRunning
}

export const getCurrentPausedTime = () => {
    return currentPausedTime
}

export const getCurrentPausedTimeCount = () => {
    return currentPausedTimeCount
}

export const smallTimer = (dispatch, timerStartFrom = 60, dialogNames = null) => {
    clearInterval(smallTimerIntervalId)
    smallTimerCount = timerStartFrom
    smallTimerIntervalId = setInterval(() => {
        if (smallTimerCount === 0) {
            dispatch({ type: ApiConstants.SMALL_TIMER, payload: 0 })
            clearInterval(smallTimerIntervalId);
            resetDispatchCalledForSmallTimer()
            if (store.getState().homeReducer.showMoveCard) {
                dispatch({
                    type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                    payload: true
                })
            }
            return
        }
        if (smallTimerCount === 120 && timerStartFrom === 130) {
            dispatch({
                type: ApiConstants.SHOW_MOVE_CARD_CHECK,
                payload: false
            })
            dispatch({
                type: ApiConstants.OPEN_DIALOG_BOX,
                payload: {
                    openDialog: dialogNames
                }
            })
        }
        dispatch({ type: ApiConstants.SMALL_TIMER, payload: smallTimerCount })
        smallTimerCount = smallTimerCount - 1

    }, 1000)
};


export const stopSmallTimer = (dispatch, isStay = false) => {
    dispatch({ type: ApiConstants.SMALL_TIMER, payload: 0 })
    clearInterval(smallTimerIntervalId);
    resetDispatchCalledForSmallTimer(isStay)
}
