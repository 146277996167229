import React from "react";
import { useSelector } from "react-redux";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import Spacer from "../Spacer";
import styles from './styles';


const MessageComponent = (props) => {
    const showMessageComponent = useSelector((state) => state.homeReducer.showMessageComponent);
    return (
        <>
            {showMessageComponent && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <Spacer height={spacing.l} />
                    <span style={styles.selectTile}>{strings.startGameText}</span>
                    <Spacer height={spacing.l} />
                </div>
            </div>
            }
        </>

    );
};

export default MessageComponent;

