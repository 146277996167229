import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { url } from '../../redux/axios/apikit';
import { commonImages } from '../../themes/appImage';
import { socket } from '../../helper/socket';
import ApiConstants from '../../themes/apiConstants';
import { getLeaveStatus } from '../../helper/globalFunctions';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { isInternetConnected } from '../../helper/networkUtils';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { popupType } from '../../themes/constants/gameConstants';

const RoomsList = (props) => {

  const {
    on,
    roomsArray,
    setOnFalse,
    setOnTrue
  } = props

  const dispatch = useDispatch()

  return (
    <>
      {roomsArray && roomsArray.length > 0 ?
        (roomsArray.map((item, index) => {
          return (
            <>
              <div className='inner_room'>
                <div className='first_join'>
                  {/*`${url}${item.host.profileImage}` */}
                  <img style={{ height: '60px', width: '60px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }} src={`${url}${item.host.profileImage}`} />
                  <div className='hostname'>
                    <h4>{`Host: ${item?.host?.name}`}</h4>
                    <p>{`Players ${item?.totalJoined}/${item?.maxPlayers} - Available`}</p>
                  </div>
                  <div className='game1'>Game: {`${index + 1}`}</div>
                </div>
                <div className='button_game'>
                  <div className='game_join'>
                    <img
                      src={commonImages.joingame}
                      onClick={() => {
                        if (item.totalJoined !== item.maxPlayers) {
                          const checkAllowed = getLeaveStatus()
                          if (isInternetConnected() && checkAllowed) {
                            if (on) {
                              setOnFalse(false)
                              socket.emit('joinRoom', { roomId: item.roomId, type: 'player' });
                              dispatch({ type: ApiConstants.IS_SPECTATOR, payload: false })
                              setTimeout(() => {
                                setOnTrue(true)
                              }, 2500)
                            }
                          } else {
                            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                          }
                        }
                      }}
                      className={`${item.totalJoined === item.maxPlayers && 'img_disabled'}`}
                    />
                    {((7 - item.maxPlayers) === item.totalSpectators) ?
                      (
                        <img
                          src={commonImages.viewgame}
                          style={{
                            cursor: 'default',
                            opacity: '0.5'
                          }}
                        />
                      ) : (
                        <img
                        src={commonImages.viewgame}
                        onClick={() => {
                          const checkAllowed = getLeaveStatus()
                          if (isInternetConnected() && checkAllowed) {
                            if (on) {
                              setOnFalse(false)
                              socket.emit('joinRoom', { roomId: item.roomId, type: 'spectate' });
                              dispatch({ type: ApiConstants.IS_SPECTATOR, payload: true })
                              setTimeout(() => {
                                setOnTrue(true)
                              }, 2500)
                            }
                          } else {
                            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                          }
                        }}
                      />
                      )
                    }
                  
                  </div>
                </div>
              </div>
            </>
          )
        })) : (
          <div className='no-data-found'><div className='no_data_found_child'>No rooms available.</div></div>
        )}
    </>
  )
}

export default RoomsList;