import { all, fork } from 'redux-saga/effects';
import authenticationSaga from './authSaga';
import homeSaga from './homeSaga';
import contactSaga from './contactSaga';

export default function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(homeSaga),
        fork(contactSaga),
    ]);
}
