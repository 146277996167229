import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAnimation, motion, AnimatePresence } from 'framer-motion';
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import { buttonClickSound } from "../../../helper/playSound";
import ApiConstants from "../../../themes/apiConstants";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { popupType } from "../../../themes/constants/gameConstants";
import { socket } from "../../../helper/socket";
import { getLeaveStatus } from "../../../helper/globalFunctions";
import { isInternetConnected } from "../../../helper/networkUtils";
import styles from './styles';


const GamePausedComponent = (props) => {
    const dispatch = useDispatch();
    const animated = useAnimation();
    const pauseGame = useSelector((state) => state.homeReducer.pauseGame);
    const isSpectator = useSelector((state) => state.homeReducer.isSpectator);

    useEffect(() => {
        if (pauseGame) {
            move(0, 0)
        } else if (!pauseGame) {
            move1(0, gameScreenDimentions.gameBoardWidth * 0.04)
        }
    }, [pauseGame])

    const move = (x, y) => {
        animated.start({
            y: [0, y],
            opacity: 1
        })
    }
    const move1 = (x, y) => {
        animated.start({
            y: [0, y],
            opacity: 0
        })
    }

    return (
        <>
            {<AnimatePresence>
                <motion.div style={styles.viewStyle}
                    transition={{ duration: 0.900 }}
                    animate={animated}>
                    <div style={styles.backgroundStyle}>
                        <span style={styles.textStyle}>Game Paused</span>
                    </div>
                    <div
                        onClick={() => {
                            if (!isSpectator) {
                                buttonClickSound();
                                const checkAllowed = getLeaveStatus()
                                if (isInternetConnected() && checkAllowed) {
                                    socket.emit('pauseResume');
                                    dispatch({
                                        type: ApiConstants.PAUSE_GAME,
                                        data: !pauseGame,
                                        move: true
                                    });
                                }
                                else {
                                    ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                                }
                                if (pauseGame) {
                                    dispatch({
                                        type: ApiConstants.PAUSE_GAME,
                                        data: !pauseGame,
                                        move: false
                                    })
                                    move1(0, gameScreenDimentions.gameBoardWidth * 0.3)
                                }
                            }
                        }}
                        style={{ ...styles.touchableStyle, cursor: pauseGame ? 'pointer' : 'default' }}>
                        <span style={{ ...styles.textStyleResume, cursor: pauseGame ? 'pointer' : 'default' }}>Resume</span>
                    </div>
                </motion.div>
            </AnimatePresence>}
        </>
    );
};

export default GamePausedComponent;