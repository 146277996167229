import { modal } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';

const styles = {
    outerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 6,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column'
    },
    card2: {
        backgroundColor: 'white',
        position: 'absolute',
        alignSelf: 'center',
        bottom: gameScreenDimentions.gameBoardWidth * 0.168,
        left: gameScreenDimentions.gameBoardWidth * 0.853,
        width: gameScreenDimentions.gameBoardWidth * 0.161,
        height: gameScreenDimentions.gameBoardWidth * 0.209,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#46c1af55',
        overflow: 'hidden',
        zIndex: 2,
    },
    card1: {
        backgroundColor: 'white',
        position: 'absolute',
        alignSelf: 'center',
        bottom: gameScreenDimentions.gameBoardWidth * 1.06,
        left: gameScreenDimentions.gameBoardWidth * 0.63,
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#46c1af55',
        overflow: 'hidden',
        zIndex: 2,
    }
}

export default styles;