import React, { useState } from 'react';
import ChatIconComponent from './chatIconComponent'
import './chat.css';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseMethods } from '../../helper/firebaseMethod';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import moment from 'moment';
import { url } from '../../redux/axios/apikit'
import { socket } from '../../helper/socket';
import ApiConstants from '../../themes/apiConstants';


const { Option } = Select;

const ChatBoxComponent = () => {
    const dispatch = useDispatch();
    const [chatVisible, setChatVisible] = React.useState(false)
    const [dropDownOpen, setDropDownOpen] = React.useState(false)
    const [messages, setMessages] = useState([]);
    const [dropDownValue, setDropDownValue] = useState('everyone');
    const [messageData, setMessageData] = useState('');
    const [playerArr, setPlayerArr] = useState([]);
    const { roomData, ourGameData } = useSelector((state) => state.homeReducer);
    const isSpectator = useSelector((state) => state.homeReducer.isSpectator);
    const userId = useSelector((state) => state.authenticationReducer.userId);
    const [roomCreated, setRoomCreated] = useState(true)
    const msgRef = React.useRef(null)

    React.useEffect(() => {
        if (roomData?.roomId && roomCreated) {
            let roomId = roomData?.roomId?.toString()
            FirebaseMethods.createInitialRoom(roomId)
            FirebaseMethods.getAllMessages(syncPosts, roomId, 2)
            setRoomCreated(false)
        }

    }, [roomData, roomCreated])

    React.useEffect(() => {
        msgRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])


    React.useEffect(() => {
        let playerData = roomData?.players
        let arr = [{ label: 'Send To Everyone', value: 'everyone' }]
        for (let i in playerData) {
            if (playerData[i].user._id !== userId && playerData[i]?.reportedBy.length === 0) {
                let obj = {
                    label: playerData[i].user.name,
                    value: playerData[i].user._id
                }
                arr.push(obj)
            }
        }
        if (arr.length > 0) {
            if (arr.length > 2) {

            } else {
                arr.splice(0, 1)
                if (arr.length > 0) {
                    setDropDownValue(arr[0].value)
                }
            }
        }
        setPlayerArr(arr)
    }, [roomData])

    const syncPosts = async (object) => {
        let arr = []
        if (object.length > 0) {
            const d = [...object];
            await d.map(async (item) => {
                if (item.receiverId == null || item.receiverId == userId || item.senderUserId == userId || isSpectator) {
                    let getUserData = _getUserData(item.senderUserId)
                    item['user'] = getUserData
                    arr.push(item)
                }
            })
            setTimeout(() => {
                setMessages([...messages, ...arr]);
                msgRef.current?.scrollIntoView({ behavior: "smooth" })
            }, 1500);
        }
    };

    const _getUserData = (senderUderId) => {
        let playerData = roomData?.players
        let userData = playerData.filter(function (item) {
            return senderUderId == item.user._id;
        });
        return userData[0].user
    }


    const _onSendMessage = async (e) => {
        e && e.preventDefault()
        if (navigator.onLine) {
            if (messageData.trim().length == 0) {
                alert('Please enter message.')
            } else {
                let roomId = roomData?.roomId?.toString()
                let messageObj = {
                    messageBody: messageData.trim(),
                    roomId: roomId,
                    createdAt: moment(new Date()).utc().format(),
                    senderUserId: userId,
                    receiverId: dropDownValue == 'everyone' ? null : dropDownValue,

                }
                socket.emit('checkPrelistWord', messageData.trim());
                setMessageData('')
                await FirebaseMethods.saveMessageToFirebase(messageObj, roomId)
            }
        } else {
            ShowAlertMessage('Please check your internet connection.', 'error', 'red')
        }
    }

    const _renderToolbar = (props) => {
        const changeHeight = (ourGameData?.reportedBy?.length > 0 || isSpectator) ? { bottom: 25 } : {}
        return (
            <div className='chat-input-toolbar-main-div' style={changeHeight}>
                {isSpectator ?
                    <div style={{ color: 'white', paddingLeft: 8, paddingRight: 8 }}>You are not able to do message, because you are spectator.</div>
                    :
                    ourGameData?.reportedBy?.length > 0 ?
                        <div style={{ color: 'white', paddingLeft: 8, paddingRight: 8 }}>You are not able to do message, because you are reported.</div>
                        :
                        <div className='chat-input-toolbar-inner-div'>
                            <input
                                placeholder='Type a message...'
                                placeholderTextColor='#5b5b5b'
                                className='chat-input-style'
                                value={messageData}
                                onChange={(e) => {
                                    if (e.target.value.charAt(0) === " ") {
                                        setMessageData(e.target.value.replace(/^ +/gm, ''))
                                    } else {
                                        setMessageData(e.target.value)
                                    }
                                }}
                                onKeyPress={(e) => {

                                    if (e.shiftKey) {
                                        setMessageData(messageData.replace(/(?:\r\n|\r|\n)/g, "\n"));
                                    } else {
                                        if (messageData.trim().length == 0 && e.key == "Enter") {
                                            e.preventDefault()
                                        } else {
                                            e.key == "Enter" && _onSendMessage(e);
                                        }
                                    }
                                }}
                            />
                            <div
                                onClick={() => _onSendMessage()}
                                className='chat-box-close-icon-view'>
                                <img src={require('../../images/send12.png')} style={{ width: 35, height: 35, cursor: 'pointer' }} />
                            </div>
                        </div>
                }

            </div>
        );
    };

    const _getUserProfileUrl = (senderUserId) => {

        let playerData = roomData?.players
        if (playerData) {
            let userData = playerData.filter(function (item) {
                return senderUserId == item.user._id;
            });
            return userData ? userData[0]?.user?.profileImage : null
        }
    }

    const _getUserName = (senderUserId) => {

        let playerData = roomData?.players
        if (playerData) {
            let userData = playerData.filter(function (item) {
                return senderUserId == item.user._id;
            });
            return userData ? userData[0]?.user?.name : null
        }
    }


    const _listView = () => {
        return (
            messages.length > 0 ? messages.map((item) => {
                let time = moment(moment(item.createdAt).toISOString()).format('hh:mm A')
                let _userProfileUrl = _getUserProfileUrl(item.senderUserId)
                let _userName = _getUserName(item.senderUserId)
                return (
                    <div style={{ marginTop: 12, paddingBottom: 18 }} ref={msgRef}>
                        <div className='chat-box-list-item-div'>
                            <span
                                key={Math.random()}
                                style={{
                                    color: '#fff',
                                    fontSize: 15,
                                    paddingLeft: 3,
                                    marginTop: 2,
                                    // wordBreak: 'break-all'
                                }}>{item.messageBody}</span>
                        </div>
                        <div className='chat-box-profile-and-time-view'>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <img
                                    src={`${url}${_userProfileUrl}`}
                                    className='chat-box-profile-icon-style' />
                                <span style={{ color: '#757575', fontSize: 12, marginTop: 0, marginLeft: 8 }}>{_userName}</span>
                            </div>

                            <span style={{ color: '#757575', fontSize: 10, marginTop: -5 }}>{time}</span>
                        </div>
                    </div>
                )
            })
                :
                <div style={{ backgroundColor: 'rgb(47,47,47)', alignItems: 'center', justifyContent: 'center', height: '95%', display: 'flex' }}>
                    <span style={{ color: '#fff', transform: [{ scaleY: -1 }] }}>{'No Data'}</span>
                </div>
        )
    }

    const _messageContainerView = () => {

        const changeHeight = (ourGameData?.reportedBy?.length > 0 || isSpectator) ? { height: '83%' } : {}
        return (
            <div className='chat-box-div' style={changeHeight}>
                <div className='chat-box-vertical-ui'
                    style={{
                        // height: messages.length > 3 ? 'unset' : '100%',
                        height: '100%',
                        overflow: (dropDownOpen && playerArr.length > 1) ? 'hidden' : 'auto',
                        justifyContent: messages.length > 3 ? 'unset' : 'flex-end',
                        // display: 'flex',
                        // flexDirection: 'column',
                        // alignItems: 'flex-end',
                        // display: 'grid',

                    }}
                >
                    {_listView()}
                </div>
                {_renderToolbar()}
            </div>

        )
    }

    const _dropDownView = () => {
        return (
            <div className='dropdown-outer-div'>
                <div className='dropdown-inner-div'>
                    {playerArr.length > 0 ?
                        <Select onChange={(value) => setDropDownValue(value)}
                            onDropdownVisibleChange={(e) => setDropDownOpen(!dropDownOpen)}
                            value={dropDownValue}
                            style={{ width: '100%' }}
                            suffixIcon={dropDownOpen ? <img src={require('../../images/up.png')} style={{ height: 12, }} /> : <img src={require('../../images/down.png')} style={{ height: 12, }} />}>
                            {playerArr.map((item, i) => {
                                return (
                                    <Option className={i == playerArr.length - 1 ? 'data-without-border' : 'data-with-border'} key={i} value={item.value}>{item.label}</Option>
                                )
                            })
                            }
                        </Select>
                        :
                        <Select
                            // onChange={(value) => setDropDownValue(value)}
                            // onDropdownVisibleChange={(e) => setDropDownOpen(!dropDownOpen)}
                            value={'Send to Everyone'}
                            style={{ width: '100%' }}
                            suffixIcon={dropDownOpen ? <img src={require('../../images/up.png')} style={{ height: 12, }} /> : <img src={require('../../images/down.png')} style={{ height: 12, }} />}>
                            <Option className='data-with-border' value={'everyone'}>{"There's nothing to show!"}</Option>
                        </Select>
                    }
                </div>
            </div >
        )
    }


    const _chatModal = () => {
        return (
            <div className='chat-main-div'>
                <div className='chat-main-outer-div'>
                    <div style={{ flexDirection: 'row' }}>
                        <div
                            className='chat-box-close-icon-div'
                            onClick={() => {
                                setChatVisible(false)
                                dispatch({
                                    type: ApiConstants.SHOW_MENU_BUTTON,
                                    payload: true
                                })
                            }}
                        >
                            <img src={require('../../images/cross.png')} style={{ width: 40, height: 40 }} />
                        </div>
                        <div className='chat-box-header-div'>
                            <h1 style={{
                                color: '#fff',
                                fontSize: 18,
                            }}>Messages</h1>
                        </div>
                    </div>

                    {_messageContainerView()}
                    {(ourGameData?.reportedBy?.length > 0 || isSpectator) ? null :
                        <div style={{ alignItems: 'center', justifyContent: 'center', width: '86%', }}>
                            {_dropDownView()}
                        </div>
                    }
                </div>
            </div>

        )
    }


    return (
        <>
            {chatVisible && _chatModal()}
            <ChatIconComponent display={chatVisible && 'none'} onClickChat={() => {
                setChatVisible(true)
                dispatch({
                    type: ApiConstants.SHOW_MENU_BUTTON,
                    payload: false,
                })
            }} />
        </>

    )
}

export default ChatBoxComponent;
