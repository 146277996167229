import { modal, textColor, textInput } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import { gameScreenDimentions } from '../../../helper/globalFunctions';
import bg from '../../../assets/images/popup_1.png';

const width = gameScreenDimentions.width;
const styles = {
    outerContainer: {
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 1007,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
    },
    modalView: {
        paddingHorizontal: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: width * 0.48,
        backgroundImage: `url(${bg})`,
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
    },
    selectTile: {
        color: textColor.white,
        textAlign: "center",
        fontWeight: '600',
        fontSize: fontSizes.f40,
        marginLeft: 25,
        marginRight: 25,
    },

}

export default styles;