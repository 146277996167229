export default {
    emailAddress: "Email Address",
    password: "Password",
    login: "Login",
    username: "Username",
    register: "Register",
    forgotPassword: "Forgot Password?",
    userName: "Username",
    confirmPass: "Confirm Password",
    forgotPass: "Forgot Password",
    send: "send",
    success: "Success",
    successMsg: "Confirmation link has been sent successfully to your registered email address.",
    okay: "Okay",
    home: "Home",
    playCard: "Play card",
    enter: "Enter",
    createAndPlay: "Create & Play",
    joinAndPlay: "Join & Play",
    publicRooms: "Public Rooms",
    host: "Host",
    noSpectators: "No spectators",
    game: "Game",
    players: "Players",
    available: "Available",
    joinGame: "Join",
    viewGame: "View Game",
    started: "Started",
    myProfile: "My Profile",
    profileImage: "Profile Image",
    edit: "Edit",
    editProfile: "Edit Profile",
    update: "Update",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmNewPass: "Confirm New Password",
    joinRoom: "Join Room",
    privateRoom: "Private Room",
    publicRoom: "Public Room",
    roomId: "Room ID",
    join: "Join",
    createRoom: "Create Room",
    gameMode: "Assign Players",
    auto: "Auto",
    manual: "Manual",
    roomType: "Room Type",
    public: "Public",
    private: "Private",
    numbersOfPlayers: "Number of Players",
    gameLobby: "Game Lobby",
    roomCode: "Room Code",
    shareRoomCode: "Share Room Code",
    joinedUsers: "Joined Users:",
    waitingForPlayers: "Waiting for players to join...",
    startGame: "Start Game",
    leave: "Leave",
    leaderboard: "Leaderboard",
    totalWins: "Total Wins:",
    sdCompetitiveWins: "SD Competitive Wins:",
    sdCooperativeWins: "SD Cooperative Wins:",
    sndCompetitiveWins: "SND Competitive Wins:",
    sndCooperativeWins: "SND Cooperative Wins:",
    settings: "Settings",
    soundOn: "Sound On",
    gameHelp: "Game Help",
    faq: "FAQ",
    privacyPolicy: "Privacy Policy",
    aboutUs: "About Us",
    contactUs: "Contact Us",
    logout: "Logout",
    yearlyPackage: "Yearly Package: $14.99",
    yearlyPackage1: "Yearly Package",
    inAppPurchase: "In-App Purchase",
    purchase: "Purchase",
    sd: "SD",
    snd: "SND",
    enterVoucherCode: "Enter Voucher Code",
    useVoucher: "Use Voucher Code",
    areYouSureHost: "Are you sure you want to cancel this room?",
    areYouSure: "Are you sure you want to leave this room?",
    areYouSureGame: "Are you sure you want to leave this game?",
    submit: "Submit",
    noRoom: "No rooms available.",
    earnSkillsTiles: "Earn 2 Skill Tiles",
    tradeTiles: "Trade Tiles",
    vote: "Vote",
    back: "Back",
    close: "Close",
    reverseVote: "Reverse Vote",
    newVote: "New Vote",
    rollDice: "Roll the dice to play the card.",
    rollDice1: "Roll Dice",
    playAPreparation: "Play a Preparation Action",
    playAnOpportunity: "Play an Opportunity Card",
    discardACard: "Discard for a new card",
    noRoom: "No rooms available.",
    selectTile: "Select a tile to trade?",
    selectTileVote: "Select a tile to Vote on?",
    enterToken: 'Enter tokens count',
    sacrificeCard: 'Sacrifice Card',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    payThree: 'Pay 3 Privilege tokens',
    sorryNotThis: 'Sorry not this time!',
    youHaveTen: 'You have 60 seconds to make a decision.',
    selectACard: 'Select a card to view.',
    youHaveOnly: 'You have only 30 seconds to roll the dice.',
    startGameText: 'SD players will roll the dice to decide who will start the game first.',
    noInternet: 'Please check your internet connection.',
    reloadResize: 'You have change the window size so, please reload the screen.',
    reloadFocus: 'You have loss the focus from the window, please reload the screen.',
    usePowerToken: 'Use your Power token to play this card?',
    usePowerToken1: 'Use Power token',
    youDontQualify: `You don't qualify to play this card.`,
    readyToPlay: 'Ready to play this card?',
    useYourInfluence: 'Use your Influence card?',
    addOrTakeDice: `Would you like to add or take one number from this player's dice roll?`,
    wantAnotherChance: 'The roll was unsuccessful. Want another chance to roll the dice?',
    wantToGive: 'The roll was unsuccessful. Want to give the current player another chance to roll the dice?',
    doYouWant: "Do you want to use your Power token to deflect the outcome?",
    youDontHave: `You don't have enough space from the START to play this card.`,
    doYouWantAnotherPlayer: "Do you want to use your Power token to deflect the outcome for another player?",
    toUseYour: "To use your Influence card, you must have 3 Privilege tokens. Once you or another player plays a card that has a dice roll you will be given the chance to use your Influence card prior to the roll.",
    selectOneNumber: 'Select one number to add to the dice roll.',
    add: 'Add',
    take: 'Take',
    yesUsePowerToken: 'Yes, use Power token',
    yesPay3Privilege: 'Yes, pay 3 Privilege tokens',
    exit: 'Exit',
    youAreInTier1: "You're in Tier 1 and get 3 Privilege tokens.",
    usePowerTokenForBreak: "Use your Power token to get a tax break?",
    youGotATaxBreak: "You got a tax break and don't have to pay taxes!",
    youAreInTier2: "You're in Tier 2 and must pay 1 Privilege token.",
    youAreInTier3: "You're in Tier 3 and must pay 3 Privilege tokens.",
    youDontHaveEnough: "You don't have enough Privilege to pay the tax.",
    eachPlayerMust: "Each player must first roll for Step A to see if they are STOPPED & TICKETED or if they are free to go.",
    youHaveTwo: "You have two Justice tokens and are automatically STOPPED and TICKETED!",
    youHaveThree: "You have three Justice tokens and are automatically CHARGED and CONVICTED!",
    youHaveBeen: "You've been STOPPED and TICKETED!",
    youAreFree: "You are free to go!",
    youMust: "You must pay the fine.",
    youDonotHave: "You don't have enough Privilege tokens to pay the fine.",
    allRemainingPlayers: "All remaining players must roll for Step B to see if they are CHARGED & CONVICTED or if they are free to go.",
    youMustServe: "You must serve your sentence.",
    youHaveTheMax: "You have reached the max number of Justice tokens.",
    youReceiveOne: "You receive 1 Justice token.",
    playYourPower: "Play your Power token to deflect the outcome?",
    youHaveReached: "You have reached the end of the tier and receive 1 Privilege token for every type of tile you have.",
    winner: "Winner",
    congratulations: "Congratulations!",
    youReceivedbadge: "You received a new badge.",
    loser: "You Lose",
    tryAgain: "Try Again!",
    betterLuck: "Better Luck next time.",
    howToPlay: "How to Play",
    howToPlay1: "How To Play",
    howToWin: "How to Win",
    howToLose: "How to Lose",
    tierOneGoal: "Tier One Goal:",
    tierOneGoalText: "Obtain a JOB wealth tile.",
    tierTwoGoal: "Tier Two Goal:",
    tierTwoGoalText: "Obtain a HOME wealth tile.",
    tierThreeGoal: "Tier Three Goal:",
    tierThreeGoalText: "Obtain a BUSINESS wealth tile.",
    tierFourGoal: "Tier Four Goal:",
    tierFourGoalText: "Obtain a REAL ESTATE wealth tile.",
    pathToWinning: "Path to Winning",
    pathToText: "You may only advance to a new tier once you have successfully achieved the Tier Goal and unlocked the next tier. If you end up moving back to a lower tier, you still must have all the required tiles to advance again.",
    howToLoseText: "An individual player achieves the Competitive Play goal before another individual player or before the group can achieve the Cooperative Play goal.",
    competitivePlay: "Competitive Play:",
    cooperativePlay: "Cooperative Play:",
    competitivePlayText: "Be the first one to obtain all four wealth tiles and make it completely around the board. In this option, only one player wins.",
    cooperativePlayText: "All players must make it to at least Tier Three and obtain a Home wealth tile. This must be achieved before any individual player achieves a Competitive Play win. In this option, everybody wins.",
    howToPlayText: "This is a semi-cooperative game, which means you can play either competitively or cooperatively and can switch between the two throughout the game, depending on how you would like to win. Changing the game mode will not impact game play in any manner. Neither user needs to update his game play mode to any one. Competitive or Cooperative is just the user perspective to play game.",
    cardLayout: "Card Layout",
    prerequisiteTile: `1. Prerequisite tile`,
    needToPlay: "needed to play card",
    diceRolls: "2. Dice rolls",
    diceRollText: "for SD + SND",
    singlePlayerOr: "3. Single Player or All Players:",
    tileEarned: "4. Tile earned",
    tileEarnedText: "if card played successfully",
    singlePlayerOrText: "May include qualifiers for who is allowed to use the card (e.g. T2+). If nothing is mentioned then it means that there is no tier restriction.",
    diveDeeperText: "Scan code with camera phone to learn more. It has nothing to do with the Game.",
    diveDeeper: '5. Dive deeper!',
    beforeStarting: "Before Starting",
    systemicallySND: "Systemically Non-Dominant (SND)",
    systemicallySD: "Systematically Dominant(SD)",
    beforeStartingText: "The 2 images will be used as the player identity and will be shown besides the user image and his name.",
    twoModes: "There will be 2 modes to play the game.",
    automatic: "1. Automatic:",
    manual1: "2. Manual:",
    manualText: "If room admin selects the manual mode all the functionality has to be completed by the room admin like",
    systemWill: "• System will allot pawns.",
    automaticText: "If room admin selects the automatic mode all the function done in the game will be done automatically like",
    givingIdentity: "• Giving identity to the Player like SD and SND. Note - The distribution should be equal. If its odd number lets say 5, then 2 will be SD and 3 will be SND.",
    givingIdentityManual: "• Giving identity to particular Player (SD/SND). Note - The distribution should be equal. If its odd number lets say 5, then 2 will be SD and 3 will be SND.",
    selectingThePawn: "• Selecting the pawn – All user will get the option to select their pawn, selection of pawn will be first come first serve.",
    allSDplayer: "All SD player will get the option to roll the dice, the SD player who get the highest number on his dice will go first. After that the turn will go clockwise. In case of draw they have to roll the dice again.",
    allThePlayer: "All the player will receive 3 opportunity cards from the deck and rest will be placed in the center of the Game board.",
    sndPlayersGet: "SND players get one Privilege token to start and SD players get two Privilege tokens.",
    theTurnsMove: "The turns move clockwise once the first SD player is chosen.",
    onYourTurn: "On your Turn.",
    onYourTurnText: "Select to play either 1) a Preparation action, 2) an Opportunity card from your hand, or 3) discard for a new card.",
    playAPrepAction: "Play a Preparation Action:",
    selectOneOf: "Select one of the following:",
    getTwoSkill: "• Get 2 Skill tiles",
    trades: "Trades",
    getTwoSkillText: "To play this option you must sacrifice a card of your choice from your hand and place it in the bottom of the opportunity card deck and after that it will shuffle. You will then play with one less card in your hand until you advance into the next tier and regain all three cards again. Once user reaches to next tier he will then pull a new opportunity card. You must have at least one Opportunity card at all times.",
    tradeTileRule: "Trade Tile Rule",
    tradeInvolvePrivilege: "Trades involve Preparation tiles (teal colored tiles) in some form. This means you can trade your Skill tiles for a Degree tile, your Degree tiles for a Startup tile, and your Startup tile for a Job wealth tile. If you want to make a Trade, you can choose to do so through a Preparation action and can make as many Trades as you like within one turn.",
    voteText1: "During your turn you may select to play a Preparation Action and hold a vote to eliminate a disparity in the game. To hold a vote, player must have 2 Privilege tokens. Player will then asked to make the contribution of privilege tokens that he want to donate for this vote. Minimum of 2 tokens should be contributed by the person who is hosting the vote. Rest user can spend with minimum 1 token as well.",
    voteText2: "To initiate a vote, you must select a tile that has a disparity between SD and SND that you would like to eliminate. Once a tile is selected, all players who are not actively skipping a turn due to a Justice conviction will pool together a total amount of Privilege tokens they believe they’ll need to surpass the vote threshold number. The threshold number is based on the forthcoming dice roll(s). Once the tokens are pooled, the player who got the vote space card will roll the die to determine the threshold number to beat (only 1 die roll until the first vote passes, then the sum of 2 dice rolls until the second vote passes, 3 until the third, and so on).",
    voteText3: "If the total is the same or higher than the number of pooled tokens, then the vote does not pass. If the total is lower, then the vote passes and you can place the selected tile on a ‘vote slot’ on the game board. Regardless of the vote outcome, all pooled Privilege tokens must be discarded.",
    reverseVoteText: "Use this Preparation action to hold a vote in order to change the rules of the game and reinstate the disparity between SD and SND on any tile with a passed vote (See VOTE section for details).",
    reverseVoteNote: "Note - If i am the only person who has participated in the vote. I donated 2 privilege tokens and after rolling the dice the number which i get was 1, so in this case as well my vote gets passed.",
    playAnOpportunityCard: "Select one of the cards from your hand that you’re qualified to play. If required, roll the dice to see if you successfully achieve the opportunity listed on the card.",
    playAnOpportunityCard2: "If you have an Influence card, you can use it to help your chance or if another player has an Influence card they can use it to hinder your chances (See HELP or HINDER section for more details). If successful, you are awarded the tile or tokens on the card (see CARD LAYOUT for details). If unsuccessful, you receive nothing. Or use three (3) privilege tokens to get a another chance to roll again (see PRIVILEGE TOKENS section for details).",
    discardForNew: "Discard for a New Card",
    endOfTurn: "End of Turn",
    endOfTurnText: "Once you have either played a Preparation action, an Opportunity card, or discarded for a new card, you will roll the dice and move that number of spaces along the board. Depending on the roll, you will land on either a JUSTICE space, a TAXES space, or a blank space and pull an EVENT CARD from the top of the Event deck (see TYPES OF BOARD SPACES for details).",
    endOfTurnText2: "When your roll moves you into the next tier, you remain at the beginning of that tier and receive 1 Privilege token for every TYPE of tile you currently have (e.g. three Skill tiles, one Degree tile, two Startup tiles and 1 Job tile = four tokens). Justice tokens and Power tokens are not included. Once you have collected your Privilege tokens, you must play the respective Tier Goal card.",
    endOfTurnText3: "If unsuccessful, you must move back to the beginning of the tier you were previously on.",
    endOfTurnText4: "If successful, you will get the qualifying tile of the tier and you will remain where you are on the board and collect your Tier Bonus which includes getting Privilege tokens respective to the tier you are on (e.g. tier two = 2 tokens, tier three = 3 tokens, etc). Reminder: SD players will collect one additional Privilege token for being SD identity.",
    discardForNewText: "If you do not wish to play a Preparation action or an Opportunity card, then you can pass your turn and simply discard one Opportunity card from your hand and pull a new one from the deck. After this the deck will shuffle.",
    prosperityPoints: "Prosperity Points",
    prosperityPointsText: "After the game has ended you have an option to allocate points to each player based on how they played the game and use that system to determine the ultimate winner of the game. Below is a suggested point allocation based on the tiles user has at the end of the game.",
    eachSkillTile: "Each Skill tile =",
    eachDegreeTile: "Each Degree tile =",
    eachStartupTile: "Each Startup tile =",
    eachPrivilege: "Each Privilege token =",
    eachJustice: "Each Justice token =",
    eachPower: "Power token =",
    eachTier: "Tier you are on multiplied by 1 Prosperity point",
    competitiveWinOnly: "Competitive win Only =",
    cooperativeWinOnly: "Cooperative win Only =",
    turnSkips: "Turn Skips",
    help: "Help",
    hinder: "Hinder",
    influenceCard: "• Influence Card",
    influenceCard2Text: "Use your influence card to take one number from the dice roll of an Opportunity card, an Event card or a Justice card. This cannot be used on “Roll-Off” cards where its a head-to-head roll between you and another player (See TYPES OF CARDS section for details).",
    influenceCardText: "Use your influence card to add one number to the dice roll of an eligible Opportunity card, eligible Event card, or a Justice card. This cannot be used on “Roll-Off” cards where there is a head-to-head roll between you and another player (See TYPES OF CARDS section for details).",
    vote2: "• Vote",
    disparityTrap: "Disparity Trap",
    disparityTrapText1: "The Social Conscious Board Game",
    disparityTrapText3: "So like life, the dice are in your hands, but the odds are not.",
    disparityTrapText2: " provides an easy way to have the hard conversations around race & privilege in America and how they impact society in systemically dominant (SD) and systemically non-dominant (SND) ways. The game play is like many of its kind, where your individual goal is to accrue as much wealth as possible. But where it differs is that you can have a team goal as well, where you work with your fellow players to dismantle the Disparity Traps seeking to keep everyone in poverty. Within this game you have the opportunity to step into someone else’s shoes; to experience the disparities within an identity different from your own. Throughout the game, the dice roll correlates your identity in the game to real life statistics.",
    vote2Text: "Use this Preparation action to hold a vote in order to change the rules. (See Vote section for details).",
    powerToken2: "• Power Token",
    powerToken2Text: "Use your Power token to deflect the negative outcomes from a space card. This includes Justice, Taxes, or Event cards) (See TYPES OF TOKENS section for details).",
    privilegeToken2: "• Privilege Token",
    privilegeToken2Text: "Use your Privilege token to get another roll on an unsuccessfully played Opportunity or Event card. This cannot be used on “Roll-Off” cards where it’s a head-to-head roll between you and another player (See TYPES OF CARDS section for details).",
    helpOrHinder: "HELP or HINDER",
    typesOfBoardSpaces: "Types of Board Spaces",
    justiceSpace: "Justice Space",
    justiceSpaceText: "Played by the player on the space and the eligible players to their left and to their right. Read the Justice card and have all eligible players play in clockwise order for Step A, before moving on to Step B. Players that hit their dice number on Step A must move on to Step B. Play through the steps until no players remain or the card is completed. If a player cannot pay the fine in Step A, they must move back three spaces.",
    justiceSpaceText2: "NOTE - if someone is convicted and lets say the player is on the beginning of the tier then he will remain on that tier and on that particular position.",
    taxSpace: "Tax Space",
    taxSpaceText: "Played by all eligible players. Read the Tax card and have all eligible players on tier one get three Privilege tokens, those on tier two pay 1 Privilege token, those on tier three pay 3 Privilege tokens, and those on tier four skip 1 turn. If a player cannot pay the tax, they must move back three spaces.",
    BlankSpace: "Blank Space",
    typesOfCards: "Types of Cards",
    typesOfTokens: "Types of Tokens",
    privilegeTokens: "Privilege Tokens",
    justiceTokens: "Justice Tokens",
    powerTokens: "Power Tokens",
    powerTokensText: "Power tokens are given either through an Event card or by putting in the most Privilege tokens on any vote that has 2 or more players involved. You can have only one Power token at a time. Once you have used your Power token you must return it. The Power token also provides you the ability to deflect the outcomes from any space cards.",
    justiceTokensText: "Justice tokens are given through a Justice card whenever a player hit their dice range and is convicted. The player must hold on to this token for the remainder of the game. If a player has two Justice tokens when playing a Justice space card, they automatically receive the first penalty and will roll for Step B with the rest of the players. If they get three Justice tokens, they don’t get to roll Step A or Step B and automatically receive the conviction penalty. A player does not receive more than 3 Justice tokens.",
    extraDiceRoll: "Extra Dice Roll:",
    participateInVote: "Participate in a Vote:",
    participateInVoteText: "Pay 2 Privilege tokens when you play a Preparation action and want to hold a vote. (See VOTE section for details).",
    influenceCard3: "Influence card:",
    influenceCard3Text: "Pay 3 Privilege tokens any time you wish to use an Influence card and add/take a number from a dice roll being played. You can use your Influence cards on your own turn or during other player’s turns. Influence cards cannot be used on Roll- Off cards (See TYPES OF CARDS for details).",
    extraDiceRollText: "Pay 3 Privilege tokens any time you wish to get another chance at rolling the dice on an Opportunity card, Event card, or Space card. Cannot be used on Roll-Off cards.",
    privilegeTokensText: "Privilege tokens are given either through an Opportunity card, an Event card, or through a Tier Bonus when you have unlocked a new Tier. The Tier Bonus given is equal to the number of tile TYPES you have (ex. if you have three Skill tiles, one Degree tile and two Startup tiles, you get three Privilege tokens for having three tile TYPES). You also receive tokens respective to the tier you are on (e.g. tier two = 2 tokens, tier three = 3 tokens, etc). SD players will recieve one additional Privilege token for being SD identity. Privilege tokens can be used in the following ways:",
    OpportunityCards: "Opportunity Cards",
    OpportunityCardsText: "Some Opportunity cards have qualifiers in order to play them. If you qualify, you can choose to play the card. If you don’t qualify, you must wait till you do before playing.",
    EventCards: "Event Cards",
    EventCardsText: "Player can use their Power token to deflect the outcome of the event card they played.",
    justiceCard: "Justice Card",
    taxesCard: "Taxes Card",
    skillTile: "Skill Tile",
    skillTileText: "Preparation tile that can be earned on ANY tier and improves your chances of obtaining Wealth tiles. Acquired through a Preparation action by sacrificing a card from your hand, an Opportunity card or an Event card.",
    degreeTile: "Degree Tile",
    degreeTileText: "Preparation tile that can be earned on ANY tier and improves your chances of obtaining Wealth tiles. Acquired through a Preparation action through a trade, an Opportunity card or an Event card.",
    startupTile: "Startup Tile",
    startupTileText: "Preparation tile that can be earned on ANY tier and improves your chances of obtaining Wealth tiles. Acquired through a Preparation action through a trade, an Opportunity card or an Event card.",
    taxesCardText: "Player can use their Power token to deflect the outcome of the tax card.",
    justiceCardText: "Player can use their Power token to deflect the outcome of the justice card.",
    influenceCards: "Influence Cards",
    influenceCardsText: "This is a type of Opportunity card that allows you to impact the chances of success on a card that has a disparity in the dice roll. This can be played on your turn or another player’s turn. You can add one number to the dice roll range listed on the card, or take one number from the dice roll range. This card cannot be played on Roll-Off cards where there is a head-to-head roll between two players. Once this card is played, discard and pull a new Opportunity card.",
    eventCardsText: "Event cards are played whenever you land on a blank space on the board. Event cards can be either good for players or can negatively impact players in the game. Some cards you have to qualify first in order to determine who can participate. If you do not qualify you must discard. If you qualify but there are no other players eligible to play, you win the card automatically. You can only deflect the negative outcome of an Event card if you have a Power token.",
    rollOfCards: "Roll-Off Cards",
    rollOfCardsText: "This is any card that has one dice roll between two players. On Roll-Off cards, you cannot use Influence cards to add/take from the dice roll. Privilege tokens can be used to get another dice roll. No Power tokens will be used to deflect the outcomes.",
    tierGoalCards: "Tier Goal Cards",
    preparationTiles: "Preparation Tiles",
    wealthTiles: "Wealth Tiles",
    jobTile: "Job Tile",
    jobTileText: "Can only be earned when you exited Tier 1 and successfully played the tier one’s goal card.",
    homeTile: "Home Tile",
    homeTileText: "Can only be earned when you exited Tier 2 and successfully played the tier two’s goal card.",
    businessTile: "Business Tile",
    businessTileText: "Can only be earned when you exited Tier 3 and successfully played the tier three’s goal card.",
    realEstateTile: "Real Estate Tile",
    realEstateTileText: "Can only be earned when you exited Tier 4 and successfully played the tier four’s goal card.",
    wealthTilesText: "Wealth tiles are acquired through a tier goal cards. ",
    preparationTilesText: "The following are the ways a tile can be earned.",
    tierGoalCardsText: "Each tier has a Tier Goal card you must play in order to advance along the board. When you roll the dice at the end of a turn and land in a new tier, you must remain at the beginning of that new tier and play the correlating Tier Goal card. If successful, you can stay on that new tier. If unsuccessful, you must move back to the beginning of the previous tier.",
    BlankSpaceText: "Played by all eligible players as specified on the card. To play, pull an Event card from the top of the Event deck and keep in center of the game board of the opportunity and event card deck.",
    typesBoardSpacesText: "Any players with a turn skip are ineligible to participate on space cards and event cards.",
    helpOrHinderText: "There are multiple ways you can help or hinder the progress of yourself, or others, in order to achieve your ultimate goal of winning the game.",
    turnSkipsText: "Some card outcomes will require a player skip their next turn. In this case, the player becomes ineligible to participate in any all Space cards or Event cards while actively playing their turn skip. Players that are skipping a turn due to a Justice card conviction are also ineligible to participate in votes. Once their skip has been completed they are eligible to participate on all cards and votes again. If user is skipping his turn but he is also eligible for any reward through on any card on which he doesn’t need to play, then in this case he will get the reward. Example #DT7, #DT8, #DT10, #DT11.",
    cooperativeWinText: "During the game, keep tally each time any player Helps or Hinders another player (See HELP OR HINDER section for details). Add 1 Prosperity point each time you help another player, Deduct 1 Prosperity point each time you hinder another player",
    pawn: "Pawn: ",
    tilesAndTOkens: "Tiles and Tokens: ",
    radioBoxHeading: "Select to play your turn:",
    tokens: "Tokens: ",
    report: "Report",
    sorry: "Sorry!",
    greatWork: "Great Work",
    ultimateWinner: "Ultimate Winner",
    cooperativeWinPoints: "It's a cooperative win and you scored the most Prosperity points.",
    competitiveWin: "It's a competitive win and you scored the most Prosperity points.",
    competitiveLoose: "It's a competitive win and you didn't score the most Prosperity points.",
    cooperativeWin: "It's a cooperative win but you didn't score the most Prosperity points.",
    logoutMessage: "Are you sure you want to logout?",
    noDataAvailable: "No data available.",
    noOne: "No one gets points.",
    buyHardCopy: 'Buy Hard Copy Game',
    termsAndConditions: "Terms and Conditions",
    selectThisButton: "Select this button if you want to create a game room. Must have a subscription to create a game room.",
    joinAPlayText: "Select this button to find public game rooms available for you to join, or to enter a Room Code and join a private game room. No subscription required to join or spectate a game room.",
    publicRText: "This window displays all of the public game rooms that you can join to play or join to spectate. No subscription required to join or spectate a game room.",
    thisIsWhere: "This is where you create your game room.",
    assignPlayerText: "Choose to have player positions (SD/SND) assigned by you (Manual) or automatically at random (Auto). Select the rulebook button for more info about SD/SND player positions.",
    roomTypeText: "Choose to have your game room accessible to the public or accessible only by invitation through a private Room Code.",
    numPlayerText: "Select the maximum number of players you want to have in your game room. Maximum 6 players in a game room. Minimum 2 players required to start the game.",
    createRoomText: "Select this button when your room settings are complete and you are ready to have other players join you in the Game Lobby.",
    youAreIn: "You are in the Game Lobby where you wait for other players to join your game room.",
    roomCodeText: `This is the unique code assigned to your game room. Players can enter this code on the home screen to find your game room directly. If you set the Room Type to "Private," only those who enter the unique code on the homescreen can find your game room and join. If you set the Room Type to "Public", then anyone on the app can find your game room and join.`,
    shareCodeText: "Select this button to send your unique Room Code to your friends/family and invite them to play.",
    usersText: "This shows the number of people who have joined to play in your game room thus far. Minimum 2 players required to start the game.",
    spectators: "Spectators",
    spectatorsText: "This shows the number of people who wish to watch your game but not play.",
    startGText: "Once you have at least 2 players in the Game Lobby, you can start the game.",
    leaveText: "Select this button if you wish to close and cancel your current Game Room.",
    sdText: `"Systemically Dominant" - One of the positions you can play in the game. It pre-determines which scenarios you will play during the game. Select the rulebook below for more info.`,
    sndText: `"Systemically Non-Dominant" -  One of the positions you can play in the game. It pre-determines which scenarios you will play during the game. Select the rulebook button for more info.`,
    termsForIAP: "Recurring billing can be cancelled at any time When you press continue the payment will be charged from your Apple Account and your subscription will be automatically renewed with the same package length at the same price, until you cancel the subscription in your settings in App Store. When you click Get more / Continue you agree to our terms.",
    deleteAccount: "Delete Account",
    deleteAccountMsg: "• Are you sure you want to delete your account?",
    blockedUsers: "Blocked Users",
    weWillDelete: "• We will permanently delete your account, and all profile information from Disparity Trap. Once deleted, your data cannot be retrieved.",
    manageSubsText: "• Are you subscribed to Package? Manage subscriptions by going to Settings > Apple ID > Subscriptions.",
    manageSubsTextAndroid: "• Are you subscribed to Package? Manage subscriptions by going to Play Store > Payments and subscriptions > Subscriptions.",
    block: 'Block',
    unBlock: "Unblock",
    spectate: 'Spectate',

    discardACard1: "Discard for a new card:",
    startYourTurn: "Start your turn by selecting one of the following: ",
    spendYourTurn: "Spend your turn by discarding one of the cards in your hand for a new Opportunity card.",
    select1Of: "Select 1 of the cards from your hand that you’re qualified to play. If required, roll the dice to see if you achieve the opportunity.",
    trade: 'Trade',
    youCanSelect: "You can select one of the option:",
    timesUp: "Time's almost up! You have two minutes left to play your turn.",
    continue: "Continue",
    spendYour: "Spend your turn playing options that may prepare you for the opportunities ahead.",
    forMoreInfo: "For more info on the rules, click the Rulebook button on your screen to download the booklet.",
    toPlayThis: "To play this option, sacrifice an Opportunity card of your choice from your hand. You will then play with 1 fewer Opportunity card in your hand until you land on the next tier and return to having all 3 cards again. You must have at least 1 Opportunity card at all times.",
    youCanTrade: "You can trade 2 Skill tiles for 1 Degree tile, 2 Degree tiles for 1 Startup tile, and 1 Startup tile for 1 Job tile. Trades are unidirectional and cannot be reversed.",
    changeTheRules: "Change the rules of the game by voting to remove or reinstate the disparity between SD and SND for a specific tile in the game. Must have at least 2 privilege tokens to initiate a vote.",
    outcomeHasBeen: "Outcome has been deflect!",
    youWillPlayTo: "You will play to see who gets Privilege tokens. Highest roll wins.",
    useYourInfluenceCard: 'Use your influence card on the other player?',
    enterTokens: "Enter how many privilege tokens you want to add to the vote.",
    instructionVedio: "Instruction Video",
    pointBreakdown: "Points Breakdown",
    viewCards: 'View Cards',

    play: 'Play',
    done: 'Done',
    areYouSure: "Are you sure?",
};