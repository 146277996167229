import React, { useState } from 'react';
import InputField from '../../common/textFiled';
import { commonImages } from '../../themes/appImage';
import { useEffect } from 'react';
import ModalPop from '../../common/Modal';
import GameRuleModal from '../../common/ruleModal';
import { Link } from 'react-router-dom';
import Header from '../../common/header';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../helper/socket';
import { getRoomListSocketAction, soloPlayAction } from '../../redux/actions/homeActions/homeActions';
import RoomsList from '../../common/roomsList';
import { DataManager } from '../../helper/dataManager';
import ButtonCustom from '../game/ButtonCustom';
import fontSizes from '../../themes/constants/fontSizes';
import appImages from '../../themes/appImages/appImages';
import { navigate } from '../../Routes/navigationServices';


const PlayJoin = (props) => {
    const dispatch = useDispatch()
    const [on, setOn] = useState(true);
    const roomsArray = useSelector((state) => state.homeReducer.publicRoomList);
    const roomData = useSelector((state) => state.homeReducer.roomData);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalSuccessVisible, setIsModalSuccessVisible] = useState(false);
    const [isModalRuleVisible, setIsModalRuleVisible] = useState(false);

    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)
    }, [])

    window.addEventListener('focus', event => {
        console.log("event focus = ", event)
    })

    window.addEventListener('blur', event => {
        console.log("event blur = ", event)
    })

    React.useEffect(() => {
        DataManager.removeLeaveGameStatus()
        setOn(true);
        socket.on('roomSuccess', getRoomList);
        socket.emit('room')
        return () => {
            socket.off('roomSuccess', getRoomList);
        }
    }, [])

    const getRoomList = (data) => {
        dispatch(getRoomListSocketAction(data.data))
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalSuccess = () => {
        setIsModalSuccessVisible(true);
    };

    const handleOkSuccess = () => {
        setIsModalSuccessVisible(false);
    };

    const handleCancelSuccess = () => {
        setIsModalSuccessVisible(false);
    };

    const successBtnOne = (
        <>
            <button>
                <Link to="/publicroom">
                    <img src={commonImages.join} onClick={() => showModalSuccess()} className="send_new" />
                </Link>
            </button>
        </>
    )

    const fragment = (
        <>
            <div className='main_pop image_pop'>
                <div></div>
                <Link to="/createroom">
                    <img src={commonImages.custom} className="send_new" />
                </Link>
                <Link to="/createroom">
                    <img src={commonImages.public} className="send_new" />
                </Link>
            </div>
        </>
    )

    const successmessage = (
        <>
            <div className='main_pop'>
                <div className='img_y'>
                    <h2>Join</h2>
                </div>
                <div>
                    <div className='form-group'>
                        <label>Room ID</label>
                        <InputField
                            type='text'
                            placeholder="Room ID"
                            width='70%'
                            height='35px'
                            className='formcontrol'
                        />
                    </div>
                </div>
            </div>
        </>
    )


    return (
        <div>
            <div className='background_image' style={{ paddingBottom: '70px' }}>
                <Header title='Home' settings={true} redirect={false} />
                <div className='info_container'>
                    <img onClick={() => {
                        setIsModalRuleVisible(true)
                    }} height={50} width={50} style={{ cursor: 'pointer' }} alt='' src={commonImages.info} />
                </div>
                <div className='inner_box box'>
                    <div className='children_play'>
                        <img src={commonImages.disparitylogo} />
                    </div>

                </div>
                <div className='button_wrapper_content'>
                    <div className='button_Wrapper'>
                        <Link to="/createroom">
                            <img src={commonImages.createplay} alt="" />
                        </Link>
                    </div>
                    <div className='button_Wrapper'>
                        <Link to="/room">
                            <img src={commonImages.join_play} alt="" />
                        </Link>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent:'center'
                    }}>
                    <ButtonCustom
                        outerContainer={{ width: 250, height: 53, marginBottom:20  }}
                        resize={"stretch"}
                        style={{ width: 250, height: 53 }}
                        iContainer={{ height: 53 }}
                        textColor={{ fontSize: fontSizes.f55 }}
                        imgBg={appImages.buttonBg}
                        title={"Solo Play"}
                        onPress={() => {
                            navigate('soloPlayRoom')
                        }}
                    />
                    </div>
                    <h3 className='rooms'>Public Rooms</h3>
                </div>


                <div className='room'>
                    <div className='scroll_div'>

                        <RoomsList
                            roomsArray={roomsArray}
                            on={on}
                            setOnFalse={() => setOn(false)}
                            setOnTrue={() => setOn(true)}
                        />
                    </div>
                </div>

            </div>

            {isModalVisible && <ModalPop
                className='center'
                content={fragment}
                btnSuccess={false}
                btnCancel={false}
                handleSuccess={handleOk}
                handleCancel={handleCancel}
                isVisible={isModalVisible}

            />
            }

            {isModalRuleVisible && <ModalPop
                className='center'
                content={<GameRuleModal />}
                btnSuccess={false}
                btnCancel={false}
                handleSuccess={() => setIsModalRuleVisible(false)}
                handleCancel={() => setIsModalRuleVisible(false)}
                isVisible={isModalRuleVisible}

            />
            }

            {
                isModalSuccessVisible &&
                <ModalPop

                    content={successmessage}
                    btnSuccess={successBtnOne}
                    btnCancel={true}
                    handleSuccess={handleOkSuccess}
                    handleCancel={handleCancelSuccess}
                    isVisible={isModalSuccessVisible}

                />
            }
        </div>

    )

}
export default PlayJoin