import React from 'react';

const CardBorder = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 300 383" {...props}>
    <defs>
      <style>
        {` .cls-1 {
        fill: none;
        stroke: #46c1af;
        stroke-width: 2px;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path class="cls-1" d="M65.027,1.122H237.02c31.118,0,60.3,25.225,60.3,56.343l0.988,267.875c0,31.117-30.167,56.343-61.285,56.343H65.027c-31.117,0-61.285-25.226-61.285-56.343L1.765,57.465C1.765,26.348,33.91,1.122,65.027,1.122Z" />
  </svg>
)

export default CardBorder