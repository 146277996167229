import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../helper/socket';
import Header from '../../common/header';
import ModalPop from '../../common/Modal';
import { commonImages } from '../../themes/appImage';
import { roomSuccessAction } from '../../redux/actions/homeActions/homeActions';
import { url } from '../../redux/axios/apikit';
import { isInternetConnected } from '../../helper/networkUtils';
import { goBack } from '../../Routes/navigationServices';
import { getLeaveStatus } from '../../helper/globalFunctions';
import ApiConstants from '../../themes/apiConstants';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import history from '../../Routes/history'
import { DataManager } from '../../helper/dataManager';
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import strings from '../../themes/constants/strings'
import GameRuleModal from '../../common/ruleModal';
import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';

let intervalId;
let globleTimer = 0;

const GameLobby = (props) => {
  const dispatch = useDispatch()
  const roomData = useSelector((state) => state.homeReducer.roomData);
  const leaveStatus = useSelector((state) => state.homeReducer.leaveGamePopup);
  const userId = useSelector((state) => state.authenticationReducer.userId);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSuccessVisible, setIsModalSuccessVisible] = useState(false);
  const [isModalRuleVisible, setIsModalRuleVisible] = useState(false);
  const [timer, setTimer] = useState('00:00:00');
  useEffect(() => {
    history.listen(location => {
      console.log("this is listener = ", location)
      DataManager.setLeaveGameStatus(true)
    })
  }, [])

  useEffect(() => {
    if (DataManager.getLeaveGameStatus()) {
      showModal()
    }
  }, [DataManager.getLeaveGameStatus()])


  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    socket.on('roomPlayerList', getRoomPlayerList);
    return () => {
      socket.off('roomPlayerList', getRoomPlayerList);
    }
  }, [])


  useEffect(() => {
    if (roomData) {
      globleTimer = moment.utc(new Date).diff(moment.utc(roomData?.createdOn), 'seconds')
      intervalId = setInterval(() => {
        globleTimer++

        let totalSeconds = globleTimer;
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        minutes = minutes < 10 ? `0${minutes}` : minutes
        let seconds = totalSeconds % 60;
        seconds = seconds < 10 ? `0${seconds}` : seconds
        setTimer(`0${hours}:${minutes}:${seconds}`)
      }, 1000)
    }
    return () => {
      clearInterval(intervalId);
    }
  }, [roomData])

  const getRoomPlayerList = (data) => {
    dispatch(roomSuccessAction(data.roomDetail))
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    DataManager.removeLeaveGameStatus()
  };

  const showModalSuccess = () => {
    setIsModalSuccessVisible(true);
  };

  const handleOkSuccess = () => {
    setIsModalSuccessVisible(false);
  };

  const handleCancelSuccess = () => {
    setIsModalSuccessVisible(false);
  };

  const successBtn = (
    <>
      <button className='forgot_text' onClick={async () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
          DataManager.deleteCurrentRount()
          goBack()
          socket.emit('selfExit');
          dispatch({ type: ApiConstants.IS_SPECTATOR, payload: false })
          dispatch({ type: ApiConstants.REST_HOME })
        }
        else {
          ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        }
      }}>
        <img src={commonImages.yes} className="send_new" />
      </button>
    </>
  )

  const fragment = (
    <>
      <div className='main_pop pops'>
        <div className='img_y alert_icons'>
          <img src={commonImages.alert} />
        </div>
        <div>
          <div className='form-group success_d leave-pop'>
            <p className='sure_delete'> {roomData?.host === userId ? strings?.areYouSureHost : strings?.areYouSure}</p>
          </div>
        </div>
      </div>
    </>
  )
  const successmessage = (
    <>
      <div className='main_pop spectarule'>
        {roomData?.spectators && roomData?.spectators.length > 0 ? (

          <div className='scroll_div'>
            {console.log("this is = ", roomData)}
            {roomData?.spectators.map((item, index) => {
              return (
                <div className='inner_room'>
                  <img style={{ height: '60px', width: '60px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }} src={`${url}${item?.profileImage}`} />
                  <span>{item?.name}</span>
                </div>
              )
            })}
          </div>) : (
          <div className='no-data-found diff_stl' style={{ minHeight: '300px' }}>{strings?.noSpectators}</div>
        )}

      </div>
    </>
  )

  return (
    <div style={{
      overflowY: 'scroll'
    }}>
      <div className='background_image'>
        <Header title='Game Lobby' settings={false} redirect={true} setIsModalRuleVisible={setIsModalRuleVisible} modal />

        <div className='inner_box box'>
          <div className='room-code'>
            <h2>{`Room Code: ${(roomData?.type === 'Public') || (roomData?.host === userId) ? roomData?.roomId : ' **********'}`}</h2>
            {((roomData?.host === userId) || (roomData?.type === 'Public')) && <ButtonCustom
              resize={"cover"}
              outerContainer={{ height: 49, alignSelf: 'center', cursor: 'pointer' }}
              textColor={{ fontSize: fontSizes.f45, padding: 2 }}
              style={{ height: 49, width: 180, borderRadius: 4 }}
              // iContainer={{ height: 49 }}
              imgBg={appImages.buttonBg}
              title={'Share Room Code'}
              className="send_new"
              onPress={() => {
                if (isInternetConnected()) {
                  const message = `Join me on the Disparity Trap board game mobile app! We can play Disparity Trap with my room code: ${roomData?.roomId}, and you can download the app here:\n\nApple Store:\n${ApiConstants.iosAppLink}\n\nGoogle Play Store:\n${ApiConstants.androidAppLink}\n\nWeb Browser:\n${ApiConstants.webLink}.`
                  navigator.clipboard.writeText(message);
                  ShowAlertMessage('Message copied successfully.', popupType.info, 3000);
                } else {
                  ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                }
              }}
            />
            }
          </div>
          <h3 className='rooms pb-2'>{`Joined Users: ${roomData?.players.length}/${roomData?.maxPlayers}`}</h3>
          <h3 className='rooms'><span style={{ cursor: 'pointer' }} onClick={showModalSuccess}>{`Spectators: ${roomData?.spectators ? roomData?.spectators?.length : 0}`}</span></h3>
        </div>

        <div className='room lobby'>
          <div className='scroll_div'>
            {
              roomData?.players && roomData?.players.length > 0 ? (
                roomData.players.map((item, index) => {
                  return (
                    <div className='inner_room'>
                      {console.log("this is data = ", roomData?.players)}
                      <div className='first_join home'>
                        <img style={{ height: '60px', width: '60px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }} src={`${url}${item.user.profileImage}`} />
                        <div className='hostname'>
                          <h4>{item?.user?.name}</h4>
                        </div>
                        <div className='game1'>
                          {(roomData?.host === userId && roomData?.mode === 'Manual') ? (
                            <span className='button-switch button-switch_fixed'>
                              {
                                item.role === 'SD' ?
                                  <button type='submit' className={item.role === 'SD' ? 'button_switch itemlast one' : 'button_switch itemlast'}>
                                    SD
                                  </button> :
                                  <button
                                    type='submit'
                                    onClick={() => {
                                      if (isInternetConnected()) {
                                        if (roomData?.host === userId) {
                                          socket.emit('assignRole', { playerId: item.user._id, role: item.role === 'SD' ? 'SND' : 'SD' })
                                        }
                                      } else {
                                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
                                      }
                                    }}
                                    className={item.role === 'SD' ? 'button_switch itemlast one' : 'button_switch itemlast'}
                                  >
                                    SD
                                  </button>
                              }
                              {
                                item.role !== 'SD' ?
                                  <button type='submit' className={item.role === 'SND' ? 'button_switch itemlast one' : 'button_switch itemlast'}>
                                    SND
                                  </button> :
                                  <button
                                    type='submit'
                                    onClick={() => {
                                      if (isInternetConnected()) {
                                        if (roomData?.host === userId) {
                                          socket.emit('assignRole', { playerId: item.user._id, role: item.role === 'SD' ? 'SND' : 'SD' })

                                        }
                                      } else {
                                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
                                      }
                                    }}
                                    className={item.role === 'SND' ? 'button_switch itemlast one' : 'button_switch itemlast'}
                                  >
                                    SND
                                  </button>
                              }
                            </span>
                          ) : (
                            <span className='button-switch'>
                              <button type='submit' className={'button_single itemlast'}>{item?.role}</button>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })

              ) : (
                null
              )
            }


          </div>

        </div>
        <div className='line_clock'>
          <div className='clock'>
            <img src={commonImages.clock} />
            <span>{timer}</span>
          </div>
          <div className='wait'><p>{(roomData?.players.length === roomData?.maxPlayers) ? '' : 'Waiting for the players to join...'}</p></div>
          <div className='data_new game_lobbies'>
            <div>
              {roomData?.host === userId &&
                <img
                  src={commonImages.startgame}
                  alt=""
                  onClick={() => {
                    const checkAllowed = getLeaveStatus()

                    if (isInternetConnected() && checkAllowed) {
                      if (roomData?.mode === 'Manual') {
                        const sdCount = roomData?.players.filter((item) => item.role === 'SD').length
                        const sndCount = roomData?.players.filter((item) => item.role === 'SND').length
                        const diff = sndCount - sdCount
                        if (diff === 0 || diff === 1) {
                          DataManager.setCurrentRoute('Game')
                          socket.emit('startMatch');
                        }
                        else if (roomData?.players.length < 2) {
                          ShowAlertMessage("Minimum 2 players are required to start the game.", popupType.error, 3000)
                        }
                        else {
                          ShowAlertMessage("SD or SND division should be equal or SND count should be greater than 1 from SD in case of odd no.", popupType.error, 3000)
                        }
                      }
                      else {
                        socket.emit('startMatch');
                      }
                    }
                    else {
                      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                    }

                  }}
                />}
            </div>
            <div>
              <img onClick={showModal} src={commonImages.leave} alt="" />
            </div>
          </div>

        </div>
      </div>
      {console.log("this is success = ", isModalVisible, leaveStatus)}
      {isModalVisible && <ModalPop

        content={fragment}
        btnSuccess={successBtn}
        btnCancel={true}
        handleSuccess={handleOk}
        handleCancel={handleCancel}
        isVisible={isModalVisible}

      />}
      {
        isModalSuccessVisible &&
        <ModalPop

          content={successmessage}
          // btnSuccess={successBtnOne}
          btnCancel={true}
          handleSuccess={handleOkSuccess}
          handleCancel={handleCancelSuccess}
          isVisible={isModalSuccessVisible}
          center='lobby_modal'

        />
      }
      {isModalRuleVisible && <ModalPop
        className='center'
        content={<GameRuleModal gameLobby />}
        btnSuccess={false}
        btnCancel={false}
        handleSuccess={() => setIsModalRuleVisible(false)}
        handleCancel={() => setIsModalRuleVisible(false)}
        isVisible={isModalRuleVisible}

      />
      }

    </div>
  )

}
export default GameLobby