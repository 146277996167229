import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Header from '../../../common/header';
import Linkify from 'react-linkify';
import { isInternetConnected } from '../../../helper/networkUtils';
import { ShowAlertMessage } from '../../../helper/showAlertMessage';
import { popupType } from '../../../themes/constants/gameConstants';
import { faqAction } from '../../../redux/actions/homeActions/homeActions';
import { ValidationConstants } from '../../../themes/constants/validationConstants';


const FAQ = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)

        if (isInternetConnected()) {
            dispatch(faqAction());
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
        }


    }, [])

    const faq = useSelector((state) => state.homeReducer.faq);

    return (
        <div>
            <div className='background_image space-bottom '>
                <Header title='FAQ' settings={false} redirect={true} />
                <div className='faq_page' style={{
                    background: 'Linear-gradient(-109deg, rgba(39, 40, 40, 0.99608) 0%, rgb(46, 47, 47) 100%)'
                }}>
                    {
                        faq && faq.length > 0 ? (
                            faq.map((item, index) => {
                                return (
                                    <div style={{
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: '#2e8f81',
                                        borderRadius: 8,
                                        marginBottom: (index === faq.length - 1) ? 0 : 12,
                                        overflow: 'hidden'
                                    }}>
                                        <div className='faq_inner'>{item?.question}</div>
                                        <p><Linkify>{item?.answer}</Linkify></p>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='no-data-found'><div className='no_data_found_child'>No data available.</div></div>
                        )
                    }

                </div>

            </div>
        </div>




    )

}
export default FAQ