import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'antd'

import Header from '../../common/header';
import ModalPop from '../../common/Modal';
import InputField from '../../common/textFiled';
import { commonImages } from '../../themes/appImage';
import { trimText } from '../../utils/validators/validator';
import { isInternetConnected } from '../../helper/networkUtils';
import { ValidationConstants } from '../../themes/constants/validationConstants'
import { redeemVoucherAction } from '../../redux/actions/homeActions/homeActions';
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import strings from '../../themes/constants/strings';

import CheckoutForm from './CheckOutForm';
import ApiConstants from '../../themes/apiConstants';
import Spacer from '../game/Spacer';
import styles from './styles';
import { textColor } from '../../themes/colors/colors';
import { spacing } from '../../themes/constants/spacing';
import { navigate } from '../../Routes/navigationServices';
import ButtonCustom from '../game/ButtonCustom';
import fontSizes from '../../themes/constants/fontSizes';
import appImages from '../../themes/appImages/appImages';

// pk_test_51LFz2hLVkCAXTprT2wHgEirwualwPjJLO9Lm7enDTEwzFtN03LvfNwxexkZFYAWarwUj31dD05QrmL2S1C7dBPQv00W09TRWWj
// pk_live_51LFz2hLVkCAXTprTiydC0raaQ6msjSQ8XWroNlM3pPsvysMCh05QNhChCh3m3a28ia7yhiw00WqUQVAxxujz7xEV00yjI3PzRL
const stripePromise = loadStripe('pk_live_51LFz2hLVkCAXTprTiydC0raaQ6msjSQ8XWroNlM3pPsvysMCh05QNhChCh3m3a28ia7yhiw00WqUQVAxxujz7xEV00yjI3PzRL');

const Package = (props) => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [voucherCode, setVoucherCode] = useState('')

  const isPurchased = useSelector((state) => state.homeReducer.isPurchased);

  React.useEffect(() => {
    dispatch({
      type: ApiConstants.API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD,
      payload: { transactionId: null, receipt: null, type: 'web' }
    });
  }, [])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (isInternetConnected) {
      if (voucherCode) {
        dispatch(redeemVoucherAction({ voucherCode: voucherCode }));
        setIsModalVisible(false);
        setVoucherCode('')
      } else {
        ShowAlertMessage(ValidationConstants.enterVoucher, popupType.error, 3000);
      }
    }
    else {
      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVoucherCode('')
  };

  const successBtn = (
    <>
      <button className='forgot_text' onClick={() => {
        handleOk()
      }}>
        <img src={commonImages.submit} className="send_new" />
      </button>
    </>
  )

  const fragment = (
    <>
      <div className='main_pop spaceing_main'>
        <div className='img_y alert_icons'>
          <img src={commonImages.crown} className='icons_u' />
        </div>
        <div>
          <div className='form-group'>
            <label>Enter Voucher Code</label>
            <InputField
              type='text'
              placeholder="Enter Voucher Code"
              width='70%'
              height='35px'
              className='formcontrol'
              value={trimText(voucherCode)}
              onChange={(e) => {
                let value = trimText(e.target.value);
                setVoucherCode(value)
              }}
            />
          </div>
          {/* <img src={commonImages.send} onClick={() => showModal()} className="send_new"/> */}

        </div>
      </div>
    </>
  )

  // console.log(props, 'props--------------')

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
      <div className='background_image'>
        <Header title='In-App Purchase' settings={false} redirect={true} />
        <div style={{ marginLeft: 50, marginRight: 50, }}>
          <div style={styles.textContainer}>
            <Spacer height={spacing.scH2cent} />
            <span style={styles.fontTextDone}>
              {"• "}
              <span style={styles.fontTextHeading}>{"After the purchase of this package you will be able to create and host new games, in either a private or public game room, for "}</span>
              <span style={{ ...styles.fontTextHeading, color: textColor.green }}>{`1 year.\n\n`}</span>
              <span style={styles.fontTextHeading}>{""}</span>
            </span>
            <Spacer height={spacing.scH2cent} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ ...styles.fontTextDone, paddingRight: 4 }}>
                {"• "}
              </span>
              <span style={{ ...styles.fontTextHeading, marginTop: 4 }}>{"The subscription charges will recur annually and can be canceled at any time. When you complete the purchase of the annual subscription, the payment will be charged to the debit/credit card you will provide. And your subscription can be canceled through the 'Cancel Subscription' button click. Subscriptions will be automatically renewed for the next year at the subscription price listed at the time of renewal. By purchasing a subscription you are agreeing to the app's terms and conditions."}</span>

            </div>

            <Spacer height={spacing.scH2cent} />
          </div>
          <Spacer height={spacing.scH2cent} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
            <span onClick={() => navigate('terms')} style={{ ...styles.fontTextHeading, textDecorationLine: 'underline', paddingLeft: 41, cursor: 'pointer' }}>{strings.termsAndConditions}</span>
            <span onClick={() => navigate('privacy')} style={{ ...styles.fontTextHeading, textDecorationLine: 'underline', paddingRight: 31, cursor: 'pointer' }}>{strings.privacyPolicy}</span>
          </div>
          <Spacer height={spacing.scH2cent} />
        </div>
        <div className='package_data'>
          <img src={commonImages.package12} className='big_img' />
          <div className="price">
            <p>$14.99</p>
          </div>
          <div className='purchase button'>
            {isPurchased?.autoRenewActive ?
              <div>
                <ButtonCustom
                  resize={"cover"}
                  outerContainer={{ height: 49, alignSelf: 'center', cursor: 'pointer' }}
                  textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                  style={{ height: 49, width: 220, borderRadius: 4 }}
                  imgBg={appImages.buttonBg}
                  title={'Cancel Subscription'}
                  className="send_new"
                  onPress={() => dispatch({ type: ApiConstants.API_CANCEL_SUBSCRIPTION_LOAD })}
                />
              </div>
              : <>
                <div>
                  <img src={commonImages.purchase} onClick={() => {
                    if (isInternetConnected()) {
                      if (isPurchased?.isAvailable) {
                        ShowAlertMessage(ValidationConstants.purchaseValidation, popupType.info)
                      }
                      else {
                        setIsPaymentModal(true)
                      }
                    }
                    else {
                      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                    }
                  }} alt="" />
                </div>
                <div>
                  <img src={commonImages.usecode} onClick={showModal} alt="" />
                </div>
              </>}

          </div>
        </div>
      </div>
      {
        isModalVisible && <ModalPop

          content={fragment}
          btnSuccess={successBtn}
          btnCancel={true}
          handleSuccess={handleOk}
          handleCancel={handleCancel}
          isVisible={isModalVisible}

        />
      }
      {
        isPaymentModal && <Modal
          centered
          className="sharemodal loginer Confirmationseasons"
          width={900}
          visible={isPaymentModal}
          footer={null}
          onOk={() => setIsPaymentModal(false)}
          onCancel={() => setIsPaymentModal(false)}
        >
          <div >
            <Elements
              stripe={stripePromise}
              options={{
                appearance: {
                  theme: 'none',
                },
              }}
            >
              <CheckoutForm
                detailType="buy package"
                closeModal={() => setIsPaymentModal(false)}
              />
            </Elements>
          </div>
        </Modal>
      }
    </div >
  )

}
export default Package