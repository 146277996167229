export const SelectCardSubmoissionType = {
    earn: 'earn',
    discard: 'discard',
    opportunity: 'opportunity'
};

export const RollDiceType = {
    turnSelect: 'turnSelect',
    vote: 'vote',
    opportunity: 'opportunity',
    newTier: 'newTier',
};

export const backFromSelectCardType = {
    earn: 'earn',
    discard: 'discard',
    opportunity: 'opportunity'
};

export const tileNames = {
    skill: 'skill',
    degree: 'degree',
    startUp: 'startUp',
    job: 'job',
    home: 'home',
    business: 'business',
    realEstate: 'realEstate',
    power: 'power',
    justice: 'justice',
    privilege: 'privilege',
};

export const dialogNames = {
    notQualified: 'notQualified',
    usePowerToken: 'usePowerToken',
    close: 'close',
    readyToPlay: 'readyToPlay',
    notEnoughSpace: 'notEnoughSpace',
    useInfluence: 'useInfluence',
    useYourInfluenceWithPower: 'useYourInfluenceWithPower',
    usePowerTokenBtnTwo: 'usePowerTokenBtnTwo',
    useYourInfluenceWithPrivilege: 'useYourInfluenceWithPrivilege',
    usePowerTokenBtnThree: 'usePowerTokenBtnThree',
    rollDiceToPlay: 'rollDiceToPlay',
    rollDiceToPlayWithoutBack: 'rollDiceToPlayWithoutBack',
    useInfluenceOtherWithPower: 'useInfluenceOtherWithPower',
    wouldYouLikeTo: 'wouldYouLikeTo',
    useInfluenceOtherWithPrivilege: 'useInfluenceOtherWithPrivilege',
    wantAnotherChanceWithPrivilegeThree: 'wantAnotherChanceWithPrivilegeThree',
    wantAnotherChanceWithPrivilegeTwo: 'wantAnotherChanceWithPrivilegeTwo',
    wantAnotherChanceWithPower: 'wantAnotherChanceWithPower',
    wantToGiveWithPower: 'wantToGiveWithPower',
    wantToGiveWithPrivilegeThree: 'wantToGiveWithPrivilegeThree',
    wantToGiveWithPrivilegeTwo: 'wantToGiveWithPrivilegeTwo',
    usePowerTokenForTaxBreak: 'usePowerTokenForTaxBreak',
    deflectOutcomeForYourself: 'deflectOutcomeForYourself',
    deflectOutcomeForAnotherPlayer: 'deflectOutcomeForAnotherPlayer',
    playCard: 'playCard',

};
export const influenceType = {
    power: 'power',
    privilege: 'privilege',
};

export const influenceAction = {
    no: 'no',
    add: 'add',
    take: 'take',
    own: 'own',
    other: 'other'
};

export const popupType = {
    info: 'info',
    error: 'error',
};

export const gameScreen = {
    screen1: 'screen1',
    screen2: 'screen2',
    sacrifice: 'sacrifice',
    tradeTile: 'tradeTile',
    selectVoteTile: 'selectVoteTile',
    selectOpportunityCard: 'selectOpportunityCard',
    discardCard: 'discardCard'
};