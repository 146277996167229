import { put, call, takeLatest } from "redux-saga/effects";
import { aboutUsApi, createRoomApi, faqApi, leaderBoardApi, privacyPolicyApi, redeemVoucher, updateProfileApi, buyPackage, checkForSubsApi, termsAndCondApi, cancelSubscriptionApi, soloPlayApi } from "../axios/axiosApi";
import ApiConstants from '../../themes/apiConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { goBack, navigate } from '../../Routes/navigationServices';
import { logout } from "../../helper/globalFunctions";
import { ValidationConstants } from "../../themes/constants/validationConstants";
import { popupType } from "../../themes/constants/gameConstants";

function* createRoomSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(createRoomApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            if (result.data.inAppPurchase) {
                yield put({
                    type: ApiConstants.API_CREATE_ROOM_SUCCESS,
                    data: result.data.roomDetail,
                });
                navigate('GameLobby');
            }
            else {
                ShowAlertMessage(result.message, popupType.error, 3000);
                navigate('package');
            }
        }
        else if (status == 2) {
            logout(result.msg)
        }
        else {
            ShowAlertMessage(result.msg, popupType.error, 3000);
            yield put({ type: ApiConstants.API_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_ERROR });
    }
}

function* soloPlaySaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(soloPlayApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        // if (status === 1) {
            
        // }
        // else 
        if (status == 2) {
            logout(result.msg)
        }
        else {
            ShowAlertMessage(result.msg, popupType.error, 3000);
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
    }
}

function* redeemVoucherSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(redeemVoucher, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_REEDEEM_VOUCHER_SUCCESS,
                data: result
            });
            goBack();
            ShowAlertMessage(result.message, popupType.info, 3000);
        }
        else if (status == 2) {
            logout(result.msg);
        }
        else {
            ShowAlertMessage(result?.msg, popupType.error, 3000);
            yield put({ type: ApiConstants.API_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.info, 3000);
        yield put({ type: ApiConstants.API_ERROR });
    }
}

function* apiAboutUsSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(aboutUsApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_ABOUT_US_SUCCESS,
                result: result?.data,
            });
        } else {
            yield put({ type: ApiConstants.API_ABOUT_US_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        yield put({ type: ApiConstants.API_ABOUT_US_ERROR });
    }
}
function* apiPrivacyPolicySaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(privacyPolicyApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_PRIVACY_POLICY_SUCCESS,
                result: result?.data,
            });
        } else {
            yield put({ type: ApiConstants.API_PRIVACY_POLICY_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        yield put({ type: ApiConstants.API_PRIVACY_POLICY_ERROR });
    }
}

function* apiFaqSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(faqApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        console.log("result: " + JSON.stringify(result));
        if (status === 1) {
            yield put({
                type: ApiConstants.API_FAQ_SUCCESS,
                result: result?.data,
            });
        } else {
            yield put({ type: ApiConstants.API_FAQ_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        yield put({ type: ApiConstants.API_FAQ_ERROR });
    }
}

//Leader Board Saga
function* apiLeaderBoardSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(leaderBoardApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_LEADER_BOARD_SUCCESS,
                result: result?.data,
            });
        } else {
            yield put({ type: ApiConstants.API_LEADER_BOARD_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        yield put({ type: ApiConstants.API_LEADER_BOARD_ERROR });
    }
}

function* apiUpdateProfileSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(updateProfileApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        console.log("result: " + JSON.stringify(result));
        if (status === 1) {
            yield put({
                type: ApiConstants.API_UPDATE_PROFILE_SUCCESS,
                result: result,
            });
            ShowAlertMessage(result.message, popupType.info)
            goBack();
        } else {
            ShowAlertMessage(result.msg, popupType.error)
            yield put({ type: ApiConstants.API_UPDATE_PROFILE_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error)
        yield put({ type: ApiConstants.API_UPDATE_PROFILE_ERROR });
    }
}

function* buyPackageSaga(action) {
    try {
        let response = yield call(buyPackage, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            ShowAlertMessage(result?.message, popupType.info, 3000);
            navigate(-1);
        }
        else if (status == 2) {
            logout(result.msg)
        }
        else {
            ShowAlertMessage(result.msg, popupType.error, 3000);
            yield put({ type: ApiConstants.API_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_ERROR });
    }
}

function* apiCheckForAnySubscriptionSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(checkForSubsApi, action.payload);
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        let { result, status } = response;
        if (status === 1) {
            yield put({ type: ApiConstants.API_CHECK_FOR_ANY_SUBSCRIPTION_SUCCESS, payload: result.data });
        } else {
            ShowAlertMessage(result.msg, popupType.error)
            yield put({ type: ApiConstants.API_CHECK_FOR_ANY_SUBSCRIPTION_SUCCESS, payload: false });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error)
        navigate(-1)
    }
}

//Terms And conditions saga
function* apiTermsAndCondSaga() {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(termsAndCondApi);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            yield put({
                type: ApiConstants.API_T_AND_C_SUCCESS,
                result: result?.data,
            });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
    }
}


function* cancelSubscriptionSaga(action) {
    try {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: true
        })
        let response = yield call(cancelSubscriptionApi, action.payload);
        let { result, status } = response;
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        if (status === 1) {
            ShowAlertMessage(result?.message, popupType.info, 3000);
            navigate(-1);
        }
        else if (status == 2) {
            logout(result.msg)
        }
        else {
            ShowAlertMessage(result.msg, popupType.error, 3000);
            yield put({ type: ApiConstants.API_FAIL });
        }
    } catch (error) {
        yield put({
            type: ApiConstants.UPDATE_LODING_STATE,
            data: false
        })
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
        yield put({ type: ApiConstants.API_ERROR });
    }
}


export default function* rootHomeSaga() {
    yield takeLatest(ApiConstants.API_SOLO_PLAY_LOAD, soloPlaySaga);
    yield takeLatest(ApiConstants.API_CREATE_ROOM_LOAD, createRoomSaga);
    yield takeLatest(ApiConstants.API_REEDEEM_VOUCHER_LOAD, redeemVoucherSaga);
    yield takeLatest(ApiConstants.API_ABOUT_US_LOAD, apiAboutUsSaga);
    yield takeLatest(ApiConstants.API_PRIVACY_POLICY_LOAD, apiPrivacyPolicySaga);
    yield takeLatest(ApiConstants.API_FAQ_LOAD, apiFaqSaga);
    yield takeLatest(ApiConstants.API_LEADER_BOARD_LOAD, apiLeaderBoardSaga);
    yield takeLatest(ApiConstants.API_UPDATE_PROFILE_LOAD, apiUpdateProfileSaga);
    yield takeLatest(ApiConstants.API_BUY_PACKAGE_LOAD, buyPackageSaga);
    yield takeLatest(ApiConstants.API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD, apiCheckForAnySubscriptionSaga);
    yield takeLatest(ApiConstants.API_T_AND_C_LOAD, apiTermsAndCondSaga);
    yield takeLatest(ApiConstants.API_CANCEL_SUBSCRIPTION_LOAD, cancelSubscriptionSaga);
}