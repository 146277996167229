import { gameScreenDimentions } from "../helper/globalFunctions";


export const pawn1Array = [
    //For Pawn one.
    { id: 0, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.068, y: -gameScreenDimentions.gameBoardWidth * 0.005 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.13, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.185, y: gameScreenDimentions.gameBoardWidth * 0.031 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.245, y: gameScreenDimentions.gameBoardWidth * 0.052 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.304, y: gameScreenDimentions.gameBoardWidth * 0.074 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.355, y: gameScreenDimentions.gameBoardWidth * 0.077 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.465, y: gameScreenDimentions.gameBoardWidth * 0.04 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.495, y: gameScreenDimentions.gameBoardWidth * 0.105 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.515, y: gameScreenDimentions.gameBoardWidth * 0.153 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.535, y: gameScreenDimentions.gameBoardWidth * 0.215 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.531, y: gameScreenDimentions.gameBoardWidth * 0.28 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.522, y: gameScreenDimentions.gameBoardWidth * 0.33 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.512, y: gameScreenDimentions.gameBoardWidth * 0.416 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.465, y: gameScreenDimentions.gameBoardWidth * 0.448 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.38, y: gameScreenDimentions.gameBoardWidth * 0.48 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.31, y: gameScreenDimentions.gameBoardWidth * 0.485 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.25, y: gameScreenDimentions.gameBoardWidth * 0.489 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.18, y: gameScreenDimentions.gameBoardWidth * 0.492 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.12, y: gameScreenDimentions.gameBoardWidth * 0.46 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.078, y: gameScreenDimentions.gameBoardWidth * 0.423 },
    { id: 21, x: gameScreenDimentions.gameBoardWidth * 0.04, y: gameScreenDimentions.gameBoardWidth * 0.394 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.015, y: gameScreenDimentions.gameBoardWidth * 0.395 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.072, y: gameScreenDimentions.gameBoardWidth * 0.392 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.15, y: gameScreenDimentions.gameBoardWidth * 0.385 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.263, y: gameScreenDimentions.gameBoardWidth * 0.365 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.27, y: gameScreenDimentions.gameBoardWidth * 0.3 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.275, y: gameScreenDimentions.gameBoardWidth * 0.235 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.276, y: gameScreenDimentions.gameBoardWidth * 0.18 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.278, y: gameScreenDimentions.gameBoardWidth * 0.12 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.29, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.218, y: gameScreenDimentions.gameBoardWidth * 0.016 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.147, y: gameScreenDimentions.gameBoardWidth * 0.012 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.085, y: gameScreenDimentions.gameBoardWidth * 0.008 },
    { id: 34, x: 0, y: 0 },
];
export const pawn2Array = [
    //For pawn 2.
    { id: 0, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.068, y: gameScreenDimentions.gameBoardWidth * 0.0 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.13, y: gameScreenDimentions.gameBoardWidth * 0.019 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.186, y: gameScreenDimentions.gameBoardWidth * 0.040 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.25, y: gameScreenDimentions.gameBoardWidth * 0.06 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.3, y: gameScreenDimentions.gameBoardWidth * 0.08 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.36, y: gameScreenDimentions.gameBoardWidth * 0.065 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.455, y: gameScreenDimentions.gameBoardWidth * 0.07 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.478, y: gameScreenDimentions.gameBoardWidth * 0.124 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.5, y: gameScreenDimentions.gameBoardWidth * 0.178 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.509, y: gameScreenDimentions.gameBoardWidth * 0.253 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.502, y: gameScreenDimentions.gameBoardWidth * 0.301 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.495, y: gameScreenDimentions.gameBoardWidth * 0.364 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.467, y: gameScreenDimentions.gameBoardWidth * 0.427 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.418, y: gameScreenDimentions.gameBoardWidth * 0.462 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.323, y: gameScreenDimentions.gameBoardWidth * 0.482 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.263, y: gameScreenDimentions.gameBoardWidth * 0.485 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.198, y: gameScreenDimentions.gameBoardWidth * 0.489 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.13, y: gameScreenDimentions.gameBoardWidth * 0.488 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.07, y: gameScreenDimentions.gameBoardWidth * 0.445 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.035, y: gameScreenDimentions.gameBoardWidth * 0.42 },
    { id: 21, x: -gameScreenDimentions.gameBoardWidth * 0.01, y: gameScreenDimentions.gameBoardWidth * 0.398 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.062, y: gameScreenDimentions.gameBoardWidth * 0.395 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.123, y: gameScreenDimentions.gameBoardWidth * 0.391 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.213, y: gameScreenDimentions.gameBoardWidth * 0.382 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.293, y: gameScreenDimentions.gameBoardWidth * 0.335 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.3, y: gameScreenDimentions.gameBoardWidth * 0.27 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.302, y: gameScreenDimentions.gameBoardWidth * 0.218 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.302, y: gameScreenDimentions.gameBoardWidth * 0.1555 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.305, y: gameScreenDimentions.gameBoardWidth * 0.095 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.296, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.213, y: gameScreenDimentions.gameBoardWidth * 0.015 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.15, y: gameScreenDimentions.gameBoardWidth * 0.013 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.085, y: gameScreenDimentions.gameBoardWidth * 0.008 },
    { id: 34, x: 0, y: 0 },];
export const pawn3Array = [
    //For pawn 3.
    { id: 0, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.060, y: -gameScreenDimentions.gameBoardWidth * 0.005 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.108, y: gameScreenDimentions.gameBoardWidth * 0.008 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.165, y: gameScreenDimentions.gameBoardWidth * 0.026 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.218, y: gameScreenDimentions.gameBoardWidth * 0.047 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.28, y: gameScreenDimentions.gameBoardWidth * 0.07 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.361, y: gameScreenDimentions.gameBoardWidth * 0.078 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.428, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.458, y: gameScreenDimentions.gameBoardWidth * 0.078 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.478, y: gameScreenDimentions.gameBoardWidth * 0.129 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.495, y: gameScreenDimentions.gameBoardWidth * 0.183 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.491, y: gameScreenDimentions.gameBoardWidth * 0.23 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.484, y: gameScreenDimentions.gameBoardWidth * 0.285 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.48, y: gameScreenDimentions.gameBoardWidth * 0.338 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.444, y: gameScreenDimentions.gameBoardWidth * 0.37 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.382, y: gameScreenDimentions.gameBoardWidth * 0.394 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.313, y: gameScreenDimentions.gameBoardWidth * 0.398 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.258, y: gameScreenDimentions.gameBoardWidth * 0.402 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.195, y: gameScreenDimentions.gameBoardWidth * 0.405 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.15, y: gameScreenDimentions.gameBoardWidth * 0.382 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.11, y: gameScreenDimentions.gameBoardWidth * 0.35 },
    { id: 21, x: gameScreenDimentions.gameBoardWidth * 0.053, y: gameScreenDimentions.gameBoardWidth * 0.305 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.01, y: gameScreenDimentions.gameBoardWidth * 0.302 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.069, y: gameScreenDimentions.gameBoardWidth * 0.3 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.14, y: gameScreenDimentions.gameBoardWidth * 0.298 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.218, y: gameScreenDimentions.gameBoardWidth * 0.332 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.215, y: gameScreenDimentions.gameBoardWidth * 0.25 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.218, y: gameScreenDimentions.gameBoardWidth * 0.195 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.224, y: gameScreenDimentions.gameBoardWidth * 0.126 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.226, y: gameScreenDimentions.gameBoardWidth * 0.07 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.282, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.206, y: gameScreenDimentions.gameBoardWidth * 0.015 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.145, y: gameScreenDimentions.gameBoardWidth * 0.011 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.089, y: gameScreenDimentions.gameBoardWidth * 0.006 },
    { id: 34, x: 0, y: 0 }
];
export const pawn4Array = [
    //For pawn 4.
    { id: 21, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.060, y: -gameScreenDimentions.gameBoardWidth * 0.005 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.119, y: gameScreenDimentions.gameBoardWidth * 0.019 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.17, y: gameScreenDimentions.gameBoardWidth * 0.037 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.232, y: gameScreenDimentions.gameBoardWidth * 0.06 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.309, y: gameScreenDimentions.gameBoardWidth * 0.085 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.385, y: gameScreenDimentions.gameBoardWidth * 0.062 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.428, y: gameScreenDimentions.gameBoardWidth * 0.05 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.449, y: gameScreenDimentions.gameBoardWidth * 0.105 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.471, y: gameScreenDimentions.gameBoardWidth * 0.155 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.475, y: gameScreenDimentions.gameBoardWidth * 0.204 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.469, y: gameScreenDimentions.gameBoardWidth * 0.259 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.464, y: gameScreenDimentions.gameBoardWidth * 0.313 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.45, y: gameScreenDimentions.gameBoardWidth * 0.358 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.4125, y: gameScreenDimentions.gameBoardWidth * 0.38 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.327, y: gameScreenDimentions.gameBoardWidth * 0.397 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.269, y: gameScreenDimentions.gameBoardWidth * 0.401 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.207, y: gameScreenDimentions.gameBoardWidth * 0.405 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.153, y: gameScreenDimentions.gameBoardWidth * 0.405 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.115, y: gameScreenDimentions.gameBoardWidth * 0.37 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.07, y: gameScreenDimentions.gameBoardWidth * 0.335 },
    { id: 21, x: gameScreenDimentions.gameBoardWidth * 0.01, y: gameScreenDimentions.gameBoardWidth * 0.306 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.054, y: gameScreenDimentions.gameBoardWidth * 0.301 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.11, y: gameScreenDimentions.gameBoardWidth * 0.301 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.195, y: gameScreenDimentions.gameBoardWidth * 0.296 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.23, y: gameScreenDimentions.gameBoardWidth * 0.28 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.235, y: gameScreenDimentions.gameBoardWidth * 0.225 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.238, y: gameScreenDimentions.gameBoardWidth * 0.158 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.243, y: gameScreenDimentions.gameBoardWidth * 0.105 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.246, y: gameScreenDimentions.gameBoardWidth * 0.05 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.255, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.203, y: gameScreenDimentions.gameBoardWidth * 0.015 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.138, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.082, y: gameScreenDimentions.gameBoardWidth * 0.007 },
    { id: 34, x: 0, y: 0 },];
export const pawn5Array = [
    //For pawn 5.
    { id: 21, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.060, y: -gameScreenDimentions.gameBoardWidth * 0.005 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.105, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.162, y: gameScreenDimentions.gameBoardWidth * 0.028 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.22, y: gameScreenDimentions.gameBoardWidth * 0.05 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.28, y: gameScreenDimentions.gameBoardWidth * 0.072 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.345, y: gameScreenDimentions.gameBoardWidth * 0.079 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.433, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.467, y: gameScreenDimentions.gameBoardWidth * 0.097 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.484, y: gameScreenDimentions.gameBoardWidth * 0.138 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.505, y: gameScreenDimentions.gameBoardWidth * 0.193 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.498, y: gameScreenDimentions.gameBoardWidth * 0.253 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.493, y: gameScreenDimentions.gameBoardWidth * 0.306 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.488, y: gameScreenDimentions.gameBoardWidth * 0.365 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.444, y: gameScreenDimentions.gameBoardWidth * 0.408 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.378, y: gameScreenDimentions.gameBoardWidth * 0.435 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.295, y: gameScreenDimentions.gameBoardWidth * 0.441 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.245, y: gameScreenDimentions.gameBoardWidth * 0.444 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.184, y: gameScreenDimentions.gameBoardWidth * 0.448 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.123, y: gameScreenDimentions.gameBoardWidth * 0.423 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.084, y: gameScreenDimentions.gameBoardWidth * 0.383 },
    { id: 21, x: gameScreenDimentions.gameBoardWidth * 0.038, y: gameScreenDimentions.gameBoardWidth * 0.35 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.025, y: gameScreenDimentions.gameBoardWidth * 0.348 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.079, y: gameScreenDimentions.gameBoardWidth * 0.345 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.155, y: gameScreenDimentions.gameBoardWidth * 0.342 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.25, y: gameScreenDimentions.gameBoardWidth * 0.34 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.255, y: gameScreenDimentions.gameBoardWidth * 0.273 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.255, y: gameScreenDimentions.gameBoardWidth * 0.212 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.261, y: gameScreenDimentions.gameBoardWidth * 0.155 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.265, y: gameScreenDimentions.gameBoardWidth * 0.095 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.295, y: gameScreenDimentions.gameBoardWidth * 0.022 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.225, y: gameScreenDimentions.gameBoardWidth * 0.015 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.16, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.102, y: gameScreenDimentions.gameBoardWidth * 0.007 },
    { id: 34, x: 0, y: 0 }];
export const pawn6Array = [
    //For pawn 6.
    { id: 21, x: 0, y: 0 },
    { id: 1, x: gameScreenDimentions.gameBoardWidth * 0.060, y: -gameScreenDimentions.gameBoardWidth * 0.005 },
    { id: 2, x: gameScreenDimentions.gameBoardWidth * 0.11, y: gameScreenDimentions.gameBoardWidth * 0.02 },
    { id: 3, x: gameScreenDimentions.gameBoardWidth * 0.168, y: gameScreenDimentions.gameBoardWidth * 0.04 },
    { id: 4, x: gameScreenDimentions.gameBoardWidth * 0.23, y: gameScreenDimentions.gameBoardWidth * 0.06 },
    { id: 5, x: gameScreenDimentions.gameBoardWidth * 0.292, y: gameScreenDimentions.gameBoardWidth * 0.082 },
    { id: 6, x: gameScreenDimentions.gameBoardWidth * 0.364, y: gameScreenDimentions.gameBoardWidth * 0.064 },
    { id: 7, x: gameScreenDimentions.gameBoardWidth * 0.432, y: gameScreenDimentions.gameBoardWidth * 0.068 },
    { id: 8, x: gameScreenDimentions.gameBoardWidth * 0.45, y: gameScreenDimentions.gameBoardWidth * 0.114 },
    { id: 9, x: gameScreenDimentions.gameBoardWidth * 0.47, y: gameScreenDimentions.gameBoardWidth * 0.168 },
    { id: 10, x: gameScreenDimentions.gameBoardWidth * 0.48, y: gameScreenDimentions.gameBoardWidth * 0.227 },
    { id: 11, x: gameScreenDimentions.gameBoardWidth * 0.475, y: gameScreenDimentions.gameBoardWidth * 0.278 },
    { id: 12, x: gameScreenDimentions.gameBoardWidth * 0.468, y: gameScreenDimentions.gameBoardWidth * 0.336 },
    { id: 13, x: gameScreenDimentions.gameBoardWidth * 0.446, y: gameScreenDimentions.gameBoardWidth * 0.391 },
    { id: 14, x: gameScreenDimentions.gameBoardWidth * 0.405, y: gameScreenDimentions.gameBoardWidth * 0.419 },
    { id: 15, x: gameScreenDimentions.gameBoardWidth * 0.31, y: gameScreenDimentions.gameBoardWidth * 0.438 },
    { id: 16, x: gameScreenDimentions.gameBoardWidth * 0.255, y: gameScreenDimentions.gameBoardWidth * 0.441 },
    { id: 17, x: gameScreenDimentions.gameBoardWidth * 0.192, y: gameScreenDimentions.gameBoardWidth * 0.444 },
    { id: 18, x: gameScreenDimentions.gameBoardWidth * 0.135, y: gameScreenDimentions.gameBoardWidth * 0.448 },
    { id: 19, x: gameScreenDimentions.gameBoardWidth * 0.08, y: gameScreenDimentions.gameBoardWidth * 0.405 },
    { id: 20, x: gameScreenDimentions.gameBoardWidth * 0.042, y: gameScreenDimentions.gameBoardWidth * 0.375 },
    { id: 21, x: -gameScreenDimentions.gameBoardWidth * 0.013, y: gameScreenDimentions.gameBoardWidth * 0.353 },
    { id: 22, x: -gameScreenDimentions.gameBoardWidth * 0.07, y: gameScreenDimentions.gameBoardWidth * 0.347 },
    { id: 23, x: -gameScreenDimentions.gameBoardWidth * 0.131, y: gameScreenDimentions.gameBoardWidth * 0.345 },
    { id: 24, x: -gameScreenDimentions.gameBoardWidth * 0.222, y: gameScreenDimentions.gameBoardWidth * 0.338 },
    { id: 25, x: -gameScreenDimentions.gameBoardWidth * 0.274, y: gameScreenDimentions.gameBoardWidth * 0.308 },
    { id: 26, x: -gameScreenDimentions.gameBoardWidth * 0.277, y: gameScreenDimentions.gameBoardWidth * 0.244 },
    { id: 27, x: -gameScreenDimentions.gameBoardWidth * 0.28, y: gameScreenDimentions.gameBoardWidth * 0.186 },
    { id: 28, x: -gameScreenDimentions.gameBoardWidth * 0.283, y: gameScreenDimentions.gameBoardWidth * 0.13 },
    { id: 29, x: -gameScreenDimentions.gameBoardWidth * 0.285, y: gameScreenDimentions.gameBoardWidth * 0.07 },
    { id: 30, x: -gameScreenDimentions.gameBoardWidth * 0.288, y: gameScreenDimentions.gameBoardWidth * 0.022 },
    { id: 31, x: -gameScreenDimentions.gameBoardWidth * 0.22, y: gameScreenDimentions.gameBoardWidth * 0.015 },
    { id: 32, x: -gameScreenDimentions.gameBoardWidth * 0.155, y: gameScreenDimentions.gameBoardWidth * 0.01 },
    { id: 33, x: -gameScreenDimentions.gameBoardWidth * 0.096, y: gameScreenDimentions.gameBoardWidth * 0.006 },
    { id: 34, x: 0, y: 0 },];