export const commonImages = {
    login: require('../images/login.png'),
    buttonLogin: require('../images/button.png'),
    registerLogin: require('../images/register.png'),
    registerLogo: require('../images/register-png.png'),
    disparitylogo: require('../images/disparitylogo.png'),
    user1: require('../images/user1.png'),
    viewgame: require('../images/viewgame.png'),
    img3: require('../images/img3.png'),
    img5: require('../images/img5.png'),
    img6: require('../images/img6.png'),
    img4: require('../images/img4.png'),
    edit_profile: require('../images/edit_profile.png'),
    button_left: require('../images/button_left.png'),
    clock: require('../images/clock.png'),
    startgame: require('../images/start-game.png'),
    leave: require('../images/leave.png'),
    leaderboard: require('../images/leaderboard.png'),
    user: require('../images/user.png'),
    change: require('../images/change-password.png'),
    help: require('../images/help.png'),
    faq: require('../images/faq.png'),
    pp: require('../images/privacy-policy.png'),
    termsConditions: require('../images/terms-and-conditions.png'),
    aboutus: require('../images/about-us.png'),
    contact: require('../images/contact.png'),
    logout: require('../images/logout.png'),
    package: require('../images/package.png'),
    passwordimg: require('../images/password_img.png'),
    pop: require('../images/pop.png'),
    send: require('../images/send.png'),
    chat: require('../images/chat.png'),
    okay: require('../images/okay.png'),
    custom: require('../images/customroom.png'),
    public: require('../images/public.png'),
    join: require('../images/join.png'),
    auto: require('../images/auto.png'),
    add: require('../images/add.png'),
    add1: require('../images/add1.png'),
    createroom: require('../images/createroom.png'),
    package12: require('../images/package12.png'),
    purchase: require('../images/purchase.png'),
    createplay: require('../images/createplay.png'),
    join_play: require('../images/join_play.png'),
    settings: require('../images/settings.png'),
    back: require('../images/back.png'),
    edit: require('../images/edit.png'),
    profile: require('../images/AvatarBlank.png'),
    update: require('../images/update.png'),
    registerlogo: require('../images/register-logo.png'),
    registerbutton: require('../images/register-button.png'),
    loginblack: require('../images/login-black.png'),
    joingame: require('../images/joingame.png'),
    alert: require('../images/alert.png'),
    submit: require('../images/submit.png'),
    yes: require('../images/yes.png'),
    usecode: require('../images/use-code.png'),
    crown: require('../images/crown.png'),
    send1: require('../images/send12.png'),
    winner: require('../images/winner.png'),
    sad: require('../images/sad.png'),
    plus: require('../images/add2.png'),
    cardRule: require('../images/cardRule.png'),
    sndImage: require('../images/sndImage.png'),
    sdImage: require('../images/sdImage.png'),
    trade_help: require('../images/trade_help.png'),
    RoundArrowWithOne: require('../images/RoundArrowWithOne.png'),
    event1: require('../images/event1.png'),

    degree: require('../images/Degree.png'),
    Startup: require('../images/Startup.png'),
    Skill: require('../images/Skill.png'),
    opportunity: require('../images/opportunity.png'),

    MoneyBag: require('../images/MoneyBag.png'),
    MultiHome: require('../images/MultiHome.png'),
    HomeDollar: require('../images/HomeDollar.png'),
    BusinessTile: require('../images/BusinessTile.png'),
    info: require('../images/info2.png'),
    useHere: require('../images/useHere.png'),
    hardCopy: require('../images/hard-copy-of-game.png'),
    blockedUsers: require('../images/blocked_users.png'),
    deleteAccount: require('../images/delete_account.png'),
    buttonBg: require('../assets/images/buttonBg.png'),
}