import { textColor, textInput } from '../../../themes/colors/colors';
import { gameScreenDimentions } from '../../../helper/globalFunctions';
import { FontFamily } from '../../../themes/fontFamily/fontFamily';
import fontSizes from '../../../themes/constants/fontSizes';
import bg from '../../../assets/images/popup_1.png';

const styles = {
    viewStyle: {
        position: 'absolute',
        right: gameScreenDimentions.gameBoardWidth * 0.55,
        bottom: gameScreenDimentions.gameBoardWidth * 0.04,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        opacity: 0,
        zIndex: 1007,
        justifyContent: 'center',
        borderColor: textColor.green,
        borderRadius: 14,
        borderWidth: 3,
        backgroundColor: textColor.green,
        borderStyle: 'solid',
        height: gameScreenDimentions.gameBoardWidth * 0.04,
    },
    backgroundStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        justifyContent: 'center',
        height: '100%',
        borderBottomLeftRadius: 14,
        borderTopLeftRadius: 14,
        borderWidth: 0,
        backgroundColor: textColor.green,
        borderStyle: 'solid',
    },
    textStyle: {
        textAlignVertical: 'center',
        // height: '100%',
        color: textColor.white,
        textAlign: "center",
        fontWeight: '700',
        fontSize: fontSizes.f50,
        marginLeft: gameScreenDimentions.gameBoardWidth * 0.03,
        marginRight: gameScreenDimentions.gameBoardWidth * 0.03,
        display: 'flex',
        flexDirection: 'column',
    },
    touchableStyle: {
        height: gameScreenDimentions.gameBoardWidth * 0.035,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: textInput.green,
        width: gameScreenDimentions.gameBoardWidth * 0.12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightColor: textColor.green,
        alignItems: 'center',
        borderBottomRightRadius: 14,
        borderTopRightRadius: 14,
    },
    textStyleResume: {
        color: textColor.white,
        textAlign: "center",
        fontWeight: '700',
        fontSize: fontSizes.f50,
        display: 'flex',
        flexDirection: 'column',
    },
}

export default styles;