import React, { useEffect } from "react";
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import { gameScreenDimentions, getLeaveStatus } from "../../../helper/globalFunctions";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import { popupType } from "../../../themes/constants/gameConstants";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import CardComponent from "../CardComponent";
import styles from './styles';
import { socket } from "../../../helper/socket";
import { buttonClickSound } from "../../../helper/playSound";
import ApiConstants from "../../../themes/apiConstants";
import { isInternetConnected } from "../../../helper/networkUtils";
import { ValidationConstants } from "../../../themes/constants/validationConstants";

var currentPosition = 0;

const PlayLotteryCard = (props) => {
    const dispatch = useDispatch();
    const fadeAnim = useAnimation();
    const animatedStyle = useAnimation();
    const animatedStyle1 = useAnimation();
    const animatedStyle2 = useAnimation();
    const animatedStyle3 = useAnimation();
    const animatedStyle4 = useAnimation();
    const animatedStyle5 = useAnimation();

    const shuffleCards = useSelector((state) => state.homeReducer.shuffleCards);
    const shuffle = useSelector((state) => state.homeReducer.shuffle);
    const ourCards = useSelector((state) => state.homeReducer.ourCards);
    const roomData = useSelector((state) => state.homeReducer.roomData);

    const [cardCount, setCardCount] = React.useState(0);
    const [cardArray, setCardArray] = React.useState([])

    useEffect(() => {
        if (shuffle && shuffleCards.length > 0) {
            setCardArray([
                {
                    id: 0,
                    selectedCard: shuffleCards[0],
                    isSelected: false,
                    animatedObject: animatedStyle,
                    positions: [{
                        x: -gameScreenDimentions.gameBoardWidth * 0.483,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.483,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.483,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    }]
                }, {
                    id: 1,
                    selectedCard: shuffleCards[1],
                    isSelected: false,
                    animatedObject: animatedStyle1,
                    positions: [{
                        x: -gameScreenDimentions.gameBoardWidth * 0.483,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.413,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.413,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    }]
                }, {
                    id: 2,
                    selectedCard: shuffleCards[2],
                    isSelected: false,
                    animatedObject: animatedStyle2,
                    positions: [{
                        x: -gameScreenDimentions.gameBoardWidth * 0.483,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.413,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    },
                    {
                        x: -gameScreenDimentions.gameBoardWidth * 0.338,
                        y: gameScreenDimentions.gameBoardWidth * 0.296,
                    }]

                }, {
                    id: 3,
                    selectedCard: ourCards[0],
                    isSelected: false,
                    animatedObject: animatedStyle3,
                    positions: [{
                        x: 0,
                        y: 0
                    },
                    {
                        x: gameScreenDimentions.gameBoardWidth * 0.07,
                        y: 0
                    },
                    {
                        x: gameScreenDimentions.gameBoardWidth * 0.142,
                        y: 0
                    }]
                }, {
                    id: 4,
                    selectedCard: ourCards[1],
                    isSelected: false,
                    animatedObject: animatedStyle4,
                    positions: [{
                        x: -gameScreenDimentions.gameBoardWidth * 0.07,
                        y: 0
                    },
                    {
                        x: 0,
                        y: 0
                    },
                    {
                        x: gameScreenDimentions.gameBoardWidth * 0.07,
                        y: 0
                    }]
                }, {
                    id: 5,
                    selectedCard: ourCards[2],
                    isSelected: false,
                    animatedObject: animatedStyle5,
                    positions: [{
                        x: -gameScreenDimentions.gameBoardWidth * 0.142,
                        y: 0
                    },
                    {
                        x: gameScreenDimentions.gameBoardWidth * 0.07,
                        y: 0
                    },
                    {
                        x: 0,
                        y: 0
                    }]
                }
            ])
            currentPosition = 0
            setTimeout(() => {
                move(-gameScreenDimentions.gameBoardWidth * 0.4, 0)
            }, 500)
        }
        else {
            resetCardsBack()
        }
    }, [shuffle, shuffleCards])

    const move = async (x, y) => {
        fadeAnim.start({
            transition: { duration: 0.700 },
            opacity: 1,
        })

        await animatedStyle.start({
            transition: { duration: 0.900 },
            x: x,
            y: y,
        })
        move1(-gameScreenDimentions.gameBoardWidth * 0.2, 0)
    }
    const move1 = async (x, y) => {
        await animatedStyle1.start({
            transition: { duration: 0.900 },
            x: x,
            y: y,
        })
        move2(0, 0)
    }
    const move2 = async (x, y) => {
        await animatedStyle2.start({
            transition: { duration: 0.900 },
            x: x,
            y: y,
        })

        playCard(gameScreenDimentions.gameBoardWidth * 0.685, -gameScreenDimentions.gameBoardWidth * 0.296)
    }

    const playCard = async (xA, yA) => {
        await animatedStyle3.start({
            transition: { duration: 0.900 },
            scaleX: 1,
            scaleY: 1,
            x: xA,
            y: yA,
        })
        playCard1(gameScreenDimentions.gameBoardWidth * 0.82, -gameScreenDimentions.gameBoardWidth * 0.296)
    }
    const playCard1 = async (xA, yA) => {
        await animatedStyle4.start({
            transition: { duration: 0.900 },
            scaleX: 1,
            scaleY: 1,
            x: xA,
            y: yA,
        })
        playCard2(gameScreenDimentions.gameBoardWidth * 0.948, -gameScreenDimentions.gameBoardWidth * 0.296)
    }
    const playCard2 = async (xA, yA) => {
        await animatedStyle5.start({
            transition: { duration: 0.900 },
            scaleX: 1,
            scaleY: 1,
            x: xA,
            y: yA,
        })
    }

    const moveCardsBack = async (xB, yB) => {
        let toValueX = null;
        let toValueY = null;
        let animatedObject = null;
        let toValueX2 = null;
        let toValueY2 = null;
        let animatedObject2 = null;
        let toValueX3 = null;
        let toValueY3 = null;
        let animatedObject3 = null;
        let sub_array = [];
        cardArray.map(obj => {
            if (obj.isSelected === true) {
                sub_array.push(obj.selectedCard);
                if (animatedObject === null) {
                    toValueX = obj.positions[currentPosition]?.x
                    toValueY = obj.positions[currentPosition]?.y
                    animatedObject = obj.animatedObject
                }
                else if (animatedObject2 === null) {
                    toValueX2 = obj.positions[currentPosition]?.x
                    toValueY2 = obj.positions[currentPosition]?.y
                    animatedObject2 = obj.animatedObject
                }
                else {
                    toValueX3 = obj.positions[currentPosition]?.x
                    toValueY3 = obj.positions[currentPosition]?.y
                    animatedObject3 = obj.animatedObject
                }
                currentPosition = currentPosition + 1
            }
        })
        animatedObject.start({
            transition: { duration: 0.900 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: toValueX,
            y: toValueY,
        })
        animatedObject2.start({
            transition: { duration: 0.900 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: toValueX2,
            y: toValueY2,
        })
        await animatedObject3.start({
            transition: { duration: 0.900 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: toValueX3,
            y: toValueY3,
        })
        animatedStyle.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle1.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle2.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle3.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })
        animatedStyle4.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })
        await animatedStyle5.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })

        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            socket.emit('discardCards', sub_array)
            dispatch({
                type: ApiConstants.SHUFFLE,
                payload: false,
            })
            dispatch({
                type: ApiConstants.SHUFFLE_CARDS,
                payload: []
            })
            setCardCount(0)
            setCardArray([])
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
        }
    }

    const resetCardsBack = () => {
        animatedStyle.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle1.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle2.start({
            transition: { duration: 0 },
            scaleX: 1,
            scaleY: 1,
            x: 1,
            y: 1,
        })
        animatedStyle3.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })
        animatedStyle4.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })
        animatedStyle5.start({
            transition: { duration: 0 },
            scaleX: 0.318,
            scaleY: 0.38,
            x: 1,
            y: 1,
        })
    }

    const onCardPress = (index) => {
        if (!roomData?.isPause) {
            if (cardArray[index].isSelected) {
                cardArray[index]['isSelected'] = false
                setCardCount(cardCount - 1);
            } else {
                if (cardCount < 3) {
                    cardArray[index].isSelected = true
                    setCardCount(cardCount + 1);
                } else {
                    ShowAlertMessage("You have already selected 3 cards.", popupType.error)
                }
            }
        } else {
            ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
        }
    }

    const fadeText = async () => {
        await fadeAnim.start({
            transition: { duration: 0.700 },
            opacity: [0, 1],
        })
    }

    return (
        <>
            {(shuffle && cardArray.length > 0) && <div style={styles.outerContainer}>
                <AnimatePresence>
                    <motion.span style={{
                        fontWeight: '800',
                        fontSize: fontSizes.f75,
                        color: 'yellow',
                        position: 'absolute',
                        left: gameScreenDimentions.gameBoardWidth * 0.45,
                        top: gameScreenDimentions.gameBoardWidth * 0.07,
                        opacity: 0
                    }}
                        animate={fadeAnim}
                    >
                        Select the three cards you want to keep:
                    </motion.span>
                </AnimatePresence>
                {shuffleCards.length > 2 &&
                    <CardComponent
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            alignSelf: 'center',
                            bottom: gameScreenDimentions.gameBoardWidth * 0.2468,
                            left: gameScreenDimentions.gameBoardWidth * 0.4426,
                            width: gameScreenDimentions.gameBoardWidth * 0.162,
                            height: gameScreenDimentions.gameBoardWidth * 0.212,
                            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                            borderWidth: 2,
                            borderColor: cardArray[2]['isSelected'] ? 'red' : '#46c1af55',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            scaleX: 1,
                            scaleY: 1
                        }}
                        animate={animatedStyle2}
                        code={shuffleCards[2]}
                        selfKey={'lotteryCard3'}
                    />
                }
                {shuffleCards.length > 1 && <CardComponent
                    style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        alignSelf: 'center',
                        bottom: gameScreenDimentions.gameBoardWidth * 0.2468,
                        left: gameScreenDimentions.gameBoardWidth * 0.4426,
                        width: gameScreenDimentions.gameBoardWidth * 0.162,
                        height: gameScreenDimentions.gameBoardWidth * 0.212,
                        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                        borderWidth: 2,
                        borderColor: cardArray[1]['isSelected'] ? 'red' : '#46c1af55',
                        borderStyle: 'solid',
                        overflow: 'hidden',
                        scaleX: 1,
                        scaleY: 1
                    }}
                    animate={animatedStyle1}
                    code={shuffleCards[1]}
                    selfKey={'lotteryCard2'}
                />}
                {shuffleCards.length > 0 &&
                    <CardComponent
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            alignSelf: 'center',
                            bottom: gameScreenDimentions.gameBoardWidth * 0.2468,
                            left: gameScreenDimentions.gameBoardWidth * 0.4426,
                            width: gameScreenDimentions.gameBoardWidth * 0.162,
                            height: gameScreenDimentions.gameBoardWidth * 0.212,
                            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                            borderWidth: 2,
                            borderColor: cardArray[0]['isSelected'] ? 'red' : '#46c1af55',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            scaleX: 1,
                            scaleY: 1
                        }}
                        animate={animatedStyle}
                        code={shuffleCards[0]}
                        selfKey={'lotteryCard1'}
                    />
                }
                {ourCards.length > 0 &&
                    <CardComponent
                        style={{
                            backgroundColor: 'white',
                            elevation: 4,
                            position: 'absolute',
                            alignSelf: 'center',
                            bottom: -gameScreenDimentions.gameBoardWidth * 0.05,
                            left: -gameScreenDimentions.gameBoardWidth * 0.042,
                            width: gameScreenDimentions.gameBoardWidth * 0.162,
                            height: gameScreenDimentions.gameBoardWidth * 0.212,
                            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                            borderWidth: 2,
                            borderColor: cardArray[3]['isSelected'] ? 'red' : '#46c1af55',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            scaleX: 0.318,
                            scaleY: 0.38
                        }}
                        animate={animatedStyle3}
                        code={ourCards[0]}
                        selfKey={'lotteryCard4'}
                    />}
                {ourCards.length > 1 &&
                    <CardComponent
                        style={{
                            backgroundColor: 'white',
                            elevation: 4,
                            position: 'absolute',
                            alignSelf: 'center',
                            bottom: -gameScreenDimentions.gameBoardWidth * 0.05,
                            left: gameScreenDimentions.gameBoardWidth * 0.028,
                            width: gameScreenDimentions.gameBoardWidth * 0.162,
                            height: gameScreenDimentions.gameBoardWidth * 0.212,
                            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                            borderWidth: 2,
                            borderColor: cardArray[4]['isSelected'] ? 'red' : '#46c1af55',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            scaleX: 0.318,
                            scaleY: 0.38
                        }}
                        animate={animatedStyle4}
                        code={ourCards[1]}
                        selfKey={'lotteryCard5'}
                    />}
                {ourCards.length > 2 &&
                    <CardComponent
                        style={{
                            backgroundColor: 'white',
                            elevation: 4,
                            position: 'absolute',
                            alignSelf: 'center',
                            bottom: -gameScreenDimentions.gameBoardWidth * 0.05,
                            left: gameScreenDimentions.gameBoardWidth * 0.1,
                            width: gameScreenDimentions.gameBoardWidth * 0.162,
                            height: gameScreenDimentions.gameBoardWidth * 0.212,
                            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
                            borderWidth: 2,
                            borderColor: cardArray[5]['isSelected'] ? 'red' : '#46c1af55',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            scaleX: 0.318,
                            scaleY: 0.38
                        }}
                        animate={animatedStyle5}
                        code={ourCards[2]}
                        selfKey={'lotteryCard6'}
                    />}
                <div onClick={() => {
                    onCardPress(0);
                }}
                    style={styles.cardWrapper1}>
                </div>
                <div onClick={() => {
                    onCardPress(1);
                }}
                    style={styles.cardWrapper2}>
                </div>
                <div onClick={() => {
                    onCardPress(2);
                }}
                    style={styles.cardWrapper3}>
                </div>
                <div onClick={() => {
                    onCardPress(3);
                }}
                    style={styles.cardWrapper4}>
                </div>
                <div onClick={() => {
                    onCardPress(4);
                }}
                    style={styles.cardWrapper5}>
                </div>
                <div onClick={() => {
                    onCardPress(5);
                }}
                    style={styles.cardWrapper6}>
                </div>
                <div style={{
                    position: 'absolute',
                    right: gameScreenDimentions.gameBoardWidth * 0.115,
                    bottom: gameScreenDimentions.gameBoardWidth * 0.04,
                }}>
                    <ButtonCustom
                        resize={'stretch'}
                        textColor={{ fontSize: fontSizes.f40 }}
                        iContainer={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        style={{
                            height: gameScreenDimentions.gameBoardWidth * 0.042,
                            paddingHorizontal: 16, width: null,
                            minWidth: gameScreenDimentions.gameBoardWidth * 0.17,
                        }}
                        imgBg={appImages.buttonBg}
                        title={strings.submit}
                        onPress={() => {
                            buttonClickSound();
                            if (!roomData?.isPause) {
                                if (cardCount === 3) {
                                    moveCardsBack(gameScreenDimentions.gameBoardWidth * 0.035, gameScreenDimentions.gameBoardWidth * 0.035)
                                } else {
                                    ShowAlertMessage('Please select 3 cards to submit.', popupType.error);
                                }
                            } else {
                                ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                            }
                        }}
                    />
                </div>
            </div>}
        </>
    );
};

export default PlayLotteryCard;