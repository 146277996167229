import React from 'react';
import { Progress } from 'antd';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import { FontFamily } from '../../themes/fontFamily/fontFamily';
import { textColor } from '../../themes/colors/colors';
import { useDispatch } from "react-redux";
import ApiConstants from '../../themes/apiConstants';

const divisionAmount = 300
const ImageProfileWIthProgress = (props) => {
    const dispatch = useDispatch()
    const gethours = (secTotal, type) => {
        const m = Math.floor(secTotal / (60));
        let secondsLeft = secTotal - m * 60;

        var mDisplay = m > 9 ? `${m}:` : ((m > 0) && (m < 10)) ? '0' + `${m}:` : '00:';
        var sDisplay = secondsLeft > 9 ? secondsLeft : ((secondsLeft > 0) && (secondsLeft < 10)) ? '0' + secondsLeft : '00';
        if (type == 'm') {
            return mDisplay;
        } else {
            return sDisplay;
        }
    };

    return (
        <>
            <div style={props.style} onClick={() => dispatch({
                type: ApiConstants.SHOW_USER_DETAIL,
                showUserDetail: {
                    index: props.index,
                    role: props.userType,
                    playerNum: props.playerNum,
                }
            })
            }>
                <Progress
                    type="circle"
                    percent={Math.floor((props.percent / divisionAmount) * 100)}
                    strokeWidth={gameScreenDimentions.width * 0.007}
                    width={gameScreenDimentions.width * 0.03}
                />
                <img
                    style={{
                        width: gameScreenDimentions.width * 0.024,
                        height: gameScreenDimentions.width * 0.024,
                        objectFit: 'cover',
                        position: 'absolute',
                        top: gameScreenDimentions.width * 0.003,
                        left: gameScreenDimentions.width * 0.003,
                        borderRadius: gameScreenDimentions.width * 0.02,
                        overflow: 'hidden'
                    }}
                    src={props.profileImage} />
                {props.percent > 0 && <div style={{
                    backgroundColor: '#00000099',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    display: 'flex',
                    top: gameScreenDimentions.width * 0.003,
                    left: gameScreenDimentions.width * 0.003,
                    borderRadius: gameScreenDimentions.width * 0.021,
                    width: gameScreenDimentions.width * 0.024,
                    height: gameScreenDimentions.width * 0.024,
                }}>
                    <span style={{
                        display: 'flex',
                        marginTop: 1,
                        marginLeft: 1,
                        color: textColor.white,
                        fontWeight: '800',
                        fontSize: gameScreenDimentions.width * 0.006,
                    }}>
                        {gethours(props.percent, 'm')}
                        {gethours(props.percent, 's')}
                    </span>
                </div>
                }
                <img
                    style={{
                        position: 'absolute',
                        top: -8,
                        right: -2,
                        objectFit: 'contain',
                        width: gameScreenDimentions.width * 0.013,
                        height: gameScreenDimentions.width * 0.013
                    }}
                    src={props?.userType === 'SND' ? require('../../assets/images/gameplayScreen/event.png') : require('../../assets/images/gameplayScreen/player_type_card.png')}

                />
            </div>


        </>
    );
};

const styles = {
    selectTile: {
        display: 'flex',
        marginTop: 1,
        marginLeft: 1,
        color: textColor.white,
        fontFamily: FontFamily.bold,
        fontSize: gameScreenDimentions.width * 0.05,
    },
}
export default ImageProfileWIthProgress