import { modal, textColor, textInput } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';
import { FontFamily } from '../../../themes/fontFamily/fontFamily';
import { gameScreenDimentions } from '../../../helper/globalFunctions';
import bg from '../../../assets/images/popup_1.png';

const height = gameScreenDimentions.height;
const width = gameScreenDimentions.width;
const styles = {
    outerContainer: {
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        height: '100%',
        zIndex: 5,
        position: 'absolute',
        display: 'flex',
    },
    outerModalView: {
        paddingTop: height * 0.28,
        elevation: 5,
        alignItems: "center",
        justifyContent: 'center',
        display: 'flex',
    },
    modalView: {
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 25,
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: textInput.green,
        overflow: 'hidden',
        alignItems: "center",
        width: width * 0.32,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column'
    },
    selectTile: {
        color: textColor.green,
        textAlign: "center",
        fontWeight: '600',
        fontSize: fontSizes.f51,
        marginLeft: 25,
        marginRight: 25,
        display: 'flex',
    },

}

export default styles;