import Header from '../../../common/header';
import fontSizes from '../../../themes/constants/fontSizes';
import strings from '../../../themes/constants/strings'
import styles from './styles';
import { useLocation } from 'react-router';

const PointsBreakdown = (props) => {
  const location = useLocation();
  const points = location.state?.points
  const pointHistory = location.state?.pointHistory
  return (
    <div>
      <div className='background_image '>
        <Header title={strings.pointBreakdown} settings={false} redirect={true} />
        <div className='faq_page' style={{ display: 'flex', flexDirection: 'column', background: 'Linear-gradient(-109deg, rgba(39, 40, 40, 0.99608) 0%, rgb(46, 47, 47) 100%)' }}>
          <span style={{ ...styles.fontLabel, fontSize: fontSizes.f48 }}>
            {pointHistory ? `Total Points: ${points}\n` : `Prosperity Points:\n`}
          </span>
          {pointHistory ? null : <span style={styles.fontLabel}>
            {`After the game has ended points are allocated to each player and an ultimate winner is determined. Here is how the points are allocated:\n`}
          </span>}
          <span style={styles.fontLabel1}>
            {pointHistory ?
              pointHistory.map(item => {
                return <>{`${item?.message} = ${item?.point} ${Math.abs(item?.point) > 1 ? 'points' : 'point'}\n`}</>
              })
              : `1 Skill tile = 1 Prosperity point
1 Degree tile = 2 Prosperity points
1 Startup tile = 3 Prosperity points
1 Privilege token = 0.5 Prosperity point(round up)
1 Justice token = -2 Prosperity points
1 Power token =  3 points (must have at end of game to count)
Tier you are on multiplied by 1 Prosperity point

Competitive Win Only:
Add 5 Prosperity points to the winner's total.

Cooperative Win Only:
Add 1 Prosperity point each time you Help.
Deduct 1 Prosperity point each time you Hinder.`}
          </span>
        </div>
      </div>
    </div>
  )

}
export default PointsBreakdown