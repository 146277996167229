import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { gameScreenDimentions, getLeaveStatus, getRollDiceEmit, setIsIRoleDice, updateRollDiceEmit } from '../../helper/globalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../helper/socket';
import appImages from '../../themes/appImages/appImages';
import ApiConstants from '../../themes/apiConstants';
import { popupType, RollDiceType } from '../../themes/constants/gameConstants';
import fontSizes from '../../themes/constants/fontSizes';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import strings from "../../themes/constants/strings";
import { buttonClickSound } from '../../helper/playSound';
import { isInternetConnected } from '../../helper/networkUtils';

const Dice1 = require('../../assets/gif/dice1.gif');
const Dice2 = require('../../assets/gif/dice2.gif');
const Dice3 = require('../../assets/gif/dice3.gif');
const Dice4 = require('../../assets/gif/dice4.gif');
const Dice5 = require('../../assets/gif/dice5.gif');
const Dice6 = require('../../assets/gif/dice6.gif');

const diceRollTime = 4400

const RollDice = (props) => {
    const dispatch = useDispatch()
    const [incrementCounter, setIncrementCounter] = React.useState(0)
    const [diceNo, setDiceNo] = React.useState(0)
    const [diceGif, setDiceGif] = React.useState(null)
    const isNewGame = useSelector((state) => state.homeReducer.isNewGame);
    const rollDice = useSelector((state) => state.homeReducer.rollDice);
    const rollDiceType = useSelector((state) => state.homeReducer.rollDiceType);
    const displayDice = useSelector((state) => state.homeReducer.displayDice);
    const roomData = useSelector((state) => state.homeReducer.roomData);
    const isSpectator = useSelector((state) => state.homeReducer.isSpectator);


    React.useEffect(() => {
        if (isNewGame) {
            onRollDice()
        }
    }, [isNewGame])

    React.useEffect(() => {
        if (displayDice) {
            const tempDic = displayDice === 1 ? Dice1 : (
                displayDice === 2 ? Dice2 : (
                    displayDice === 3 ? Dice3 : (
                        displayDice === 4 ? Dice4 : (
                            displayDice === 5 ? Dice5 : Dice6
                        )
                    )
                )
            )
            setDiceGif(tempDic)
            setDiceNo(displayDice)
            setIncrementCounter(incrementCounter=> incrementCounter +1)
        }
    }, [displayDice])

    const onRollDiceClick = () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            if (rollDice) {
                setIsIRoleDice(true)
                dispatch({
                    type: ApiConstants.UPDATE_IS_NEW_GAME,
                    data: true
                })
                dispatch({
                    type: ApiConstants.ROLL_DICE_UPDATE,
                    payload: { rollDice: false, rollDiceType: rollDiceType }
                })
            }
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
        }
    }

    const onRollDice = () => {
        const tempDiceNo = ((Math.floor(Math.random() * 6) + 1))
        setDiceNo(tempDiceNo)
        setIncrementCounter(incrementCounter=> incrementCounter +1)
        const tempDic = tempDiceNo === 1 ? Dice1 : (
            tempDiceNo === 2 ? Dice2 : (
                tempDiceNo === 3 ? Dice3 : (
                    tempDiceNo === 4 ? Dice4 : (
                        tempDiceNo === 5 ? Dice5 : Dice6
                    )
                )
            )
        )
        setDiceGif(tempDic)
        if (rollDiceType !== RollDiceType.turnSelect) {
            socket.emit('diceBeforeAnimation', tempDiceNo)
        }
    }

    const onAnimationFinished = () => {
        if (!displayDice) {
            if (rollDiceType === RollDiceType.turnSelect) {
                socket.emit('initialTurn', diceNo)
            }
            else if (rollDiceType === RollDiceType.vote) {
                socket.emit('voteRollDice', diceNo)
            }
            else if (rollDiceType && getRollDiceEmit() === false) {
                socket.emit('rollDice', { number: diceNo, type: rollDiceType })
                updateRollDiceEmit(true)
            }
        }

        setTimeout(() => {
            setIsIRoleDice(false)
            setDiceNo(0)
            setDiceGif(null)
            dispatch({
                type: ApiConstants.UPDATE_IS_NEW_GAME,
                data: false
            })
            dispatch({
                type: ApiConstants.ROLL_DICE_UPDATE,
                payload: { rollDice: false, rollDiceType: null }
            })
            dispatch({
                type: ApiConstants.DISPLAY_DICE,
                payload: null
            })
        }, 1000)
    }

    return (
        <>
            {(diceNo !== 0 && diceGif) ?
                <img
                    key={incrementCounter.toString()}
                    onLoad={() => {
                        setTimeout(() => {
                            onAnimationFinished()
                        }, diceRollTime);
                    }}
                    style={{
                        bottom: gameScreenDimentions.width * 0.23,
                        width: gameScreenDimentions.width * 0.95,
                        height: gameScreenDimentions.width * 0.2,
                        position: 'absolute',
                        objectFit: 'contain',
                        zIndex: 3
                    }}
                    src={diceGif}
                />
                : null
            }
            {rollDice && <AnimatePresence>
                <motion.div
                    transition={{ duration: 1, ease: "easeInOut", }}
                    animate={{ scale: 1 }}
                    layoutId={'scale1'}
                    key={'scale1'}
                    style={{
                        scale: 0,
                        position: 'absolute',
                        top: 0,
                        width: gameScreenDimentions.width,
                        height: gameScreenDimentions.height,
                        backgroundColor: 'rgba(56,56,56, 0.5)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 0
                    }}
                >
                    <motion.span style={{
                        fontWeight: '800',
                        fontSize: fontSizes.f90,
                        color: 'yellow',
                    }}>
                        {rollDiceType === 'movePawn' ? "Please roll the dice to move along the board." : "Please roll the dice."}
                    </motion.span>
                    {(rollDiceType === RollDiceType.turnSelect) && <motion.span style={{
                        fontWeight: '800',
                        fontSize: fontSizes.f75,
                        color: 'yellow',
                    }}>
                        {strings.youHaveOnly}
                    </motion.span>}
                </motion.div>
            </AnimatePresence>}
            {!isSpectator && <div
                onClick={() => {
                    buttonClickSound();
                    if (!roomData?.isPause) {
                        onRollDiceClick();
                    } else {
                        ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                    }
                }}
                style={{
                    position: 'absolute',
                    // right: gameScreenDimentions.width * 0.04,
                    // bottom: gameScreenDimentions.width * 0.125,
                    right: gameScreenDimentions.width * 0.038,
                    bottom: gameScreenDimentions.width * 0.056,
                    width: gameScreenDimentions.gameBoardWidth * 0.060,
                    height: gameScreenDimentions.gameBoardWidth * 0.060,
                    // zIndex: 9999,
                    cursor: 'pointer'
                }}
            >

                <img
                    src={appImages.dice}
                    style={{
                        width: gameScreenDimentions.gameBoardWidth * 0.061,
                        height: gameScreenDimentions.gameBoardWidth * 0.060,
                        objectFit: 'contain'
                    }} />
            </div>}

        </>
    );
};

export default RollDice
