import ApiConstants from '../../themes/apiConstants';
import { dialogNames } from '../../themes/constants/gameConstants';

const initialState = {
    onLoad: false,
    publicRoomList: [],
    publicRoomListOffset: 0,
    limit: 10,
    roomData: null,
    isNewGame: false,
    currentTimer: 0,
    isOurTurn: false,
    ourGameData: null,
    isPart1Completed: false,
    ourCards: [],
    selectCard: false,
    selectCardSubmissionType: null,
    rollDice: false,
    rollDiceType: null,
    tradeTile: false,
    enterTokenForVote: false,
    voteTileName: '',
    awardATile: null,
    isBackFromCardSelection: null,
    showMessageComponent: false,
    noInternet: false,
    showCardNormally: null,
    openDialog: dialogNames.close,
    selectedCard: 0,
    openDiceBox: false,
    tokenType: '',
    influenceAction: null,
    showMoveCard: null,
    showMoveCardCheck: false,
    showMoveCardOther: null,
    otherPlayRoleType: null,
    displayDice: null,
    smallTimer: 0,
    pawn1: 1,
    pawn2: 1,
    pawn3: 1,
    pawn4: 1,
    pawn5: 1,
    pawn6: 1,
    cPawn1: 1,
    cPawn2: 1,
    cPawn3: 1,
    cPawn4: 1,
    cPawn5: 1,
    cPawn6: 1,
    influenceType: null,
    displayRollDicePopUpType: null,
    reRollType: null,
    playCardType: null,
    outComeType: null,
    shuffleCards: [],
    shuffle: false,
    openRuleBook: false,
    isSpectator: false,
    showUserDetail: null,
    isSomeoneLeft: false,
    useInfluenceCard: null,
    toggleSound: true,
    aboutUs: '',
    privacyPolicy: '',
    faq: [],
    leaderBoard: [],
    winnerDetails: [],
    isPurchased: false,
    termAndConditions: '',
    blockedUsersList: [],
    tradeTileAgain: false,
    isTradeAvailable: false,
    logsData: [],
    displayContinueButton: false,
    range: [],
    viewAllCards: false,
    showMenuButton: true,
    dialogId: 0,
    pauseGame: false,
}

function homeReducer(state = initialState, action) {

    switch (action.type) {
        case ApiConstants.REST_HOME:
            return {
                ...state,
                roomData: null,
                isNewGame: false,
                currentTimer: 0,
                isOurTurn: false,
                ourGameData: null,
                isPart1Completed: false,
                ourCards: [],
                selectCard: false,
                selectCardSubmissionType: null,
                rollDice: false,
                rollDiceType: null,
                tradeTile: false,
                enterTokenForVote: false,
                voteTileName: '',
                awardATile: null,
                isBackFromCardSelection: null,
                showMessageComponent: false,
                noInternet: false,
                showCardNormally: null,
                openDialog: dialogNames.close,
                selectedCard: 0,
                openDiceBox: false,
                tokenType: '',
                influenceAction: null,
                showMoveCard: null,
                showMoveCardCheck: false,
                showMoveCardOther: null,
                otherPlayRoleType: null,
                displayDice: null,
                pawn1: 1,
                pawn2: 1,
                pawn3: 1,
                pawn4: 1,
                pawn5: 1,
                pawn6: 1,
                cPawn1: 1,
                cPawn2: 1,
                cPawn3: 1,
                cPawn4: 1,
                cPawn5: 1,
                cPawn6: 1,
                influenceType: null,
                displayRollDicePopUpType: null,
                reRollType: null,
                playCardType: null,
                outComeType: null,
                shuffleCards: [],
                shuffle: false,
                openRuleBook: false,
                isSpectator: false,
                showUserDetail: null,
                isSomeoneLeft: false,
                useInfluenceCard: null,
                tradeTileAgain: false,
                isTradeAvailable: false,
                logsData: [],
                displayContinueButton: false,
                range: [],
                viewAllCards: false,
                showMenuButton: true,
                dialogId: 0,
                pauseGame: false,
            }
        case ApiConstants.API_CREATE_ROOM_LOAD:
            return { ...state, onLoad: true }
        case ApiConstants.API_CREATE_ROOM_SUCCESS:
            return {
                ...state,
                onLoad: false,
                roomData: action.data
            }

        case ApiConstants.API_REEDEEM_VOUCHER_LOAD:
            return { ...state, onLoad: true }
        case ApiConstants.API_REEDEEM_VOUCHER_SUCCESS:
            return {
                ...state,
                onLoad: false,
            }

        case ApiConstants.API_PUBLIC_ROOM_LIST_SUCCESS:
            return {
                ...state,
                publicRoomList: action.payload
            }
        case ApiConstants.API_FAIL:
            return {
                ...state,
                onLoad: false,
            };
        case ApiConstants.API_ERROR:
            return {
                ...state,
                onLoad: false,
            };
        case ApiConstants.UPDATE_IS_NEW_GAME:
            return {
                ...state,
                isNewGame: action.data,
            };
        case ApiConstants.CURRENT_TIMER_UPDATE:
            return {
                ...state,
                currentTimer: action.payload,
            };
        case ApiConstants.OUR_GAME_DATA_UPDATE:
            return {
                ...state,
                isOurTurn: action.payload.isOurTurn,
                ourGameData: action.payload.ourGameData,
                isPart1Completed: action.payload.isPart1Completed,
            };
        case ApiConstants.SELECT_CARD_UPDATE:
            return {
                ...state,
                selectCard: action.payload.selectCard,
                selectCardSubmissionType: action.payload.selectCardSubmissionType,
            };
        case ApiConstants.ROLL_DICE_UPDATE:
            return {
                ...state,
                rollDice: action.payload.rollDice,
                rollDiceType: action.payload.rollDiceType,
            };
        case ApiConstants.TRADE_TILE_UPDATE:
            return {
                ...state,
                tradeTile: action.payload,
            };
        case ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE:
            return {
                ...state,
                enterTokenForVote: action.payload,
                voteTileName: action.voteTileName
            };
        case ApiConstants.AWARD_TILE_UPDATE:
            return {
                ...state,
                awardATile: action.payload,
            };
        case ApiConstants.BACK_FROM_SELECT_CARD_UPDATE:
            return {
                ...state,
                isBackFromCardSelection: action.payload,
            };
        case ApiConstants.SHOW_MESSAGE_COMPONENT_UPDATE:
            return {
                ...state,
                showMessageComponent: action.payload,
            };
        case ApiConstants.NO_INTERNET_COMPONENT_UPDATE:
            return {
                ...state,
                noInternet: action.payload,
            };
        case ApiConstants.SHOW_CARD_NORMALLY_UPDATE:
            return {
                ...state,
                showCardNormally: action.payload,
            };
        case ApiConstants.OPEN_DIALOG_BOX:
            return {
                ...state,
                openDialog: action.payload.openDialog
            };
        case ApiConstants.SELECTED_CARD:
            return {
                ...state,
                selectedCard: action.payload.selectedCard
            };
        case ApiConstants.OPEN_DICE_BOX:
            return {
                ...state,
                openDiceBox: action.payload.openDiceBox,
                tokenType: action.payload?.tokenType
            };
        case ApiConstants.INFLUENCE_ACTION:
            return {
                ...state,
                influenceAction: action.payload
            };
        case ApiConstants.INFLUENCE_TYPE:
            return {
                ...state,
                influenceType: action.payload
            };
        case ApiConstants.SHOW_MOVE_CARD:
            return {
                ...state,
                showMoveCard: action.payload.showMoveCard
            };
        case ApiConstants.SHOW_MOVE_CARD_CHECK:
            return {
                ...state,
                showMoveCardCheck: action.payload
            };
        case ApiConstants.SHOW_MOVE_CARD_OTHER:
            return {
                ...state,
                showMoveCardOther: action.payload.showMoveCardOther,
                otherPlayRoleType: action.payload.otherPlayRoleType,
            };
        case ApiConstants.OTHER_PLAYER_ROLE_TYPE:
            return {
                ...state,
                otherPlayRoleType: action.payload,
            };
        case ApiConstants.DISPLAY_DICE:
            return {
                ...state,
                displayDice: action.payload
            };
        case ApiConstants.SMALL_TIMER:
            return {
                ...state,
                smallTimer: action.payload
            };
        case ApiConstants.OUR_CARD_UPDATE:
            return {
                ...state,
                ourCards: action.payload.ourCards
            };
        case ApiConstants.PAWN_1:
            return {
                ...state,
                pawn1: action.payload
            };
        case ApiConstants.PAWN_2:
            return {
                ...state,
                pawn2: action.payload
            };
        case ApiConstants.PAWN_3:
            return {
                ...state,
                pawn3: action.payload
            };
        case ApiConstants.PAWN_4:
            return {
                ...state,
                pawn4: action.payload
            };
        case ApiConstants.PAWN_5:
            return {
                ...state,
                pawn5: action.payload
            };
        case ApiConstants.PAWN_6:
            return {
                ...state,
                pawn6: action.payload
            };
        case ApiConstants.CPAWN_1:
            return {
                ...state,
                cPawn1: action.payload
            };
        case ApiConstants.CPAWN_2:
            return {
                ...state,
                cPawn2: action.payload
            };
        case ApiConstants.CPAWN_3:
            return {
                ...state,
                cPawn3: action.payload
            };
        case ApiConstants.CPAWN_4:
            return {
                ...state,
                cPawn4: action.payload
            };
        case ApiConstants.CPAWN_5:
            return {
                ...state,
                cPawn5: action.payload
            };
        case ApiConstants.CPAWN_6:
            return {
                ...state,
                cPawn6: action.payload
            };
        case ApiConstants.DISPLAY_ROLL_DICE_POPUP_TYPE:
            return {
                ...state,
                displayRollDicePopUpType: action.payload
            };
        case ApiConstants.RE_ROLL_TYPE:
            return {
                ...state,
                reRollType: action.payload,
            };
        case ApiConstants.PLAY_CARD_TYPE:
            return {
                ...state,
                playCardType: action.payload,
            };
        case ApiConstants.OUT_COME_TYPE:
            return {
                ...state,
                outComeType: action.payload,
            };
        case ApiConstants.SHUFFLE_CARDS:
            return {
                ...state,
                shuffleCards: action.payload,
            };
        case ApiConstants.SHUFFLE:
            return {
                ...state,
                shuffle: action.payload,
            };
        case ApiConstants.OPEN_RULE_BOOK:
            return {
                ...state,
                openRuleBook: action.openRuleBook,
            };
        case ApiConstants.IS_SPECTATOR:
            return {
                ...state,
                isSpectator: action.payload,
            };
        case ApiConstants.SHOW_USER_DETAIL:
            return {
                ...state,
                showUserDetail: action.showUserDetail,
            };
        case ApiConstants.UPDATE_PART_COMPLETE:
            return {
                ...state,
                isPart1Completed: action.payload,
            };
        case ApiConstants.IS_SOMEONE_LEFT:
            return {
                ...state,
                isSomeoneLeft: action.payload,
            };
        case ApiConstants.USE_INFLUENCE_CARD:
            return {
                ...state,
                useInfluenceCard: action.payload,
            };
        case ApiConstants.SOUND_ON_OFF:
            return {
                ...state,
                toggleSound: action.payload,
            };

        case ApiConstants.WINNER_DETAILS:
            return {
                ...state,
                winnerDetails: action?.winnerDetails,
            }

        ////AboutUs Reducer.....
        case ApiConstants.API_ABOUT_US_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_ABOUT_US_SUCCESS:
            return {
                ...state,
                aboutUs: action?.result?.aboutUs,
                onLoad: false
            }

        case ApiConstants.API_ABOUT_US_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_ABOUT_US_FAIL:
            return { ...state, onLoad: false }
        ////Privacy Reducer.....
        case ApiConstants.API_PRIVACY_POLICY_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_PRIVACY_POLICY_SUCCESS:
            return {
                ...state,
                privacyPolicy: action?.result?.privacyPolicy,
                onLoad: false
            }

        case ApiConstants.API_PRIVACY_POLICY_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_PRIVACY_POLICY_FAIL:
            return { ...state, onLoad: false }

        ////FAQ Reducer.....
        case ApiConstants.API_FAQ_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_FAQ_SUCCESS:
            return {
                ...state,
                faq: action?.result,
                onLoad: false
            }

        case ApiConstants.API_FAQ_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_FAQ_FAIL:
            return { ...state, onLoad: false }
        ////LEADER BOARD Reducer.....
        case ApiConstants.API_LEADER_BOARD_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_LEADER_BOARD_SUCCESS:
            return {
                ...state,
                leaderBoard: action?.result,
                onLoad: false
            }

        case ApiConstants.API_LEADER_BOARD_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_LEADER_BOARD_FAIL:
            return { ...state, onLoad: false }
        ////Update Profile Reducer.....
        case ApiConstants.API_UPDATE_PROFILE_LOAD:
            return { ...state, onLoad: true }

        case ApiConstants.API_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                // leaderBoard: action?.result,
                onLoad: false
            }

        case ApiConstants.API_UPDATE_PROFILE_ERROR:
            return { ...state, onLoad: false }

        case ApiConstants.API_UPDATE_PROFILE_FAIL:
            return { ...state, onLoad: false }

        case ApiConstants.API_CHECK_FOR_ANY_SUBSCRIPTION_SUCCESS:
            return { ...state, isPurchased: action.payload }

        case ApiConstants.API_T_AND_C_SUCCESS:
            return {
                ...state,
                termAndConditions: action?.result?.terms,
            }

        case ApiConstants.API_BLOCKED_USERS_LIST_SUCCESS:
            return {
                ...state,
                blockedUsersList: action.payload
            }
        case ApiConstants.TRADE_TILE_AGAIN:
            return {
                ...state,
                tradeTileAgain: action.payload,
            };
        case ApiConstants.IS_TRADE_AVAILABLE:
            return {
                ...state,
                isTradeAvailable: action.payload,
            };
        case ApiConstants.LOGS_DATA:
            return {
                ...state,
                logsData: action.payload
            };
        case ApiConstants.DISPLAY_CONTINUE_BUTTON:
            return {
                ...state,
                displayContinueButton: action.payload
            };
        case ApiConstants.GET_RANGE:
            return {
                ...state,
                range: action.payload,
            };

        case ApiConstants.VIEW_ALL_CARDS:
            return {
                ...state,
                viewAllCards: action.payload,
            };
        case ApiConstants.SHOW_MENU_BUTTON:
            return {
                ...state,
                showMenuButton: action.payload,
            };
        case ApiConstants.SAVE_DIALOG_ID:
            return {
                ...state,
                dialogId: action.payload.id
            };
        case ApiConstants.PAUSE_GAME:
            return {
                ...state,
                pauseGame: action.data,
            };
        default:
            return state;
    }
}


export default homeReducer;

