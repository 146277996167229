import React from 'react';
import { gameScreenDimentions } from '../../helper/globalFunctions';
import BackAndModal from './BackAndModal';
import GameBoard from './GameBoard';
import GameUsers from './GameUsers';
import SmallTimer from './SmallTimer';
import TilesContainer from './TilesContainer';
import MessageComponent from './messageComponent';
import ShowUserDetails from './showUserDetails';

import SelectDiceComponent from './selectDiceComponent';
import TextCardBox from './textCardBox';
import NoInternet from './noInternetComponent';
import ReloadFocus from './reloadFocus';
import Pawn from './pawn';
import UserCard from './UserCards';
import MoveCardComponent from './moveCardComponent';
import PlayLotteryCard from './playLotteryCard';
import ShowCardComponent from './showCardComponent';
import ViewAllCards from './viewAllCards';
import ShowMenuComponent from './showMenuComponent';
import GamePausedComponent from './gamePausedComponent';

const Game = (props) => {

  return (
    <div className='gameScreen' style={{ width: gameScreenDimentions.width, height: gameScreenDimentions.height }}>
      <span
        style={{
          color: '#46c1af',
          fontSize: gameScreenDimentions.width * 0.02,
          paddingLeft: 1,
          position: 'absolute',
          fontWeight: '600',
          left: gameScreenDimentions.width * 0.42,
          top: gameScreenDimentions.width * 0.004,
        }}
      >Game Screen</span>
      <Pawn />
      <ShowCardComponent />
      <ViewAllCards />
      <GameBoard />
      <TilesContainer />
      <UserCard />
      <GameUsers />
      <MessageComponent />
      <TextCardBox />
      <MoveCardComponent />
      <PlayLotteryCard />
      <ShowUserDetails />
      <SelectDiceComponent />
      <SmallTimer />
      <BackAndModal {...props} />
      <ShowMenuComponent />
      <GamePausedComponent />
      <NoInternet />
      <ReloadFocus />
    </div>
  )

}
export default Game