import { useState, useEffect } from 'react';
import InputField from '../../common/textFiled';
import { commonImages } from '../../themes/appImage';
import Header from '../../common/header';
import { isInternetConnected } from '../../helper/networkUtils';
import { socket } from '../../helper/socket';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import { trimText } from '../../utils/validators/validator';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { popupType } from '../../themes/constants/gameConstants';
import { DataManager } from '../../helper/dataManager';
import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';


const CustomRoom = (props) => {

  const [roomId, setRoomId] = useState('');
  const [on, setOn] = useState(true);

  useEffect(() => {
    DataManager.removeLeaveGameStatus()
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    setOn(true)
  }, [])

  return (
    <div>
      <div className='background_image'>
        <Header title='Private Room' settings={false} />

        <div className='join_room space_join'>
          <div></div>
          <div className='main_pop data_one respo'>
            <div className='img_y join_room_rooms'>
              {/* <img src={commonImages.passwordimg}/> */}
              <h2>Join Room</h2>
            </div>
            <div>
              <div className='form-group'>
                <label>Room ID</label>
                <InputField
                  type='text'
                  placeholder="Room ID"
                  width='70%'
                  height='35px'
                  className='formcontrol'
                  value={trimText(roomId)}
                  onChange={e => {
                    let txt = trimText(e.target.value);
                    setRoomId(txt);
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 70, }}>
                <ButtonCustom
                  resize={"cover"}
                  outerContainer={{ height: 49, alignSelf: 'center', cursor: 'pointer' }}
                  textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                  style={{ height: 49, width: 180, borderRadius: 4 }}
                  // iContainer={{ height: 49 }}
                  imgBg={appImages.buttonBg}
                  title={'Spectate'}
                  className="send_new"
                  onPress={() => {
                    if (isInternetConnected()) {
                      if (roomId.trim()) {
                        if (on) {
                          setOn(false)
                          socket.emit('joinRoom', { roomId: roomId, type: 'spectate' });
                          setTimeout(() => {
                            setOn(true)
                          }, 1000)
                        }
                      }
                      else {
                        ShowAlertMessage(ValidationConstants.roomId, popupType.error, 3000);
                      }
                    } else {
                      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                    }
                  }}
                />
                <ButtonCustom
                  resize={"cover"}
                  outerContainer={{ height: 49, alignSelf: 'center', cursor: 'pointer' }}
                  textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                  style={{ height: 49, width: 180, borderRadius: 4 }}
                  // iContainer={{ height: 49 }}
                  imgBg={appImages.buttonBg}
                  title={'Join'}
                  className="send_new"
                  onPress={() => {
                    if (isInternetConnected()) {
                      if (roomId.trim()) {
                        if (on) {
                          setOn(false)
                          socket.emit('joinRoom', { roomId: roomId, type: 'player' });
                          setTimeout(() => {
                            setOn(true)
                          }, 1000)
                        }
                      }
                      else {
                        ShowAlertMessage(ValidationConstants.roomId, popupType.error, 3000);
                      }
                    } else {
                      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                    }
                  }}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default CustomRoom