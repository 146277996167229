import { modal, textColor, textInput } from '../../themes/colors/colors';
import fontSizes from '../../themes/constants/fontSizes';
import { spacing } from '../../themes/constants/spacing';
import { FontFamily } from '../../themes/fontFamily/fontFamily';

const styles = {
    outerContainer: {
        elevation: 5,
        flex: 1,
        alignItems: 'center',
        backgroundColor: modal.outerView,
    },
    outerModalView: {
        width: '100%',
        paddingTop: spacing.scH12cent,
        elevation: 5,
        alignItems: "center",
    },
    modalView: {
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 25,
        alignItems: "flex-start",
        width: spacing.scW40cent + spacing.scW40cent + spacing.scW8cent,
        borderWidth: 1.5,
        borderColor: textInput.green,
        overflow: 'hidden',
        paddingTop: 2,
        paddingBottom: 2
    },
    fontLabel: {
        includeFontPadding: false,
        fontWeight: 800,
        color: textColor.green,
        fontSize: fontSizes.f75,
        textAlignVertical: 'center',
        textAlign: 'left',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    fontText: {
        includeFontPadding: false,
        fontWeight: 600,
        color: textColor.white,
        fontSize: fontSizes.f50,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
    fontTextHeading: {
        includeFontPadding: false,
        fontWeight: 800,
        color: textColor.green,
        fontSize: fontSizes.f50,
        textAlignVertical: 'center',
        textAlign: 'left',
    },
}

export default styles;