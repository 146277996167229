import React from 'react';
import fontSizes from '../../themes/constants/fontSizes';

const ButtonCustom = (props) => {

    const {
        imgBg,
        title,
        onPress,
        disabled,
        className
    } = props;

    const disabledStyle = disabled && {
        width: '100%', height: '100%', backgroundColor: '#00000077',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'not-allowed'
    }

    return (
        <div style={{ alignItems: 'center', ...props.outerContainer }} className={className}>
            <div
                disabled={disabled}
                style={{ ...styles.innerContainer, ...props.style }}
                onClick={onPress}>
                <div style={{
                    ...styles.container, ...props.iContainer, ...{
                        backgroundImage: `url(${imgBg})`,
                        backgroundRepeat: 'round',
                    }
                }}>
                    <span
                        style={{ ...styles.textStyle, ...props.textColor, ...disabledStyle }}>
                        {title}
                    </span>
                </div>
            </div>
            {
                disabled &&
                <div
                    style={{ ...styles.disabledContainer, ...props.disabledStyle }}>
                </div>
            }
        </div>
    );
}



const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: 45,
        width: '100%',
        objectFit: 'contain',
        display: 'flex'
    },
    innerContainer: {
        height: 45,
        width: 120,
        flexDirection: 'row',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex'
    },
    disabledContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: 44,
        flexDirection: 'row',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: "#00000088",
        display: 'flex'
    },
    textStyle: {
        fontSize: fontSizes.f45,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
        textAlignVertical: 'top',
        includeFontPadding: false,
        paddingBottom: 2,
        display: 'flex'
    }
}

export default ButtonCustom;