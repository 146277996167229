import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import ChangePassword from "../components/changePassword";
import ContactUs from "../components/contactus";
import CreateRoom from "../components/createRoom";
import CustomRoom from "../components/customRoom";
import GameLobby from "../components/gameLobby";
import GameWinner from "../components/gameWinner";
import JoinRoom from "../components/join";
import LeaderBoard from "../components/leaderboard";
import Blockedusers from "../components/blockedusers";
import Login from "../components/Login";
import GameLoser from "../components/LoseGame";
import Package from "../components/package";
import PlayJoin from "../components/playJoin";
import Profile from "../components/profile";
import ProfileView from "../components/profile/myprofile";
import PublicRoom from "../components/publicRooms";
import SoloPlayRoom from "../components/soloPlayRoom";
import Register from "../components/register";
import Settings from "../components/settings";
import Aboutus from "../components/settings/aboutus";
import PointsBreakdown from "../components/settings/pointsBreakdown";
import FAQ from "../components/settings/faq";
import GameHelp from "../components/settings/gamehelp";
import PrivacyPolicy from "../components/settings/privacypolicy";
import Terms from "../components/settings/terms";

import { PrivateRoute, PublicRoute } from './screen';
import { DataManager } from '../helper/dataManager'
import { setAccessToken } from '../redux/axios/apiMethods.js';
import ApiConstants from '../themes/apiConstants/index.js';
import Game from "../components/game";
import HelpCenter from "../components/helpCenter";

const RoutesNew = () => {
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    getToken()
  }, [])

  const getToken = () => {
    const tempToken = DataManager.getAccessToken();
    const tempUserId = DataManager.getUserId();

    if (tempToken && tempToken !== null) {
      setAccessToken(tempToken);
      dispatch({
        type: ApiConstants.API_LOGIN_SUCCESS,
        result: tempUserId,
      })
    }
  }

  return (
    <Routes>
      <Route path="/"
        element={
          DataManager.getAccessToken() ?
            (<Navigate to="/playjoin" />)
            : (<Navigate to="/login" />)
        }
      />
      <Route path="/*" element={<Navigate to="/" />} />

      {/*Public Route without localStorage only */}
      <Route path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route path="/register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />

      {/* protected routes */}
      <Route exact path="/playjoin"
        element={
          <PrivateRoute type='playjoin'>
            <PlayJoin />
          </PrivateRoute>
        }
      />
      <Route path="/changepassword"
        element={
          <PrivateRoute type='changepassword'>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route path="/gameHelp"
        element={
          <PrivateRoute type='gameHelp'>
            <HelpCenter />
          </PrivateRoute>
        }
      />
      <Route exact path="/profile"
        element={
          <PrivateRoute type='profile'>
            <ProfileView />
          </PrivateRoute>
        }
      />
      <Route exact path="/profileview"
        element={
          <PrivateRoute type='profileview'>
            <ProfileView />
          </PrivateRoute>
        }
      />
      <Route path="/publicroom"
        element={
          <PrivateRoute type='publicroom'>
            <PublicRoom />
          </PrivateRoute>
        }
      />

      <Route path="/soloPlayRoom"
        element={
          <PrivateRoute type='soloPlayRoom'>
            <SoloPlayRoom />
          </PrivateRoute>
        }
      />


      <Route path="/leaderboard"
        element={
          <PrivateRoute type='leaderboard'>
            <LeaderBoard />
          </PrivateRoute>
        }
      />
      <Route path="/blockedusers"
        element={
          <PrivateRoute type='blockedusers'>
            <Blockedusers />
          </PrivateRoute>
        }
      />
      <Route path="/settings"
        element={
          <PrivateRoute type='settings'>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route path="/createroom"
        element={
          <PrivateRoute type='createroom'>
            <CreateRoom />
          </PrivateRoute>
        }
      />
      <Route path="/package"
        element={
          <PrivateRoute type='package'>
            <Package />
          </PrivateRoute>
        }
      />
      <Route path="/room"
        element={
          <PrivateRoute type='room'>
            <JoinRoom />
          </PrivateRoute>
        }
      />
      <Route path="/customroom"
        element={
          <PrivateRoute type='customroom'>
            <CustomRoom />
          </PrivateRoute>
        }
      />
      <Route path="/GameLobby"
        element={
          <PrivateRoute type='GameLobby'>
            <GameLobby />
          </PrivateRoute>

        }
      />
      <Route path="/Game"
        element={
          <PrivateRoute type='Game'>
            <Game />
          </PrivateRoute>

        }
      />
      <Route path="/faq"
        element={
          <PrivateRoute type='faq'>
            <FAQ />
          </PrivateRoute>
        }
      />
      <Route path="/contactus"
        element={
          <PrivateRoute type='contactus'>
            <ContactUs />
          </PrivateRoute>
        }
      />
      <Route path="/gamewinner"
        element={
          <PrivateRoute type='gamewinner'>
            <GameWinner />
          </PrivateRoute>
        }
      />
      <Route path="/gameloser"
        element={
          <PrivateRoute type='gameloser'>
            <GameLoser />
          </PrivateRoute>
        }
      />
      <Route path="/aboutus"
        element={
          <PrivateRoute type='aboutus'>
            <Aboutus />
          </PrivateRoute>
        }
      />
      <Route path="/pointsBreakdown"
        element={
          <PrivateRoute type='pointsBreakdown'>
            <PointsBreakdown />
          </PrivateRoute>
        }
      />
      <Route path="/privacy"
        element={
          <PrivateRoute type='privacy'>
            <PrivacyPolicy />
          </PrivateRoute>
        }
      />
      <Route path="/terms"
        element={
          <PrivateRoute type='terms'>
            <Terms />
          </PrivateRoute>
        }
      />
      <Route path="/gamehelp"
        element={
          <PrivateRoute type='gamehelp'>
            <GameHelp />
          </PrivateRoute>
        }
      />
    </Routes>
  )

}
export default RoutesNew