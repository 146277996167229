import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../common/header';
import { commonImages } from '../../themes/appImage';
import { isInternetConnected } from '../../helper/networkUtils';
import { leaderBoardAction } from '../../redux/actions/homeActions/homeActions';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { popupType } from '../../themes/constants/gameConstants';
import { ValidationConstants } from '../../themes/constants/validationConstants';
import strings from '../../themes/constants/strings'
import { url } from '../../redux/axios/apikit';

const LeaderBoard = (props) => {
  const dispatch = useDispatch()
  const leaderBoard = useSelector((state) => state.homeReducer.leaderBoard);
  console.log(leaderBoard);
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

    if (isInternetConnected()) {
      dispatch(leaderBoardAction());
    } else {
      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
    }

  }, [])

  return (
    <div>
      <div className='background_image' style={{ paddingBottom: '79px' }}>
        <Header title='Leaderboard' settings={false} redirect={true} />

        <div className='room lobby mt_12'>
          <div className='scroll_div'>
            {
              leaderBoard && leaderBoard.length > 0 ? (
                leaderBoard.map((item, index) => {
                  return (
                    <div className='inner_room'>
                      <div className='first_join home'>
                        <img src={`${url}${item.profileImage}`} style={{ width: 50, height: 50, borderRadius: 25, borderStyle: 'solid', borderWidth: 2, borderColor: '#2E8F81' }} />
                        <div className='hostname'>
                          <h4>{item.name}</h4>
                          <p>{strings.totalWins} {item.totalWins}</p>
                        </div>
                      </div>
                      <div className='wins_ends'>
                        <div className='Life_wins'>
                          <p>{strings.sdCompetitiveWins} {item.sdCompetitive}</p>
                          <p>{strings.sndCompetitiveWins} {item.sndCompetitive}</p>
                        </div>
                        <div className='Life_wins'>
                          <p>{strings.sdCooperativeWins} {item.sdCooperative}</p>
                          <p>{strings.sndCooperativeWins} {item.sndCooperative}</p>
                        </div>
                      </div>
                    </div>
                  )
                })

              ) : (
                <div className='no-data-found'><div className='no_data_found_child'>No data available.</div></div>
              )
            }
          </div>

        </div>

      </div>
    </div>
  )

}
export default LeaderBoard