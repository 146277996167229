import React from "react";
import { spacing } from "../../../themes/constants/spacing";
import strings from "../../../themes/constants/strings";
import Spacer from "../Spacer";
import ButtonCustom from "../ButtonCustom";
import styles from './styles';
import fontSizes from "../../../themes/constants/fontSizes";
import appImages from "../../../themes/appImages/appImages";

function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });

    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

const ReloadResize = () => {
    const [isResized, setIsResized] = React.useState(false)
    const [currentSize, setCurrentSize] = React.useState({ width: undefined, height: undefined })
    const size = useWindowSize();

    React.useEffect(() => {
        if (!currentSize.width && !currentSize.height) {
            setCurrentSize(size)
        }
        else if (currentSize.width && currentSize.height) {
            if (currentSize.height != size.height) {
                if (currentSize.height > size.height && (currentSize.height - size.height) > 30) {
                    setIsResized(true)
                }
                else if (currentSize.height < size.height && (size.height - currentSize.height) > 30) {
                    setIsResized(true)
                }
            }
            else if (currentSize.width != size.width) {
                setIsResized(true)
            }
        }
    }, [currentSize, size])

    return (
        <>
            {isResized && <div style={styles.outerContainer}>
                <div style={styles.modalView} >
                    <Spacer height={spacing.l} />
                    <span style={styles.selectTile}>{strings.reloadResize}</span>
                    <Spacer height={spacing.l} />
                    <ButtonCustom
                        textColor={{ fontSize: fontSizes.f40 }}
                        imgBg={appImages.buttonBg}
                        title={'Reload'}
                        onPress={() => {
                            window.location.reload()
                        }}
                    />
                    <Spacer height={spacing.l} />
                </div>
            </div>
            }
        </>

    );
};

export default ReloadResize;

