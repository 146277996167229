import react from 'react';
import { Modal, Button } from 'antd'

const ModalPop = (props) => {

    const {
        title,
        content,
        btnSuccess,
        btnCancel,
        handleSuccess,
        handleCancel,
        isVisible,
        center,
        maskClosable
    } = props


    return (
        <div>
            <Modal
                className={center}
                title={title}
                visible={isVisible}
                onOk={handleSuccess}
                onCancel={handleCancel}
                maskClosable={maskClosable}
                footer={[
                    btnSuccess ? btnSuccess : null
                ]}
                style={{ zIndex: 9 }}
            >
                <p>{content}</p>
            </Modal>
        </div>
    )

}

export default ModalPop;