import { useSelector } from 'react-redux';
import { commonImages } from '../../themes/appImage';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { url } from '../../redux/axios/apikit'


const Header = (props) => {
    const navigate = useNavigate();

    const userData = useSelector((state) => state.authenticationReducer.userData);

    return (
        <div className='top_header'>
            {props.redirect == false ?
                <Link to='/profileview'> <img style={{ height: '60px', width: '60px', borderRadius: '50%', border: '1px solid rgb(51, 194, 173)' }} src={`${url}${userData?.profileImage}`} /></Link> :
                <img src={commonImages.back} onClick={() => navigate(-1)} className="back_img" />}
            <span>{props.title}</span>
            {props.modal ? <img onClick={() => {
                props?.setIsModalRuleVisible(true)
            }} height={50} width={50} style={{ cursor: 'pointer' }} alt='' src={commonImages.info} /> : props.settings == false ? <img src={commonImages.settings} style={{ visibility: 'hidden', width: '50px' }} /> :
                <Link to='/settings'>
                    <img src={commonImages.settings} style={{ width: '50px' }} />
                </Link>
            }
        </div>
    )
}
export default Header
