import { gameScreenDimentions } from '../../../helper/globalFunctions';
import { modal, textColor } from '../../../themes/colors/colors';
import fontSizes from '../../../themes/constants/fontSizes';

const styles = {
    outerContainer: {
        height: '100%',
        alignItems: 'center',
        backgroundColor: modal.outerView,
        width: '100%',
        zIndex: 9,
        position: 'absolute'
    },
    closeBtnView: {
        width: 40,
        height: 40,
        left: -13,
        top: -10,
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        cursor: 'pointer'
    },
    innerView: {
        backgroundColor: 'rgb(33,33,33)',
        height: gameScreenDimentions.height * 0.75,
        width: gameScreenDimentions.height * 0.61,
        borderRadius: 10,
        position: 'absolute',
        bottom: 20,
        right: 40,
    },
    chatBoxView: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 30,
        paddingTop: 4,
        paddingBottom: 4,
        height: gameScreenDimentions.height * 0.67,
        marginBottom: 20,
        borderRadius: 10,
        borderBottomRightRadius: 25,
        borderBottomLeftRadius: 25,
        borderColor: 'rgb(0,132,118)',
        borderWidth: 2,
        borderStyle: 'solid',
        backgroundColor: 'rgb(47,47,47)',
    },
    chatBoxView1: {
        marginLeft: 8,
        marginRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        height: gameScreenDimentions.height * 0.65,
    },
    chatTextView: {
        width: 150,
        backgroundColor: 'rgb(39,38,38)',
        borderRadius: 15,
        justifyContent: 'center',
        padding: 10,
        marginLeft: 10,
        marginBottom: 5,
    },
    selectTile: {
        flex: 1,
        color: textColor.green,
        fontWeight: '600',
        fontSize: fontSizes.f33,
        paddingStart: 8
    },
    dateStyle: {
        color: textColor.green,
        textAlign: "center",
        fontWeight: '600',
        fontSize: fontSizes.f33,
    },
    viewStyle: {
        flexDirection: 'row',
        // marginLeft: 8,
        // marginRight: 8
    },
    card1: {
        backgroundColor: 'white',
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
        borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
        borderWidth: 1,
        borderColor: '#46c1af55',
        overflow: 'hidden',
        marginLeft: gameScreenDimentions.gameBoardWidth * 0.08,
        marginTop: 4,
        borderStyle: 'solid'
    },
    imgStyle: {
        width: gameScreenDimentions.gameBoardWidth * 0.162,
        height: gameScreenDimentions.gameBoardWidth * 0.212,
    }
}

export default styles;