import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging'
const firebaseConfig = {
    apiKey: "AIzaSyDRTXqtozJ42Li1CK8YdQuZY-bIInTY6GQ",
    authDomain: "disparity-trap-board-game.firebaseapp.com",
    projectId: "disparity-trap-board-game",
    storageBucket: "disparity-trap-board-game.appspot.com",
    messagingSenderId: "597667129909",
    appId: "1:597667129909:web:2bc586c05f23017f1ddf7c",
    measurementId: "G-E9X3JT25BH"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const chatCollection = firestore.collection('chatRoom');
const chatWithAdmin = firestore.collection('chatWithAdmin');
const usersList = firestore.collection('users');

export const FirebaseMethods = {
    ///Creating room
    async createInitialRoom(roomId) {
        chatCollection.doc(roomId)
            .set({ roomId: roomId })
    },

    ///Saving messages and all other data in firbase store.
    async saveMessageToFirebase(messagePayload, roomId) {
        await chatCollection.doc(roomId).collection('messageList').add(messagePayload)
    },

    ///Gettng all messages from firestore
    async getAllMessages(callback, roomId, limit, msgFrom) {
        if (msgFrom) {
            await chatCollection
                .doc(roomId)
                .collection("messageList")
                .limitToLast(10)
                .orderBy("createdAt")
                .endBefore(msgFrom)
                .onSnapshot(querySnapshot => {
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr);
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        } else {
            await chatCollection
                .doc(roomId)
                .collection("messageList")
                .orderBy("createdAt")
                // .limitToLast(10)
                .onSnapshot(querySnapshot => {
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr.reverse());
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        }

    },

    //// Chat With Admin

    async createRoomWithAdmin(userId) {
        chatWithAdmin.doc(userId)
            .set({ userId: userId })
    },

    ///Saving messages and all other data in firbase store.
    async saveAdminMessageToFirebase(messagePayload, userId) {
        const { id } = await chatWithAdmin.doc(userId).collection('chatList')
            .add(messagePayload)
        if (id) {
            messagePayload.messageId = id
            chatWithAdmin.doc(userId).collection('chatList').doc(id).set(messagePayload)
                .then((res) => {
                }).catch((e) => { alert(e) });
        }
    },

    ///Gettng all messages from firestore
    async getAllMessagesWithAdmin(callback, userId, limit, msgFrom) {
        console.log('getAllMessages', msgFrom)
        if (msgFrom) {
            await chatWithAdmin
                .doc(userId)
                .collection("chatList")
                .limitToLast(10)
                .orderBy("createdAt")
                .endBefore(msgFrom)
                .onSnapshot(querySnapshot => {
                    console.log('querySnapshot', querySnapshot)
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr);
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        } else {
            await chatWithAdmin
                .doc(userId)
                .collection("chatList")
                .orderBy("createdAt")
                .limitToLast(10)
                .onSnapshot(querySnapshot => {
                    console.log('querySnapshot', querySnapshot)
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr);
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        }

    },

    //// Create user list
    async createUserList(userId, isOnline) {
        usersList.doc(userId)
            .set({ userId: userId, isOnline: isOnline })
    },

}

