import { gameScreenDimentions } from '../../helper/globalFunctions';

export const spacing = {
    xxxs: 4,
    xxs: 8,
    xs: 12,
    s: 16,
    m: 24,
    l: 32,
    xl: 40,
    xxl: 48,
    xxxl: 64,
    macro: 80,
    oneCent: "1%",
    TwoCent: "2%",
    fourCent: "4%",
    fiveCent: "5%",
    eightCent: "8%",
    twelveCent: "12%",
    sixteenCent: "16%",
    cent24: "24%",
    cent32: "32%",
    cent40: "40%",
    scH1cent: gameScreenDimentions.height / 100,
    scH2cent: gameScreenDimentions.height / 50,
    scH3cent: gameScreenDimentions.height * 3 / 100,
    scH4cent: gameScreenDimentions.height / 25,
    scH5cent: gameScreenDimentions.height / 20,
    scH6cent: gameScreenDimentions.height * 3 / 50,
    scH7cent: gameScreenDimentions.height * 7 / 100,
    scH8cent: gameScreenDimentions.height * 2 / 25,
    scH10cent: gameScreenDimentions.height / 10,
    scH11cent: gameScreenDimentions.height * 11 / 100,
    scH12cent: gameScreenDimentions.height * 3 / 25,
    scH15cent: gameScreenDimentions.height * 3 / 20,
    scH16cent: gameScreenDimentions.height * 4 / 25,
    scH20cent: gameScreenDimentions.height / 5,
    scH24cent: gameScreenDimentions.height * 6 / 25,
    scH32cent: gameScreenDimentions.height * 8 / 25,
    scH38cent: gameScreenDimentions.height * 0.38,
    scH40cent: gameScreenDimentions.height * 2 / 5,
    scH44cent: gameScreenDimentions.height * 0.44,
    scH50cent: gameScreenDimentions.height / 2,
    scH70cent: gameScreenDimentions.height * 7 / 10,
    scW1cent: gameScreenDimentions.width / 100,
    scW2cent: gameScreenDimentions.width / 50,
    scW3cent: gameScreenDimentions.width * 0.03,
    scW4cent: gameScreenDimentions.width / 25,
    scW5cent: gameScreenDimentions.width / 20,
    scW5pcent: gameScreenDimentions.width * .055,
    scW6cent: gameScreenDimentions.width * .06,
    scW8cent: gameScreenDimentions.width * 2 / 25,
    scW12cent: gameScreenDimentions.width * 3 / 25,
    scW15cent: gameScreenDimentions.width * 3 / 20,
    scW16cent: gameScreenDimentions.width * 4 / 25,
    scW20cent: gameScreenDimentions.width / 5,
    scW22cent: gameScreenDimentions.width * 0.22,
    scW23cent: gameScreenDimentions.width * 0.23,
    scW24cent: gameScreenDimentions.width * 6 / 25,
    scW28cent: gameScreenDimentions.width * 7 / 25,
    scW30cent: gameScreenDimentions.width * 3 / 10,
    scW32cent: gameScreenDimentions.width * 8 / 25,
    scW34cent: gameScreenDimentions.width * 0.34,
    scW37cent: gameScreenDimentions.width * 0.37,
    scW40cent: gameScreenDimentions.width * 2 / 5,
    scW42cent: gameScreenDimentions.width * 0.42,
    scW43cent: gameScreenDimentions.width * 0.43,
    scW50cent: gameScreenDimentions.width / 2,
    scW60cent: gameScreenDimentions.width * 0.60,
    scW66cent: gameScreenDimentions.width * 0.66,
    scW78cent: gameScreenDimentions.width * 0.78,
    scW88cent: gameScreenDimentions.width * 0.88,
};