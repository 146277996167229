import react, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Linkify from 'react-linkify';
import Header from '../../../common/header';
import { ShowAlertMessage } from '../../../helper/showAlertMessage';
import { popupType } from '../../../themes/constants/gameConstants';
import { ValidationConstants } from '../../../themes/constants/validationConstants';
import strings from '../../../themes/constants/strings'
import { isInternetConnected } from '../../../helper/networkUtils';
import { privacyPolicyAction } from '../../../redux/actions/homeActions/homeActions';


const PrivacyPolicy = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

    if (isInternetConnected()) {
      dispatch(privacyPolicyAction());
    } else {
      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
    }

  }, [])

  const privacyPolicy = useSelector((state) => state.homeReducer.privacyPolicy);
  const newlineText = (data) => {
    const text = data;
    const newText = text.split('\n').map(str => <p style={{
      fontSize: 18,
      color: '#fff',
      borderTop: '0px solid #fff',
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent'
    }}>{str}</p>);
    return newText;
  }
  return (
    <div>
      <div className='background_image '>
        <Header title={strings.privacyPolicy} settings={false} redirect={true} />
        <div className='faq_page' style={{
          background: 'Linear-gradient(-109deg, rgba(39, 40, 40, 0.99608) 0%, rgb(46, 47, 47) 100%)'
        }}>
          <span className='tet_color' style={{
            whiteSpace: 'pre-wrap',
          }}>
            <Linkify>{privacyPolicy}</Linkify>
          </span>
        </div>
      </div>
    </div>
  )

}
export default PrivacyPolicy