import { commonImages } from '../../themes/appImage';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/header';
import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';


const JoinRoom = (props) => {
    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)

    }, [])

    return (
        <div>
            <div className='background_image '>
                <Header title='Join Room' settings={false} redirect={true} />
                <div className='join_room space_join'>
                    <div></div>
                    <Link to="/customroom">
                        <ButtonCustom
                            resize={"cover"}
                            outerContainer={{ height: 49, alignSelf: 'center' }}
                            textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                            style={{ height: 49, width: 224, borderRadius: 4 }}
                            iContainer={{ height: 49 }}
                            imgBg={appImages.buttonBg}
                            title={'Private Room'}
                            className="send_new"
                        />
                    </Link>
                    <Link to="/publicroom">
                        <img src={commonImages.public} className="send_new" />
                    </Link>

                </div>
            </div>
        </div>




    )

}
export default JoinRoom