import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Header from '../../common/header';
import { isInternetConnected } from '../../helper/networkUtils';
import { commonImages } from '../../themes/appImage';
import { ValidationConstants } from '../../themes/constants/validationConstants'
import { createRoomAction, soloPlayAction } from '../../redux/actions/homeActions/homeActions';
import { DataManager } from '../../helper/dataManager';
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import GameRuleModal from '../../common/ruleModal';
import ModalPop from '../../common/Modal';
import ButtonCustom from '../game/ButtonCustom';
import fontSizes from '../../themes/constants/fontSizes';
import appImages from '../../themes/appImages/appImages';


const CreateRoom = (props) => {

  const dispatch = useDispatch();

  const [gameMode, setGameMode] = useState('SD');
  const [isModalRuleVisible, setIsModalRuleVisible] = useState(false);
  const [playersCount, setPlayersCount] = useState(1);

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    DataManager.removeLeaveGameStatus()
  }, [])

  return (
    <div>
      <div className='background_image'>
        <Header title='Solo Play Room' settings={true} />
        <div className='info_container'>
          <img onClick={() => {
            setIsModalRuleVisible(true)
          }} height={50} width={50} style={{ cursor: 'pointer' }} alt='' src={commonImages.info} />
        </div>
        <div className='new_data_p'>
          <div className='create-room_img' style={{paddingLeft:40, paddingRight:40}}>
            <div className='Auto text'>
              <span className='label_mode' style={{flex:1.68}}>Role Type</span>
              <span className='button-switch special_case'>
                <button type='submit' onClick={() => setGameMode('SD')} className={gameMode === 'SD' ? 'button_switch items one' : 'button_switch items'}>SD</button>
                <button type='submit' onClick={() => setGameMode('SND')} className={gameMode === 'SND' ? 'button_switch itemlast one' : 'button_switch itemlast'}>SND</button>
              </span>
            </div>
            <div className='Auto text lat-child_d'>
              <span className='label_mode' style={{flex:2.5}}>Number of Bot Players</span>
              <span  style={{flex:1}}>
                {/* <button className='add_button'>
                  <img 
                  onClick={() => setPlayersCount((prevState) => prevState > 2 ? prevState - 1 : prevState)} 
                  src={commonImages.add} />
                </button> */}
                <input type='text' disabled value={playersCount} style={{ cursor: 'default', color: '#fff' }} className='form-control control_new'></input>
                {/* <button className='add_button'>
                  <img 
                  onClick={() => setPlayersCount((prevState) => prevState < 6 ? prevState + 1 : prevState)} 
                  src={commonImages.add1} />
                </button> */}
              </span>
            </div>
            <ButtonCustom
            outerContainer={{ width: 250, height: 53, marginTop: 60 }}
            resize={"stretch"}
            style={{ width: 250, height: 53 }}
            iContainer={{ height: 53 }}
            textColor={{ fontSize: fontSizes.f55 }}
            imgBg={appImages.buttonBg}
            title={"Start Game"}
            onPress={() => {
              dispatch(soloPlayAction({ role: gameMode }));
            }}
          />
          </div>
        </div>
      </div>
      {isModalRuleVisible && <ModalPop
        className='center'
        content={<GameRuleModal createRoom />}
        btnSuccess={false}
        btnCancel={false}
        handleSuccess={() => setIsModalRuleVisible(false)}
        handleCancel={() => setIsModalRuleVisible(false)}
        isVisible={isModalRuleVisible}
      />
      }
    </div>
  )
}
export default CreateRoom