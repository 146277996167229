export const DataManagersKeys = {
    access_token: "access_token",
    isLoggedin: "isLoggedin",
    userId: "userId",
    profileData: "profileData",
    composerDetails: "composerDetails",
    onBoarding: "onBoarding",
    fcmToken: "fcmToken",
    postId: "postId",
    currentRoute: "currentRoute",
    leaveGameAction: "leaveGameAction",
    sound: "sound",
};
