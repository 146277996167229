import ApiConstants from '../../themes/apiConstants';

const initialState = {
    contactLoad: false
}

function contactReducer(state = initialState, action) {

    switch (action.type) {

        //// Add Contact
        case ApiConstants.ADD_CONTACT_LOAD:
            return {
                ...state,
                contactLoad: true
            }

        case ApiConstants.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                contactLoad: false
            }

        case ApiConstants.ADD_CONTACT_FAIL:
            return {
                ...state,
                contactLoad: false
            }
        default:
            return state;
    }
}


export default contactReducer;

