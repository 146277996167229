import React from 'react';
import { gameScreenDimentions } from '../../helper/globalFunctions';

import Tile1 from '../../assets/svg/Tile1';
import Tile2 from '../../assets/svg/Tile2';
import Tile3 from '../../assets/svg/Tile3';
import Tile4 from '../../assets/svg/Tile4';
import Tile5 from '../../assets/svg/Tile5';
import Tile6 from '../../assets/svg/Tile6';
import Tile7 from '../../assets/svg/Tile7';
import Tile8 from '../../assets/svg/Tile8';
import Tile9 from '../../assets/svg/Tile9';
import Border from '../../assets/svg/Border';
import Tile10 from '../../assets/svg/Tile10';
import Tile11 from '../../assets/svg/Tile11';
import Tile12 from '../../assets/svg/Tile12';
import Tile13 from '../../assets/svg/Tile13';
import Tile14 from '../../assets/svg/Tile14';
import Tile15 from '../../assets/svg/Tile15';
import Tile16 from '../../assets/svg/Tile16';
import Tile17 from '../../assets/svg/Tile17';
import Tile18 from '../../assets/svg/Tile18';
import Tile19 from '../../assets/svg/Tile19';
import Tile20 from '../../assets/svg/Tile20';
import Tile21 from '../../assets/svg/Tile21';
import Tile22 from '../../assets/svg/Tile22';
import Tile23 from '../../assets/svg/Tile23';
import Tile24 from '../../assets/svg/Tile24';
import Tile25 from '../../assets/svg/Tile25';
import Tile26 from '../../assets/svg/Tile26';
import Tile27 from '../../assets/svg/Tile27';
import Tile28 from '../../assets/svg/Tile28';
import Tile29 from '../../assets/svg/Tile29';
import Tile30 from '../../assets/svg/Tile30';
import Tile31 from '../../assets/svg/Tile31';
import Tile32 from '../../assets/svg/Tile32';
import Tile33 from '../../assets/svg/Tile33';
import Tile34 from '../../assets/svg/Tile34';
import CardBorder from '../../assets/svg/CardBorder';
import CardPlaceholder from '../../assets/svg/CardPlaceholder';
import OpportunityPlaceholder from '../../assets/svg/OpportunityPlaceholder';

const GameBoard = (props) => {

    return (
        <div style={{
            position: 'absolute',
            width: gameScreenDimentions.gameBoardWidth,
            height: gameScreenDimentions.gameBoardHeight,
            alignSelf: 'center',
            bottom: gameScreenDimentions.width * 0.025,
            left: gameScreenDimentions.width * 0.15,
        }}>
            <Border width={gameScreenDimentions.gameBoardWidth} height={gameScreenDimentions.gameBoardHeight} />
            <div style={{
                position: 'absolute',
                left: gameScreenDimentions.gameBoardWidth * 0.355,
                top: gameScreenDimentions.gameBoardWidth * 0.006,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <Tile1
                    width={gameScreenDimentions.gameBoardWidth * 0.082}
                    height={gameScreenDimentions.gameBoardWidth * 0.086} />
                <div
                    style={{
                        position: 'absolute',
                        width: gameScreenDimentions.gameBoardWidth * 0.02,
                        height: gameScreenDimentions.gameBoardWidth * 0.02,
                        backgroundColor: '#000000',
                        borderRadius: gameScreenDimentions.gameBoardWidth * 0.01,
                        justifyContent: 'center',
                        alignItems: "center",
                        top: gameScreenDimentions.gameBoardWidth * 0.03,
                        transform: 'rotate(-90deg)',
                        display: 'flex',
                    }}>
                    <span
                        style={{
                            color: '#46c1af',
                            fontSize: gameScreenDimentions.width * 0.009,
                            fontWeight: '900',
                            textAlign: 'center',
                            marginLeft: -1
                        }}
                    >1</span>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        width: gameScreenDimentions.gameBoardWidth * 0.06,
                        justifyContent: 'center',
                        alignItems: "center",
                        left: -gameScreenDimentions.gameBoardWidth * 0.014,
                        transform: 'rotate(-85deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.014
                    }}>
                    <span
                        style={{
                            color: '#000000',
                            fontSize: 7,
                            fontWeight: '900',
                            paddingLeft: 1,
                            fontStyle: 'italic'
                        }}
                    >START</span>
                </div>
            </div>

            <Tile2
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.4,
                    top: gameScreenDimentions.gameBoardWidth * 0.003,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.102}
                height={gameScreenDimentions.gameBoardWidth * 0.086} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.456,
                    top: gameScreenDimentions.gameBoardWidth * 0.015,
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'rotate(-1deg)',
                    display: 'flex'
                }}>
                <Tile3
                    width={gameScreenDimentions.gameBoardWidth * 0.097}
                    height={gameScreenDimentions.gameBoardWidth * 0.097} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(-65deg)',
                        width: gameScreenDimentions.gameBoardWidth * 0.026,
                        height: gameScreenDimentions.gameBoardWidth * 0.026,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Justice.png')}
                /> */}
            </div>
            <Tile4
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.517,
                    top: gameScreenDimentions.gameBoardWidth * 0.036,
                    transform: 'rotate(-1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.098}
                height={gameScreenDimentions.gameBoardWidth * 0.092} />
            <Tile5
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.565,
                    top: gameScreenDimentions.gameBoardWidth * 0.057,
                    transform: 'rotate(1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.108}
                height={gameScreenDimentions.gameBoardWidth * 0.0924} />
            <Tile6
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.626,
                    top: gameScreenDimentions.gameBoardWidth * 0.0815,
                    transform: 'rotate(1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.096}
                height={gameScreenDimentions.gameBoardWidth * 0.096} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.715,
                    top: gameScreenDimentions.gameBoardWidth * 0.073,
                    transform: 'rotate(-1deg)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile7
                    width={gameScreenDimentions.gameBoardWidth * 0.086}
                    height={gameScreenDimentions.gameBoardWidth * 0.104}
                />

                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(-110deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.025,
                        left: gameScreenDimentions.gameBoardWidth * 0.019,
                        width: gameScreenDimentions.gameBoardWidth * 0.043,
                        height: gameScreenDimentions.gameBoardWidth * 0.043,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Dollar.png')} /> */}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.777,
                    top: gameScreenDimentions.gameBoardWidth * 0.037,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile8
                    width={gameScreenDimentions.gameBoardWidth * 0.100}
                    height={gameScreenDimentions.gameBoardWidth * 0.101} />
                <div
                    style={{
                        position: 'absolute',
                        width: gameScreenDimentions.gameBoardWidth * 0.02,
                        height: gameScreenDimentions.gameBoardWidth * 0.02,
                        backgroundColor: '#000000',
                        borderRadius: gameScreenDimentions.gameBoardWidth * 0.01,
                        justifyContent: 'center',
                        alignItems: "center",
                        display: 'flex',
                        top: gameScreenDimentions.gameBoardWidth * 0.045,
                        left: gameScreenDimentions.gameBoardWidth * 0.042,
                        transform: 'rotate(-22deg)',
                    }}>
                    <span
                        style={{
                            color: '#46c1af',
                            fontSize: gameScreenDimentions.width * 0.009,
                            fontWeight: '900',
                            // marginLeft: -1
                        }}
                    >2</span>
                </div>
            </div>

            <Tile9
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.805,
                    top: gameScreenDimentions.gameBoardWidth * 0.105,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.093}
                height={gameScreenDimentions.gameBoardWidth * 0.092} />
            <Tile10
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.831,
                    top: gameScreenDimentions.gameBoardWidth * 0.157,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.090}
                height={gameScreenDimentions.gameBoardWidth * 0.092} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.849,
                    top: gameScreenDimentions.gameBoardWidth * 0.2125,
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'rotate(-1deg)'
                }}>
                <Tile11
                    width={gameScreenDimentions.gameBoardWidth * 0.080}
                    height={gameScreenDimentions.gameBoardWidth * 0.080} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(-5deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.029,
                        left: gameScreenDimentions.gameBoardWidth * 0.024,
                        width: gameScreenDimentions.gameBoardWidth * 0.043,
                        height: gameScreenDimentions.gameBoardWidth * 0.043,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Dollar.png')} /> */}
            </div>

            <Tile12
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.845,
                    top: gameScreenDimentions.gameBoardWidth * 0.283,

                }}
                width={gameScreenDimentions.gameBoardWidth * 0.077}
                height={gameScreenDimentions.gameBoardWidth * 0.058} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.84,
                    top: gameScreenDimentions.gameBoardWidth * 0.3375,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile13
                    width={gameScreenDimentions.gameBoardWidth * 0.077}
                    height={gameScreenDimentions.gameBoardWidth * 0.077} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(5deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.019,
                        width: gameScreenDimentions.gameBoardWidth * 0.026,
                        height: gameScreenDimentions.gameBoardWidth * 0.026,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Justice.png')} /> */}
            </div>
            <Tile14
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.819,
                    top: gameScreenDimentions.gameBoardWidth * 0.392,
                    transform: 'rotate(1deg)',
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.090}
                height={gameScreenDimentions.gameBoardWidth * 0.087} />
            <Tile15
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.79,
                    top: gameScreenDimentions.gameBoardWidth * 0.4244,
                    transform: 'rotate(-1deg)',
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.084}
                height={gameScreenDimentions.gameBoardWidth * 0.088} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.696,
                    top: gameScreenDimentions.gameBoardWidth * 0.448,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile16
                    width={gameScreenDimentions.gameBoardWidth * 0.093}
                    height={gameScreenDimentions.gameBoardWidth * 0.079} />
                <div
                    style={{
                        position: 'absolute',
                        width: gameScreenDimentions.gameBoardWidth * 0.02,
                        height: gameScreenDimentions.gameBoardWidth * 0.02,
                        backgroundColor: '#000000',
                        borderRadius: gameScreenDimentions.gameBoardWidth * 0.01,
                        justifyContent: 'center',
                        alignItems: "center",
                        display: 'flex',
                        transform: 'rotate(90deg)',
                    }}>
                    <span
                        style={{
                            color: '#46c1af',
                            fontSize: gameScreenDimentions.width * 0.009,
                            fontWeight: '900',
                            paddingLeft: 1
                        }}
                    >3</span>
                </div>
            </div>

            <Tile17
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.6435,
                    top: gameScreenDimentions.gameBoardWidth * 0.454,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.077}
                height={gameScreenDimentions.gameBoardWidth * 0.0755} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.577,
                    top: gameScreenDimentions.gameBoardWidth * 0.458,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile18
                    width={gameScreenDimentions.gameBoardWidth * 0.076}
                    height={gameScreenDimentions.gameBoardWidth * 0.075} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(90deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.020,
                        width: gameScreenDimentions.gameBoardWidth * 0.043,
                        height: gameScreenDimentions.gameBoardWidth * 0.043,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Dollar.png')} /> */}
            </div>
            <Tile19
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.5,
                    top: gameScreenDimentions.gameBoardWidth * 0.452,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.082}
                height={gameScreenDimentions.gameBoardWidth * 0.084} />
            <Tile20
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.45,
                    top: gameScreenDimentions.gameBoardWidth * 0.421,
                    transform: 'rotate(-1deg)',
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.092}
                height={gameScreenDimentions.gameBoardWidth * 0.091} />
            <Tile21
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.42,
                    top: gameScreenDimentions.gameBoardWidth * 0.3833,
                    // transform: 'rotate(-1deg)',
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.087}
                height={gameScreenDimentions.gameBoardWidth * 0.093} />
            <Tile22
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.384,
                    top: gameScreenDimentions.gameBoardWidth * 0.362,
                    transform: 'rotate(-1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.075}
                height={gameScreenDimentions.gameBoardWidth * 0.091}
            />
            <Tile23
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.321,
                    top: gameScreenDimentions.gameBoardWidth * 0.359,
                    transform: 'rotate(1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.077}
                height={gameScreenDimentions.gameBoardWidth * 0.081} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.259,
                    top: gameScreenDimentions.gameBoardWidth * 0.357,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile24
                    style={{
                        transform: 'rotate(1deg)'
                    }}
                    width={gameScreenDimentions.gameBoardWidth * 0.076}
                    height={gameScreenDimentions.gameBoardWidth * 0.079} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(90deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.026,
                        width: gameScreenDimentions.gameBoardWidth * 0.026,
                        height: gameScreenDimentions.gameBoardWidth * 0.026,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Justice.png')} /> */}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.168,
                    top: gameScreenDimentions.gameBoardWidth * 0.35,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    transform: 'rotate(-1deg)'
                }}>
                <Tile25
                    width={gameScreenDimentions.gameBoardWidth * 0.092}
                    height={gameScreenDimentions.gameBoardWidth * 0.082} />
                <div
                    style={{
                        position: 'absolute',
                        width: gameScreenDimentions.gameBoardWidth * 0.02,
                        height: gameScreenDimentions.gameBoardWidth * 0.02,
                        backgroundColor: '#000000',
                        borderRadius: gameScreenDimentions.gameBoardWidth * 0.01,
                        justifyContent: 'center',
                        alignItems: "center",
                        display: 'flex',
                        transform: 'rotate(97deg)',
                    }}>
                    <span
                        style={{
                            color: '#46c1af',
                            fontSize: gameScreenDimentions.width * 0.009,
                            fontWeight: '900',
                            // marginLeft: -1
                        }}
                    >4</span>
                </div>
            </div>

            <Tile26
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.090,
                    top: gameScreenDimentions.gameBoardWidth * 0.331,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.102}
                height={gameScreenDimentions.gameBoardWidth * 0.094} />
            <Tile27
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.087,
                    top: gameScreenDimentions.gameBoardWidth * 0.27,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.087}
                height={gameScreenDimentions.gameBoardWidth * 0.080} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.085,
                    top: gameScreenDimentions.gameBoardWidth * 0.205,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile28
                    width={gameScreenDimentions.gameBoardWidth * 0.0864}
                    height={gameScreenDimentions.gameBoardWidth * 0.080} />

                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(175deg)',
                        top: gameScreenDimentions.gameBoardWidth * 0.034,
                        width: gameScreenDimentions.gameBoardWidth * 0.026,
                        height: gameScreenDimentions.gameBoardWidth * 0.026,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Justice.png')} /> */}
            </div>
            <Tile29
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.0815,
                    top: gameScreenDimentions.gameBoardWidth * 0.152,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.086}
                height={gameScreenDimentions.gameBoardWidth * 0.085} />
            <Tile30
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.078,
                    top: gameScreenDimentions.gameBoardWidth * 0.102,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.086}
                height={gameScreenDimentions.gameBoardWidth * 0.067} />
            <Tile31
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.073,
                    top: gameScreenDimentions.gameBoardWidth * 0.03,
                    transform: 'rotate(1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.1}
                height={gameScreenDimentions.gameBoardWidth * 0.078} />
            <Tile32
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.118,
                    top: gameScreenDimentions.gameBoardWidth * 0.0228,
                    // transform: 'rotate(-1deg)'
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.088}
                height={gameScreenDimentions.gameBoardWidth * 0.082} />
            <div
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.205,
                    top: gameScreenDimentions.gameBoardWidth * 0.02,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Tile33
                    width={gameScreenDimentions.gameBoardWidth * 0.07}
                    height={gameScreenDimentions.gameBoardWidth * 0.081} />
                {/* <img
                    style={{
                        position: 'absolute',
                        transform: 'rotate(-90deg)',
                        width: gameScreenDimentions.gameBoardWidth * 0.043,
                        height: gameScreenDimentions.gameBoardWidth * 0.043,
                        objectFit: 'contain'
                    }}
                    src={require('../../assets/images/gameplayScreen/Dollar.png')} /> */}
            </div>

            <Tile34
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.267,
                    top: gameScreenDimentions.gameBoardWidth * 0.014,
                }}
                width={gameScreenDimentions.gameBoardWidth * 0.07}
                height={gameScreenDimentions.gameBoardWidth * 0.0815} />
            <div
                style={{
                    position: 'absolute',
                    width: gameScreenDimentions.gameBoardWidth * 0.06,
                    justifyContent: 'center',
                    alignItems: "center",
                    left: gameScreenDimentions.gameBoardWidth * 0.314,
                    top: gameScreenDimentions.gameBoardWidth * 0.048,
                    transform: 'rotate(93deg)',
                }}>
                <span
                    style={{
                        color: '#46c1af',
                        fontSize: 7,
                        fontWeight: '900',
                        paddingLeft: 1,
                        fontStyle: 'italic'
                    }}
                >COMPETITIVE</span>
            </div>
            <div
                style={{
                    position: 'absolute',
                    width: gameScreenDimentions.gameBoardWidth * 0.06,
                    justifyContent: 'center',
                    alignItems: "center",
                    left: gameScreenDimentions.gameBoardWidth * 0.762,
                    top: gameScreenDimentions.gameBoardWidth * 0.47,
                    transform: 'rotate(253deg)',
                }}>
                <span
                    style={{
                        color: '#46c1af',
                        fontSize: 7,
                        fontWeight: '900',
                        paddingLeft: 1,
                        fontStyle: 'italic'
                    }}
                >COOPERATIVE</span>
            </div>
            <div
                style={{
                    position: 'absolute',
                    justifyContent: 'center',
                    display: 'flex',
                    left: gameScreenDimentions.gameBoardWidth * 0.2,
                    top: gameScreenDimentions.gameBoardWidth * 0.117,
                }}>
                <CardBorder
                    width={gameScreenDimentions.gameBoardWidth * 0.22}
                    height={gameScreenDimentions.gameBoardWidth * 0.22} />

                <OpportunityPlaceholder
                    style={{
                        position: 'absolute',
                        alignSelf: 'center',
                    }}
                    width={gameScreenDimentions.gameBoardWidth * 0.16}
                    height={gameScreenDimentions.gameBoardWidth * 0.21} />

            </div>
            <div
                style={{
                    position: 'absolute',
                    justifyContent: 'center',
                    display: 'flex',
                    left: gameScreenDimentions.gameBoardWidth * 0.61,
                    top: gameScreenDimentions.gameBoardWidth * 0.196,
                }}>
                <CardBorder
                    width={gameScreenDimentions.gameBoardWidth * 0.22}
                    height={gameScreenDimentions.gameBoardWidth * 0.22} />

                <CardPlaceholder
                    style={{
                        position: 'absolute',
                        alignSelf: 'center',
                    }}
                    width={gameScreenDimentions.gameBoardWidth * 0.16}
                    height={gameScreenDimentions.gameBoardWidth * 0.21} />
            </div>

            <img
                style={{
                    position: 'absolute',
                    left: gameScreenDimentions.gameBoardWidth * 0.42,
                    top: gameScreenDimentions.gameBoardWidth * 0.17,
                    width: gameScreenDimentions.gameBoardWidth * 0.18,
                    height: gameScreenDimentions.gameBoardWidth * 0.18,
                    objectFit: 'contain'
                }}
                src={require('../../assets/images/gameplayScreen/logo.png')} />
        </div>
    );
};

export default GameBoard