import React from "react";
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import appImages from "../../../themes/appImages/appImages";
import fontSizes from "../../../themes/constants/fontSizes";
import strings from "../../../themes/constants/strings";
import ButtonCustom from "../ButtonCustom";
import { buttonClickSound } from "../../../helper/playSound";
import styles from './styles';
import { useSelector } from "react-redux";
import { commonImages } from "../../../themes/appImage";

const HelpComponent = (props) => {
    const [visibility, setVisibility] = React.useState(false);
    const { isSpectator } = useSelector((state) => state.homeReducer);
    return (
        <>
            {visibility && <div style={styles.outerContainer}>
                <div style={styles.modalView}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <img src={commonImages.back}
                            onClick={() => {
                                buttonClickSound();
                                setVisibility(false);
                            }}
                            style={{
                                width: gameScreenDimentions.gameBoardWidth * 0.030,
                                height: gameScreenDimentions.gameBoardWidth * 0.028,
                                cursor: 'pointer'
                            }}
                        />
                        <span style={styles.selectTile}>{strings.tradeTileRule}</span>
                    </div>
                    <img style={styles.imageStyle} src={appImages.tradeHelp} />

                </div>
            </div>}
            <div
                onClick={() => {
                    buttonClickSound();
                    setVisibility(true);
                }}
                style={{
                    position: 'absolute',
                    right: gameScreenDimentions.width * 0.1625,
                    bottom: gameScreenDimentions.width * 0.019,
                    width: gameScreenDimentions.gameBoardWidth * 0.062,
                    height: gameScreenDimentions.gameBoardWidth * 0.062,
                    display: 'flex',
                    cursor: 'pointer',
                    zIndex: 0
                }}
            >
                <img
                    src={appImages.help}
                    style={{
                        width: gameScreenDimentions.gameBoardWidth * 0.062,
                        height: gameScreenDimentions.gameBoardWidth * 0.062,
                        objectFit: "contain"
                    }} />
            </div>
        </>
    );
};

export default HelpComponent;