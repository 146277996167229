import react, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ModalPop from '../../common/Modal';
import InputField from '../../common/textFiled';
import { isInternetConnected } from '../../helper/networkUtils'
import { commonImages } from '../../themes/appImage';
import { loginCheck, trimText, forgotPasswordCheck } from '../../utils/validators/validator';
import { ValidationConstants } from '../../themes/constants/validationConstants'
import { loginAction, forgotPasswordAction, updateAuthenticationForm } from '../../redux/actions/authActions'
import { getLeaveStatus } from '../../helper/globalFunctions'
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';

const Login = (props) => {

  const dispatch = useDispatch()

  const { onLoad, forgotpasswordSuccess } = useSelector((state) => state.authenticationReducer)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSuccessVisible, setIsModalSuccessVisible] = useState(false);
  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [forgotEmail, onChangeForgotEmail] = useState('');


  useLayoutEffect(() => {
    if (forgotpasswordSuccess) {
      showModalSuccess()
      handleCancel()
      onChangeForgotEmail('')
      dispatch(updateAuthenticationForm(false, 'forgotpasswordSuccess'))
    }

  }, [forgotpasswordSuccess])

  const showModal = () => {
    console.log("welcome")
    setIsModalVisible(true);

  };

  const handleOk = () => {
    console.log("::::::::::::::::::")
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    onChangeForgotEmail('')
  };

  const showModalSuccess = () => {
    console.log("welcome")
    setIsModalSuccessVisible(true);
  };

  const handleOkSuccess = () => {
    setIsModalSuccessVisible(false);
  };

  const handleCancelSuccess = () => {
    setIsModalSuccessVisible(false);
  };

  const handleLoginSubmit = () => {
    const isValidationFailed = loginCheck(email, password, ValidationConstants)

    if (isValidationFailed) {
      ShowAlertMessage(isValidationFailed, popupType.error, 3000)
    }
    else {
      if (isInternetConnected()) {
        dispatch(loginAction({
          email: email.trim()?.replace(/ /g, ''),
          password: password?.trim()?.replace(/ /g, ''),
          deviceToken: null,
          deviceType: 'web'
        }));
      } else {
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
      }
    }
  }

  const handleForgotSubmit = () => {
    const isValidationFailed = forgotPasswordCheck(forgotEmail, ValidationConstants)

    if (isValidationFailed) {
      ShowAlertMessage(isValidationFailed, popupType.error, 3000)
    } else {

      if (isInternetConnected()) {
        dispatch(forgotPasswordAction({
          email: forgotEmail?.trim()?.replace(/ /g, '')
        }));
      } else {
        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000)
      }
    }
  }

  const successBtn = (
    <>
      <button className='forgot_text' onClick={() => {
        handleForgotSubmit()
        console.log("hey submit")
      }}>
        <img src={commonImages.send} className="send_new" />
      </button>
    </>
  )
  const successBtnOne = (
    <>
      <button className='forgot_text' onClick={() => handleCancelSuccess()}>
        <img id="forgot_form" src={commonImages.okay} onClick={() => showModalSuccess()} className="send_new" />
      </button>
    </>
  )

  const fragment = (
    <>
      <form
        id="forgot_form"
        onKeyPress={(e) => {
          console.log("enter = ", e)
          if (e.key === 'Enter') {
            handleForgotSubmit()
          }
        }}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div className='main_pop spaceing_small'>
          <div className='img_y passwoerd_modal'>
            <img src={commonImages.passwordimg} />
            <h2>Forgot Password</h2>
          </div>
          <div>
            <div className='form-group'>
              <label>Email Address</label>
              <InputField
                type='text'
                placeholder="Email Address"
                width='70%'
                height='35px'
                className='formcontrol'
                value={forgotEmail?.trim()?.replace(/ /g, '')}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangeForgotEmail(value)
                }}
              />
            </div>
            {/* <img src={commonImages.send} onClick={() => showModal()} className="send_new"/> */}

          </div>
        </div>
      </form>
    </>
  )

  const successmessage = (
    <>
      <div className='main_pop'>
        <div className='img_y passwoerd_modal'>
          <img src={commonImages.chat} />
          <h2>Success</h2>
        </div>
        <div>
          <div className='form-group success_d sucessss'>
            <p>Confirmation link has been sent successfully to your registered email address</p>
          </div>
          {/* <img src={commonImages.send} className="send_new"/> */}

        </div>
      </div>
    </>
  )
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])
  console.log('huguhj = ', props)
  return (
    <div>
      <div className='background_image'>
        <form
          id="login_form"
          onKeyPress={(e) => {
            console.log("enter = ", e)
            if (e.key === 'Enter') {
              handleLoginSubmit();
            }
          }}
        >
          <div className='inner_box'>
            <div className='heading_text'>
              <img src={commonImages.login} style={{ width: '185px' }} />
            </div>
            <div className='form-group'>
              <label>Email Address</label>
              <InputField
                type='text'
                placeholder="Email Address"
                width='70%'
                height='35px'
                className='formcontrol'
                value={email?.trim()?.replace(/ /g, '')}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangeEmail(value)
                }}
              />
            </div>
            <div className='form-group'>
              <label>Password</label>
              <InputField
                type='password'
                placeholder="Password"
                width='70%'
                height='35px'
                className='formcontrol'
                value={trimText(password)}
                onChange={(e) => {
                  let value = e.target.value.trimLeft();
                  onChangePassword(value)
                }}
              />
            </div>
            <div className='forgot_password'>
              <p onClick={() => showModal()}>Forgot Password?</p>
            </div>
            <div className='data_new'>
              <div>
                {/* <Link to ='/playjoin'> */}
                <img onClick={() => handleLoginSubmit()} src={commonImages.buttonLogin} alt="" />
                {/* </Link> */}
              </div>
              <div>
                <Link to='/register'>
                  <img src={commonImages.registerLogin} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isModalVisible &&
        <ModalPop
          content={fragment}
          btnSuccess={successBtn}
          btnCancel={true}
          handleCancel={handleCancel}
          isVisible={isModalVisible}
        />
      }
      {
        isModalSuccessVisible &&
        <ModalPop
          content={successmessage}
          btnSuccess={successBtnOne}
          btnCancel={true}
          handleSuccess={handleOkSuccess}
          handleCancel={handleCancelSuccess}
          isVisible={isModalSuccessVisible}

        />
      }
    </div>
  )

}
export default Login