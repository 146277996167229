import React from "react";
import { gameScreenDimentions, getLeaveStatus } from "../../../helper/globalFunctions";
import appImages from "../../../themes/appImages/appImages";
import { backgroundMusicSound, buttonClickSound, soundOff, soundOn } from "../../../helper/playSound";

import ChatBoxComponent from '../../Chat/chatBoxComponent';
import styles from './styles';
import { useDispatch, useSelector } from "react-redux";
import LogModal from "../logModal";
import HelpComponent from "../helpComponent";
import { pdfURL } from "../../../redux/axios/apikit";
import { DataManager } from "../../../helper/dataManager";
import ApiConstants from "../../../themes/apiConstants";
import PointsBreakdownModal from "../pointsBreakdownModal";

import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { popupType } from "../../../themes/constants/gameConstants";
import { isInternetConnected } from '../../../helper/networkUtils';
import { socket } from "../../../helper/socket";

const ShowMenuComponent = (props) => {
    const dispatch = useDispatch();
    const [visibility, setVisibility] = React.useState(false);
    const [showPointsBreakdown, setShowPointsBreakdown] = React.useState(false);
    const showUserDetail = useSelector((state) => state.homeReducer.showUserDetail);
    const showMenuButton = useSelector((state) => state.homeReducer.showMenuButton);
    const toggleSound = useSelector((state) => state.homeReducer?.toggleSound);
    const pauseGame = useSelector((state) => state.homeReducer.pauseGame);
    const isSpectator = useSelector((state) => state.homeReducer.isSpectator);

    return (
        <>
            {visibility && (
                <div style={{ ...styles.outerContainer, position: 'absolute' }}>
                    <PointsBreakdownModal
                        closeDisplay={() => setShowPointsBreakdown(false)}
                        load={showPointsBreakdown} />
                    <ChatBoxComponent />
                    <LogModal />
                    <img
                        onClick={() => {
                            buttonClickSound();
                            if (toggleSound) {
                                soundOn();
                                DataManager.setSound("on");
                                dispatch({
                                    type: ApiConstants.SOUND_ON_OFF,
                                    payload: false
                                })
                                backgroundMusicSound();
                            } else {
                                soundOff();
                                DataManager.setSound("off");
                                dispatch({
                                    type: ApiConstants.SOUND_ON_OFF,
                                    payload: true
                                })
                            }
                        }}
                        src={toggleSound ? appImages.soundOffIcon : appImages.soundOnIcon}
                        style={{
                            position: 'absolute',
                            right: gameScreenDimentions.width * 0.037,
                            bottom: gameScreenDimentions.width * 0.13,
                            width: gameScreenDimentions.gameBoardWidth * 0.062,
                            height: gameScreenDimentions.gameBoardWidth * 0.062,
                            zIndex: 0,
                            cursor: 'pointer',
                            objectFit: 'contain'

                        }} />
                    <img
                        onClick={() => {
                            buttonClickSound();
                            setShowPointsBreakdown(true);
                        }}
                        src={appImages.pointsBreak}
                        style={{
                            position: 'absolute',
                            right: gameScreenDimentions.width * 0.078,
                            bottom: gameScreenDimentions.width * 0.019,
                            width: gameScreenDimentions.gameBoardWidth * 0.062,
                            height: gameScreenDimentions.gameBoardWidth * 0.062,
                            zIndex: 0,
                            cursor: 'pointer',
                            objectFit: 'contain'
                        }} />
                    <HelpComponent />
                    <img
                        onClick={() => {
                            buttonClickSound();
                            window.open(`${pdfURL}`, '_blank')
                        }}
                        src={appImages.rules}
                        style={{
                            position: 'absolute',
                            bottom: gameScreenDimentions.gameBoardWidth * 0.034,
                            right: gameScreenDimentions.gameBoardWidth * 0.179,
                            width: gameScreenDimentions.gameBoardWidth * 0.051,
                            height: gameScreenDimentions.gameBoardWidth * 0.047,
                            zIndex: 0,
                            cursor: 'pointer',
                            objectFit: 'contain'
                        }} />
                    {/* {!isSpectator && <img
                        onClick={() => {
                            buttonClickSound();
                            const checkAllowed = getLeaveStatus()
                            if (isInternetConnected() && checkAllowed) {
                                socket.emit('pauseResume');
                            }
                            else {
                                ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                            }
                        }}
                        src={pauseGame ? appImages.play : appImages.pause}
                        style={{
                            position: 'absolute',
                            bottom: gameScreenDimentions.gameBoardWidth * 0.24,
                            right: gameScreenDimentions.gameBoardWidth * 0.054,
                            width: gameScreenDimentions.gameBoardWidth * 0.062,
                            height: gameScreenDimentions.gameBoardWidth * 0.062,
                            zIndex: 0,
                            cursor: 'pointer',
                            objectFit: 'contain',
                        }} />} */}

                </div>
            )
            }
            {!showUserDetail && showMenuButton &&
                <img
                    onClick={() => {
                        buttonClickSound();
                        setVisibility(!visibility);
                    }}
                    src={appImages.menuIcon}
                    style={{
                        position: 'absolute',
                        right: gameScreenDimentions.width * 0.037,
                        bottom: gameScreenDimentions.width * 0.019,
                        width: gameScreenDimentions.gameBoardWidth * 0.062,
                        height: gameScreenDimentions.gameBoardWidth * 0.062,
                        zIndex: 9,
                        cursor: 'pointer',
                        objectFit: 'contain'
                    }} />
            }
        </>
    );
};

export default ShowMenuComponent;