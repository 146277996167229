import React from "react";
import { gameScreenDimentions } from "../../../helper/globalFunctions";
import appImages from "../../../themes/appImages/appImages";
import Spacer from "../Spacer";
import { buttonClickSound } from "../../../helper/playSound";

import styles from './style';
import { useDispatch, useSelector } from "react-redux";
import { spacing } from "../../../themes/constants/spacing";
import moment from "moment";
import ApiConstants from "../../../themes/apiConstants";
import CardComponent from "../CardComponent";

const LogModal = (props) => {
    const dispatch = useDispatch();
    const [visibility, setVisibility] = React.useState(false);
    const [data, setData] = React.useState([]);
    const logsData = useSelector((state) => state.homeReducer.logsData);

    React.useEffect(() => {
        const newarray = [...logsData].reverse()
        setData(newarray)
    }, [logsData])

    return (
        <>
            {visibility && (
                <div style={styles.outerContainer}>
                    <div style={styles.innerView}>
                        <div style={{ flexDirection: 'row' }}>
                            <img
                                onClick={() => {
                                    setVisibility(false)
                                    dispatch({
                                        type: ApiConstants.SHOW_MENU_BUTTON,
                                        payload: true
                                    })
                                }}
                                src={appImages.close}
                                style={styles.closeBtnView}
                            />
                        </div>
                        <div style={styles.chatBoxView}>
                            <div className='scroll_div' style={styles.chatBoxView1}>
                                {data.map((item) => {
                                    return (
                                        <React.Fragment key={Math.random().toString()}>
                                            <div style={styles.viewStyle} >
                                                <span style={styles.dateStyle}>
                                                    [{moment(item?.date).format('LTS')}] :
                                                </span>
                                                <span style={styles.selectTile}>
                                                    {" " + item?.message}
                                                </span>
                                                <Spacer height={spacing.scH1cent} />
                                            </div>
                                            {item?.card && <CardComponent
                                                style={styles.card1}
                                                imgStyle={styles.imgStyle}
                                                code={item?.card}
                                            />
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                        <Spacer height={spacing.scH1cent} />
                    </div>
                </div>
            )
            }
            <img
                onClick={() => {
                    buttonClickSound();
                    dispatch({
                        type: ApiConstants.SHOW_MENU_BUTTON,
                        payload: false
                    })
                    setVisibility(true);
                }}
                src={appImages.history}
                style={{
                    position: 'absolute',
                    right: gameScreenDimentions.width * 0.037,
                    bottom: gameScreenDimentions.width * 0.092,
                    width: gameScreenDimentions.gameBoardWidth * 0.062,
                    height: gameScreenDimentions.gameBoardWidth * 0.062,
                    objectFit: "contain",
                    zIndex: 0,
                    cursor: 'pointer'
                }}
            />
        </>
    );
};

export default LogModal;