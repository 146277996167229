import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlertMessage } from "../../../helper/showAlertMessage";
import { socket } from "../../../helper/socket";
import ApiConstants from "../../../themes/apiConstants";
import appImages from "../../../themes/appImages/appImages";
import strings from "../../../themes/constants/strings";
import { backFromSelectCardType, popupType, SelectCardSubmoissionType, tileNames, gameScreen } from "../../../themes/constants/gameConstants";
import { gameScreenDimentions, getLeaveStatus, updateTradeTile } from "../../../helper/globalFunctions";
import { ValidationConstants } from "../../../themes/constants/validationConstants";
import { textInput } from "../../../themes/colors/colors";
import { isInternetConnected } from '../../../helper/networkUtils';
import styles from './styles';
import Spacer from "../Spacer";
import ButtonCustom from "../ButtonCustom";

import { buttonClickSound } from "../../../helper/playSound";
import fontSizes from "../../../themes/constants/fontSizes";
import { spacing } from "../../../themes/constants/spacing";
import { commonImages } from "../../../themes/appImage";
import Emitter from "../../../helper/emitter";
import CardComponent from '../CardComponent';

const allTiles = [
    {
        id: 1,
        title: tileNames.degree,
        image: appImages.degree,
    }, {
        id: 2,
        title: tileNames.startUp,
        image: appImages.startup,
    }, {
        id: 3,
        title: tileNames.job,
        image: appImages.bag,
    }, {
        id: 4,
        title: tileNames.home,
        image: appImages.homeDollarRound,
    }, {
        id: 5,
        title: tileNames.business,
        image: appImages.moneyBag,
    }, {
        id: 6,
        title: tileNames.realEstate,
        image: appImages.multiHomeRound,
    },
    // {
    //     id: 7,
    //     title: tileNames.justice,
    //     image: appImages.justiceRound,
    // },
]
var selectedPlayType = null
const RadioCardBox = (props) => {
    const dispatch = useDispatch()
    const roomData = useSelector((state) => state.homeReducer.roomData);
    const isOurTurn = useSelector((state) => state.homeReducer.isOurTurn);
    const isPart1Completed = useSelector((state) => state.homeReducer.isPart1Completed);
    const ourGameData = useSelector((state) => state.homeReducer.ourGameData);
    const ourCards = useSelector((state) => state.homeReducer.ourCards);
    const isBackFromCardSelection = useSelector((state) => state.homeReducer.isBackFromCardSelection);
    const tradeTileAgain = useSelector((state) => state.homeReducer.tradeTileAgain);
    const noInternet = useSelector((state) => state.homeReducer.noInternet);
    const pauseGame = useSelector((state) => state.homeReducer.pauseGame);

    const [selectedBox, setSelectedBox] = React.useState('Close');//Close,Start,Action,Oppotunity,Discard,Trade,VoteType,VoteTile
    const [radioBoxOne, setRadioBoxOne] = React.useState('Opportunity'); //Action,Opportunity,Discard
    const [radioBoxTwo, setRadioBoxTwo] = React.useState('Earn'); //Earn,Trade,Vote
    const [tilesArray, setTilesArray] = React.useState(allTiles); //Earn,Trade,Vote
    const [radioBoxVote, setRadioBoxVote] = React.useState('Reverse'); //Reverse,New
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [isInfoOpen, setInfoOpen] = React.useState(false);
    const [isInfoOpenStart, setInfoOpenStart] = React.useState(false);
    const [isInfoOpenAction, setInfoOpenAction] = React.useState(false);
    const [hideCards, setHideCards] = React.useState(false);
    const [selected, setSelected] = React.useState(0);
    const [cardsStatus, setCardsStatus] = React.useState([]);

    React.useEffect(() => {
        socket.on('cardQualifyStatusSuccess', cardQualifyStatusSuccess);
        socket.emit('cardQualifyStatus');
        return () => {
            socket.off('cardQualifyStatusSuccess', cardQualifyStatusSuccess);
        }
    }, [])

    React.useEffect(() => {
        setInfoOpen(false)
        if (!noInternet) {
            if (isOurTurn && isPart1Completed === false && !pauseGame) {
                // socket.emit("gameScreen", gameScreen.screen1);
                // setSelectedBox('Start')
            }
            else {
                setSelectedBox('Close')
            }
        }
    }, [isOurTurn, isPart1Completed, noInternet])

    React.useEffect(() => {
        if (isBackFromCardSelection) {
            setSelectedBox(isBackFromCardSelection === backFromSelectCardType.earn ? 'Action' : 'Start')
            dispatch({
                type: ApiConstants.BACK_FROM_SELECT_CARD_UPDATE,
                payload: null
            })
        }
        else if (tradeTileAgain) {
            if (ourGameData?.tiles?.skill > 1 || ourGameData?.tiles?.degree > 1 || (ourGameData?.tiles?.startUp > 0 && ourGameData?.tiles?.job === 0)) {
                const tempArray = []
                ourGameData?.tiles?.skill > 1 && tempArray.push({
                    id: 1,
                    title: tileNames.skill,
                    image: appImages.skill,
                })
                ourGameData?.tiles?.degree > 1 && tempArray.push({
                    id: 2,
                    title: tileNames.degree,
                    image: appImages.degree,
                })
                if (ourGameData?.tiles?.job === 0) {
                    ourGameData?.tiles?.startUp > 0 && tempArray.push({
                        id: 3,
                        title: tileNames.startUp,
                        image: appImages.startup,
                    })
                }
                setTilesArray(tempArray)
                setSelectedBox('Trade')
            }
        }
    }, [isBackFromCardSelection, tradeTileAgain])

    React.useEffect(() => {
        if (selectedBox === 'Close') {
            // setRadioBoxOne('Action')
            setRadioBoxOne('Opportunity')
            setRadioBoxTwo('Earn')
            setTilesArray(allTiles)
            setRadioBoxVote('Reverse')
            setSelectedIndex(0)
        }
        if (selectedBox === 'Start') {
            socket.emit('cardQualifyStatus')
        }
    }, [selectedBox])

    React.useEffect(() => {
        Emitter.on("openRadioBox", (e) => {
            if (e?.screen === gameScreen.screen1) {
                setSelected(0);
                setHideCards(false);
                setSelectedBox('Start');
            } else if (e?.screen === gameScreen.screen2) {
                setSelectedBox('Action');
            } else if (e?.screen === gameScreen.sacrifice) {
                if (ourCards.length > 1) {
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.earn,
                        }
                    })
                    setSelectedBox('Close')
                }
            } else if (e?.screen === gameScreen.tradeTile) {
                if (ourGameData?.tiles?.skill > 1 || ourGameData?.tiles?.degree > 1 || (ourGameData?.tiles?.startUp > 0 && ourGameData?.tiles?.job === 0)) {
                    const tempArray = []
                    ourGameData?.tiles?.skill > 1 && tempArray.push({
                        id: 1,
                        title: tileNames.skill,
                        image: appImages.skill,
                    })
                    ourGameData?.tiles?.degree > 1 && tempArray.push({
                        id: 2,
                        title: tileNames.degree,
                        image: appImages.degree,
                    })
                    if (ourGameData?.tiles?.job === 0) {
                        ourGameData?.tiles?.startUp > 0 && tempArray.push({
                            id: 3,
                            title: tileNames.startUp,
                            image: appImages.startup,
                        })
                    }
                    setTilesArray(tempArray)
                    setSelectedBox('Trade')
                }
            } else if (e?.screen === gameScreen.selectVoteTile) {
                if (ourGameData?.tokens?.privilege > 1) {
                    const tempVote = roomData?.vote
                    const tempArray = []
                    !tempVote?.degree && tempArray.push(allTiles[0])
                    !tempVote?.startUp && tempArray.push(allTiles[1])
                    !tempVote?.job && tempArray.push(allTiles[2])
                    !tempVote?.home && tempArray.push(allTiles[3])
                    !tempVote?.business && tempArray.push(allTiles[4])
                    !tempVote?.realEstate && tempArray.push(allTiles[5])
                    // !tempVote?.justice && tempArray.push(allTiles[6])
                    setTilesArray(tempArray)
                    if (tempArray.length === 6) {
                        setSelectedBox('VoteTile')
                    }
                    else {
                        setSelectedBox('VoteType')
                    }
                }
            } else if (e?.screen === gameScreen.selectOpportunityCard) {
                if (ourCards.length > 0) {
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.opportunity,
                        }
                    })
                    setSelectedBox('Close')
                }
            } else if (e?.screen === gameScreen.discardCard) {
                if (ourCards.length > 0) {
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.discard,
                        }
                    })
                    setSelectedBox('Close')
                }
            }
        })
    }, [ourGameData?.tiles?.skill, ourGameData?.tiles?.degree, ourGameData?.tiles?.startUp, ourGameData?.tiles?.job, ourGameData?.tokens?.privilege, ourCards])

    const cardQualifyStatusSuccess = (data) => {
        setCardsStatus(data?.qualifiedStatus)
    }

    const onTileClick = (item, index) => {
        setSelectedIndex(index);
    }

    const onSubmitPress = () => {
        if (selectedBox === 'Start') {
            if (radioBoxOne === 'Action') {
                setSelectedBox('Action');
                socket.emit("gameScreen", gameScreen.screen2);
            }
            if (radioBoxOne === 'Discard') {
                if (ourCards.length > 0) {
                    socket.emit("gameScreen", gameScreen.discardCard);
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.discard,
                        }
                    })
                    setSelectedBox('Close')
                }
                else {
                    ShowAlertMessage("You don't have any card.", popupType.error)
                }
            }
            if (radioBoxOne === 'Opportunity') {
                if (ourCards.length > 0) {
                    socket.emit("gameScreen", gameScreen.selectOpportunityCard);
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.opportunity,
                        }
                    })
                    setSelectedBox('Close')
                }
                else {
                    ShowAlertMessage("You don't have any card.", popupType.error)
                }
            }
            // radioBoxOne === 'Oppotunity' && setSelectedBox('Oppotunity');
        }
        if (selectedBox === 'Action') {
            if (radioBoxTwo === 'Earn') {
                if (ourCards.length > 1) {
                    socket.emit("gameScreen", gameScreen.sacrifice);
                    dispatch({
                        type: ApiConstants.SELECT_CARD_UPDATE,
                        payload: {
                            selectCard: true,
                            selectCardSubmissionType: SelectCardSubmoissionType.earn,
                        }
                    })
                    //socket.emit('earnSkillTile', ourCards[0])
                    setSelectedBox('Close')
                }
                else {
                    ShowAlertMessage("You don't have any card.", popupType.error)
                }
            }
            if (radioBoxTwo === 'Trade') {
                tradeTileCall();
            }
            if (radioBoxTwo === 'Vote') {
                if (ourGameData?.tokens?.privilege > 1) {
                    socket.emit("gameScreen", gameScreen.selectVoteTile);
                    const tempVote = roomData?.vote
                    const tempArray = []
                    // !tempVote?.skill && tempArray.push(allTiles[0])
                    !tempVote?.degree && tempArray.push(allTiles[0])
                    !tempVote?.startUp && tempArray.push(allTiles[1])
                    !tempVote?.job && tempArray.push(allTiles[2])
                    !tempVote?.home && tempArray.push(allTiles[3])
                    !tempVote?.business && tempArray.push(allTiles[4])
                    !tempVote?.realEstate && tempArray.push(allTiles[5])
                    // !tempVote?.justice && tempArray.push(allTiles[6])
                    setTilesArray(tempArray)
                    if (tempArray.length === 6) {
                        setSelectedBox('VoteTile')
                    }
                    else {
                        setSelectedBox('VoteType')
                    }
                }
                else {
                    ShowAlertMessage("You don't have enough privilege tokens.", popupType.error)
                }
            }
        }
        if (selectedBox === 'Trade') {
            tradeTileSubmittion()
        }
        if (selectedBox === 'VoteType') {
            const tempVote = roomData?.vote
            const tempArray = []
            if (radioBoxVote === 'Reverse') {
                // tempVote?.skill && tempArray.push(allTiles[0])
                tempVote?.degree && tempArray.push(allTiles[0])
                tempVote?.startUp && tempArray.push(allTiles[1])
                tempVote?.job && tempArray.push(allTiles[2])
                tempVote?.home && tempArray.push(allTiles[3])
                tempVote?.business && tempArray.push(allTiles[4])
                tempVote?.realEstate && tempArray.push(allTiles[5])
                // tempVote?.justice && tempArray.push(allTiles[6])

                setTilesArray(tempArray)
                setSelectedBox('VoteTile')
            }
            else {
                // !tempVote?.skill && tempArray.push(allTiles[0])
                !tempVote?.degree && tempArray.push(allTiles[0])
                !tempVote?.startUp && tempArray.push(allTiles[1])
                !tempVote?.job && tempArray.push(allTiles[2])
                !tempVote?.home && tempArray.push(allTiles[3])
                !tempVote?.business && tempArray.push(allTiles[4])
                !tempVote?.realEstate && tempArray.push(allTiles[5])
                // !tempVote?.justice && tempArray.push(allTiles[6])

                if (tempArray.length === 0) {
                    ShowAlertMessage('There are no tiles available for voting, you can choose reverse voting.', popupType.error)
                }
                else {
                    setTilesArray(tempArray)
                    setSelectedBox('VoteTile')
                }
            }
        }
        if (selectedBox === 'VoteTile') {
            voteTileSubmittion()
        }
        selectedBox === 'BoxThree' && setSelectedBox('Close');
        selectedBox === 'BoxVote' && setSelectedBox('Close');
        selectedBox === 'BoxVoteTile' && setSelectedBox('Close');
    }

    const tradeTileCall = () => {
        if (ourGameData?.tiles?.skill > 1 || ourGameData?.tiles?.degree > 1 || (ourGameData?.tiles?.startUp > 0 && ourGameData?.tiles?.job === 0)) {
            socket.emit("gameScreen", gameScreen.tradeTile);
            const tempArray = []
            ourGameData?.tiles?.skill > 1 && tempArray.push({
                id: 1,
                title: tileNames.skill,
                image: appImages.skill,
            })
            ourGameData?.tiles?.degree > 1 && tempArray.push({
                id: 2,
                title: tileNames.degree,
                image: appImages.degree,
            })
            if (ourGameData?.tiles?.job === 0) {
                ourGameData?.tiles?.startUp > 0 && tempArray.push({
                    id: 3,
                    title: tileNames.startUp,
                    image: appImages.startup,
                })
            }
            setTilesArray(tempArray)
            setSelectedBox('Trade')
        }
        else {
            ShowAlertMessage("You don't have enough tiles to trade.", popupType.error)
        }
    }

    const tradeTileSubmittion = () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            dispatch({
                type: ApiConstants.TRADE_TILE_AGAIN,
                payload: false
            })
            const tileName = tilesArray[selectedIndex]
            updateTradeTile(tileName.title)
            socket.emit('tradeTile', tileName.title)
            setSelectedBox('Close')
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
        }
    }

    const voteTileSubmittion = () => {
        const checkAllowed = getLeaveStatus()
        if (isInternetConnected() && checkAllowed) {
            const tileName = tilesArray[selectedIndex]
            // socket.emit('vote', tileName.title)
            setSelectedBox('Close')
            dispatch({
                type: ApiConstants.ENTER_TOKEN_FOR_VOTE_UPDATE,
                payload: true,
                voteTileName: tileName.title
            })
        } else {
            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
        }
    }

    const onBackPress = () => {
        if (!roomData?.isPause) {
            if (selectedBox === 'Action') {
                setSelectedBox('Start');
                socket.emit("gameScreen", gameScreen.screen1);
            }
            if (selectedBox === 'VoteType' || selectedBox === 'Trade') {
                // setSelectedBox('Action');
                // socket.emit("gameScreen", gameScreen.screen2);

                setSelectedBox('Start');
                socket.emit("gameScreen", gameScreen.screen1);
            }
            if (selectedBox === 'VoteTile') {
                socket.emit("gameScreen", gameScreen.screen2);
                const tempVote = roomData?.vote
                const tempArray = []
                !tempVote?.degree && tempArray.push(allTiles[0])
                !tempVote?.startUp && tempArray.push(allTiles[1])
                !tempVote?.job && tempArray.push(allTiles[2])
                !tempVote?.home && tempArray.push(allTiles[3])
                !tempVote?.business && tempArray.push(allTiles[4])
                !tempVote?.realEstate && tempArray.push(allTiles[5])
                // !tempVote?.justice && tempArray.push(allTiles[6])
                if (tempArray.length === 6) {
                    setSelectedBox('Action')
                }
                else {
                    setSelectedBox('VoteType')
                }
            }
        } else {
            ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error);
        }

    }

    const getVoteTileName = (name) => {
        return name === tileNames.degree ? 'Degree' : name === tileNames.startUp ? 'Startup' : name === tileNames.job ? 'Job' : name === tileNames.home ? 'Home' : name === tileNames.business ? 'Business' : name === tileNames.realEstate ? 'Real Estate' : 'Justice'
    }

    const NotQualifyText = ({ extraStyle }) => (<div
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#00000055',
            color: 'yellow',
            borderRadius: gameScreenDimentions.gameBoardWidth * 0.032,
            alignItems: 'flex-end',
            justifyContent: 'center',
            display: 'flex',
            borderStyle: 'solid',
            borderWidth: 2,
            ...extraStyle
        }}
    ><span style={{
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 9,
        marginBottom: 2
    }}>
            Not qualified but<br />can discard
        </span></div>);

    const isDisables = selected > 0 ? (!hideCards && !cardsStatus[selected - 1]) : false
    return selectedBox !== 'Close' && (
        <>
            {selectedBox == 'Start' ? <div style={{ ...styles.outerContainer, ...{ position: 'absolute' } }}>
                {ourCards.length > 2 && !hideCards && <CardComponent
                    style={{ ...styles.card, ...{ borderColor: (selected == 3 && isOurTurn) ? "red" : '#46c1af55' } }}
                    code={ourCards[2]}
                />}
                {ourCards.length > 1 && !hideCards && <CardComponent
                    style={{ ...styles.card2, ...{ borderColor: (selected == 2 && isOurTurn) ? "red" : '#46c1af55' } }}
                    code={ourCards[1]}
                />}
                {ourCards.length > 0 && !hideCards && <CardComponent
                    style={{ ...styles.card3, ...{ borderColor: (selected == 1 && isOurTurn) ? "red" : '#46c1af55' } }}
                    code={ourCards[0]}
                />}

                {isOurTurn && !hideCards && <>
                    <div
                        onClick={() => {
                            setSelected(3);
                        }
                        } style={styles.cardWrapper1}>
                        {(cardsStatus.length && !cardsStatus[2]) && <NotQualifyText extraStyle={{ borderColor: (selected == 3 && isOurTurn) ? "red" : 'transparent' }} />}
                    </div>
                    <div
                        onClick={() => {
                            setSelected(2);
                        }
                        }
                        style={styles.cardWrapper2}>
                        {(cardsStatus.length && !cardsStatus[1]) && <NotQualifyText extraStyle={{ borderColor: (selected == 2 && isOurTurn) ? "red" : 'transparent' }} />}
                    </div>
                    <div
                        onClick={() => {
                            setSelected(1)
                        }
                        } style={styles.cardWrapper3}>
                        {(cardsStatus.length && !cardsStatus[0]) && <NotQualifyText extraStyle={{ borderColor: (selected == 1 && isOurTurn) ? "red" : 'transparent' }} />}
                    </div>
                </>}

                {hideCards && <div style={styles.modalView} resizeMode={'stretch'}
                    source={appImages.modalBg1}>
                    <span style={styles.areYouSure}>{strings.areYouSure}</span>
                </div>}

                {!hideCards && < ButtonCustom
                    outerContainer={{
                        bottom: gameScreenDimentions.gameBoardWidth * 0.043, position: 'absolute',
                        right: gameScreenDimentions.gameBoardWidth * 0.35
                    }}
                    textColor={{ fontSize: fontSizes.f40 }}
                    iContainer={styles.btnStyle}
                    style={styles.btnStyle}
                    imgBg={appImages.buttonBg}
                    title={strings.tradeTiles}
                    disabled={!(ourGameData?.tiles?.skill > 1 || ourGameData?.tiles?.degree > 1 || (ourGameData?.tiles?.startUp > 0 && ourGameData?.tiles?.job === 0))}
                    onPress={() => {
                        if (ourGameData?.tiles?.skill > 1 || ourGameData?.tiles?.degree > 1 || (ourGameData?.tiles?.startUp > 0 && ourGameData?.tiles?.job === 0)) {
                            setRadioBoxTwo('Trade');
                            tradeTileCall();
                        }
                    }}
                />}
                <ButtonCustom
                    outerContainer={{
                        bottom: gameScreenDimentions.gameBoardWidth * 0.043, position: 'absolute',
                        right: hideCards ? gameScreenDimentions.gameBoardWidth * 0.42 : gameScreenDimentions.gameBoardWidth * 0.65
                    }}
                    textColor={{ fontSize: fontSizes.f40 }}
                    iContainer={styles.btnStyle}
                    style={styles.btnStyle}
                    imgBg={appImages.buttonBg}
                    title={hideCards ? strings.yes : strings.discard}
                    onPress={() => {
                        if (selected) {
                            if (hideCards) {
                                setSelectedBox('Close');
                                Emitter.emit('showSelectedCardThenPlay', {
                                    cardNo: selected,
                                    type: selectedPlayType
                                })
                            }
                            else {
                                setHideCards(true)
                                selectedPlayType = 'Discard'
                            }
                        }
                        else ShowAlertMessage("Select a card first.", popupType.error)
                    }}
                />
                <ButtonCustom
                    outerContainer={{
                        bottom: gameScreenDimentions.gameBoardWidth * 0.043, position: 'absolute',
                        right: hideCards ? gameScreenDimentions.gameBoardWidth * 0.82 : gameScreenDimentions.gameBoardWidth * 0.95
                    }}
                    iContainer={styles.btnStyle}
                    textColor={{ fontSize: fontSizes.f40 }}
                    style={styles.btnStyle}
                    imgBg={appImages.buttonBg}
                    title={hideCards ? strings.back : strings.play}
                    disabled={isDisables}
                    onPress={() => {
                        if (selected) {
                            if (hideCards) {
                                setHideCards(false)
                            }
                            else if (cardsStatus[selected - 1]) {
                                {
                                    setHideCards(true)
                                    selectedPlayType = 'Play'
                                }
                            }
                        }
                        else ShowAlertMessage("Select a card first.", popupType.error)
                    }}
                />
            </div>
                :
                <div style={styles.outerContainer}>
                    <div style={selectedBox === 'VoteTile' ? styles.modalView2 : styles.modalView}>
                        {!isInfoOpen && !isInfoOpenStart && !isInfoOpenAction && <Spacer height={spacing.m} />}
                        {(selectedBox != 'Trade') && (selectedBox != 'VoteTile') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction && <span style={styles.selectTile}>{strings.radioBoxHeading}</span>}
                        {(selectedBox != 'Trade') && (selectedBox != 'VoteTile') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction && <Spacer height={spacing.xs} />}
                        {isInfoOpenAction && <>
                            <div
                                style={styles.scrollStyle}
                                contentContainerStyle={{ ...styles.outerModalView1, paddingHorizontal: 32 }}>
                                <Spacer height={spacing.xs} />
                                <span style={{ ...styles.fontTextHeading, fontSize: fontSizes.f48, }}>{strings.youCanSelect}</span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.earnSkillsTiles}: <span style={styles.fontText}>{strings.toPlayThis}</span></span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.trade}: <span style={styles.fontText}>{strings.youCanTrade}</span></span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.vote}: <span style={styles.fontText}>{strings.changeTheRules}</span></span>
                                <Spacer height={spacing.xxxs} />
                                <span style={styles.fontTextHeading}>{strings.forMoreInfo}</span>
                                <Spacer height={spacing.xxxs} />
                            </div>
                            <img src={commonImages.back}
                                onClick={() => setInfoOpenAction(false)}
                                style={{
                                    position: 'absolute',
                                    left: gameScreenDimentions.width * 0.007,
                                    top: 20,
                                    width: gameScreenDimentions.gameBoardWidth * 0.025,
                                    height: gameScreenDimentions.gameBoardWidth * 0.022,
                                    cursor: 'pointer'
                                }}
                            />
                        </>
                        }
                        {isInfoOpenStart && <>
                            <div
                                showsVerticalScrollIndicator={false}
                                style={styles.scrollStyle}>
                                <Spacer height={spacing.xs} />
                                <span style={{ ...styles.fontTextHeading, fontSize: fontSizes.f48, }}>{strings.startYourTurn}</span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.playAPrepAction} <span style={styles.fontText}>{strings.spendYour}</span></span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.playAnOpportunity}: <span style={styles.fontText}>{strings.select1Of}</span></span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.discardACard1} <span style={styles.fontText}>{strings.spendYourTurn}</span></span>
                                <Spacer height={spacing.xxs} />
                                <span style={styles.fontTextHeading}>{strings.forMoreInfo}</span>
                                <Spacer height={spacing.xs} />
                            </div>
                            <img src={commonImages.back}
                                onClick={() => setInfoOpenStart(false)}
                                style={{
                                    position: 'absolute',
                                    left: gameScreenDimentions.width * 0.007,
                                    top: 20,
                                    width: gameScreenDimentions.gameBoardWidth * 0.025,
                                    height: gameScreenDimentions.gameBoardWidth * 0.022,
                                    cursor: 'pointer'
                                }}
                            />
                        </>
                        }
                        {isInfoOpen && <>
                            <img src={commonImages.back}
                                onClick={() => setInfoOpen(false)}
                                style={{
                                    position: 'absolute',
                                    left: gameScreenDimentions.width * 0.02,
                                    top: 23,
                                    width: gameScreenDimentions.gameBoardWidth * 0.025,
                                    height: gameScreenDimentions.gameBoardWidth * 0.022,
                                    cursor: 'pointer'
                                }}
                            />
                            <Spacer height={spacing.m} />
                            <span style={styles.selectTile1}>{strings.tradeTileRule}</span>
                            <img style={styles.imageStyle}
                                src={appImages.tradeHelp} />
                        </>
                        }
                        {(selectedBox === 'Start') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <>
                                <img
                                    onClick={() => setInfoOpenStart(true)}
                                    style={{
                                        position: 'absolute',
                                        tintColor: textInput.green,
                                        right: gameScreenDimentions.width * 0.02,
                                        top: 22,//gameScreenDimentions.width * 0.014,
                                        width: gameScreenDimentions.width * 0.02,
                                        height: gameScreenDimentions.width * 0.022,
                                        objectFit: 'contain',
                                        cursor: 'pointer'
                                    }}
                                    src={commonImages.info} />
                                {/* <div style={styles.radioBox}>
                                <div onClick={() => { setRadioBoxOne('Action') }} style={styles.outerDot}>
                                    {radioBoxOne === 'Action' && <div style={styles.innerDot} />}
                                </div>
                                <span onClick={() => { setRadioBoxOne('Action') }} style={styles.radioText}>{strings.playAPreparation}</span>
                            </div> */}
                                <div style={styles.radioBox}>
                                    <div style={styles.outerDot} onClick={() => { setRadioBoxOne('Opportunity') }}>
                                        {radioBoxOne === 'Opportunity' && <div style={styles.innerDot} />}
                                    </div>
                                    <span onClick={() => { setRadioBoxOne('Opportunity') }} style={styles.radioText}>{strings.playAnOpportunity}</span>
                                </div>
                                <div style={styles.radioBox}>
                                    <div onClick={() => { setRadioBoxOne('Discard') }} style={styles.outerDot}>
                                        {radioBoxOne === 'Discard' && <div style={styles.innerDot} />}
                                    </div>
                                    <span onClick={() => { setRadioBoxOne('Discard') }} style={styles.radioText}>{strings.discardACard}</span>
                                </div>
                            </>}
                        {(selectedBox === 'Action') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <>
                                <img
                                    onClick={() => setInfoOpenAction(true)}
                                    style={{
                                        position: 'absolute',
                                        tintColor: textInput.green,
                                        right: gameScreenDimentions.width * 0.02,
                                        top: 22,
                                        width: gameScreenDimentions.width * 0.02,
                                        height: gameScreenDimentions.width * 0.022,
                                        objectFit: 'contain',
                                        cursor: 'pointer'
                                    }}
                                    src={commonImages.info} />
                                <div style={styles.radioBox}>
                                    <div onClick={() => { setRadioBoxTwo('Earn') }} style={styles.outerDot}>
                                        {radioBoxTwo === 'Earn' && <div style={styles.innerDot} />}
                                    </div>
                                    <span onClick={() => { setRadioBoxTwo('Earn') }} style={styles.radioText}>{strings.earnSkillsTiles}</span>
                                </div>
                                <div style={styles.radioBox}>
                                    <div onClick={() => { setRadioBoxTwo('Trade') }} style={styles.outerDot}>
                                        {radioBoxTwo === 'Trade' && <div style={styles.innerDot} />}
                                    </div>
                                    <span onClick={() => { setRadioBoxTwo('Trade') }} style={styles.radioText}>{strings.tradeTiles}</span>
                                </div>
                                <div style={styles.radioBox}>
                                    <div onClick={() => { setRadioBoxTwo('Vote') }} style={styles.outerDot}>
                                        {radioBoxTwo === 'Vote' && <div style={styles.innerDot} />}
                                    </div>
                                    <span onClick={() => { setRadioBoxTwo('Vote') }} style={styles.radioText}>{strings.vote}</span>
                                </div>
                            </>}
                        {(selectedBox === 'Trade') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <>
                                <div
                                    onClick={() => setInfoOpen(true)}
                                    style={{
                                        display: 'flex',
                                        position: 'absolute',
                                        right: gameScreenDimentions.width * 0.02,
                                        top: 21,
                                        cursor: 'pointer'
                                    }}
                                    disabled={isInfoOpen}
                                >
                                    <img
                                        style={{
                                            tintColor: textInput.green,
                                            width: gameScreenDimentions.width * 0.025,
                                            height: gameScreenDimentions.width * 0.025,
                                            objectFit: 'contain'
                                        }}
                                        src={appImages.help} />
                                </div>
                                <span style={styles.selectTile}>{strings.selectTile}</span>
                                <Spacer height={spacing.m} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {tilesArray.map((item, index) => {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }} key={item.id}>
                                                <div
                                                    onClick={() => onTileClick(item, index)}
                                                >
                                                    <img
                                                        src={item.image}
                                                        style={styles.profileImageStyle} />
                                                </div>
                                                <div style={styles.radioBox2}>
                                                    <div onClick={() => onTileClick(item, index)} style={styles.outerDot}>
                                                        {selectedIndex === index &&
                                                            <div style={styles.innerDot}>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        {(selectedBox === 'VoteType') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <>
                                <div style={styles.radioBox}>
                                    <div onClick={() => {
                                        setRadioBoxVote('Reverse');
                                    }} style={styles.outerDot}>
                                        {radioBoxVote === 'Reverse' &&
                                            <div style={styles.innerDot}>
                                            </div>
                                        }
                                    </div>
                                    <span onClick={() => {
                                        setRadioBoxVote('Reverse');
                                    }} style={styles.radioText}>{strings.reverseVote}</span>
                                </div>
                                <div style={styles.radioBox}>
                                    <div onClick={() => {
                                        setRadioBoxVote('New');
                                    }} style={styles.outerDot}>
                                        {radioBoxVote === 'New' &&
                                            <div style={styles.innerDot}>
                                            </div>
                                        }
                                    </div>
                                    <span onClick={() => {
                                        setRadioBoxVote('New');
                                    }} style={styles.radioText}>{strings.newVote}</span>
                                </div>
                            </>
                        }
                        {(selectedBox === 'VoteTile') && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <>
                                <span style={styles.selectTile}>{strings.selectTileVote}</span>
                                <Spacer height={spacing.m} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {tilesArray.map((item, index) => {
                                        return (
                                            <div style={styles.tileContainer} key={item.id}>
                                                <div style={styles.imageViewStyle} >
                                                    <div
                                                        onClick={() => onTileClick(item, index)}
                                                    >
                                                        <img
                                                            src={item.image}
                                                            style={styles.profileImageStyle} />
                                                    </div>
                                                </div>
                                                <span
                                                    style={{
                                                        color: 'white',
                                                        fontWeight: 600,
                                                        textAlign: 'center'
                                                    }}
                                                >{getVoteTileName(item.title)}</span>
                                                <div style={styles.radioBox2}>
                                                    <div onClick={() => onTileClick(item, index)} style={styles.outerDot}>
                                                        {selectedIndex === index &&
                                                            <div style={styles.innerDot}>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}

                                </div>
                            </>
                        }
                        <Spacer height={spacing.m} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                            {(selectedBox !== 'Start' && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction) &&
                                <ButtonCustom
                                    textColor={{ fontSize: fontSizes.f40 }}
                                    imgBg={appImages.buttonBg}
                                    title={(selectedBox === 'Trade' && tradeTileAgain) ? "Done" : strings.back}
                                    onPress={() => {
                                        buttonClickSound();
                                        const checkAllowed = getLeaveStatus()
                                        if (isInternetConnected() && checkAllowed) {
                                            if (!roomData?.isPause) {
                                                if (selectedBox === 'Trade' && tradeTileAgain) {
                                                    dispatch({
                                                        type: ApiConstants.TRADE_TILE_AGAIN,
                                                        payload: false
                                                    })
                                                    setSelectedBox('Close');
                                                    socket.emit('startNextPart')
                                                } else {
                                                    onBackPress();
                                                }
                                            }
                                            else {
                                                ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                                            }
                                        } else {
                                            ShowAlertMessage(ValidationConstants.internetCheck, popupType.error);
                                        }
                                    }}
                                />
                            }
                            {
                                selectedBox === 'Start' && !isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                                <ButtonCustom
                                    textColor={{ fontSize: fontSizes.f40 }}
                                    imgBg={appImages.buttonBg}
                                    title={strings.viewCards}
                                    onPress={() => {
                                        buttonClickSound();
                                        dispatch({
                                            type: ApiConstants.VIEW_ALL_CARDS,
                                            payload: true
                                        })
                                    }}
                                />
                            }
                            {!isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                                <ButtonCustom
                                    textColor={{ fontSize: fontSizes.f40 }}
                                    imgBg={appImages.buttonBg}
                                    title={selectedBox === 'Trade' ? 'Trade up' : strings.submit}
                                    onPress={() => {
                                        buttonClickSound();
                                        if (!roomData?.isPause) {
                                            onSubmitPress();
                                        } else {
                                            ShowAlertMessage(`${ValidationConstants.gamePaused}${roomData?.pauseStatus?.pausedBy?.name}.`, popupType.error)
                                        }

                                    }}
                                />

                            }
                        </div>
                        {!isInfoOpen && !isInfoOpenStart && !isInfoOpenAction &&
                            <Spacer height={spacing.l} />
                        }
                    </div>
                </div >}
        </>

    );
};

export default RadioCardBox;