import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../common/header';
import { soundOn, soundOff, backgroundMusicSound } from '../../helper/playSound';
import { commonImages } from '../../themes/appImage';
import {
    deleteAccountAction,
    logoutAction
} from '../../redux/actions/authActions';
import ApiConstants from '../../themes/apiConstants';
import { buyHardCopyURL, pdfURL, videoURL } from '../../redux/axios/apikit';
import { DataManager } from '../../helper/dataManager';
import ModalPop from '../../common/Modal';
import ButtonCustom from "../game/ButtonCustom";
import appImages from '../../themes/appImages/appImages';
import fontSizes from '../../themes/constants/fontSizes';
import { isInternetConnected } from '../../helper/networkUtils';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import { popupType } from '../../themes/constants/gameConstants';
import { ValidationConstants } from '../../themes/constants/validationConstants';



const Settings = (props) => {
    const dispatch = useDispatch()
    const { userId } = useSelector((state) => state.authenticationReducer);
    const toggleSound = useSelector((state) => state.homeReducer?.toggleSound);
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)

    }, [])
    const fragment = (
        <>
            <div className='main_pop pops' style={{ padding: '0px 0px' }}>
                <div className='img_y alert_icons'>
                    <img src={commonImages.alert} />
                </div>
                <div>
                    <div className='form-group success_d leave-pop'>
                        <div style={{ marginTop: 20, }}>
                            <ul>
                                <li style={{
                                    fontSize: 18,
                                    marginBottom: 8,
                                    color: 'white'
                                }}>We will permanently delete your account and all profile informtion from Disparity Trap. Once deleted, your data cannot be retrieved.</li>
                                <li style={{
                                    fontSize: 18,
                                    color: 'white'
                                }}>Are you sure you want to delete your account?</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const successBtn = (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: -20 }}>
            <ButtonCustom
                resize={"cover"}
                outerContainer={{ height: 49, alignSelf: 'center' }}
                textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                style={{ height: 49, width: 170, borderRadius: 4 }}
                imgBg={appImages.buttonBg}
                title={'Yes'}
                className="send_new"
                onPress={() => {
                    if (isInternetConnected()) {
                        dispatch(deleteAccountAction(userId));
                    }
                    else {
                        ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
                    }
                }}
            />
            <ButtonCustom
                resize={"cover"}
                outerContainer={{ height: 49, alignSelf: 'center' }}
                textColor={{ fontSize: fontSizes.f50, padding: 2 }}
                style={{ height: 49, width: 170, borderRadius: 4 }}
                imgBg={appImages.buttonBg}
                title={'No'}
                className="send_new"
                onPress={() => setShowModal(false)}
            />
        </div>
    )
    return (
        <div>
            <div className='background_image' style={{ paddingBottom: '20px' }}>
                <Header title='Settings' settings={false} redirect={true} />
                <div className='settings'>

                    <div className='settings_button' style={{ cursor: 'pointer' }} onClick={() => window.open(`${pdfURL}`, '_blank')}>
                        <h4>Game Rulebook</h4>
                        <img src={commonImages.help} />
                    </div>
                    <div className='settings_button' style={{ cursor: 'pointer' }} onClick={() => window.open(`${videoURL}`, '_blank')}>
                        <h4>Instruction Video</h4>
                        <img src={appImages.instructionVideo} />
                    </div>
                    <div className='settings_button' style={{ cursor: 'pointer' }} onClick={() => window.open(`${buyHardCopyURL}`, '_blank')}>
                        <h4>Buy Hard Copy Game</h4>
                        <img src={commonImages.hardCopy} />
                    </div>
                    <div className='settings_button'>
                        <Link to="/leaderboard">
                            <h4>Leaderboard</h4>
                            <img src={commonImages.leaderboard} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/pointsBreakdown">
                            <h4>Points Breakdown</h4>
                            <img src={appImages.pointsBreak2} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/profileview">
                            <h4>My Profile</h4>
                            <img src={appImages.myProfile} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/blockedusers">
                            <h4>Blocked Users</h4>
                            <img src={appImages.blockedUser} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/changepassword">
                            <h4>Change Password</h4>
                            <img src={commonImages.change} style={{
                                width: 69,
                                right: 24,
                                top: 11,
                            }} />
                        </Link>
                    </div>
                    <div className='settings_button slide_data'>
                        <h4>Sound On</h4>
                        <label className="switch">
                            <input
                                checked={toggleSound}
                                onClick={() => {
                                    const tempData = toggleSound ? false : true
                                    dispatch({
                                        type: ApiConstants.SOUND_ON_OFF,
                                        payload: tempData
                                    })
                                    tempData ? DataManager.setSound("on") : DataManager.setSound("off")
                                    tempData ? soundOn() : soundOff()
                                    tempData && backgroundMusicSound()
                                }}
                                type="checkbox"
                                value={toggleSound}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='settings_button'>
                        <Link to="/faq">
                            <h4>FAQ</h4>
                            <img src={commonImages.faq} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/privacy">
                            <h4>Privacy Policy</h4>
                            <img src={commonImages.pp} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/terms">
                            <h4>Terms and Conditions</h4>
                            <img src={commonImages.termsConditions} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/aboutus">
                            <h4>About Us</h4>
                            <img src={commonImages.aboutus} />
                        </Link>
                    </div>
                    <div className='settings_button'>
                        <Link to="/contactus">
                            <h4>Contact Us</h4>
                            <img src={commonImages.contact} />
                        </Link>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => dispatch(logoutAction(userId))} className='settings_button'>
                        {/* <Link to="/login"> */}
                        <h4>Logout</h4>
                        <img src={commonImages.logout} />
                        {/* </Link> */}
                    </div>
                    <div className='settings_button'>
                        <Link to="/package">
                            <h4>Yearly Package: $14.99</h4>
                            <img src={commonImages.package} />
                        </Link>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)} className='settings_button'>
                        <h4>Delete Account</h4>
                        <img src={appImages.deleteAcc} />
                    </div>
                </div>
            </div>
            {showModal &&
                <ModalPop
                    maskClosable={false}
                    content={fragment}
                    btnSuccess={successBtn}
                    btnCancel={true}
                    handleCancel={() => setShowModal(false)}
                    isVisible={showModal}
                    center='lobby_modal_reload'
                />
            }
        </div>
    )

}
export default Settings