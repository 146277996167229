import { navigate, reset } from "../Routes/navigationServices"
import { DataManager } from '../helper/dataManager';
import { ShowAlertMessage } from "./showAlertMessage";
import { popupType } from '../themes/constants/gameConstants';

var isAbleToLeave = true
var tradeTile = null
var isRollDiceEmit = false

var currentP1 = 1
var currentP2 = 1
var currentP3 = 1
var currentP4 = 1
var currentP5 = 1
var currentP6 = 1
var awardTileCount = 0
var cardType = null
var isIRoleDice = false

export const logout = (message) => {
    DataManager.removeData()
    navigate('login')
    ShowAlertMessage(message, popupType.error, 3000);
}

export const getLeaveStatus = () => {
    return isAbleToLeave
}

export const updateLeaveStatus = (data) => {
    isAbleToLeave = data
}

export const gameScreenDimentions = {
    height: (window.innerHeight > window.innerWidth) ? window.innerWidth : window.innerHeight,
    width: (window.innerHeight > window.innerWidth) ? window.innerHeight : window.innerWidth,
    gameBoardWidth: (window.innerHeight > window.innerWidth) ? (window.innerHeight * 0.70) : (window.innerWidth * 0.70),
    gameBoardHeight: (window.innerHeight > window.innerWidth) ? (window.innerHeight * 0.38) : (window.innerWidth * 0.38),
}

export const updateTradeTile = (data) => {
    tradeTile = data
}
export const getTradedTile = () => {
    return tradeTile
}

export const updateRollDiceEmit = (data) => {
    isRollDiceEmit = data
}
export const getRollDiceEmit = () => {
    return isRollDiceEmit
}


export const setCurrentP1 = (data) => {
    currentP1 = data
}
export const getCurrentP1 = () => {
    return currentP1
}

export const setCurrentP2 = (data) => {
    currentP2 = data
}
export const getCurrentP2 = () => {
    return currentP2
}

export const setCurrentP3 = (data) => {
    currentP3 = data
}
export const getCurrentP3 = () => {
    return currentP3
}

export const setCurrentP4 = (data) => {
    currentP4 = data
}
export const getCurrentP4 = () => {
    return currentP4
}

export const setCurrentP5 = (data) => {
    currentP5 = data
}
export const getCurrentP5 = () => {
    return currentP5
}

export const setCurrentP6 = (data) => {
    currentP6 = data
}
export const getCurrentP6 = () => {
    return currentP6
}

export const setAwardTileCount = (data) => {
    awardTileCount = data
}
export const getAwardTileCount = () => {
    return awardTileCount
}

export const setCardType = (data) => {
    cardType = data
}
export const getCardType = () => {
    return cardType
}

export const setIsIRoleDice = (data) => {
    isIRoleDice = data
}
export const getIsIRoleDice = () => {
    return isIRoleDice
}