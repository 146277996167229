import { combineReducers } from 'redux';
import authenticationReducer from './authReducer';
import homeReducer from './homeReducer';
import globalReducer from './globalReducer';
import gameReducer from './gameReducer';
import contactReducer from './contactReducer';

const rootReducer = combineReducers({
    authenticationReducer,
    homeReducer,
    globalReducer,
    gameReducer,
    contactReducer
});

export default rootReducer;