import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Header from '../../common/header';
import { isInternetConnected } from '../../helper/networkUtils';
import { commonImages } from '../../themes/appImage';
import { ValidationConstants } from '../../themes/constants/validationConstants'
import { createRoomAction } from '../../redux/actions/homeActions/homeActions';
import { DataManager } from '../../helper/dataManager';
import { popupType } from '../../themes/constants/gameConstants';
import { ShowAlertMessage } from '../../helper/showAlertMessage';
import GameRuleModal from '../../common/ruleModal';
import ModalPop from '../../common/Modal';


const CreateRoom = (props) => {

  const dispatch = useDispatch();

  const [gameMode, setGameMode] = useState('Auto');
  const [roomType, setRoomType] = useState('Public');
  const [isModalRuleVisible, setIsModalRuleVisible] = useState(false);
  const [playersCount, setPlayersCount] = useState(2);
  const [on, setOn] = useState(true);

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    DataManager.removeLeaveGameStatus()
  }, [])

  const _onCreateRoomClick = () => {

    if (isInternetConnected()) {
      dispatch(createRoomAction({
        type: roomType,
        mode: gameMode,
        maxPlayers: playersCount,
      }));
    } else {
      ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
    }


  }

  // console.log(props, 'props--------------')
  return (
    <div>
      <div className='background_image'>
        <Header title='Create Room' settings={true} />
        <div className='info_container'>
          <img onClick={() => {
            setIsModalRuleVisible(true)
          }} height={50} width={50} style={{ cursor: 'pointer' }} alt='' src={commonImages.info} />
        </div>
        <div className='new_data_p'>
          <div className='create-room_img'>
            <div className='Auto text'>
              <span className='label_mode'>Assign Players</span>
              <span className='button-switch special_case'>
                <button type='submit' onClick={() => setGameMode('Auto')} className={gameMode === 'Auto' ? 'button_switch items one' : 'button_switch items'}>Auto</button>
                <button type='submit' onClick={() => setGameMode('Manual')} className={gameMode === 'Manual' ? 'button_switch itemlast one' : 'button_switch itemlast'}>Manual</button>
              </span>
            </div>
            <div className='Auto text'>
              <span className='label_mode'>Room Type</span>
              <span className='button-switch special_case'>
                <button type='submit' onClick={() => setRoomType('Public')} className={roomType === 'Public' ? 'button_switch items one' : 'button_switch items'}>Public</button>
                <button type='submit' onClick={() => setRoomType('Private')} className={roomType === 'Private' ? 'button_switch one' : 'button_switch'}>Private</button></span>
            </div>
            <div className='Auto text lat-child_d'>
              <span className='label_mode'>Number of Players</span>
              <span>
                <button className='add_button'>
                  <img onClick={() => setPlayersCount((prevState) => prevState > 2 ? prevState - 1 : prevState)} src={commonImages.add} />
                </button>
                <input type='text' disabled value={playersCount} style={{ cursor: 'default', color: '#fff' }} className='form-control control_new'></input>
                <button className='add_button'>
                  <img onClick={() => setPlayersCount((prevState) => prevState < 6 ? prevState + 1 : prevState)} src={commonImages.add1} />
                </button>
              </span>
            </div>


          </div>
        </div>
        <div className='data_new create-room-new data_i'>
          <div>
            <img onClick={() => {
              if (isInternetConnected()) {
                if (on) {
                  setOn(false);
                  _onCreateRoomClick();
                  setTimeout(() => {
                    setOn(true)
                  }, 1000)
                }
              } else {
                ShowAlertMessage(ValidationConstants.internetCheck, popupType.error, 3000);
              }
            }} src={commonImages.createroom} alt="" />
          </div>


        </div>
      </div>
      {isModalRuleVisible && <ModalPop
        className='center'
        content={<GameRuleModal createRoom />}
        btnSuccess={false}
        btnCancel={false}
        handleSuccess={() => setIsModalRuleVisible(false)}
        handleCancel={() => setIsModalRuleVisible(false)}
        isVisible={isModalRuleVisible}

      />
      }

    </div>
  )

}
export default CreateRoom