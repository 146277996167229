import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CARDS } from '../../../utils/jsonData';
import { gameScreenDimentions } from '../../../helper/globalFunctions';

const CardComponent = (props) => {

    return (
        <>{props?.code && <AnimatePresence>
            <motion.div style={props?.style} animate={props?.animate}
                layoutId={props?.selfKey}
                key={props?.selfKey}
            >
                <motion.img
                    style={{
                        width: gameScreenDimentions.gameBoardWidth * 0.162,
                        height: gameScreenDimentions.gameBoardWidth * 0.212,
                        ...props.imgStyle
                    }}
                    src={CARDS?.[props?.code].cardNo} />
            </motion.div>
        </AnimatePresence>}
        </>
    );
}

export default CardComponent;